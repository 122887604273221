import { Group, Paper, Select, Stack, Text, Title } from '@mantine/core';
import { styles } from '../../../../../../settings/tabs/styles';
import { OrganizationSettings } from '../../../../../../../Atoms/settings';
import { useEffect, useState } from 'react';
import { logger } from '../../../../../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../../../helpers/notifications';
import { saveOrganizationSettings } from '../../../../../../../api/settings';
import { useParams } from 'react-router';

interface Props {
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
	title: string;
	settingKey: string;
	options: { value: string; label: string }[];
	description: string;
}

export default function SettingSelectPreference({
	organizationSettings,
	setOrganizationSettings,
	title,
	settingKey,
	options,
	description,
}: Props) {
	const { classes } = styles();
	const { organizationID } = useParams();
	const settingValue = organizationSettings[settingKey];
	const [value, setValue] = useState<string>(settingValue || '');

	useEffect(() => {
		setValue(settingValue);
	}, [settingValue]);

	const handleChange = async (value: string) => {
		try {
			setValue(value);
			const payload = [
				{
					key: settingKey,
					value,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				message: `You have successfully changed the ${title.toLowerCase()} setting.`,
			});
		} catch (error) {
			logger('error', `Error changing ${title.toLowerCase()} setting`, error);
			showFailureNotification({
				message: `Failed to change the ${title.toLowerCase()} setting. Please try again.`,
			});
		}
	};

	return (
		<>
			<Title order={4}>{title}</Title>
			<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
				<Group position='apart' noWrap>
					<Text mb={4} size={'sm'}>
						{description}
					</Text>
					<Select
						withinPortal
						rightSection={null}
						value={value}
						onChange={(val) => handleChange(val)}
						data={options}
						maw={150}
						miw={150}
						radius={'lg'}
						size='sm'
						style={{
							flex: 1,
						}}
					/>
				</Group>
			</Paper>
		</>
	);
}
