import React, { useState } from 'react';
import { Button, Group, Text, Stack, TextInput } from '@mantine/core';
import {
	faKeyboard,
	faCalendar,
	faClock,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from '@mantine/form';
import {
	createBot,
	getMeeting,
	getMeetingAnalytics,
	updateMeeting,
} from '../../../api/api';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUser, currentUserMeetings } from '../../../Atoms/userAtoms';
import { DateTimePicker } from '@mantine/dates';
import { IMeeting } from '../../../interfaces/meeting';
import {
	meetingsSharedWithMe as SharedMeetingAtom,
	myMeetings as MyMeetingAtom,
} from '../../../Atoms/meetingAtom';
import { logger } from '../../../helpers/logger';
import CustomCalendarDay from '../../CustomCalendarDay';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';

interface Props {
	formType: string;
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddRecallBotForm = ({ formType, setOpened }: Props) => {
	const [loading, setLoading] = useState(false);
	const setMyMeetings = useSetRecoilState(MyMeetingAtom);
	const setMeetingsSharedWithMe = useSetRecoilState(SharedMeetingAtom);
	const [allMeetings, setAllMeetings] = useRecoilState(currentUserMeetings);
	const user = useRecoilValue(currentUser);

	const [viewingDeadlineValue, setViewingDeadlineValue] = useState(null);
	const [meetingTimeValue, setMeetingTimeValue] = useState(null);

	const addBotForm = useForm({
		initialValues: {
			meetingUrl: '',
			meetingTitle: '',
		},
		validate: {
			meetingUrl: (value) =>
				value && value.trim().length > 0 ? null : 'Meeting link is required.',
			meetingTitle: (value) => {
				if (!value || value.trim().length === 0)
					return 'Meeting title is required.';
				if (value.trim().length < 3)
					return 'Meeting title must be at least 3 characters long.';
				if (value.trim().length > 100)
					return 'Meeting title must not exceed 100 characters.';
				return null;
			},
		},
	});

	const sendMeetingLink = async (values) => {
		try {
			if (formType === 'future' && !meetingTimeValue) {
				showFailureNotification({
					title: 'Invalid Form',
					message: 'Please select a meeting time for future meetings.',
				});
				return;
			}

			logger('info', 'Sending meeting link', values);

			let joinAt;
			if (formType === 'future') {
				joinAt = meetingTimeValue.toISOString();
			}

			const { meetingUrl, meetingTitle } = values;
			setLoading(true);
			const response = await createBot({
				meetingTitle,
				meetingUrl,
				organizationID: user.currentOrganizationID,
				joinAt,
			});
			logger('info', 'Meeting link sent', response);

			// get new meetings list which should include new meeting just created
			// meetings are created when the createBot() fires
			const newMeetingData = await getMeeting(
				response?.data?.data?.meetingID,
				user?.currentOrganizationID
			);
			const meetingAnalytics = await getMeetingAnalytics(
				response?.data?.data?.meetingID,
				user?.currentOrganizationID
			);
			const views = meetingAnalytics?.data?.data?.users?.length
				? meetingAnalytics?.data?.data?.users?.reduce((prev, cur) => {
						if (cur.viewed) return prev + Number(cur.viewed);
						return prev;
				  }, 0)
				: 0;

			let updatedMeetingRes;
			if (viewingDeadlineValue) {
				// if viewing deadline added, then add to meeting
				const viewingDeadlineISOString = viewingDeadlineValue.toISOString();
				updatedMeetingRes = await updateMeeting(
					{ viewingDeadline: viewingDeadlineISOString },
					newMeetingData?.data?.data.id,
					user?.currentOrganizationID
				);
			}

			const newMeeting = {
				...(updatedMeetingRes || newMeetingData)?.data?.data,
				views,
			};

			logger('info', 'New meeting', newMeeting);
			const newMeetings = [newMeeting, ...allMeetings];
			const myMeetings: IMeeting[] = newMeetings.filter(
				(meeting: IMeeting) => meeting.author?.id === user.id
			);
			const meetingsSharedWithMe: IMeeting[] = newMeetings.filter(
				(meeting: IMeeting) => meeting.author?.id !== user.id
			);
			setMyMeetings(myMeetings);
			setMeetingsSharedWithMe(meetingsSharedWithMe);
			setAllMeetings(newMeetings);

			showSuccessNotification({
				message:
					'Reelay added to meeting. You should see it join in a few seconds.',
			});
			setOpened(false);
			addBotForm.reset();
			// send meeting link
		} catch (err) {
			logger('error', 'Error creating meeting', err);
			// handle error
			showFailureNotification({
				title: 'Reelay was not able to join meeting.',
				message: `Make sure you are using a proper meeting link with the accepted apps listed.`,
			});
		} finally {
			// reset state
			setLoading(false);
		}
	};

	return (
		<form onSubmit={addBotForm.onSubmit((values) => sendMeetingLink(values))}>
			<Stack spacing={'xl'}>
				<Text size={12} color={'reelay-secondary-dark'}>
					{formType === 'live'
						? 'Enter your live meeting link in the field below.'
						: 'Enter your upcoming meeting link in the field below.'}
				</Text>
				<Group align={'flex-start'} noWrap>
					<TextInput
						{...addBotForm.getInputProps('meetingTitle')}
						placeholder='Enter Meeting Title'
						description={'Required'}
						required
						inputWrapperOrder={['label', 'input', 'error', 'description']}
						style={{
							flex: 2,
						}}
					/>
					<DateTimePicker
						valueFormat={'MM/DD/YYYY h:mm A'}
						value={viewingDeadlineValue}
						onChange={setViewingDeadlineValue}
						placeholder='Enter Viewing Deadline'
						description='Optional'
						icon={<FontAwesomeIcon icon={faCalendar} />}
						inputWrapperOrder={['label', 'input', 'description', 'error']}
						maw={300}
						style={{
							flex: 3,
						}}
						renderDay={(date) => <CustomCalendarDay date={date} />}
						onPointerEnterCapture={undefined}
						onPointerLeaveCapture={undefined}
					/>
				</Group>
				{formType === 'future' && (
					<DateTimePicker
						valueFormat={'MM/DD/YYYY h:mm A'}
						value={meetingTimeValue}
						onChange={setMeetingTimeValue}
						placeholder='Enter Meeting Time'
						description='Pick the time you want your reelay bot to join at.'
						icon={<FontAwesomeIcon icon={faClock} />}
						inputWrapperOrder={['label', 'input', 'description', 'error']}
						maw={300}
						renderDay={(date) => <CustomCalendarDay date={date} />}
						required
						error={
							formType === 'future' && !meetingTimeValue
								? 'Meeting time is required for future meetings'
								: null
						}
						onPointerEnterCapture={undefined}
						onPointerLeaveCapture={undefined}
					/>
				)}
				{formType === 'future' ? (
					<Group align={'start'} grow noWrap>
						<Group noWrap align={'start'}>
							<TextInput
								{...addBotForm.getInputProps('meetingUrl')}
								icon={<FontAwesomeIcon icon={faKeyboard} />}
								description={'Accepts Zoom, Google Meet, and Microsoft Teams.'}
								placeholder='Enter Meeting Link'
								inputWrapperOrder={['label', 'input', 'description', 'error']}
								withAsterisk
							/>
							<Button
								type={'submit'}
								disabled={
									!addBotForm.isValid() ||
									(formType === 'future' && !meetingTimeValue)
								}
								loading={loading}
							>
								Join
							</Button>{' '}
						</Group>
					</Group>
				) : (
					<Group noWrap align={'start'}>
						<TextInput
							{...addBotForm.getInputProps('meetingUrl')}
							icon={<FontAwesomeIcon icon={faKeyboard} />}
							description={'Accepts Zoom, Google Meet, and Microsoft Teams.'}
							placeholder='Enter Meeting Link'
							inputWrapperOrder={['label', 'input', 'description', 'error']}
							withAsterisk
						/>
						<Button
							type={'submit'}
							disabled={
								!addBotForm.isValid() ||
								(formType === 'future' && !meetingTimeValue)
							}
							loading={loading}
						>
							Join
						</Button>{' '}
					</Group>
				)}
			</Stack>
		</form>
	);
};

export default AddRecallBotForm;
