import React, { useState } from 'react';
import {
	Table,
	Group,
	Text,
	Checkbox,
	ActionIcon,
	UnstyledButton,
	Center,
	rem,
	Paper,
} from '@mantine/core';
import {
	IconChevronDown,
	IconChevronUp,
	IconSelector,
} from '@tabler/icons-react';
import { VideoClip } from '../../../Atoms/clips';
import { Link } from 'react-router-dom';
import { previousPageURL } from '../../../Atoms/meetingAtom';
import { useSetRecoilState } from 'recoil';
import { DateTime } from 'luxon';
import { ClipCardMenu } from './ClipCardMenu';
import {
	convertMillisecondsToHHMMSS,
	formatTimestamp,
} from '../../../_utils/time';
import { MILLISECONDS_PER_SECOND } from '../../../components/constants';

interface ClipTableProps {
	clips: VideoClip[];
	setClips: React.Dispatch<React.SetStateAction<VideoClip[]>>;
	search: string;
	clipCount: number;
	rowsPerPage: number;
	fetchingClips: boolean;
	changeSort: (value: string) => void;
	segmentValueProp: string;
	openShareModal: (clip: VideoClip) => void;
}

interface ThProps {
	children: React.ReactNode;
	reversed: boolean;
	sorted: boolean;
	onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
	const Icon = sorted
		? reversed
			? IconChevronUp
			: IconChevronDown
		: IconSelector;
	return (
		<th>
			<UnstyledButton onClick={onSort} style={{ width: '100%' }}>
				<Group position='apart' noWrap>
					<Text fw={500} fz='sm'>
						{children}
					</Text>
					<Center>
						<Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
					</Center>
				</Group>
			</UnstyledButton>
		</th>
	);
}

export default function ClipsTable({
	clips,
	setClips,
	search,
	clipCount,
	rowsPerPage,
	fetchingClips,
	changeSort,
	segmentValueProp,
	openShareModal,
}: ClipTableProps) {
	const [selection, setSelection] = useState<string[]>([]);
	const [sortBy, setSortBy] = useState<string | null>(null);
	const [reverseSortDirection, setReverseSortDirection] = useState(false);
	const setPreviousURL = useSetRecoilState(previousPageURL);

	const toggleRow = (id: string) =>
		setSelection((current) =>
			current.includes(id)
				? current.filter((item) => item !== id)
				: [...current, id]
		);

	const toggleAll = () =>
		setSelection((current) =>
			current.length === clips.length ? [] : clips.map((clip) => clip.id)
		);

	const setSorting = (field: string) => {
		const reversed = field === sortBy ? !reverseSortDirection : false;
		setReverseSortDirection(reversed);
		setSortBy(field);
		changeSort(field);
	};

	const rows = clips.map((clip) => {
		const selected = selection.includes(clip.id);
		const { title, createdAt, owningUser } = clip;
		const date = DateTime.fromMillis(+createdAt).toFormat('MM/dd/yyyy');
		const createdBy = `${owningUser?.firstName} ${owningUser?.lastName}`;
		const duration = formatTimestamp(clip.endTime - clip.startTime);

		return (
			<tr key={clip.id}>
				<td>
					<Checkbox
						checked={selected}
						onChange={() => toggleRow(clip.id)}
						transitionDuration={0}
					/>
				</td>
				<td style={{ width: '30%' }}>
					<Link
						to={`/clips/${clip.id}`}
						onClick={() => setPreviousURL(location.pathname)}
						style={{ color: 'black', textDecoration: 'none' }}
					>
						<Text weight={600}>{title}</Text>
					</Link>
				</td>
				<td
					style={{
						width: '15%',
					}}
				>
					<Text>{createdBy}</Text>
				</td>
				<td
					style={{
						width: '10%',
					}}
				>
					<Text>{date}</Text>
				</td>
				<td
					style={{
						width: '10%',
					}}
				>
					<Text>{duration}</Text>
				</td>
				<td>
					<Text lineClamp={1}>{clip.summary}</Text>
				</td>
				<td>
					<ClipCardMenu clip={clip} />
				</td>
			</tr>
		);
	});

	return (
		<Paper radius='md' shadow='md' withBorder>
			<Table striped highlightOnHover captionSide='bottom'>
				<thead>
					<tr>
						<th>
							<Checkbox
								onChange={toggleAll}
								checked={selection.length === clips.length && clips.length > 0}
								indeterminate={
									selection.length > 0 && selection.length !== clips.length
								}
								transitionDuration={0}
							/>
						</th>
						<Th
							sorted={sortBy === 'title'}
							reversed={reverseSortDirection}
							onSort={() => setSorting('title')}
						>
							Title
						</Th>
						<Th
							sorted={sortBy === 'createdBy'}
							reversed={reverseSortDirection}
							onSort={() => setSorting('createdBy')}
						>
							Created By
						</Th>
						<Th
							sorted={sortBy === 'createdAt'}
							reversed={reverseSortDirection}
							onSort={() => setSorting('createdAt')}
						>
							Date
						</Th>
						<Th
							sorted={sortBy === 'duration'}
							reversed={reverseSortDirection}
							onSort={() => setSorting('duration')}
						>
							Duration
						</Th>
						<th>Summary</th>
						<th />
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		</Paper>
	);
}
