import React, { useEffect, useRef, useState } from 'react';
import {
	ActionIcon,
	Button,
	Center,
	Group,
	Loader,
	Paper,
	Popover,
	SegmentedControl,
	Stack,
	Table,
	Text,
	UnstyledButton,
	createStyles,
	Pagination,
	Checkbox,
	Tooltip,
	Menu,
	CopyButton,
	Indicator,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPencil,
	faTrash as faTrashLight,
	faEllipsisVertical,
	faCopy,
	faLink,
	faFileDoc,
	faCheck,
	faRotateRight,
	faSort,
	faSortUp,
	faSortDown,
	faCheckCircle,
	faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import {
	destroyMeeting,
	searchMeetings,
	getAllOrganizations,
	getQuery,
	updateMeeting,
} from '../../api/api';
import { useRecoilState, useRecoilValue } from 'recoil';
import { organizations as organizationsAtom } from '../../Atoms/organizationAtom';
import { StatusBadge } from '../Badges/StatusBadge';
import { currentUser, currentUserMeetings } from '../../Atoms/userAtoms';
import {
	filterMeetingsByStatus,
	getMeetingHost,
	meetingDetailsReady,
} from '../../helpers/meetings';
import { logger } from '../../helpers/logger';
import { keys } from '@mantine/utils';
import { capitalizeFirstLetter } from '../../_utils/handy-functions';
import {
	calculateSeconds,
	createMeetingTableData,
	formatTimeStampIntoHHMMSS,
	MeetingTableProps,
} from './helper';
import { useMediaQuery } from 'react-responsive';
import { MeetingSearch } from '../MeetingSearch';
import { encode } from 'js-base64';
import { NavigateParams } from '../Meetings/Meetings';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import TableRowSkeleton from '../Skeletons/TableRowSkeleton';
import BulkActionRow from '../BulkActionRow/BulkActionRow';
import { IMeeting } from '../../interfaces/meeting';
import { modals } from '@mantine/modals';
import { useDisclosure } from '@mantine/hooks';
import ResolveMissingDetailsModal from './components/ResolveMissingDetailsModal';

export const details = [
	{ key: 'summary', label: 'Summary' },
	{ key: 'longSummary', label: 'Long Summary' },
	{ key: 'highlights', label: 'Highlights' },
	{ key: 'actions', label: 'Actions' },
	{ key: 'questions', label: 'Questions' },
	{ key: 'chapters', label: 'Chapters' },
];

const useStyles = createStyles((theme) => ({
	th: {
		padding: `${theme.spacing.xs} ${theme.spacing.md}`,
		width: '100%',
		height: 40,
		'&:hover': {
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[6]
					: theme.colors.gray[0],
		},
		cursor: 'pointer',
	},
	icon: {
		width: 21,
		height: 21,
		borderRadius: 21,
	},
	rowSelected: {
		backgroundColor:
			theme.colorScheme === 'dark'
				? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
				: theme.colors[theme.primaryColor][0],
	},
	bulkActionIcon: {
		cursor: 'pointer',
	},
	disabledIcon: {
		opacity: 0.5 /* Decrease opacity to visually mute the icon */,
		pointerEvents: 'none' /* Disable pointer events to prevent interaction */,
	},
}));

type Props = {
	viewingOrganization?: boolean;
	isAdminTable?: boolean;
	segmentValue?: string;
	skip?: number;
	search?: string;
	order?: string;
};
export function SupportMeetingsTable({
	isAdminTable = false,
	segmentValue,
	skip,
	search,
	order = '-auditTime',
}: Props) {
	let currentOrder = order;
	if (!currentOrder) {
		currentOrder = '-auditTime';
	}
	const { classes, theme, cx } = useStyles();
	const { organizationID } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = useState('');
	const [meetings, setMeetings] = useRecoilState(currentUserMeetings);
	const user = useRecoilValue(currentUser);
	const [loading, setLoading] = useState(true);
	const [organizations, setOrganizations] = useRecoilState(organizationsAtom);
	const [currentData, setCurrentData] = useState(
		createMeetingTableData(
			filterMeetingsByStatus(meetings, segmentValue),
			organizations
		)
	);
	const isLaptopOrSmaller = useMediaQuery({
		query: `(max-height: ${theme.breakpoints.md}px)`,
	});
	const ITEMS_PER_PAGE = isLaptopOrSmaller ? 5 : 10;
	// pagination
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [meetingCount, setMeetingCount] = useState(0);
	// const data = createMeetingTableData(
	//   filterMeetingsByStatus(meetings, value),
	//   organizations
	// );
	const viewport = useRef<HTMLDivElement>(null);
	const [currentSegmentValue, setSegmentValue] = useState('');
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	const [isInitialized, setInitialized] = useState(false);
	// table selection
	const [selection, setSelection] = useState([]);
	const [opened, setOpened] = useState(false);
	const [currentSearchValue, setCurrentSearchValue] = useState('');
	const [
		resolveModalOpened,
		{ open: openResolveModal, close: closeResolveModal },
	] = useDisclosure(false);

	const toggleRow = (id: string) =>
		setSelection((current) =>
			current.includes(id)
				? current.filter((item) => item !== id)
				: [...current, id]
		);

	const toggleAll = () =>
		setSelection((current) =>
			current.length === data.length ? [] : data.map((item) => item.id)
		);

	useEffect(() => {
		const loadPage = async () => {
			await refresh();
			setInitialized(true);
		};
		loadPage();
	}, []);

	useEffect(() => {
		const supportChannel = window?.pusher?.subscribe('reelay-support-users');
		const handleAuditRefresh = async (data: any) => {
			showSuccessNotification({
				message: `New meeting ${data?.meeting?.name} has arrived. Audit data has been refreshed.`,
			});
			await refresh();
		};

		supportChannel.bind('audit:refresh', handleAuditRefresh);

		return () => {
			supportChannel.unbind('audit:refresh', handleAuditRefresh);
		};
	}, []);

	const [reverseSortDirection, setReverseSortDirection] = useState(false);

	const changeSegmentValue = (value: string) => {
		navigateTo({
			segment: value,
			search: encode(search),
		});
	};

	const changePage = (value: number) => {
		navigateTo({
			segment: currentSegmentValue,
			skip: (value > 0 ? value - 1 : 0) * limit,
			limit,
			search: encode(search),
		});
	};

	const handleSearch = (value: string) => {
		return navigateTo({
			// segment: currentSegmentValue,
			segment: segmentValue,
			search: encode(value),
		});
	};

	const navigateTo = (params: NavigateParams) => {
		const { segment, skip, limit, order, search } = params;
		const query = getQuery({ skip, limit, order, search });
		const pathname = isAdminTable
			? '/admin/meetings'
			: `/admin/organizations/edit/${organizationID}`;
		const uri = `${pathname}/${segment !== 'live' ? segment : ''}?${query}`;
		return navigate(uri);
	};

	useEffect(() => {
		if (!isInitialized) return;
		refresh();
	}, [segmentValue, skip, search, order]);

	const refresh = async () => {
		const currentSkip = (page - 1) * limit;

		if (segmentValue !== currentSegmentValue) {
			setLoading(true);
			// changeSegmentValue(segmentValue);
			// changePage(Math.floor(skip / limit));
			setSegmentValue(segmentValue);
			setPage(Math.floor(skip / limit) + 1);

			await getMeetings({
				skip,
				limit,
				order,
				search,
			});
			scrollToTop();
		} else if (skip !== currentSkip) {
			await getMeetings({
				skip,
				limit,
				order,
				search,
			});
			scrollToTop();
		} else if (currentSearchValue !== search) {
			setCurrentSearchValue(search);
			await getMeetings({
				skip: 0,
				search,
			});
			scrollToTop();
		} else {
			await getMeetings({
				skip,
				limit,
				order,
				search,
			});
			scrollToTop();
		}
	};

	const scrollToTop = () => {
		if (viewport?.current) {
			viewport.current.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const getMeetings = async (params?: any) => {
		setLoading(true);
		const { searchValue = '', skip = 0 } = params || {};

		setPage(Math.floor(skip / limit) + 1);

		const filter = {
			status: undefined,
			isExpired: undefined,
		};

		if (segmentValue === 'live') {
			filter.status = 'published';
			filter.isExpired = false;
		} else if (segmentValue === 'scheduled') {
			filter.status = 'scheduled,created,planned';
			currentOrder = 'startAt';
		} else if (segmentValue === 'review') {
			filter.status = 'review,audit,processing,draft,pending';
		} else if (segmentValue === 'audit') {
			filter.status = 'audit';
		} else if (segmentValue === 'draft') {
			filter.status = 'draft';
		}
		const query = {
			organizationID: isAdminTable ? undefined : organizationID,
			...filter,
			search,
			limit: params.limit || 10,
			offset: isNaN(params.skip) ? 0 : Number(params.skip),
			order: currentOrder,
		};
		const response = await searchMeetings(query);
		const { count, data } = response?.data || {};
		if (count !== undefined) setTotal(count);
		if (data !== undefined)
			setData(createMeetingTableData(data, organizations));
		setLoading(false);
	};

	const [sortBy, setSortBy] = useState<keyof MeetingTableProps | null>(null);

	const getAllOrgs = async () => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const organizations = await getAllOrganizations();
		logger('info', 'Retrieved organizations', organizations);
		const res = await getAllOrganizations();
		setOrganizations(res.data.data);
	};

	useEffect(() => {
		getAllOrgs();
	}, []);

	const handleDestroyMeeting = async (
		meetingID: string,
		meetingName: string
	) => {
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const deleteReelay = await destroyMeeting(meetingID, organizationID);
			await refresh();
			showSuccessNotification({
				message: `Meeting ${meetingName} was successfully deleted.`,
			});
		} catch (err: any) {
			logger('error', 'Error deleting meeting', err);
			showFailureNotification({
				message: 'Error deleting meeting. Please try again.',
			});
		}
	};

	const handleBulkAction = async (action: string) => {
		showFailureNotification({
			message: 'This bulk feature is not implemented yet.',
		});
	};

	const bulkActionDeleteMeetings = async () => {
		try {
			const deletedMeetings = await Promise.allSettled(
				selection.map((id) => destroyMeeting(id, user.organizationID))
			);
			setSelection([]);
			setOpened(false);
			await refresh();
			showSuccessNotification({
				message: `Meetings were successfully deleted.`,
			});
		} catch (err) {
			logger('error', 'Error deleting meeting', err);
			showFailureNotification({
				message: 'Error deleting meeting. Please try again.',
			});
		}
	};

	function sortData(
		data: MeetingTableProps[],
		payload: {
			sortBy: keyof MeetingTableProps | null;
			reversed: boolean;
			search: string;
		}
	) {
		const { sortBy } = payload;

		if (!sortBy) {
			return filterData(data, payload.search);
		}

		const sortedData = [...data].sort((a, b) => {
			if (sortBy === 'auditTime' || sortBy === 'reviewTime') {
				const secondsA = calculateSeconds(a[sortBy]);
				const secondsB = calculateSeconds(b[sortBy]);
				return payload.reversed ? secondsB - secondsA : secondsA - secondsB;
			} else {
				const aSortBy = String(a[sortBy]);
				const bSortBy = String(b[sortBy]);
				if (payload.reversed) {
					return bSortBy.localeCompare(aSortBy);
				}

				return aSortBy.localeCompare(bSortBy);
			}
		});
		const result = filterData(sortedData, payload.search);
		return result;
	}

	function filterData(data: MeetingTableProps[], search: string) {
		const query = search.toLowerCase().trim();
		return data.filter((item) => {
			return keys(data[0]).some((key) => {
				return String(item[key])?.toLowerCase().includes(query);
			});
		});
	}

	const changeSort = (value: string) => {
		// setPage(value);
		navigateTo({
			segment: currentSegmentValue,
			skip,
			limit,
			order: value,
			search: encode(search),
		});
	};

	const setSorting = (field: keyof MeetingTableProps) => {
		const reversed = field === sortBy ? !reverseSortDirection : false;
		setReverseSortDirection(reversed);
		setSortBy(field);
		// %2B is "+" in URL encoding, and "-" is "%2D
		const order = `${reversed ? '-' : '%2B'}${field}`;
		changeSort(order);
	};

	const handleUpdateMeeting = async (meeting: MeetingTableProps) => {
		const { id, organizationID, recapOnly } = meeting;
		try {
			const res = await updateMeeting(
				{
					recapOnly: !recapOnly,
				},
				id,
				organizationID
			);
			const updatedMeeting = res?.data?.data;
			const newData = data.map((item) => {
				if (item.id === id)
					return {
						...item,
						...updatedMeeting,
					};
				return item;
			});
			const result = createMeetingTableData(newData, organizations);
			setData(newData);
			// setData(createMeetingTableData(newData, organizations));
			showSuccessNotification({
				message: `Meeting recap set to ${updatedMeeting.recapOnly}.`,
			});
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to update the meeting. Please give it another try.',
			});
			logger('error', 'error updating meeting:', {
				meetingID: id,
				organizationID,
				meetingDetails: {
					recapOnly: !recapOnly,
				},
				error,
			});
		}
	};

	interface ThProps {
		children: React.ReactNode;
		reversed: boolean;
		sorted: boolean;
		onSort(): void;
	}
	function Th({ children, reversed, sorted, onSort }: ThProps) {
		const { classes } = useStyles();
		return (
			<th
				onClick={onSort}
				className={classes.th}
				style={{ width: `${children === 'Meeting Name' ? '300px' : 'auto'}` }}
			>
				<Group position='apart' noWrap>
					{children}
					<UnstyledButton>
						<Center className={classes.icon}>
							<FontAwesomeIcon
								icon={sorted ? (reversed ? faSortUp : faSortDown) : faSort}
								size='xs'
							/>
						</Center>
					</UnstyledButton>
				</Group>
			</th>
		);
	}

	const ths = (
		<tr>
			<th style={{ width: '50px' }}>
				<Checkbox
					onChange={toggleAll}
					checked={selection.length === data.length}
					indeterminate={
						selection.length > 0 && selection.length !== data.length
					}
					transitionDuration={0}
				/>
			</th>
			<Th
				sorted={sortBy === 'name'}
				reversed={reverseSortDirection}
				onSort={() => setSorting('name')}
			>
				Meeting Name
			</Th>
			<th>Organization Name</th>
			<Th
				sorted={sortBy === 'host'}
				reversed={reverseSortDirection}
				onSort={() => setSorting('host')}
			>
				Created By
			</Th>
			{segmentValue !== 'scheduled' && (
				<>
					{segmentValue === 'review' ? (
						<Th
							sorted={sortBy === 'reviewTime'}
							reversed={reverseSortDirection}
							onSort={() => setSorting('reviewTime')}
						>
							Time in Review
						</Th>
					) : (
						<Th
							sorted={sortBy === 'auditTime'}
							reversed={reverseSortDirection}
							onSort={() => setSorting('auditTime')}
						>
							Audit Time
						</Th>
					)}
				</>
			)}
			<Th
				sorted={sortBy === 'viewingDeadline'}
				reversed={reverseSortDirection}
				onSort={() => setSorting('viewingDeadline')}
			>
				Viewing Deadline
			</Th>
			{segmentValue === 'scheduled' && (
				<Th
					sorted={sortBy === 'formattedJoinAt'}
					reversed={reverseSortDirection}
					onSort={() => setSorting('formattedJoinAt')}
				>
					Scheduled
				</Th>
			)}
			{segmentValue !== 'audit' && segmentValue !== 'review' && (
				<Th
					sorted={sortBy === 'formattedCreatedAt'}
					reversed={reverseSortDirection}
					onSort={() => setSorting('formattedCreatedAt')}
				>
					Created At
				</Th>
			)}
			{segmentValue === 'all' && (
				<Th
					sorted={sortBy === 'status'}
					reversed={reverseSortDirection}
					onSort={() => setSorting('status')}
				>
					Status
				</Th>
			)}
			<Th
				sorted={sortBy === 'platform'}
				reversed={reverseSortDirection}
				onSort={() => setSorting('platform')}
			>
				Platform
			</Th>
			<th>Audit Reason</th>
			<th />
		</tr>
	);

	const calculateTotalPages = (reelays: number, reelaysPerPage: number) => {
		const reelaysPerPageNumber = +reelaysPerPage;
		let fullPages = Math.floor(reelays / reelaysPerPageNumber);
		if (reelays % reelaysPerPageNumber > 0) fullPages++;
		return fullPages;
	};

	const currentMeetingsInTable = () => {
		let lastMeetingNum = activePage * ITEMS_PER_PAGE;
		const firstMeetingNum = lastMeetingNum - ITEMS_PER_PAGE + 1;
		if (lastMeetingNum >= meetingCount) lastMeetingNum = meetingCount;
		return `${firstMeetingNum}-${lastMeetingNum}`;
	};

	const isDetailReady = (processingResults, detailKey) =>
		processingResults && processingResults[detailKey] === 'success';

	const handlePublish = async (meeting: IMeeting, confirmed = false) => {
		try {
			const { id, name, publishedAt, processingResults } = meeting;
			const readyToPublish = meetingDetailsReady(processingResults);

			if (!readyToPublish && !confirmed) {
				const missingDetails = Object.keys(processingResults).reduce(
					(prev, cur) => {
						if (processingResults[cur] !== 'success') {
							prev.push(cur);
						}
						return prev;
					},
					[]
				);
				modals.openConfirmModal({
					title: `Publish ${name}`,
					children: (
						<Text size='sm'>
							Are you sure you want to publish this meeting? These meeting
							details are missing:{' '}
							<Text span fw={500}>
								{missingDetails.join(', ')}
							</Text>
							.
						</Text>
					),
					labels: { confirm: 'Publish', cancel: 'Cancel' },
					onCancel: () => console.log('cancel'),
					onConfirm: () => handlePublish(meeting, true),
				});
				return;
			}

			const res = await updateMeeting(
				{
					status: publishedAt ? 'planned' : 'published',
					auditedByUserID: user.id,
				},
				id,
				organizationID
			);
			showSuccessNotification({
				message: `Meeting ${name} was successfully published.`,
			});
			refresh();
		} catch (err) {
			logger('error', 'publishing meeting', err);
			showFailureNotification({
				message: `Error publishing meeting ${name}. Please try again.`,
			});
		}
	};

	const [meetingDetails, setMeetingDetails] = useState<string[]>([]);
	const [processingStatus, setProcessingStatus] = useState<{
		[key: string]: boolean;
	}>({});
	const [currentKey, setCurrentKey] = useState<string | null>('');
	const [resolvingMeeting, setResolvingMeeting] = useState<IMeeting | null>(
		null
	);

	const regenerateMissingDetails = async (
		meeting: IMeeting,
		processingResults: { [key: string]: string }
	) => {
		if (!processingResults) {
			showFailureNotification({
				message: 'No processing results found for this meeting.',
			});
			return;
		}
		const keys = Object.keys(processingResults);
		setMeetingDetails(keys);
		setResolvingMeeting(meeting);

		const initialStatus = Object.fromEntries(
			keys.map((key) => [key, processingResults[key] === 'success'])
		);
		setProcessingStatus(initialStatus);
		openResolveModal();
	};

	const TimestampText = ({ time }: { time: string | null }) => {
		const result = formatTimeStampIntoHHMMSS(time);
		return (
			<Text color={result.greaterThanTwoHours ? 'red-stroke' : ''}>
				{result.time}
			</Text>
		);
	};

	const rows = data.length ? (
		data.map((meeting: MeetingTableProps) => {
			const {
				id,
				organizer,
				author,
				friendlyID,
				name,
				platform,
				botMetadata,
				recapOnly,
				viewingDeadline,
				auditTime,
				formattedJoinAt,
				formattedCreatedAt,
				organizationName,
				host,
				notes,
				status,
				reviewTime,
				processingResults,
			} = meeting;

			const meetingHost = getMeetingHost(organizer, author);
			const selected = selection.includes(meeting.id);
			const meetingDetailsNotReady = !meetingDetailsReady(
				meeting.processingResults
			);
			return (
				<tr key={id} className={cx({ [classes.rowSelected]: selected })}>
					<td>
						<Checkbox
							checked={selection.includes(id)}
							onChange={() => toggleRow(id)}
							transitionDuration={0}
						/>
					</td>
					<td
						onClick={() => navigate(`/admin/edit/${friendlyID}`)}
						style={{ cursor: 'pointer' }}
					>
						<Text
							weight={600}
							style={{ transition: 'color 0.2s' }}
							onMouseEnter={(e) =>
								(e.currentTarget.style.color = theme.colors['reelay-purple'][6])
							}
							onMouseLeave={(e) => (e.currentTarget.style.color = '')}
						>
							{name}
						</Text>
					</td>
					<td>
						<Text>{organizationName}</Text>
					</td>
					<td>{meetingHost}</td>
					{segmentValue !== 'scheduled' && (
						<td>
							<TimestampText
								time={segmentValue === 'review' ? reviewTime : auditTime}
							/>
						</td>
					)}
					<td>{viewingDeadline}</td>
					{segmentValue === 'scheduled' && <td>{formattedJoinAt}</td>}
					{segmentValue !== 'audit' && segmentValue !== 'review' && (
						<td>{formattedCreatedAt}</td>
					)}
					{segmentValue === 'all' && (
						<td>
							<StatusBadge status={status} botStatus={botMetadata?.status} />
						</td>
					)}
					<td>
						{typeof platform === 'string'
							? capitalizeFirstLetter(platform)
							: 'N/A'}
					</td>
					<td>
						<Tooltip label={notes} withArrow>
							<Text lineClamp={1}>{notes}</Text>
						</Tooltip>
					</td>
					<td>
						<Group position={'right'} noWrap spacing={0}>
							{(status === 'audit' || status === 'draft') && (
								<ActionIcon
									m={10}
									variant='transparent'
									component={Link}
									to={`/admin/edit/${friendlyID}`}
								>
									<FontAwesomeIcon icon={faPencil} />
								</ActionIcon>
							)}
							<Popover withArrow shadow='md' width={200}>
								<Popover.Target>
									<ActionIcon m={10} variant='transparent'>
										<FontAwesomeIcon icon={faTrashLight} />
									</ActionIcon>
								</Popover.Target>
								<Popover.Dropdown>
									<Text size='sm'>
										Are you sure you want to permanently delete this Meeting?
									</Text>
									<Button
										fullWidth
										onClick={() => handleDestroyMeeting(id, name)}
										color='red'
										uppercase
										mt={'md'}
									>
										Delete
									</Button>
								</Popover.Dropdown>
							</Popover>
							<Menu shadow='md' width={200}>
								<Menu.Target>
									{meetingDetailsNotReady ? (
										<Indicator
											styles={{ indicator: { padding: 2 } }}
											color='red'
											size={15}
											label='!'
										>
											<ActionIcon variant='transparent'>
												<FontAwesomeIcon icon={faEllipsisVertical} />
											</ActionIcon>
										</Indicator>
									) : (
										<ActionIcon variant='transparent'>
											<FontAwesomeIcon icon={faEllipsisVertical} />
										</ActionIcon>
									)}
								</Menu.Target>

								<Menu.Dropdown>
									<CopyButton value={friendlyID} timeout={2000}>
										{({ copy }) => (
											<Menu.Item
												icon={<FontAwesomeIcon icon={faCopy} />}
												onClick={(e) => {
													e.stopPropagation();
													showSuccessNotification({
														message: 'Friendly ID copied to clipboard!',
													});
													copy();
												}}
											>
												Copy Friendly ID
											</Menu.Item>
										)}
									</CopyButton>
									<CopyButton
										value={`${window.location.origin}/meetings/${friendlyID}`}
										timeout={2000}
									>
										{({ copy }) => (
											<Menu.Item
												icon={<FontAwesomeIcon icon={faLink} />}
												onClick={(e) => {
													e.stopPropagation();
													showSuccessNotification({
														message: 'Meeting link copied to clipboard!',
													});
													copy();
												}}
											>
												Copy Meeting Link
											</Menu.Item>
										)}
									</CopyButton>
									<Menu.Item
										icon={<FontAwesomeIcon icon={faFileDoc} />}
										onClick={(e) => {
											e.stopPropagation();
											handleUpdateMeeting(meeting);
										}}
									>
										{recapOnly ? 'Set to Full Meeting' : 'Set to Recap Only'}
									</Menu.Item>

									<Menu.Label>Details</Menu.Label>
									{details.map(({ key, label }) => {
										const isReady = isDetailReady(processingResults, key);
										return (
											<Menu.Item
												key={key}
												icon={
													<FontAwesomeIcon
														icon={isReady ? faCheckCircle : faTimesCircle}
														color={isReady ? 'green' : 'red'}
													/>
												}
											>
												{label}
											</Menu.Item>
										);
									})}
									<Menu.Item
										icon={<FontAwesomeIcon icon={faRotateRight} />}
										onClick={(e) => {
											e.stopPropagation();

											regenerateMissingDetails(meeting, processingResults);
										}}
									>
										Resolve
									</Menu.Item>
									<Menu.Item
										icon={<FontAwesomeIcon icon={faCheck} />}
										onClick={(e) => {
											e.stopPropagation();
											handlePublish(meeting);
										}}
									>
										Publish
									</Menu.Item>
								</Menu.Dropdown>
							</Menu>
						</Group>
					</td>
				</tr>
			);
		})
	) : (
		<Text w={200} m={'xl'} size='xs' color={'secondary-text'}>
			No Meetings Found
		</Text>
	);

	return (
		// eslint-disable-next-line no-nested-ternary
		loading ? (
			<Stack align={'center'} mt={'xl'}>
				<Loader variant='bars' />
				<Text>Loading...</Text>
			</Stack>
		) : (
			<>
				<ResolveMissingDetailsModal
					opened={resolveModalOpened}
					close={closeResolveModal}
					keys={meetingDetails}
					meeting={resolvingMeeting}
					processingStatus={processingStatus}
					setProcessingStatus={setProcessingStatus}
					currentKey={currentKey}
					setCurrentKey={setCurrentKey}
					publishMeeting={handlePublish}
				/>
				<Group position={'right'} align={'center'} mb={'lg'}>
					<Group
						w={'100%'}
						mt={10}
						// maw={1408}
						noWrap
						position={'apart'}
						align={'center'}
					>
						<MeetingSearch
							searchValue={searchValue}
							handleSearch={handleSearch}
						/>
						<BulkActionRow
							selection={selection}
							// handleBulkAction={handleBulkAction}
							bulkActionDelete={bulkActionDeleteMeetings}
							opened={opened}
							setOpened={setOpened}
							deleteConfirmationText='Are you sure you want to delete these meetings?'
						/>
						<Group position='center'>
							<SegmentedControl
								value={segmentValue}
								onChange={(val) => changeSegmentValue(val)}
								data={[
									{ label: 'Audit', value: 'audit' },
									{ label: 'Draft', value: 'draft' },
									{ label: 'Review', value: 'review' },
									{ label: 'Scheduled', value: 'scheduled' },
									{ label: 'All', value: 'all' },
								]}
							/>
						</Group>
					</Group>
				</Group>
				<Paper radius='md' shadow='md' withBorder>
					<Table captionSide='bottom' striped>
						<thead>{ths}</thead>
						<tbody>
							{loading
								? Array.from({ length: 20 }, (_, index) => (
										<TableRowSkeleton key={index} colSpan={11} />
								  ))
								: rows}
						</tbody>
					</Table>
				</Paper>
				<Group position={'apart'}>
					<Group p={'md'} align={'end'}>
						<Text
							mb={'10px'}
							color='secondary-text'
							align={'center'}
							size={12}
						>{`${currentMeetingsInTable()} of ${meetingCount}`}</Text>
					</Group>
					<Group position='right'>
						<Pagination
							withEdges
							value={page}
							onChange={changePage}
							total={Math.ceil(total / limit)}
						/>
						{total === 0 ? null : (
							<Text mt={0} color='secondary-text' align={'center'} size={12}>
								{`${skip}-${
									skip + limit > total ? total : skip + limit
								} of ${total}`}
							</Text>
						)}
					</Group>
				</Group>
			</>
		)
	);
}
