import {
	showSuccessNotification,
	showFailureNotification,
} from '../helpers/notifications';
import { logger } from '../helpers/logger';
import {
	generateClipSummary as apiGenerateClipSummary,
	updateVideoClip as apiUpdateVideoClip,
	destroyVideoClips,
} from '../api/clips';
import {
	updateCollectionContent,
	deleteCollectionContent,
} from '../api/collections';
import { VideoClip } from '../Atoms/clips';
import { Dispatch, SetStateAction } from 'react';

export const buildVideoClipsPlaybackUrl = (url, startTime, endTime) =>
	`${url}?asset_start_time=${startTime}&asset_end_time=${endTime}`;

export const handleRegenerateSummary = async (
	clip: VideoClip,
	organizationID: string,
	updateClip: (updatedClip: any) => void
) => {
	try {
		const updatedClip = await apiGenerateClipSummary(clip.id, organizationID);
		updateClip({
			...clip,
			...updatedClip,
		});
		showSuccessNotification({ message: 'Summary regenerated successfully.' });
		return updatedClip;
	} catch (error) {
		logger('error', 'Error regenerating summary', error);
		showFailureNotification({
			message: 'Failed to regenerate summary. Please try again.',
		});
		throw error;
	}
};

export const handleSaveClip = async ({
	clip,
	organizationID,
	title,
	summary,
	startTime,
	endTime,
	collectionIDs,
	updateClip,
	setClips,
}: {
	clip: VideoClip;
	organizationID: string;
	title: string;
	summary: string;
	collectionIDs: string[];
	startTime: number;
	endTime: number;
	updateClip: (updatedClip: VideoClip) => void;
	setClips?: Dispatch<SetStateAction<VideoClip[]>>;
}) => {
	try {
		// Update clip
		const savedClip = await apiUpdateVideoClip(
			clip.id,
			{ title, summary, startTime, endTime },
			organizationID
		);

		// Handle collection updates
		const currentCollections =
			clip.collectionContents?.map((cc: any) => cc.collectionID) || [];
		const collectionsToAdd = collectionIDs.filter(
			(id) => !currentCollections.includes(id)
		);
		const collectionsToRemove = currentCollections.filter(
			(id: string) => !collectionIDs.includes(id)
		);

		// Execute collection updates
		const updatePromises = [
			...collectionsToAdd.map((collectionID: string) =>
				updateCollectionContent(collectionID, [{ targetID: clip.id }])
			),
			...collectionsToRemove.map((collectionID: string) =>
				deleteCollectionContent(collectionID, clip.id)
			),
		];

		await Promise.all(updatePromises);

		const updatedClip = {
			...clip,
			...savedClip,
			collectionContents: collectionIDs.map((collectionID) => ({
				collectionID,
			})),
		};

		updateClip(updatedClip);
		if (setClips) {
			setClips((prevClips: VideoClip[]) =>
				prevClips.map((c: VideoClip) => (c.id === clip.id ? updatedClip : c))
			);
		}
		showSuccessNotification({ message: 'Clip updated successfully.' });
		return updatedClip;
	} catch (error) {
		logger('error', 'Error updating clip', error);
		showFailureNotification({
			message: 'Failed to update clip. Please try again.',
		});
		throw error;
	}
};

export const handleDeleteClip = async (
	clipId: string,
	organizationID: string,
	updateClips: (clipIds: string[]) => void
) => {
	try {
		await destroyVideoClips([clipId], organizationID);
		updateClips([clipId]);
		showSuccessNotification({ message: 'Clip successfully deleted.' });
	} catch (error) {
		logger('error', 'Error deleting clip', error);
		showFailureNotification({
			message: 'Failed to delete clip. Please try again.',
		});
		throw error;
	}
};
