import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Group,
	ScrollArea,
	Stack,
	Text,
	createStyles,
	useMantineTheme,
} from '@mantine/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	attendeesList,
	showAllAttendeesAtom,
} from '../../../../../Atoms/meetingAtom';
import UserList from '../../../../../components/UserList';
import useMediaQueries from '../../../../../customHooks/useMediaQueries';
import { deduplicateUsers } from '../../../../../helpers/users';

interface Props {
	inviteUser?: ({
		email,
		firstName,
		lastName,
	}: {
		email: string;
		firstName: string;
		lastName: string;
	}) => void;
}
export default function AttendeesTabContent({ inviteUser }: Props) {
	const theme = useMantineTheme();
	const attendees = useRecoilValue(attendeesList);
	const setShowAllAttendees = useSetRecoilState(showAllAttendeesAtom);
	const { widthOrHeightLessThanLG } = useMediaQueries();

	const showAllAttendees = () => {
		setShowAllAttendees(true);
	};

	const attendeesWithoutDuplicates = deduplicateUsers([...attendees]);

	return (
		<Stack
			h={'calc(100% - 16px)'}
			justify='flex-start'
			mt={'md'}
			spacing={widthOrHeightLessThanLG ? 'xs' : 'md'}
		>
			<Text
				fw={600}
				color='secondary-text'
				size={12}
			>{`In this meeting (${attendeesWithoutDuplicates.length})`}</Text>
			<ScrollArea
				// h={200}
				lh={'25px'}
				type='always'
				// viewportRef={viewportRef}
				offsetScrollbars
				scrollbarSize={10}
				// onScrollPositionChange={handleScroll}
				styles={{
					root: {
						flex: 2,
					},
					viewport: {
						overflowX: 'hidden',
						width: '100%',
						maxWidth: '100%',
						fontSize: 12,
						flex: 2,
					},
				}}
			>
				<UserList
					users={attendeesWithoutDuplicates}
					gridColSpan={12}
					emptyText={'No attendees found.'}
					isAttendees={true}
					hideIcons={true}
					inviteUser={inviteUser}
				/>
			</ScrollArea>
			<Group
				position='center'
				align='flex-start'
				spacing={'xs'}
				mb={widthOrHeightLessThanLG ? 0 : 32}
			>
				<Group
					spacing={'xs'}
					style={{ cursor: 'pointer' }}
					onClick={showAllAttendees}
				>
					<Text
						size={12}
						fw={500}
						color='primary-cta'
					>{`See All ${attendeesWithoutDuplicates.length} Attendees`}</Text>
					<FontAwesomeIcon
						color={theme.colors['primary-cta'][0]}
						icon={faChevronDown}
						size='lg'
						style={{
							position: 'relative',
							bottom: '2px',
						}}
					/>
				</Group>
			</Group>
		</Stack>
	);
}
