import React, { useEffect, useMemo, useState } from 'react';
import {
	Modal,
	Button,
	Text,
	Stack,
	createStyles,
	Group,
	Checkbox,
	Textarea,
	Select,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faShare,
	faUser,
	faPaperPlaneTop,
	faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons';
import { useForm } from '@mantine/form';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	currentOrganizationID,
	teamDataAtom,
	userOrganizationMembersMap,
} from '../../../Atoms/userAtoms';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import ModalTitle from '../../Titles/ModalTitle';
import { logger } from '../../../helpers/logger';
import { UsersMultiSelect } from '../../UsersMultiSelect';
import { ANYONE_WITH_LINK } from '../../../helpers/settings/settings.constants';
import SharePermissionSelect from '../../SharePermissionSelect/SharePermissionSelect';
import {
	organizationSettingSelector,
	sharingClipsDisabled,
} from '../../../Atoms/settings';
import { InvitedUser } from '../../../interfaces/user';
import { VideoClip, VideoClipUserLink } from '../../../Atoms/clips';
import { useShareClipModal } from '../../../customHooks/clips/useShareClipModal';

const useStyles = createStyles((theme) => ({
	shareButton: {
		height: 40,
		borderRadius: theme.radius.md,
		padding: '0 24px',
		backgroundColor: theme.fn.primaryColor(),
		color: theme.white,
		fontWeight: 500,
		'&:hover': {
			backgroundColor: theme.fn.primaryColor(),
		},
	},
}));

interface ShareWithOthersModalProps {
	opened: boolean;
	close: () => void;
	onShare: ({ emails, message, sharingOption }) => void;
	clip: VideoClip;
}

export function ShareWithOthersModal({
	opened,
	close,
	onShare,
	clip,
}: ShareWithOthersModalProps) {
	const { classes } = useStyles();
	const organizationID = useRecoilValue(currentOrganizationID);
	const teamData = useRecoilValue(teamDataAtom);
	const [notifyPeopleChecked, setNotifyPeopleChecked] = useState(false);
	const [message, setMessage] = useState('');
	const [sharingOption, setSharingOption] = useState(ANYONE_WITH_LINK);
	const [buttonLoading, setButtonLoading] = useState(false);
	const sharingDisabled = useRecoilValue(sharingClipsDisabled);
	const [data, setData] = useState<InvitedUser[]>([]);
	const orgUserMap = useRecoilValue(userOrganizationMembersMap);

	const { handleChangeSharingOption } = useShareClipModal(organizationID || '');

	const userListWithOwnerFirst = useMemo(() => {
		return [...(clip?.videoClipUserLinks || [])]
			.sort((a, b) => {
				if (clip.owningUserID === a.userID) return -1;
				if (clip.owningUserID === b.userID) return 1;
				return (
					new Date(a.createdAt || 0).getTime() -
					new Date(b.createdAt || 0).getTime()
				);
			})
			.map((link: VideoClipUserLink): any => {
				const user = orgUserMap.get(link.userID) || {};
				if (user && typeof user === 'object' && Object.keys(user).length > 0) {
					return {
						id: link.userID,
						email: user.email || '',
						firstName: user.firstName || '',
						lastName: user.lastName || '',
						userlink: link as any,
					};
				}

				return link;
			});
	}, [clip?.videoClipUserLinks, clip?.owningUserID, orgUserMap]);

	useEffect(() => {
		const userIDs = new Set(userListWithOwnerFirst.map((user) => user.id));
		const filteredTeamData = [...teamData].filter(
			(user) => !userIDs.has(user.id)
		);
		setData(filteredTeamData);
	}, [teamData, userListWithOwnerFirst]);

	const form = useForm({
		initialValues: {
			viewers: [],
		},
		validate: {
			viewers: (value) =>
				value.length ? null : 'At least one viewer is required.',
		},
	});

	const handleShare = async () => {
		if (form.values.viewers.length > 0) {
			try {
				setButtonLoading(true);
				await onShare({
					emails: form.values.viewers,
					message,
					sharingOption,
				});
				form.reset();
				close();
			} catch (err) {
				console.log('handleShare -> err', err);
				logger('error', 'error adding users to clip', err);
			} finally {
				setButtonLoading(false);
			}
		}
	};

	useEffect(() => {
		if (!opened) {
			form.reset();
			setMessage('');
			setSharingOption(ANYONE_WITH_LINK);
			setNotifyPeopleChecked(false);
		}
	}, [opened]);

	return (
		<Modal
			opened={opened}
			onClose={close}
			title={<ModalTitle text='Share Video Clip' />}
			size='md'
			radius='md'
			zIndex={10000}
		>
			<Stack spacing='md'>
				<UsersMultiSelect
					creatable={true}
					descriptionText={'Add Viewers'}
					variantType={'filled'}
					data={data}
					setData={setData}
					searchable={true}
					labelText={''}
					placeholder='Add a name, email or team.'
					name={'viewers'}
					form={form}
					icon={<FontAwesomeIcon icon={faUser} />}
				/>

				<Group position='apart' align='center'>
					<Checkbox
						checked={notifyPeopleChecked}
						onChange={(event) =>
							setNotifyPeopleChecked(event.currentTarget.checked)
						}
						label='Add a message'
					/>
				</Group>

				{notifyPeopleChecked && (
					<Textarea
						placeholder='Add a custom message to the notification email'
						minRows={3}
						value={message}
						onChange={(event) => setMessage(event.currentTarget.value)}
					/>
				)}

				<SharePermissionSelect
					sharingOption={sharingOption}
					// setSharingOption={setSharingOption}
					setSharingOption={(value) => {
						handleChangeSharingOption(value);
						setSharingOption(value);
					}}
					sharingDisabled={sharingDisabled}
				/>

				<Group position='right'>
					<Button variant='outline' onClick={close}>
						Skip
					</Button>
					<Button
						loading={buttonLoading}
						onClick={handleShare}
						className={classes.shareButton}
						leftIcon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
					>
						Share
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
