import React from 'react';
import { Group, Button, Text, useMantineTheme } from '@mantine/core';

const QuickFilters = ({
	activeFilter,
	setSelectedFilter,
}: {
	activeFilter: string;
	setSelectedFilter: (filter: string) => void;
}) => {
	const theme = useMantineTheme();

	const filters = [
		{ label: 'All', value: 'all' },
		{ label: 'Meetings', value: 'meetings' },
		{ label: 'Clips', value: 'clips' },
	];

	return (
		<Group spacing='md' align='center'>
			<Text size='sm' weight={500}>
				Quick Filters:
			</Text>
			<Group spacing='xs'>
				{filters.map((filter) => (
					<Button
						key={filter.value}
						onClick={() => setSelectedFilter(filter.value)}
						styles={{
							root: {
								backgroundColor:
									activeFilter === filter.value
										? theme.colors['reelay-purple'][0]
										: 'white',
								color:
									activeFilter === filter.value
										? 'white'
										: theme.colors['reelay-purple'][0],
								border: `1px solid ${theme.colors['reelay-purple'][0]}`,
								borderRadius: theme.radius.xl,
								padding: '8px 16px',
								fontWeight: 500,
								boxShadow: 'none',
								transition: 'none',
								'&:hover': {
									backgroundColor:
										activeFilter === filter.value
											? theme.colors['reelay-purple'][0]
											: 'white',
									color:
										activeFilter === filter.value
											? 'white'
											: theme.colors['reelay-purple'][0],
								},
							},
						}}
					>
						{filter.label}
					</Button>
				))}
			</Group>
		</Group>
	);
};

export default QuickFilters;
