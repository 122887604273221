import React, { useState } from 'react';
import { ActionIcon, Menu, Loader } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEllipsisVertical,
	faTrash,
	faEdit,
	faUserRobot,
	faFileInvoice,
	faBoxArchive,
	faPlus,
} from '@fortawesome/pro-regular-svg-icons';

import { modals } from '@mantine/modals';
import { Text } from '@mantine/core';
import { IMeeting } from '../../../interfaces/meeting';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUser, currentUserMeetings } from '../../../Atoms/userAtoms';
import {
	editScheduledMeetingInitialValues,
	editScheduledMeetingModalOpen,
	reloadMeetingsAtom,
} from '../../../Atoms/meetingAtom';
import { destroyMeeting, updateMeeting } from '../../../api/api';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import {
	addMeetingToCollectionModalOpenedAtom,
	itemBeingAddedToCollection,
	archiveCollectionAtom,
	SpecialCollections,
} from '../../../Atoms/collections';
import { isMeetingOwnerOrProjectManager } from '../../../helpers/auth';
import { BotStatus, BotCreationStatus } from '../../../interfaces/recallai';
import {
	removeBotFromCalendarEvent,
	scheduleBotForCalendarEvent,
} from '../../../api/calendar';
import {
	upcomingMeetings,
	updateMeetingLibraryToggleAtom,
} from '../../../Atoms/recallAI';
import { logger } from '../../../helpers/logger';
import { useCollections } from '../../../customHooks/useCollections';
import { useFlags } from 'flagsmith/react';
import { flagsmithFeatureFlags } from '../../constants';
import { useLocation } from 'react-router';
import { mutate } from 'swr';

interface MeetingCardMenuProps {
	meeting: IMeeting;
	searchValue?: string;
	handleGetMeetingsData?: ({ searchValue }: { searchValue: string }) => void;
}

export function MeetingCardMenu({
	meeting,
	searchValue,
	handleGetMeetingsData,
}: MeetingCardMenuProps) {
	const location = useLocation();
	const flags = useFlags([flagsmithFeatureFlags.meetingCollections]);
	const collectionsEnabled = flags.meeting_collections.enabled;
	const user = useRecoilValue(currentUser);
	const setEditScheduledMeetingModalOpen = useSetRecoilState(
		editScheduledMeetingModalOpen
	);
	const setEditScheduledMeetingInitialValues = useSetRecoilState(
		editScheduledMeetingInitialValues
	);
	const setMeetings = useSetRecoilState(currentUserMeetings);
	const setAddMeetingToCollectionModalOpened = useSetRecoilState(
		addMeetingToCollectionModalOpenedAtom
	);
	const setReloadMeetings = useSetRecoilState(reloadMeetingsAtom);
	const setMeetingToAdd = useSetRecoilState(itemBeingAddedToCollection);
	const archiveCollection = useRecoilValue(archiveCollectionAtom);
	const [upcomingMeetingsState, setUpcomingMeetings] =
		useRecoilState(upcomingMeetings);
	const setUpdateMeetingLibraryToggle = useSetRecoilState(
		updateMeetingLibraryToggleAtom
	);
	const {
		addContentToCollection,
		removeContentFromCollection,
		collection,
		setCollection,
	} = useCollections(false);
	const [menuLoading, setMenuLoading] = useState(false);

	const {
		id,
		friendlyID,
		organizationID,
		name,
		author,
		organizer,
		meetingDate,
		videoMetadata,
		summary,
		startAt,
		views,
		actionsCount,
		questionsCount,
		status,
		thumbnailTime,
		botMetadata,
		botCreationStatus,
		invitedUsers,
		platform,
		publishedAt,
		recapOnly,
		viewingDeadline,
		owningUserID,
	} = meeting;

	const isMeetingHostOrPM = isMeetingOwnerOrProjectManager(
		organizer,
		owningUserID,
		user.id,
		user.roles
	);
	const isScheduledMeeting = status === 'scheduled';
	const isArchived = archiveCollection?.content?.some((c) => c.id === id);
	const botNotCreated =
		botCreationStatus === BotCreationStatus.NOT_CREATED_RESTRICTED_DOMAIN;
	const botDontJoin =
		botMetadata?.status === BotStatus.BotDeleted || botNotCreated;
	const isCalendarEvent = Boolean(botMetadata?.recallEventID);
	const viewingArchived = collection?.name === SpecialCollections.Archived;
	const isCollectionOwner = collection?.owningUserID === user.id;

	const isCollections = location.pathname.includes('/collections');

	const handleDeleteMeeting = async () => {
		modals.openConfirmModal({
			title: `Delete ${name}`,
			children: (
				<Text size='sm'>
					Are you sure you want to delete this meeting? This action is
					irreversible.
				</Text>
			),
			labels: { confirm: 'Delete', cancel: 'Cancel' },
			onCancel: () => console.log('cancel'),
			onConfirm: () => deleteMeeting(),
		});
	};

	const deleteMeeting = async () => {
		try {
			const res = await destroyMeeting(id, organizationID);
			showSuccessNotification({
				message: `Meeting was successfully deleted.`,
			});
			// re-fetch the meetings list so the page is full and the pagination is correct.
			handleGetMeetingsData({ searchValue });
			setReloadMeetings((prev) => !prev);
		} catch (err) {
			logger('error', 'Error deleting meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully deleted. Please try the operation again.`,
			});
		}
	};

	const handleUpdateMeeting = () => {
		setEditScheduledMeetingModalOpen(true);
		setEditScheduledMeetingInitialValues({
			name: name,
			meetingLink: botMetadata?.meetingLink,
			joinAt: startAt || botMetadata?.joinAt,
			meetingID: id,
			botID: botMetadata?.id,
		});
	};

	const handleArchiveMeeting = async () => {
		try {
			setMenuLoading(true);
			await addContentToCollection(archiveCollection.id, [id]);
			if (setReloadMeetings) setReloadMeetings((prev: any) => !prev);
		} catch (err) {
			logger('error', 'Error archiving meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully archived. Please try the operation again.`,
			});
		} finally {
			setMenuLoading(false);
		}
	};

	const handleUnarchiveMeeting = async () => {
		try {
			setMenuLoading(true);
			removeContentFromCollection(archiveCollection, id, false);
			if (setReloadMeetings) setReloadMeetings((prev: any) => !prev);
			showSuccessNotification({
				message: `Meeting was successfully unarchived.`,
			});
		} catch (err) {
			logger('error', 'Error archiving meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully archived. Please try the operation again.`,
			});
		} finally {
			setMenuLoading(false);
		}
	};

	const handleRecapOnly = async () => {
		try {
			setMenuLoading(true);
			const updatedMeetingRes = await updateMeeting(
				{ recapOnly: !recapOnly },
				id,
				organizationID
			);
			const updatedMeeting = updatedMeetingRes?.data?.data;
			if (isCollections) {
				setCollection((prev) => ({
					...prev,
					content: prev.content.map((m) =>
						m.id === updatedMeeting?.id ? updatedMeeting : m
					),
				}));
			} else {
				setMeetings((prev) =>
					prev.map((m) =>
						m.id === updatedMeeting?.id
							? {
									...m,
									recapOnly: updatedMeeting?.recapOnly,
							  }
							: m
					)
				);
			}

			showSuccessNotification({
				message: `Meeting recap set to ${updatedMeeting?.recapOnly}.`,
			});
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to update the  Please try again.',
			});
			console.error('Error updating meeting:', error);
		} finally {
			setMenuLoading(false);
		}
	};

	const handleBotJoin = async () => {
		try {
			if (botNotCreated) {
				showFailureNotification({
					title: 'Meeting Contains Restricted Email Domain',
					message: `Sorry, the bot for this meeting has not been created because your organization has restricted Reelay from joining meetings with certain email domains. Please contact your administrator if you think this is an error.`,
				});
				return;
			}
			if (botDontJoin) {
				// add bot to calendar event
				const res = await scheduleBotForCalendarEvent(
					botMetadata?.recallEventID,
					id,
					organizationID
				);
				const newBotMetadata = res?.data?.data;
				setMeetings((prev) =>
					prev.map((m) => {
						if (m.id === id) {
							return {
								...m,
								botMetadata: newBotMetadata || m.botMetadata,
							};
						}
						return m;
					})
				);
				showSuccessNotification({
					message: `Bot was successfully scheduled for this `,
				});
			} else {
				const botRes = await removeBotFromCalendarEvent(
					botMetadata.recallEventID,
					id,
					organizationID,
					botMetadata.eventID
				);
				if (botRes.status === 200) {
					showSuccessNotification({
						message: 'Bot was successfully removed from this ',
					});
					const botMetadata = botRes?.data?.data?.botMetadata;
					setUpcomingMeetings((prev) =>
						prev.map((m) =>
							m.id === id
								? { ...m, botMetadata: botMetadata || m.botMetadata }
								: m
						)
					);
					setMeetings((prev) =>
						prev.map((m) =>
							m.id === id
								? { ...m, botMetadata: botMetadata || m.botMetadata }
								: m
						)
					);
					setTimeout(() => {
						setUpdateMeetingLibraryToggle((prev) => !prev);
					}, 1);
				}
			}
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to update the  Please give it another try.',
			});
			logger('error', 'error updating meeting:', error);
		} finally {
			setMenuLoading(false);
		}
	};

	const handleRemoveFromCollection = async () => {
		try {
			const res = await removeContentFromCollection(collection, id);
			if (res) {
				setCollection((prev) => ({
					...prev,
					content: prev.content.filter((c) => c.id !== id),
				}));
			}
		} catch (err) {
			logger('error', 'Error removing meeting from collection', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully removed from the collection. Please try the operation again.`,
			});
		}
	};

	return (
		<Menu shadow='md' width={200} position='bottom-end'>
			<Menu.Target>
				<ActionIcon size='xs' variant='transparent'>
					{menuLoading ? (
						<Loader size={'sm'} />
					) : (
						<FontAwesomeIcon icon={faEllipsisVertical} size='xl' />
					)}
				</ActionIcon>
			</Menu.Target>
			<Menu.Dropdown>
				{/* Section: On Collections Page */}
				{collectionsEnabled && isCollections && (
					<>
						{isCollectionOwner && !viewingArchived && (
							<Menu.Item
								onClick={handleRemoveFromCollection}
								icon={<FontAwesomeIcon icon={faTrash} />}
							>
								Remove from collection
							</Menu.Item>
						)}
						{collection.name === SpecialCollections.Archived &&
							!isScheduledMeeting && (
								<Menu.Item
									icon={<FontAwesomeIcon icon={faBoxArchive} />}
									onClick={() =>
										isArchived
											? handleUnarchiveMeeting()
											: handleArchiveMeeting()
									}
								>
									{isArchived ? 'Unarchive' : 'Archive'}
								</Menu.Item>
							)}
					</>
				)}

				{isScheduledMeeting ? (
					<>
						{isMeetingHostOrPM && (
							<>
								<Menu.Item
									icon={<FontAwesomeIcon icon={faUserRobot} />}
									onClick={handleBotJoin}
								>
									{botDontJoin ? 'Change to join' : "Change to don't join"}
								</Menu.Item>
								<Menu.Item
									icon={<FontAwesomeIcon icon={faTrash} />}
									onClick={handleDeleteMeeting}
								>
									Delete
								</Menu.Item>
								{!botDontJoin && (
									<Menu.Item
										icon={<FontAwesomeIcon icon={faFileInvoice} />}
										onClick={handleRecapOnly}
									>
										{recapOnly ? 'Change to recording' : 'Change to recap only'}
									</Menu.Item>
								)}
							</>
						)}
					</>
				) : (
					<>
						{!isScheduledMeeting && (
							<>
								{!isCollections && (
									<>
										<Menu.Item
											icon={<FontAwesomeIcon icon={faPlus} />}
											onClick={() => {
												setAddMeetingToCollectionModalOpened(true);
												setMeetingToAdd(meeting);
											}}
										>
											Add to a collection
										</Menu.Item>
										<Menu.Item
											icon={<FontAwesomeIcon icon={faBoxArchive} />}
											onClick={
												isArchived
													? handleUnarchiveMeeting
													: handleArchiveMeeting
											}
										>
											{isArchived ? 'Unarchive' : 'Archive'}
										</Menu.Item>
									</>
								)}
								{isMeetingHostOrPM && (
									<>
										<Menu.Item
											icon={<FontAwesomeIcon icon={faTrash} />}
											onClick={handleDeleteMeeting}
										>
											Delete
										</Menu.Item>
										{!botDontJoin && (
											<Menu.Item
												icon={<FontAwesomeIcon icon={faFileInvoice} />}
												onClick={handleRecapOnly}
											>
												{recapOnly
													? 'Change to recording'
													: 'Change to recap only'}
											</Menu.Item>
										)}
									</>
								)}
							</>
						)}
					</>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}
