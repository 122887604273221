import { DateTime } from 'luxon';

const SECONDS_IN_A_HOUR = 3600;
const SECONDS_IN_A_MINUTE = 60;
const HOURS_MINUTES_SECONDS_ARRAY_LENGTH = 3;
const MINUTES_SECONDS_ARRAY_LENGTH = 2;
const MAXIMUM_NUMBER_VALUE_FOR_A_TIME = 59;
const DAYS_IN_A_WEEK = 7;
const MONTHS_IN_A_YEAR = 12;
const START_INDEX_OF_TIMESTAMP_FOR_ISOSTRING = 11;
const END_INDEX_OF_TIMESTAMP_FOR_ISOSTRING = 19;
const GRAB_CORRECT_LENGTH = 3;

export const calculateTimeStamp = (timeInMilliSeconds: number | string) => {
	const timeInMilliSecondsNumber = Number(timeInMilliSeconds);

	if (isNaN(timeInMilliSecondsNumber)) return '00:00';
	const timestamp = new Date(timeInMilliSecondsNumber)
		.toISOString()
		.slice(
			START_INDEX_OF_TIMESTAMP_FOR_ISOSTRING,
			END_INDEX_OF_TIMESTAMP_FOR_ISOSTRING
		);
	if (timestamp?.substring(0, 2) === '00')
		return timestamp?.substring(GRAB_CORRECT_LENGTH);

	return timestamp;
};

export const convertSecondsToHHMMSS = (seconds: number): string => {
	const totalSeconds = Math.floor(seconds);
	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const secs = totalSeconds % 60;

	const padZero = (num: number): string => num.toString().padStart(2, '0');

	if (totalSeconds < 60) {
		// Duration is less than one minute: format as 00:SS
		return `00:${padZero(secs)}`;
	} else if (hours > 0) {
		// Duration is one hour or more: format as HH:MM:SS
		return `${padZero(hours)}:${padZero(minutes)}:${padZero(secs)}`;
	}
	// Duration is at least one minute but less than one hour: format as MM:SS
	return `${padZero(minutes)}:${padZero(secs)}`;
};

export const convertMillisecondsToHHMMSS = (ms) => {
	const seconds = ms / 1000;
	const roundedSeconds = Math.floor(seconds);
	const hours = Math.floor(roundedSeconds / SECONDS_IN_A_HOUR);
	const minutes = Math.floor(
		(roundedSeconds % SECONDS_IN_A_HOUR) / SECONDS_IN_A_MINUTE
	);
	const remainingSeconds = roundedSeconds % SECONDS_IN_A_MINUTE;

	if (hours > 0) {
		return `${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`;
	} else {
		return `${padZero(minutes)}:${padZero(remainingSeconds)}`;
	}
};

function padZero(num) {
	return num.toString().padStart(2, '0');
}

export const formatSecondsToMinutesAndSeconds = (seconds = 0) => {
	if (isNaN(seconds)) return '00:00';

	const MILLISECONDS_TO_SECONDS = 1000;

	const date = new Date(seconds * MILLISECONDS_TO_SECONDS);
	const hh = date.getUTCHours();
	const mm = date.getUTCMinutes();
	const ss = date.getUTCSeconds().toString().padStart(2, '0');
	if (hh) return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;

	// add a preceding zero because mm can sometimes just be '0'
	return `${mm.toString().padStart(2, '0')}:${ss}`;
};

export const convertTimeToSeconds = (time) => {
	const timeParts = time.split(':').map(Number);

	if (timeParts.length === HOURS_MINUTES_SECONDS_ARRAY_LENGTH) {
		const [hours, minutes, seconds] = timeParts;
		return hours * SECONDS_IN_A_HOUR + minutes * SECONDS_IN_A_MINUTE + seconds;
	} else if (timeParts.length === MINUTES_SECONDS_ARRAY_LENGTH) {
		const [minutes, seconds] = timeParts;
		return minutes * SECONDS_IN_A_MINUTE + seconds;
	} else {
		throw new Error('Invalid time format. Expected either hh:mm:ss or mm:ss.');
	}
};

export const validateTime = (time) => {
	const timeParts = time.length ? time.split(':').map(Number) : [0, 0];

	if (timeParts.length === HOURS_MINUTES_SECONDS_ARRAY_LENGTH) {
		const [hours, minutes, seconds] = timeParts;

		const validatedHours = hours.toString().padStart(2, '0');
		const validatedMinutes = minutes.toString().padStart(2, '0');
		const validatedSeconds =
			seconds > MAXIMUM_NUMBER_VALUE_FOR_A_TIME
				? '59'
				: seconds.toString().padStart(2, '0');

		if (minutes > MAXIMUM_NUMBER_VALUE_FOR_A_TIME) {
			return `${validatedHours}:59:${validatedSeconds}`;
		} else if (seconds > MAXIMUM_NUMBER_VALUE_FOR_A_TIME) {
			return `${validatedHours}:${validatedMinutes}:59`;
		}
		return time;
	} else if (timeParts.length === 2) {
		const [minutes, seconds] = timeParts;

		const validatedMinutes = minutes.toString().padStart(2, '0');
		const validatedSeconds =
			seconds > MAXIMUM_NUMBER_VALUE_FOR_A_TIME
				? '59'
				: seconds.toString().padStart(2, '0');

		if (minutes > MAXIMUM_NUMBER_VALUE_FOR_A_TIME) {
			return `59:${validatedSeconds}`;
		} else if (seconds > MAXIMUM_NUMBER_VALUE_FOR_A_TIME) {
			return `${validatedMinutes}:59`;
		}
		return time;
	} else {
		throw new Error('Invalid time format. Expected either hh:mm:ss or mm:ss.');
	}
};

export function formatRelativeTime(timestamp) {
	const now = DateTime.now();
	const createdAt = DateTime.fromISO(timestamp);

	const diff = now.diff(createdAt, ['days']).toObject();
	const daysAgo = Math.floor(diff.days);
	const monthsAgo = Math.floor(diff.months);

	if (daysAgo === 0) {
		return 'Today';
	} else if (daysAgo === 1) {
		return 'Yesterday';
	} else if (daysAgo < DAYS_IN_A_WEEK) {
		return `${daysAgo} days ago`;
	} else if (daysAgo >= DAYS_IN_A_WEEK) {
		const weeksAgo = Math.floor(daysAgo / DAYS_IN_A_WEEK);
		if (weeksAgo === 1) {
			return '1 week ago';
		} else {
			return `${weeksAgo} weeks ago`;
		}
	} else if (monthsAgo === 1) {
		return '1 month ago';
	} else if (monthsAgo < MONTHS_IN_A_YEAR) {
		return `${monthsAgo} months ago`;
	} else {
		const yearsAgo = Math.floor(monthsAgo / MONTHS_IN_A_YEAR);
		if (yearsAgo === 1) {
			return '1 year ago';
		} else {
			return `${yearsAgo} years ago`;
		}
	}
}

export function formatRelativeTimeUpToYesterday(timestamp) {
	const now = DateTime.now();
	const createdAt = DateTime.fromISO(timestamp);

	const diff = now.diff(createdAt, 'days').toObject();
	const daysAgo = Math.floor(diff.days);

	if (daysAgo === 0) {
		return 'Today';
	} else if (daysAgo === 1) {
		return 'Yesterday';
	} else {
		return createdAt.toFormat('MM/dd/yyyy');
	}
}

export const formatTimestamp = (timestamp: string | number): string => {
	// Convert number input (assumed to be in seconds) to time format
	if (typeof timestamp === 'number') {
		const hours = Math.floor(timestamp / 3600);
		const minutes = Math.floor((timestamp % 3600) / 60);
		const seconds = timestamp % 60;

		// Format time parts to include leading zeros
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
		const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

		// Determine correct format based on hours presence
		if (hours > 0) {
			const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
			return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		} else {
			return `${formattedMinutes}:${formattedSeconds}`;
		}
	}

	// Process string input
	const timeSplit = timestamp.split(':');

	// Format as hh:mm:ss if it includes hours
	if (timeSplit.length === 3) {
		return timestamp; // Already in hh:mm:ss format
	}

	// Format as mm:ss or hh:mm:ss based on the minute range
	if (timeSplit.length === 2) {
		const formattedMinutes =
			timeSplit[0].length === 1 ? `0${timeSplit[0]}` : timeSplit[0];
		const formattedSeconds =
			timeSplit[1].length === 1 ? `0${timeSplit[1]}` : timeSplit[1];
		return `${formattedMinutes}:${formattedSeconds}`; // Return as mm:ss
	}

	// Assume it is only seconds and format as 00:SS
	if (timeSplit.length === 1) {
		const formattedSeconds =
			timeSplit[0].length === 1 ? `0${timeSplit[0]}` : timeSplit[0];
		return `00:${formattedSeconds}`;
	}

	return timestamp; // Fallback, should not typically reach here
};

// Create a function to check if a date is today's date
export const isToday = (date: Date) => {
	const today = new Date();
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	);
};

export const convertEpochToDate = (epochTime, timeZone) => {
	const dateTime = DateTime.fromMillis(Number(epochTime)).setZone(timeZone);
	const formattedDateTime = dateTime.toFormat('M/d/yyyy');
	return formattedDateTime;
};

export const parseTimeToSeconds = (timeString: string): number => {
	const parts = timeString.split(':').map((part) => parseInt(part, 10));
	if (parts.some(isNaN)) return NaN;

	let seconds = 0;
	const hasHours = parts.length === 3;

	if (hasHours) {
		seconds += parts[0] * 3600; // hours
		seconds += parts[1] * 60; // minutes
		seconds += parts[2]; // seconds
	} else if (parts.length === 2) {
		seconds += parts[0] * 60; // minutes
		seconds += parts[1]; // seconds
	} else if (parts.length === 1) {
		seconds += parts[0]; // seconds
	}

	return seconds;
};

export const formatSecondsToTime = (seconds: number): string => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = Math.floor(seconds % 60);
	const parts = [];
	if (hours > 0) parts.push(hours.toString().padStart(2, '0'));
	parts.push(minutes.toString().padStart(2, '0'));
	parts.push(remainingSeconds.toString().padStart(2, '0'));
	return parts.join(':');
};
