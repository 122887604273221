import React, {
	useState,
	useRef,
	useMemo,
	useCallback,
	useEffect,
} from 'react';
import {
	ActionIcon,
	Group,
	ScrollArea,
	Stack,
	Text,
	createStyles,
	Button,
	Tooltip,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faVideo } from '@fortawesome/pro-regular-svg-icons';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentUser,
	userOrganizationMembers,
} from '../../../../../Atoms/userAtoms';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../helpers/notifications';
import { logger } from '../../../../../helpers/logger';
import type { IMeeting } from '../../../../../interfaces/meeting';
import { isMeetingOwnerOrProjectManager } from '../../../../../helpers/auth';
import { useDisclosure } from '@mantine/hooks';
import {
	meetingClipsAtom,
	showAllClipsAtom,
	currentMeeting,
	createClipModalOpenedAtom,
} from '../../../../../Atoms/meetingAtom';
import useMediaQueries from '../../../../../customHooks/useMediaQueries';
import { destroyVideoClips } from '../../../../../api/clips';
import { teamDataAtom } from '../../../../../Atoms/userAtoms';
import { organizationSettings } from '../../../../../Atoms/settings';
import { useForm } from '@mantine/form';
import { ClipQuickViewModal } from './components/ClipQuickViewModal';
import { ClipItem } from './components/ClipItem';
import { ShareClipModal } from './components/ShareClipModal';
import { EditClipModal } from './components/EditClipModal';
import { useGetCollections } from '../../../../../customHooks/collections/useGetCollections';
import { modals } from '@mantine/modals';
import { selectedClipAtom, VideoClip } from '../../../../../Atoms/clips';

const useStyles = createStyles((theme) => ({
	scrollArea: {
		height: '100%',
	},
	clipSection: {
		flex: 1,
		minHeight: 0,
	},
}));

interface Props {
	meeting: IMeeting;
}

export default function ClipsTabContent({ meeting }: Props) {
	const { classes, theme } = useStyles();
	const [
		quickViewModalOpened,
		{ open: openQuickViewModal, close: closeQuickViewModal },
	] = useDisclosure(false);
	const [
		deleteModalOpened,
		{ open: openDeleteModal, close: closeDeleteModal },
	] = useDisclosure(false);
	const user = useRecoilValue(currentUser);
	const orgUsers = useRecoilValue(userOrganizationMembers);
	const { organizationID, owningUserID, organizer } = meeting;
	const isMeetingHostOrPM = isMeetingOwnerOrProjectManager(
		organizer,
		owningUserID,
		user.id,
		user.roles
	);

	const [clips, setClips] = useRecoilState(meetingClipsAtom);
	const setShowAllClips = useSetRecoilState(showAllClipsAtom);
	const setCreateClipModalOpened = useSetRecoilState(createClipModalOpenedAtom);
	const { widthOrHeightLessThanLG } = useMediaQueries();

	const [selectedClip, setSelectedClip] = useRecoilState(selectedClipAtom);
	const videoRef = useRef<HTMLVideoElement>(null);
	const currentMeetingData = useRecoilValue(currentMeeting);

	const [isRegenerating, setIsRegenerating] = useState(false);
	const teamData = useRecoilValue(teamDataAtom);
	const { sharing: sharingEnabled } = useRecoilValue(organizationSettings);
	const [buttonLoading, setButtonLoading] = useState(false);

	const [shareModalOpened, { open: openShareModal, close: closeShareModal }] =
		useDisclosure(false);
	const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
		useDisclosure(false);
	// const isOwner = clip.owningUserID === currentUserId;

	const {
		collections,
		setCollections,
		error: fetchError,
		isLoading,
	} = useGetCollections(organizationID);

	useEffect(() => {
		const updatedClip = clips.find((clip) => clip.id === selectedClip?.id);
		if (updatedClip) setSelectedClip(updatedClip);
	}, [clips]);

	const form = useForm({
		initialValues: {
			viewers: [],
		},
		validate: {
			viewers: (value) =>
				value.length === 0 ? 'Please add at least one viewer' : null,
		},
	});

	const handleClipUpdate = (updatedClip: VideoClip | null) => {
		if (updatedClip) {
			// Update your state here based on parent context
			setClips((prev) =>
				prev.map((c) => (c.id === updatedClip.id ? updatedClip : c))
			);
			setSelectedClip(updatedClip);
		}
	};

	const removeClip = useCallback(
		async (clipId: string) => {
			modals.openConfirmModal({
				title: 'Delete Clip',
				children: (
					<Text size='sm'>
						Are you sure you want to delete this clip? This action cannot be
						undone.
					</Text>
				),
				labels: { confirm: 'Delete', cancel: 'Cancel' },
				confirmProps: { color: 'red' },
				onConfirm: async () => {
					try {
						const res = await destroyVideoClips([clipId], organizationID);
						const {
							data: { meetingVideoClipIDs },
						} = res;
						setClips((prevClips) =>
							prevClips.filter((clip) => !meetingVideoClipIDs.includes(clip.id))
						);
						showSuccessNotification({ message: 'Clip successfully deleted.' });
					} catch (error) {
						logger('error', 'Error deleting clip', error);
						showFailureNotification({
							message: 'Failed to delete clip. Please try again.',
						});
					}
				},
			});
		},
		[organizationID, setClips]
	);

	const addClip = useCallback(() => {
		setCreateClipModalOpened(true);
	}, [setCreateClipModalOpened]);

	const handlePlayClip = useCallback(
		(clip) => {
			setSelectedClip(clip);
			openEditModal();
		},
		[openEditModal]
	);

	const myClips = useMemo(
		() => clips.filter((clip) => clip.owningUserID === user.id),
		[clips, user.id]
	);
	const sharedClips = useMemo(
		() => clips.filter((clip) => clip.owningUserID !== user.id),
		[clips, user.id]
	);

	const handleShareClip = useCallback(
		(clip) => {
			setSelectedClip(clip);
			openShareModal();
		},
		[openShareModal]
	);

	const handleEditClip = useCallback(
		(clip) => {
			setSelectedClip(clip);
			openEditModal();
		},
		[openEditModal]
	);

	const onCopyLink = (clip) => {
		navigator.clipboard.writeText(`${window.location.origin}/clips/${clip.id}`);
		const url = new URL(`${window.location.origin}/clips/${clip.id}`);
		if (clip?.sharingToken?.active) {
			url.searchParams.append('vt', clip.sharingToken.token);
		}
		navigator.clipboard.writeText(url.toString());
		showSuccessNotification({ message: 'Link copied to clipboard.' });
	};

	const renderClipList = useCallback(
		(clipList, title) => (
			<Stack spacing='xs' className={classes.clipSection}>
				<Group noWrap position='apart'>
					<Group spacing={'xs'} noWrap>
						<FontAwesomeIcon
							icon={faVideo}
							color={theme.colors['secondary-text'][0]}
						/>
						<Text fw={600} color='secondary-text' size={14}>
							{`${title} (${clipList.length})`}
						</Text>
					</Group>
					{title === 'My Clips' && (
						<Tooltip label='Add clip' position='top'>
							<ActionIcon variant='transparent' onClick={addClip}>
								<FontAwesomeIcon icon={faPlus} />
							</ActionIcon>
						</Tooltip>
					)}
				</Group>
				<Stack spacing='xs'>
					{clipList.map((clip) => (
						<ClipItem
							key={clip.id}
							clip={clip}
							onPlay={handlePlayClip}
							onQuickView={() => {
								setSelectedClip(clip);
								openQuickViewModal();
							}}
							onShare={handleShareClip}
							onEdit={handleEditClip}
							onDelete={removeClip}
							currentUserId={user.id}
							onCopyLink={onCopyLink}
						/>
					))}
				</Stack>
			</Stack>
		),
		[
			classes.clipSection,
			classes.scrollArea,
			handlePlayClip,
			openQuickViewModal,
			openEditModal,
			removeClip,
			user.id,
			handleShareClip,
			handleEditClip,
		]
	);

	useEffect(() => {
		if (!quickViewModalOpened) setSelectedClip(null);
	}, [quickViewModalOpened]);

	useEffect(() => {
		if (!editModalOpened) setSelectedClip(null);
	}, [editModalOpened]);

	useEffect(() => {
		if (!shareModalOpened) setSelectedClip(null);
	}, [shareModalOpened]);

	return (
		<Stack
			h={'calc(100% - 16px)'}
			justify='flex-start'
			mt={'md'}
			spacing={widthOrHeightLessThanLG ? 'xs' : 'md'}
		>
			<Stack spacing='md' style={{ flex: 1, minHeight: 0 }}>
				{clips.length > 0 ? (
					<ScrollArea
						className={classes.scrollArea}
						scrollbarSize={10}
						offsetScrollbars
					>
						{renderClipList(myClips, 'My Clips')}
						{renderClipList(sharedClips, 'Shared Clips')}
					</ScrollArea>
				) : (
					<Stack align='center' spacing='md'>
						<Text fw={600} color='secondary-text' size={14}>
							No clips found for this meeting.
						</Text>
						<Button
							leftIcon={<FontAwesomeIcon icon={faPlus} />}
							onClick={addClip}
						>
							Create a clip
						</Button>
					</Stack>
				)}
			</Stack>

			<ClipQuickViewModal
				opened={quickViewModalOpened}
				onClose={closeQuickViewModal}
				clip={selectedClip}
				currentUser={user}
				orgUsers={orgUsers}
			/>

			<ShareClipModal
				opened={shareModalOpened}
				onClose={closeShareModal}
				selectedClip={selectedClip}
				onClipUpdate={handleClipUpdate}
				organizer={organizer}
				isClipOwner={selectedClip?.owningUserID === user.id}
			/>

			<EditClipModal
				opened={editModalOpened}
				onClose={closeEditModal}
				selectedClip={selectedClip}
				currentMeetingData={currentMeetingData}
				videoRef={videoRef}
				// isRegenerating={isRegenerating}
				// regenerateSummary={regenerateSummary}
				// onSave={handleSaveClip}
			/>
		</Stack>
	);
}
