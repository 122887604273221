import React from 'react';
import { Modal, Title, Stack, Tabs } from '@mantine/core';
import { DateTime } from 'luxon';
import { VideoPlayer } from '../../../../../ClipPage/components/video-player';
import { ClipInfo } from '../../../../../ClipPage/components/clip-info';
import { ClipSummary } from '../../../../../ClipPage/components/clip-summary';
import { buildVideoClipsPlaybackUrl } from '../../../../../../helpers/clips';

interface ClipQuickViewModalProps {
	opened: boolean;
	onClose: () => void;
	clip: any;
	currentUser: any;
	orgUsers: any[];
}

export function ClipQuickViewModal({
	opened,
	onClose,
	clip,
	currentUser,
	orgUsers,
}: ClipQuickViewModalProps) {
	if (!clip) return null;

	const {
		playbackURL,
		startTime,
		endTime,
		title,
		owningUserID,
		meetingName,
		meeting,
		summary,
	} = clip;

	const { meetingDate, createdAt: meetingCreatedAt } = meeting;

	const playbackUrlWithTimestamps = buildVideoClipsPlaybackUrl(
		playbackURL,
		startTime,
		endTime
	);

	const creatorUser = orgUsers.find((user) => user.id === owningUserID);
	const avatarUrl = creatorUser?.userAvatarURL || '';

	return (
		<Modal
			opened={opened}
			onClose={onClose}
			title={<Title order={3}>{title}</Title>}
			size='xl'
		>
			<Stack>
				<VideoPlayer src={playbackUrlWithTimestamps} />

				<Tabs defaultValue='meeting-info'>
					<Tabs.List>
						<Tabs.Tab value='meeting-info'>Meeting Info</Tabs.Tab>
						<Tabs.Tab value='clip-description'>Clip Description</Tabs.Tab>
					</Tabs.List>

					<Tabs.Panel value='meeting-info'>
						<ClipInfo
							title={title}
							creator={`${creatorUser?.firstName} ${creatorUser?.lastName}`}
							host={`${currentUser.firstName} ${currentUser.lastName}`}
							meetingTitle={meetingName}
							avatarUrl={avatarUrl}
							date={DateTime.fromMillis(
								Number(meetingDate || meetingCreatedAt)
							).toFormat('MM/dd/yyyy')}
						/>
					</Tabs.Panel>

					<Tabs.Panel value='clip-description'>
						<ClipSummary summary={summary} />
					</Tabs.Panel>
				</Tabs>
			</Stack>
		</Modal>
	);
}
