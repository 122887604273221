import React, { useState } from 'react';
import {
	Button,
	createStyles,
	Divider,
	Group,
	Paper,
	Popover,
	Stack,
	Text,
} from '@mantine/core';
import {
	currentUser,
	currentUserMeetings,
	isProjectManagerAtom,
} from '../../Atoms/userAtoms';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { updateMeeting } from '../../api/api';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { logger } from '../../helpers/logger';
import { attendeesCount } from '../../Atoms/meetingAtom';
import { IMeeting } from '../../interfaces/meeting';
import { DateTime } from 'luxon';
import { useMediaQuery } from 'react-responsive';
import useMediaQueries from '../../customHooks/useMediaQueries';

const useStyles = createStyles((theme) => ({
	analyticType: {
		color: theme.colors?.['secondary-text'],
		fontWeight: 400,
		fontSize: '12px',
	},
	analyticValue: {
		color: theme.fn.primaryColor(),
		fontWeight: 600,
		fontSize: '20px',
	},
}));

interface Props {
	backgroundColor?: string;
	meeting: IMeeting;
}

export const MeetingInfoBar = ({
	meeting,
	backgroundColor = 'white',
}: Props) => {
	const { classes, theme } = useStyles();
	const { widthOrHeightLessThanLG } = useMediaQueries();
	const {
		id,
		owningUserID,
		views,
		actionsCount,
		questionsCount,
		viewingDeadline,
		meetingDate,
	} = meeting;
	const user = useRecoilValue(currentUser);
	const widthLessThanLG = useMediaQuery({
		query: `(max-width: ${theme.breakpoints.lg})`,
	});
	const heightLessThanLG = useMediaQuery({
		query: `(max-height: ${theme.breakpoints.lg})`,
	});
	const isLessThanLarge = widthLessThanLG || heightLessThanLG;
	const [loading, setLoading] = useState<boolean>(false);
	const [opened, setOpened] = useState(false);
	const [viewingDeadlineValue, setViewingDeadlineValue] = useState(
		new Date(viewingDeadline)
	);
	const setMeetings = useSetRecoilState(currentUserMeetings);
	const numberOfAttendees = useRecoilValue(attendeesCount);
	const isMobile = useMediaQuery({
		query: `(max-width: ${theme.breakpoints.xs})`,
	});
	const labelFontSize = widthOrHeightLessThanLG
		? theme.fontSizes.xs
		: theme.fontSizes.md;

	const TextAndCount = ({ text, count }) => (
		<Text size={labelFontSize} color='primary-text'>
			{text}{' '}
			{
				<Text span color='primary-cta' fw={700} size={labelFontSize}>
					{count || 0}
				</Text>
			}
		</Text>
	);

	return (
		<>
			{isMobile ? (
				<Paper bg={backgroundColor} shadow='xs' radius='lg' py={'xs'} px={'md'}>
					<Group position={'apart'}>
						{meetingDate ? (
							<Stack spacing={0} align={'center'} justify={'center'}>
								<Text className={classes.analyticType}>Date</Text>
								<Text className={classes.analyticValue}>
									{DateTime.fromISO(meetingDate).toFormat('M/d')}
								</Text>
							</Stack>
						) : null}

						<Stack spacing={0} align={'center'} justify={'center'}>
							<Text className={classes.analyticType}>Views</Text>
							<Text className={classes.analyticValue}>{views || 0}</Text>
						</Stack>
						<Stack spacing={0} align={'center'} justify={'center'}>
							<Text className={classes.analyticType}>Actions</Text>
							<Text className={classes.analyticValue}>{actionsCount}</Text>
						</Stack>
						<Stack spacing={0} align={'center'} justify={'center'}>
							<Text className={classes.analyticType}>Questions</Text>
							<Text className={classes.analyticValue}>{questionsCount}</Text>
						</Stack>
						<Stack spacing={0} align={'center'} justify={'center'}>
							<Text className={classes.analyticType}>Attendees</Text>
							<Text className={classes.analyticValue}>{numberOfAttendees}</Text>
						</Stack>
					</Group>
				</Paper>
			) : (
				<Group
					spacing={isLessThanLarge ? 'xs' : 'lg'}
					position='center'
					mr={5}
					noWrap
				>
					<TextAndCount text={'Views'} count={views} />
					<Divider orientation='vertical' />
					<TextAndCount text={'Actions'} count={actionsCount} />
					<Divider orientation='vertical' />
					<TextAndCount text={'Questions'} count={questionsCount} />
					<Divider orientation='vertical' />
					<TextAndCount text={'Attendees'} count={numberOfAttendees} />
				</Group>
			)}
		</>
	);
};
