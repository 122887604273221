import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	Accordion,
	ActionIcon,
	Button,
	createStyles,
	FileButton,
	Group,
	List,
	Loader,
	Paper,
	Space,
	Stack,
	Switch,
	Text,
	Textarea,
	Title,
	Tooltip,
} from '@mantine/core';
import { useParams, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faX, faShieldCheck } from '@fortawesome/pro-solid-svg-icons';
import {
	faExternalLink,
	faPlus,
	faShieldExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { ReelayVideoPlayer } from '../../../../components/reelay_video_player';
import EditMeetingTextbox from '../../../../components/EditMeetingTextbox';
import TimelineBars from '../../../../components/TimelineBars';
import { InvitedUser } from '../../../../interfaces/user';
import { IAttachment, User } from '../../../../Atoms/userAtoms';
import ThumbnailSetter from './ThumbnailSetter';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	currentMeeting as MeetingAtom,
	currentMeetingChapters,
} from '../../../../Atoms/meetingAtom';
import {
	addAttachmentsToMeeting,
	getMeetingExternalUsers,
	getMeetingInvitedUsers,
	getMeetingPresentedUsers,
	inviteUsersToMeeting,
	removeAttachmentsFromMeeting,
	removeUsersFromAMeeting,
	updateMeeting,
} from '../../../../api/api';
import { logger } from '../../../../helpers/logger';
import { DateTime } from 'luxon';
import { useDisclosure } from '@mantine/hooks';
import { useFlags } from 'flagsmith/react';
import {
	recallAIBotDetails,
	recallAIBotEvent,
} from '../../../../Atoms/recallAI';
import AddViewersDialog from '../../../../components/aside/Dialogs/AddViewersDialog';
import BotDetailsTimelineBars from '../../../../components/TimelineBars/BotDetailsTimelineBars';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { OrganizationSettings } from '../../../../Atoms/settings';

const useStyles = createStyles((theme) => ({
	cardText: {
		fontSize: 12,
		color: theme.colors.primary[5],
	},
	title: {
		color: theme.colors?.['primary-text'],
		fontWeight: 600,
		fontSize: 12,
	},
	sectionHeader: {
		color: theme.colors?.['primary-text'],
		fontWeight: 600,
		fontSize: 16,
	},
	dateText: {
		color: theme.colors?.['primary-text'],
		fontSize: 12,
	},
	submitButton: {
		backgroundColor: theme.colors?.primary[8],
		// width:           '100%',
		// fontSize:        16,
	},
	deleteIcon: {
		'&:hover': {
			color: 'red',
		},
	},
	'&:hover': {
		backgroundColor: theme.colors?.primary[8],
	},
}));

interface Props {
	previewAttachment: (index: number) => void;
	createFilePreview: (attachment: IAttachment) => void;
	showVideoPlayer: boolean;
	showDetails: boolean;
	handleMenuSelect: (model: string, type: string) => void;
	currentUsers: InvitedUser[];
	setCurrentUsers: Dispatch<SetStateAction<InvitedUser[]>>;
	userSettings: {
		job_title?: string;
		bio?: string;
		meeting_minutes?: boolean;
		address?: object;
	};
	orgSettings: OrganizationSettings;
	orgUsers: User[];
}

export default function VideoPlayerAndMeetingDetailsColumn({
	previewAttachment,
	createFilePreview,
	showVideoPlayer,
	showDetails,
	currentUsers,
	setCurrentUsers,
	userSettings,
	orgSettings,
	orgUsers,
}: Props) {
	const { classes, theme } = useStyles();
	const { meetingID } = useParams();
	const playerRef = useRef<any>(null);
	const flags = useFlags(['audit_options']);
	const auditOptionsEnabled = flags?.audit_options?.enabled;
	const auditOptionsParsed = JSON.parse(flags?.audit_options?.value as any);
	const { audio_enabled } = !auditOptionsEnabled
		? {
			audio_enabled: false,
		}
		: auditOptionsParsed;
	const [currentMeeting, setCurrentMeeting] = useRecoilState(MeetingAtom);
	const {
		id,
		name,
		videoMetadata,
		botMetadata,
		createdAt,
		organizationID,
		owningUserID,
		recapOnly: initialRecapOnly,
		notes: initialNotes,
	} = currentMeeting;

	const [currentAttachments, setCurrentAttachments] = useState<IAttachment[]>(
		[]
	);
	const chapters = useRecoilValue(currentMeetingChapters);
	const botDetails = useRecoilValue(recallAIBotDetails);
	const botEvent = useRecoilValue(recallAIBotEvent);
	const [uploadingAttachment, setUploadingAttachment] = useState(false);
	const [currentExternalUsers, setCurrentExternalUsers] = useState([]);
	const [presentedUsers, setPresentedUsers] = useState([]);
	const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
	const [opened, { toggle, close }] = useDisclosure(false);
	const [recapOnly, setRecapOnly] = useState(initialRecapOnly);
	const meetingHost = currentUsers.find(
		(user: InvitedUser) => user?.id === owningUserID
	);
	const [searchParams] = useSearchParams();
	const sharingToken = searchParams.get('vt');
	const minutesOn = userSettings.meeting_minutes || orgSettings.meeting_minutes;

	const [notes, setNotes] = useState(initialNotes || '');

	useEffect(() => {
		const fetchUsersLists = async () => {
			logger('info', 'fetching users lists', { meetingID: meetingID });
			try {
				const users = await Promise.allSettled([
					getMeetingInvitedUsers(
						meetingID,
						organizationID,
						undefined,
						sharingToken
					),
					getMeetingPresentedUsers(meetingID, organizationID, sharingToken),
					getMeetingExternalUsers(meetingID, organizationID, sharingToken),
				]);

				setCurrentUsers(
					users[0].status === 'fulfilled'
						? users[0].value.data.data.invitedUsers
						: []
				);
				const externalUsers = (
					users[2].status === 'fulfilled'
						? users[2].value.data.data.externalUsers
						: []
				).map((user: [string | null, string | null, string | null]) => {
					const [email, firstName, lastName] = user;
					const userFound = orgUsers.find((orgUser) => {
						const firstNameLower =
							typeof orgUser.firstName === 'string'
								? orgUser.firstName.toLowerCase()
								: '';
						const lastNameLower =
							typeof orgUser.lastName === 'string'
								? orgUser.lastName.toLowerCase()
								: '';
						return (
							firstNameLower ===
							(typeof firstName === 'string'
								? firstName.toLowerCase()
								: '') &&
							lastNameLower ===
							(typeof lastName === 'string' ? lastName.toLowerCase() : '')
						);
					});
					if (userFound) {
						return {
							isInvited: currentUsers.some(
								(user) =>
									user.email.toLowerCase() === userFound.email.toLowerCase()
							),
							...userFound,
						};
					}
					return {
						email,
						firstName,
						lastName,
						isInvited: false,
					};
				});

				setCurrentExternalUsers(externalUsers);

				setPresentedUsers(
					users[1].status === 'fulfilled'
						? users[1].value.data.data.presentedUsers
						: []
				);
			} catch (error) {
				logger('error', 'fetching users lists', error);
			}
		};

		if (orgUsers.length) fetchUsersLists();
	}, [orgUsers]);

	useEffect(() => {
		setCurrentExternalUsers((prev) =>
			prev.map((user) => {
				const isInvited = currentUsers.some(
					(invitedUser) =>
						typeof invitedUser.email === 'string' &&
						typeof user.email === 'string' &&
						invitedUser.email.toLowerCase() === user.email.toLowerCase()
				);

				return {
					...user,
					isInvited,
				};
			})
		);
	}, [currentUsers]);

	useEffect(() => {
		setNotes(notes || '');
	}, [notes]);

	const uploadAttachment = async (files: File[]) => {
		try {
			setUploadingAttachment(true);
			const file = files[0];
			const attachmentData = new FormData();
			attachmentData.append(`attachment${0}`, file, file.name);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const attachmentResponse = await addAttachmentsToMeeting({
				data: attachmentData,
				params: {
					meetingID: meetingID,
					organizationID: organizationID,
				},
				headers: { 'Content-Type': undefined },
			});
			setCurrentAttachments([
				...currentAttachments,
				attachmentResponse.data.data[0],
			]);
			createFilePreview(attachmentResponse.data.data[0]);
			showSuccessNotification({
				message: 'Attachment was successfully uploaded! Great work!',
			});
		} catch (err) {
			logger('error', 'uploading attachment', err);
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to upload the attachment. Please give it another try.',
			});
		} finally {
			setUploadingAttachment(false);
		}
	};

	const removeAttachment = async (attachmentID: string) => {
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			await removeAttachmentsFromMeeting(
				[attachmentID],
				meetingID,
				organizationID
			);
			setCurrentAttachments(
				currentAttachments.filter(
					(attachment: IAttachment) => attachment.id !== attachmentID
				)
			);
			showFailureNotification({
				message: 'Attachment was successfully removed! Great work!',
			});
		} catch (err) {
			logger('error', 'removing attachment', err);
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to remove the attachment. Please give it another try.',
			});
		}
	};

	const handleRemoveUser = async (email: string, index?: number) => {
		try {
			if (index !== null) setLoadingIndex(index);
			logger('info', 'remove user from meeting', {
				meetingID,
				organizationID: organizationID,
				email,
			});
			const res = await removeUsersFromAMeeting(
				[email],
				meetingID,
				organizationID
			);
			const newInvitedUsers = res?.data?.data?.invitedUsers;
			setCurrentUsers(newInvitedUsers);
			showSuccessNotification({
				message: `${email} has been removed from this meeting.`,
			});
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to remove the user from the meeting. Please give it another try.',
			});
			logger('error', 'error removing user from meeting:', {
				meetingID,
				organizationID: organizationID,
				email,
				error,
			});
		} finally {
			setLoadingIndex(null);
		}
	};

	const handleInviteUser = async (email: string, index?: number) => {
		try {
			if (index !== null) setLoadingIndex(index);
			logger('info', 'invite user to meeting', {
				meetingID,
				organizationID: organizationID,
				email,
			});

			const res = await inviteUsersToMeeting({
				emails: [email],
				meetingID: id,
				organizationID: organizationID,
			});
			const newInvitedUsers = res?.data?.data;
			setCurrentUsers((prev) => [...prev, ...newInvitedUsers]);
			showSuccessNotification({
				message: `${email} has been invited to this meeting.`,
			});
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to invite a user to the meeting. Please give it another try.',
			});
			logger('error', 'error inviting user to meeting:', {
				meetingID,
				organizationID: organizationID,
				email,
				error,
			});
		} finally {
			setLoadingIndex(null);
		}
	};

	const handleUpdateMeeting = async (
		meetingDetails:
			| Record<string, unknown>
			| { summary: string }
			| { name: string }
			| { thumbnailTime: number }
			| { notes: string }
	) => {
		try {
			const res = await updateMeeting(
				meetingDetails,
				meetingID,
				organizationID
			);
			const updatedMeeting = res?.data?.data;
			setCurrentMeeting(updatedMeeting);
			setRecapOnly(updatedMeeting.recapOnly);

			const detailsUpdated = Object.keys(meetingDetails).join(', ');
			showSuccessNotification({
				message: `Meeting ${detailsUpdated} updated successfully.`,
			});
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to update the meeting. Please give it another try.',
			});
			logger('error', 'error updating meeting:', {
				meetingID,
				organizationID: organizationID,
				meetingDetails,
				error,
			});
		}
	};

	return (
		<Stack align={'stretch'} h={'100%'}>
			{showVideoPlayer ? (
				<Stack spacing={0}>
					<Group grow>
						<Paper shadow='lg' radius='lg' p='sm'>
							<Group position={'apart'}>
								<Text className={classes.dateText}>
									<Text span className={classes.title}>
										{'Name: '}
									</Text>
									{name}
								</Text>
								{botMetadata?.joinAt ? (
									<Text className={classes.dateText}>
										<Text span className={classes.title}>
											{`${name} : `}
										</Text>
										{DateTime.fromISO(botMetadata?.joinAt).toFormat(
											'LLL d, h:mm a'
										)}
									</Text>
								) : (
									<Text className={classes.dateText}>
										<Text span className={classes.title}>
											Created At:{' '}
										</Text>
										{DateTime.fromISO(createdAt).toLocaleString(
											DateTime.DATE_MED
										)}
									</Text>
								)}
								<Text className={classes.dateText}>
									<Text span className={classes.title}>
										Reelay Owner:
									</Text>{' '}
									{`${meetingHost?.firstName} ${meetingHost?.lastName}`}
								</Text>
								<Text className={classes.dateText}>
									<Text span className={classes.title}>
										Meeting Minutes:{' '}
									</Text>
									{minutesOn ? (
										<span style={{ color: 'green' }}>✓</span>
									) : (
										<span style={{ color: 'red' }}>X</span>
									)}
								</Text>
								<Switch
									checked={recapOnly}
									onChange={(event) =>
										handleUpdateMeeting({
											recapOnly: event.currentTarget.checked,
										})
									}
									labelPosition='left'
									label='Recap only'
								/>
								<Text className={classes.dateText}>
									<Text span className={classes.title}>
										Distribution preference:
									</Text>{' '}
									{orgSettings.distribution_preference}
								</Text>
							</Group>
						</Paper>
					</Group>
					{videoMetadata ? (
						<ReelayVideoPlayer
							videoMetadata={videoMetadata}
							name={name}
							meeting={currentMeeting}
							setMeeting={setCurrentMeeting}
							nameInputWidth={200}
							audio_enabled={audio_enabled}
							playerRef={playerRef}
						// isSupport={true}
						/>
					) : (
						<Text>Woah - no video info!</Text>
					)}
				</Stack>
			) : null}
			{showDetails ? (
				<Group grow>
					<Paper shadow='lg' radius='lg' p='sm'>
						<Accordion
							variant='separated'
							radius='lg'
							w={'100%'}
							defaultValue='agenda'
						>
							<Accordion.Item value='agenda'>
								<Accordion.Control>Agenda</Accordion.Control>
								<Accordion.Panel>
									<EditMeetingTextbox field='agenda' title='agenda' />
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value='summary'>
								<Accordion.Control>Summary</Accordion.Control>
								<Accordion.Panel>
									<EditMeetingTextbox field='summary' title='summary' />
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value='longSummary'>
								<Accordion.Control>Long Summary</Accordion.Control>
								<Accordion.Panel>
									<EditMeetingTextbox field='longSummary' title='longSummary' />
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value='chapters'>
								<Accordion.Control>Topics</Accordion.Control>
								<Accordion.Panel>
									{chapters ? (
										<TimelineBars
											title={'Topics'}
											isWeb={true}
											items={chapters}
											bulletColor={'primary.8'}
											activeBackgroundColor={'primary.1'}
											editable={true}
											meeting={currentMeeting}
										// handleAddChapter={handleAddChapter}
										// handleEditChapter={handleEditChapter}
										// handleDeleteChapter={handleDeleteChapter}
										/>
									) : (
										<Text>No topics for this meeting</Text>
									)}
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value='thumbnail'>
								<Accordion.Control>Thumbnail</Accordion.Control>
								<Accordion.Panel>
									<ThumbnailSetter
										meeting={currentMeeting}
										screenshareHistory={botDetails?.screenshareHistory || []}
									/>
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value='audience'>
								<Accordion.Control>Audience</Accordion.Control>
								<Accordion.Panel>
									<>
										<AddViewersDialog
											setDistributionList={setCurrentUsers}
											close={close}
											opened={opened}
											// asideRef={asideRef}
											meeting={currentMeeting}
											isSupport={true}
											orgUsers={orgUsers}
										/>
										<Group position={'apart'} noWrap align={'top'}>
											<Stack>
												<Title order={5} color={'primary-text'}>
													Meeting Host
												</Title>
												<Text>
													{botDetails?.host?.name ||
														`${meetingHost?.firstName} ${meetingHost?.lastName} - ${meetingHost?.email}`}
												</Text>
											</Stack>
											<Button onClick={toggle}>Add Viewers</Button>
										</Group>

										<Space h={'md'} />
										<Title order={5} color={'primary-text'}>
											Distribution List
										</Title>
										{currentUsers.length ? (
											<List
												size={'sm'}
												mt={'md'}
												styles={(theme) => ({
													itemWrapper: {
														width: '100%',
														paddingRight: theme.spacing.lg,
													},
												})}
											>
												{currentUsers.map((user: InvitedUser) => {
													const { email, firstName, lastName, id } = user;
													return (
														<List.Item key={id}>
															<Group position='apart' noWrap w={'100%'}>
																<Text>{`${firstName ? firstName + ' ' : ''}${lastName ? lastName + ', ' : ''
																	}${email}`}</Text>
																<ActionIcon
																	variant='subtle'
																	size={'sm'}
																	radius={'md'}
																	component={'button'}
																	onClick={() => handleRemoveUser(email)}
																	w={30}
																	className={classes.deleteIcon}
																>
																	<FontAwesomeIcon icon={faX} fontSize={15} />
																</ActionIcon>
															</Group>
														</List.Item>
													);
												})}
											</List>
										) : (
											<Text>None</Text>
										)}
										<Space h={'md'} />

										<Title order={5} color={'primary-text'}>
											Meeting Participants
										</Title>
										{currentExternalUsers.length ? (
											<List
												// withPadding
												size={'sm'}
												mt={'md'}
												style={{ listStyleType: 'none' }}
												styles={{
													itemWrapper: {
														width: '100%',
														'span:nth-child(2)': {
															flex: 2,
														},
													},
												}}
											>
												{currentExternalUsers.map((user: any, index) => {
													const { email, firstName, lastName, id, isInvited } =
														user;
													return (
														<List.Item
															key={`${email}-${firstName}-${index}`}
															icon={
																<FontAwesomeIcon
																	icon={
																		id ? faShieldCheck : faShieldExclamation
																	}
																	className={id ? 'fa-solid' : 'fa-regular'}
																	fontSize={16}
																	color={theme.colors[theme.primaryColor][3]}
																	title={
																		id
																			? 'Internal organization user'
																			: 'External organization user'
																	}
																	style={{
																		position: 'relative',
																		top: '5px',
																	}}
																/>
															}
														>
															<Group position='apart' noWrap w={'100%'}>
																<Text>
																	{`
																			${firstName} ${lastName}, ${email}
																		`}
																</Text>
																{index === loadingIndex ? (
																	<Loader size={'sm'} />
																) : isInvited ? (
																	<Tooltip label='Uninvite'>
																		<ActionIcon
																			variant='transparent'
																			onClick={() =>
																				handleRemoveUser(email, index)
																			}
																		>
																			<FontAwesomeIcon icon={faX} />
																		</ActionIcon>
																	</Tooltip>
																) : (
																	<Tooltip label='Invite'>
																		<ActionIcon
																			variant='transparent'
																			onClick={() =>
																				handleInviteUser(email, index)
																			}
																		>
																			<FontAwesomeIcon icon={faPlus} />
																		</ActionIcon>
																	</Tooltip>
																)}
															</Group>
														</List.Item>
													);
												})}
											</List>
										) : (
											<Text>None</Text>
										)}
										<Space h={'md'} />
										<Space h={'md'} />
									</>
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value='botEvent'>
								<Accordion.Control>Calendar Event</Accordion.Control>
								<Accordion.Panel>
									{botEvent.data ? (
										<div>

											<Stack>
												<Text size={'sm'} fw={400}><b>Recall Event ID:</b> {botEvent?.data?.recallData?.recallData?.id ? botEvent?.data?.recallData?.recallData?.id : 'No Event ID Found'} </Text>
												<Group position={'left'} noWrap>
													<Button
														component='a'
														href={`https://api.recall.ai/dashboard/explorer/calendar/v2/${botEvent?.data?.recallData?.recallData?.calendar_id}`}
														target='_blank'
														rel='noopener noreferrer'
														size='xs'
														rightIcon={<FontAwesomeIcon
															style={{ cursor: 'pointer' }}
															icon={faExternalLink}
															size={'xs'} />}
													>
														See Calendar
													</Button>
												</Group>
											</Stack>
											<h3>Event Organizer</h3>
											<Text>
												{`
													${botEvent.data.organizer.name}
													${botEvent.data.organizer.email}
												`}
											</Text>
											<h3>Event Invitees</h3>
											{(() => {
												const attendees = Array.isArray(botEvent.data.attendees)
													? botEvent.data.attendees.map((attendee) => ({
														...attendee,
														name: attendee.name,
														email: attendee.email,
														isInvited: currentUsers.some(
															(user) =>
																typeof user.email === 'string' &&
																typeof attendee.email === 'string' &&
																user.email.toLowerCase() ===
																attendee.email.toLowerCase()
														),
													}))
													: [];

												return attendees.length ? (
													<List
														// withPadding
														size={'sm'}
														mt={'md'}
														style={{ listStyleType: 'none' }}
														styles={{
															itemWrapper: {
																width: '100%',
																'span:nth-child(2)': {
																	flex: 2,
																},
															},
														}}
													>
														{attendees.map((attendee, index) => (
															<List.Item
																key={`${attendee.email}-${attendee?.name}-${index}`}
																icon={
																	<FontAwesomeIcon
																		icon={
																			attendee.isOrganizationMember
																				? faShieldCheck
																				: faShieldExclamation
																		}
																		className={
																			attendee.isOrganizationMember
																				? 'fa-solid'
																				: 'fa-regular'
																		}
																		fontSize={16}
																		color={theme.colors[theme.primaryColor][3]}
																		title={
																			attendee.isOrganizationMember
																				? 'Internal organization user'
																				: 'External organization user'
																		}
																		style={{
																			position: 'relative',
																			top: '5px',
																		}}
																	/>
																}
															>
																<Group position='apart' noWrap w={'100%'}>
																	<Text>
																		{attendee.name ? `${attendee.name}, ` : ''}
																		{attendee.email}
																		{attendee.aliasEmail &&
																			attendee.aliasEmail !==
																			attendee.email && (
																				<Text
																					component='span'
																					italic
																					color='secondary-gray'
																					style={{ marginLeft: '5px' }}
																				>
																					({attendee.aliasEmail})
																				</Text>
																			)}
																	</Text>
																	{index === loadingIndex ? (
																		<Loader size={'sm'} />
																	) : attendee.isInvited ? (
																		<Tooltip label='Uninvite'>
																			<ActionIcon
																				variant='transparent'
																				onClick={() =>
																					handleRemoveUser(
																						attendee.email,
																						index
																					)
																				}
																			>
																				<FontAwesomeIcon icon={faX} />
																			</ActionIcon>
																		</Tooltip>
																	) : (
																		<Tooltip label='Invite'>
																			<ActionIcon
																				variant='transparent'
																				onClick={() =>
																					handleInviteUser(
																						attendee.email,
																						index
																					)
																				}
																			>
																				<FontAwesomeIcon icon={faPlus} />
																			</ActionIcon>
																		</Tooltip>
																	)}
																</Group>
															</List.Item>
														))}
													</List>
												) : (
													<Text>No Invited Users</Text>
												);
											})()}
										</div>
									) : (
										<Text>No calendar event found.</Text>
									)}
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value='botDetails'>
								<Accordion.Control>Bot Details</Accordion.Control>
								<Accordion.Panel>
									{botDetails?.status_changes?.length ? (
										<BotDetailsTimelineBars
											recallBotID={botMetadata?.recallBotID || 'N/A'}
										/>
									) : (
										<Text>No bot details for this meeting.</Text>
									)}
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value='notes'>
								<Accordion.Control>Notes</Accordion.Control>
								<Accordion.Panel>
									<Stack align={'flex-start'}>
										<Textarea
											placeholder='Add your notes here...'
											maxLength={255}
											autosize
											minRows={4}
											maxRows={8}
											w={'100%'}
											value={notes}
											onChange={(event) => setNotes(event.currentTarget.value)}
										/>
										<Button
											onClick={() =>
												handleUpdateMeeting({
													notes,
												})
											}
										>
											Save
										</Button>
									</Stack>
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value='objective'>
								<Accordion.Control>Objective</Accordion.Control>
								<Accordion.Panel>
									<EditMeetingTextbox field='objective' title='objective' />
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value='uploads'>
								<Accordion.Control>Uploads</Accordion.Control>
								<Accordion.Panel>
									<FileButton
										onChange={uploadAttachment}
										accept='image/png,image/jpeg,application/pdf,.doc,.docx,video/*'
										multiple
									>
										{(props) => (
											<Button {...props} className={classes.submitButton}>
												Add
											</Button>
										)}
									</FileButton>
									<List size='sm' mt={5} withPadding>
										{currentAttachments.map(
											(attachment: IAttachment, index: number) => (
												<List.Item key={index}>
													<Group
														position={'apart'}
														align={'center'}
														noWrap
														w={'100%'}
														style={{
															maxWidth: '100%',
															minWidth: '90%',
														}}
													>
														<Button
															variant={'subtle'}
															compact
															onClick={() => previewAttachment(index)}
														>
															{attachment.fileName}
														</Button>
														<FontAwesomeIcon
															icon={faTrash}
															onClick={() => removeAttachment(attachment.id)}
														/>
													</Group>
												</List.Item>
											)
										)}
										{uploadingAttachment && (
											<List.Item>
												<Group align={'center'}>
													<Loader size={'sm'} variant={'dots'} />
												</Group>
											</List.Item>
										)}
									</List>
								</Accordion.Panel>
							</Accordion.Item>
						</Accordion>
					</Paper>
				</Group>
			) : null}
		</Stack>
	);
}
