import { IMeeting, IOrganizer, ProcessingResults } from '../interfaces/meeting';
import {
	createMeetingHighlight,
	getMeetingAnalytics,
	updateSharingTokenIsActive,
} from '../api/api';
import { IAction } from '../interfaces/action';
import { User } from '../Atoms/userAtoms';
import { TransferListItem } from '@mantine/core';
import {
	showFailureNotification,
	showSuccessNotification,
} from './notifications';
import { logger } from './logger';
import { ANYONE_WITH_LINK } from './settings/settings.constants';

export interface Chapter {
	id: string;
	time: number;
	timeStartMS?: number;
	start?: number;
	content: string;
	value?: string;
	label?: string;
	gist?: string;
	headline?: string;
	summary?: string;
	type?: string;
}

export const addViewsToMeetings = async (meetings: IMeeting[]) => {
	const meetingsAnalyticData = await Promise.allSettled(
		meetings.map((meeting: IMeeting) => {
			try {
				if (meeting.status !== 'published') return Promise.resolve();
				return getMeetingAnalytics(meeting.id, meeting.organizationID);
			} catch (error) {
				return error;
			}
		})
	);

	const meetingsWithViews = meetings.map((meeting: IMeeting, idx: number) => {
		const response = meetingsAnalyticData[idx];
		if (response.status === 'fulfilled' && response?.value !== undefined) {
			const data = response?.value?.data?.data;
			const views = data?.users?.length
				? data?.users?.reduce((prev, cur) => {
						if (cur.viewed) return prev + Number(cur.viewed);
						return prev;
				  }, 0)
				: 0;
			return {
				...meeting,
				views,
			};
		} else {
			return {
				...meeting,
				views: 0,
			};
		}
	});
	return meetingsWithViews;
};

export const filterByPublishedMeetingsWithUnclaimedActionsAndSearchValue = (
	meetings: IMeeting[],
	searchValue: string
) => {
	return meetings
		.filter((meeting) => {
			const actionsWithSearchTerm = meeting.actions.filter(
				(action: IAction) =>
					(!('assignees' in action) || action.assignees.length === 0) &&
					action.content.toLowerCase().includes(searchValue.toLowerCase())
			);
			return (
				meeting.status === 'published' &&
				meeting.actions.some(
					(action) => !('assignees' in action) || action.assignees.length === 0
				) &&
				actionsWithSearchTerm.length > 0
			);
		})
		.map((meeting) => {
			return {
				...meeting,
				actions: meeting.actions.filter(
					(action) =>
						(!('assignees' in action) || action.assignees.length === 0) &&
						action.content.toLowerCase().includes(searchValue.toLowerCase())
				),
			};
		});
};

export const isMeetingExpired = (meetingDeadline: string) => {
	if (!meetingDeadline) return false;
	return new Date(meetingDeadline) < new Date();
};

/**
 * sort meetings from latest to oldest for non published meetings first using createdAt,
 * then sort the published meetings from latest to oldest using publishedAt
 * @param meetings
 */
export const sortMeetingsFromLatestToOldest = (
	meetings: IMeeting[],
	type = 'default'
) => {
	if (type === 'all') {
		return meetings.sort((a, b) => {
			// Sort published meetings by viewing deadline
			if (a.status === 'published' && b.status === 'published') {
				return (
					new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
				);
			}

			// Prioritize 'published' meetings ahead of other types
			if (a.status === 'published' && b.status !== 'published') {
				return -1;
			}

			if (a.status !== 'published' && b.status === 'published') {
				return 1;
			}

			// Handle the case where one meeting has a null publishedAt value
			if (a.publishedAt === null && b.publishedAt !== null) {
				return 1; // Move the meeting with null publishedAt to the end
			}

			if (a.publishedAt !== null && b.publishedAt === null) {
				return -1; // Move the meeting with null publishedAt to the beginning
			}

			// Sort non-published meetings by createdAt
			return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
		});
	} else if (type === 'scheduled,created') {
		return meetings.sort((a, b) => {
			return (
				new Date(a?.botMetadata?.joinAt).getTime() -
				new Date(b?.botMetadata?.joinAt).getTime()
			);
		});
	} else if (type === 'published') {
		return meetings.sort((a, b) => {
			if (a.viewingDeadline === null && b.viewingDeadline !== null) {
				return 1; // Move meetings with null viewingDeadline to the end
			}

			if (a.viewingDeadline !== null && b.viewingDeadline === null) {
				return -1; // Move meetings with null viewingDeadline to the beginning
			}

			if (a.viewingDeadline && b.viewingDeadline) {
				return (
					new Date(a.viewingDeadline).getTime() -
					new Date(b.viewingDeadline).getTime()
				);
			}

			// If both have null viewingDeadline or no viewingDeadline, maintain their order
			return 0;
		});
	} else {
		return meetings.sort((a, b) => {
			// Sort published meetings by viewing deadline
			if (a.publishedAt && b.publishedAt) {
				return (
					new Date(a.viewingDeadline).getTime() -
					new Date(b.viewingDeadline).getTime()
				);
			}

			// Handle the case where one meeting has a null publishedAt value
			if (a.publishedAt === null && b.publishedAt !== null) {
				return 1; // Move the meeting with null publishedAt to the end
			}

			if (a.publishedAt !== null && b.publishedAt === null) {
				return -1; // Move the meeting with null publishedAt to the beginning
			}

			// Sort non-published meetings by createdAt
			return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
		});
	}
};

export const filterActionsBySearch = (data: IAction[], search: string) => {
	const query = search.toLowerCase().trim();
	return data.filter((item) => {
		const meeting = item.meeting;
		return ['content', 'meeting'].some((key) => {
			if (key === 'meeting') return meeting.name.toLowerCase().includes(query);
			return item[key].toLowerCase().includes(query);
		});
	});
};

export const containsValidCharacters = (string: string) => {
	// Define a regular expression pattern to match HTML tags
	const htmlPattern = /<.*?>/g;

	// Remove HTML tags from the string
	const cleanedString = string.replace(htmlPattern, '');

	// Check if the cleaned string contains any non-whitespace characters
	const nonWhitespacePattern = /\S/;
	return nonWhitespacePattern.test(cleanedString);
};

export const filterMeetingsByStatus = (meetings: IMeeting[], statusFilter) => {
	if (statusFilter === 'all') return meetings;

	if (Array.isArray(statusFilter)) {
		if (statusFilter.includes('all')) return meetings;
		return meetings.filter((meeting: any) =>
			statusFilter.includes(meeting.status)
		);
	}
	return meetings.filter(
		(meeting: IMeeting) => meeting.status === statusFilter
	);
};

export const filterUserMeetingsByStatus = (
	meetings: IMeeting[],
	segmentValue: string
) => {
	const meetingStatuses = segmentValue.split(',');
	return meetings.filter((meeting) => {
		const meetingExpired = isMeetingExpired(meeting.viewingDeadline);
		if (segmentValue === 'expired') {
			return meetingExpired && meeting.status === 'published';
		}
		if (segmentValue === 'published') {
			return !meetingExpired && meeting.status === 'published';
		}
		return segmentValue === 'all' || meetingStatuses.includes(meeting.status);
	});
};

export const getMeetingHost = (
	organizer?: IOrganizer,
	author?: User,
	fallbackString = 'N/A'
): string => {
	const getFullName = (person: IOrganizer | User): string => {
		const firstName = person.firstName ?? '';
		const lastName = person.lastName ?? '';
		return `${firstName} ${lastName}`.trim() || fallbackString;
	};

	if (organizer) {
		const fullName = getFullName(organizer);
		if (fullName !== fallbackString) {
			return fullName;
		}
	}
	if (author) {
		const fullName = getFullName(author);
		if (fullName !== fallbackString) {
			return fullName;
		}
	}
	return 'N/A';
};

export const meetingDetailsReady = (processingResults: ProcessingResults) => {
	if (typeof processingResults !== 'object' || processingResults === null) {
		return true;
	}
	return Object.values(processingResults).every(
		(result) => result === 'success'
	);
};

export const createHighlights = async (
	values: TransferListItem[],
	meetingId: string,
	organizationId: string,
	type: string
) => {
	try {
		const responses = await Promise.allSettled(
			values.map((highlight) => {
				const { value, timeStartMS, isTopQuestion } = highlight;
				const payload = {
					timeStartMS: timeStartMS || 0,
					timeEndMS: timeStartMS || 0,
					content: value,
					visibility: 'group',
					type,
					isTopQuestion,
				};
				return createMeetingHighlight(payload, meetingId, organizationId);
			})
		);

		const highlights = responses
			.filter((res) => res.status === 'fulfilled')
			.map((res: any) => res?.value);
		showSuccessNotification({
			message: `${type}s were successfully updated! Great work!`,
		});
		return highlights;
	} catch (error: unknown) {
		logger('error', `Error creating ${type}s`, error);
		showFailureNotification({
			message: `Error creating ${type}s. Please try again.`,
		});
	}
};

export const changeMeetingSharing = async (
	value: string,
	targetID: string,
	organizationID: string
) => {
	try {
		const isActive = value === ANYONE_WITH_LINK;
		const response = await updateSharingTokenIsActive(
			{
				targetID,
				value: isActive,
			},
			organizationID
		);
		const { sharingToken } = response.data;
		return sharingToken;
	} catch (error) {
		logger('error', 'error updating sharing token of a meeting', error);
		showFailureNotification({
			message:
				'Sorry, there was an error updating the sharing setting of the meeting. Please try again.',
		});
	}
};

const enabledMeetingStatuses = ['review', 'published'];
export const isAbleToOpenMeeting = (status: string, recapOnly) =>
	enabledMeetingStatuses.includes(status) && !recapOnly;
