import React, { useEffect, useState } from 'react';

import {
	Modal,
	Button,
	Space,
	TextInput,
	Title,
	Group,
	Divider,
} from '@mantine/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	User,
	currentUserFormattedTeams,
	userOrganizationMembers,
	userOrganization,
} from '../../../Atoms/userAtoms';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { UserList } from '../../../interfaces/user';
import { createTeam, getUsersByOrg } from '../../../api/api';
import theme from '../../../_utils/theme';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { UsersMultiSelect } from '../../UsersMultiSelect';
import { logger } from '../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';

interface Props {
	type: 'top' | 'bottom';
}

export default function CreateTeamButton({ type }: Props) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>();
	const [opened, setOpened] = useState(false);
	const [members, setMembers] = useRecoilState(userOrganizationMembers);
	const organization = useRecoilValue(userOrganization);
	const organizationID = organization.id;
	const [teams, setTeams] = useRecoilState(currentUserFormattedTeams);

	const formattedMembers: { value: string; label: string }[] = members.map(
		(member: User) => {
			const label = `${member.firstName || ''} ${member.lastName || ''}${
				member.firstName || member.lastName ? ', ' : ''
			}${member.email}`.trim();

			return {
				value: member.email,
				label,
			};
		}
	);

	const form = useForm({
		initialValues: {
			name: '',
			emails: [],
		},
		validate: {
			emails: (value) =>
				value.length > 0 ? null : 'Teammates are required to create a team',
		},
	});

	const handleModalOpen = () => {
		setOpened(true);
	};

	const handleModalClose = () => {
		setOpened(false);
	};

	const handleCreateTeam = async (values: {
		name: string;
		emails: string[];
	}) => {
		setLoading(true);
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const response = await createTeam(
				values.name,
				values.emails,
				organizationID
			);
			setTeams([response.data.data, ...teams]);
			setLoading(false);
			showSuccessNotification({
				message: 'Team was successfully created! Great work!',
			});
			handleModalClose();
			form.reset();
		} catch (err: unknown) {
			logger('error', 'Error creating team', err);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setError(err.message);
			setLoading(false);
			showFailureNotification({
				message: 'Sorry, failed to create a team. Please try again.',
			});
		}
	};

	return (
		<>
			<Modal
				centered={true}
				title={
					<Title order={4} weight={400}>
						Create a Team
					</Title>
				}
				opened={opened}
				onClose={() => setOpened(false)}
			>
				<>
					<Divider size='xs' />
					<Space h={'xl'} />
					<form onSubmit={form.onSubmit((values) => handleCreateTeam(values))}>
						<TextInput
							required
							error='Team Name required'
							placeholder='Team Name'
							{...form.getInputProps('name')}
						/>
						<Space h={'xl'} />
						<UsersMultiSelect
							data={formattedMembers}
							form={form}
							name={'emails'}
							searchable={true}
							required={true}
							withAsterisk={true}
							placeholder='Select Teammates'
							labelText={'Teammates'}
							errorText='Teammates are required to create a team'
						/>
						<Space h={'xl'} />
						<Group position={'right'}>
							<Button radius={'md'} type='submit'>
								Create
							</Button>
						</Group>
					</form>
				</>
			</Modal>

			{type === 'top' ? (
				<Button
					variant={'filled'}
					radius={'md'}
					leftIcon={<FontAwesomeIcon icon={faPlus} />}
					onClick={() => handleModalOpen()}
					color={'primary'}
				>
					Create a Team
				</Button>
			) : (
				<Button
					variant={'outline'}
					radius={'md'}
					leftIcon={<FontAwesomeIcon icon={faPlus} />}
					onClick={() => handleModalOpen()}
					color={'primary'}
				>
					Create a Team
				</Button>
			)}
			{/*<Button variant={'white'} radius={'md'} color={'reelay-secondary-dark'} leftIcon={<FontAwesomeIcon icon={faPlus} />}  onClick={ () => handleModalOpen() }>Create a Team</Button>*/}
		</>
	);
}
