import type React from 'react';
import { useCallback, useState } from 'react';
import {
	Card,
	Image,
	Text,
	Group,
	Grid,
	Flex,
	Box,
	Divider,
	useMantineTheme,
	AspectRatio,
	Button,
	Center,
	Stack,
	Checkbox,
	createStyles,
	Tooltip,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFileLines,
	faCirclePlay,
	faFileInvoice,
	faScissors,
} from '@fortawesome/pro-regular-svg-icons';
import { DateTime } from 'luxon';
import { createVideoThumbnail } from '../../../helpers/data';
import { convertSecondsToHHMMSS, formatTimestamp } from '../../../_utils/time';
import { removeHtmlTags } from '../../../_utils/handy-functions';
import reactStringReplace from 'react-string-replace';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { previousPageURL } from '../../../Atoms/meetingAtom';
import { ClipCardMenu } from './ClipCardMenu';
import { MILLISECONDS_PER_SECOND } from '../../../components/constants';
import { clipBeingAddedToCollection, VideoClip } from '../../../Atoms/clips';
import { extractPlaybackID } from '../../../_utils/video';
import { showSuccessNotification } from '../../../helpers/notifications';
import {
	bulkDeletingCollectionMeetings,
	bulkDeletingCollectionMeetingsSelection,
} from '../../../Atoms/collections';

const IMAGE_ASPECT_RATIO_NOMINATOR = 16;
const IMAGE_ASPECT_RATIO_DENOMINATOR = 9;
const SUMMARY_HEIGHT = '55.78px';

const useStyles = createStyles((theme) => ({
	thumbnailContainer: {
		position: 'relative',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		borderRadius: theme.radius.lg,
	},
	timestampContainer: {
		backgroundColor: 'black',
		opacity: 0.8,
		color: 'white',
		marginBottom: '2.5%',
		marginRight: '1.5%',
		borderRadius: '4px',
		padding: 3,
	},
	card: {
		position: 'relative',
	},
	checkboxContainer: {
		position: 'absolute',
		top: '20px',
		left: '20px',
		zIndex: 1,
	},
}));

const THUMBNAIL_ROW_HEIGHT = 37;

interface ClipCardProps {
	clip: VideoClip;
	searchValue?: string;
	handleGetClipsData?: ({ searchValue }: { searchValue: string }) => void;
	openShareModal?: (clip: VideoClip) => void;
	openEditModal?: (clip: VideoClip) => void;
}

export function ClipCard({
	clip,
	searchValue = '',
	handleGetClipsData,
	openShareModal,
	openEditModal,
}: ClipCardProps) {
	const navigate = useNavigate();
	const theme = useMantineTheme();
	const { classes } = useStyles();
	const setPreviousURL = useSetRecoilState(previousPageURL);
	const bulkDeletingMeetings = useRecoilValue(bulkDeletingCollectionMeetings);
	const [selection, setSelection] = useRecoilState(
		bulkDeletingCollectionMeetingsSelection
	);
	const setAddingClip = useSetRecoilState(clipBeingAddedToCollection);

	const {
		id,
		organizationID,
		meetingID,
		owningUserID,
		title,
		startTime,
		endTime,
		thumbnailTime,
		summary,
		createdAt,
		playbackURL,
		owningUser,
		meeting,
	} = clip;

	const {
		owningUser: meetingOwningUser,
		publishedAt,
		meetingDate,
	} = meeting || {};
	const meetingHost = `${meetingOwningUser?.firstName || ''} ${
		meetingOwningUser?.lastName || ''
	}`.trim();
	const formattedMeetingDate = meetingDate
		? DateTime.fromMillis(+meetingDate).toFormat('MM/dd/yyyy')
		: publishedAt
		? DateTime.fromMillis(+publishedAt).toFormat('MM/dd/yyyy')
		: 'N/A';

	const highlightText = (text: string | undefined) => {
		return reactStringReplace(text, searchValue, (match, i) => (
			<span key={i} style={{ backgroundColor: 'yellow' }}>
				{match}
			</span>
		));
	};

	const summaryText = summary
		? searchValue.length
			? highlightText(removeHtmlTags(summary))
			: removeHtmlTags(summary)
		: 'No summary available.';

	const hostName = owningUser
		? `${owningUser.firstName || ''} ${owningUser.lastName || ''}`.trim()
		: 'Unknown';
	const formattedDate = DateTime.fromMillis(
		!Number.isNaN(Number(createdAt))
			? +createdAt
			: DateTime.fromISO(createdAt as string).toMillis()
	).toFormat('MM/dd/yyyy');

	const openClip = (e: React.MouseEvent) => {
		e.preventDefault();
		setPreviousURL(location.pathname);
		navigate(`/clips/${id}`);
	};

	const handleAddToCollection = () => {
		setAddingClip(clip);
	};

	const handleShareClip = useCallback(() => {
		openShareModal(clip);
	}, [openShareModal]);

	const handleEditClip = useCallback(() => {
		openEditModal(clip);
	}, [openEditModal]);

	const onCopyLink = (clip) => {
		navigator.clipboard.writeText(`${window.location.origin}/clips/${clip.id}`);
		const url = new URL(`${window.location.origin}/clips/${clip.id}`);
		if (clip?.sharingToken?.active) {
			url.searchParams.append('vt', clip.sharingToken.token);
		}
		navigator.clipboard.writeText(url.toString());
		showSuccessNotification({ message: 'Link copied to clipboard.' });
	};

	const handleCheckboxChange = () => {
		setSelection((current) =>
			current.includes(id)
				? current.filter((item) => item !== id)
				: [...current, id]
		);
	};

	return (
		<Grid.Col xs={12} md={6} xl={4}>
			<Card shadow='md' radius='lg' withBorder p='xs' className={classes.card}>
				{bulkDeletingMeetings && (
					<div className={classes.checkboxContainer}>
						<Checkbox
							checked={selection.includes(id)}
							onChange={handleCheckboxChange}
						/>
					</div>
				)}
				<Flex direction='column' style={{ height: '100%' }}>
					<Box
						sx={{
							position: 'relative',
							borderRadius: theme.radius.lg,
							overflow: 'hidden',
							flex: 1,
							cursor: 'pointer',
						}}
						onClick={bulkDeletingMeetings ? handleCheckboxChange : openClip}
					>
						<AspectRatio
							ratio={
								IMAGE_ASPECT_RATIO_NOMINATOR / IMAGE_ASPECT_RATIO_DENOMINATOR
							}
							sx={{ backgroundColor: theme.colors['light-blue'][0] }}
						>
							<div className={classes.thumbnailContainer}>
								<Image
									src={
										createVideoThumbnail(
											extractPlaybackID(playbackURL),
											thumbnailTime
										) || '/placeholder.svg'
									}
									alt={title || 'Clip thumbnail'}
									style={{ objectFit: 'cover', width: '100%', height: '100%' }}
								/>
							</div>
						</AspectRatio>
						<Box
							sx={{
								position: 'absolute',
								bottom: 0,
								left: 0,
								right: 0,
								height: '38%',
								background: 'rgba(0, 0, 0, 0.5)',
								borderBottomLeftRadius: theme.radius.lg,
								borderBottomRightRadius: theme.radius.lg,
								paddingLeft: theme.spacing.md,
								paddingRight: theme.spacing.md,
							}}
						>
							<Flex justify='center' align='center' style={{ height: '100%' }}>
								<Box style={{ flex: 1 }}>
									<Text
										size='18px'
										weight={700}
										color='white'
										lh='20px'
										mb='5px'
										lineClamp={2}
									>
										{title || 'Untitled Clip'}
									</Text>
									<Text size='12px' color='white'>
										{hostName} | {formattedDate}
									</Text>
								</Box>
								<Flex
									direction='column'
									align='center'
									justify='center'
									style={{ position: 'relative', top: '6px' }}
								>
									<FontAwesomeIcon
										icon={faCirclePlay}
										color='white'
										style={{ width: '38px', height: '38px' }}
									/>
									<Text size='xs' color='#ffffff' mt={4}>
										{formatTimestamp(endTime - startTime)}
									</Text>
								</Flex>
							</Flex>
						</Box>
					</Box>

					<Box style={{ flex: 1 }}>
						<Flex
							justify='space-between'
							align='center'
							mt='md'
							mb='xs'
							gap='2px'
						>
							<Group
								spacing='xs'
								noWrap
								style={{ flexGrow: 1, overflow: 'hidden' }}
							>
								<Group spacing='xs' noWrap style={{ width: '100%' }}>
									<Flex align='center'>
										<Tooltip
											label={meeting?.name || 'Unknown'}
											openDelay={300}
											withinPortal
										>
											<Text
												size='12px'
												component='span'
												color='primary-cta'
												weight={700}
												lineClamp={1}
												style={{
													cursor: 'default',
												}}
											>
												{meeting?.name || 'Unknown'}
											</Text>
										</Tooltip>
									</Flex>
									<Text size='12px' color='#c7c7c7'>
										|
									</Text>
									<Flex align='center'>
										<Tooltip
											label={meetingHost || 'Unknown'}
											openDelay={300}
											withinPortal
										>
											<Text
												size='12px'
												component='span'
												color='primary-cta'
												weight={700}
												lineClamp={1}
												style={{
													cursor: 'default',
												}}
											>
												{meetingHost || 'Unknown'}
											</Text>
										</Tooltip>
									</Flex>
									<Text size='12px' color='#c7c7c7'>
										|
									</Text>
									<Flex align='center'>
										<Text
											size='12px'
											component='span'
											color='primary-cta'
											weight={700}
										>
											{formattedMeetingDate || 'N/A'}
										</Text>
									</Flex>
								</Group>
							</Group>
							<ClipCardMenu
								clip={clip}
								searchValue={searchValue}
								handleGetClipsData={handleGetClipsData}
								handleAddToCollection={handleAddToCollection}
								onCopyLink={onCopyLink}
								onEdit={handleEditClip}
								onManageAccess={handleShareClip}
								isClipCard={true}
							/>
						</Flex>

						<Divider my='xs' color='#8C919B4D' />

						<Text
							size='12px'
							color='primary-text'
							mb='xs'
							lineClamp={3}
							mih={SUMMARY_HEIGHT}
						>
							{summaryText}
						</Text>

						<Button
							onClick={openClip}
							leftIcon={
								<FontAwesomeIcon
									icon={faScissors}
									size='lg'
									color={theme.colors['reelay-purple'][0]}
								/>
							}
							variant='subtle'
							styles={{
								label: {
									position: 'relative',
									top: '2px',
								},
							}}
						>
							<Text
								size='12px'
								color={theme.colors['reelay-purple'][0]}
								fw={700}
							>
								View Clip
							</Text>
						</Button>
					</Box>
				</Flex>
			</Card>
		</Grid.Col>
	);
}
