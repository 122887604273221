import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import {
	createStyles,
	Button,
	Tabs,
	Loader,
	Group,
	CopyButton,
	Tooltip,
	ActionIcon,
} from '@mantine/core';
import { VideoPlayer } from './components/video-player';
import { ClipInfo } from './components/clip-info';
import { ClipSummary } from './components/clip-summary';
import { currentUser, userOrganizationMembers } from '../../Atoms/userAtoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { buildVideoClipsPlaybackUrl } from '../../helpers/clips';
import { useForm } from '@mantine/form';
import {
	INVITE_ONLY,
	ANYONE_WITH_LINK,
} from '../../helpers/settings/settings.constants';
import { DateTime } from 'luxon';
import { ClipCardMenu } from '../ClipsPage/components/ClipCardMenu';
import { ShareClipModal } from '../meeting/components/MeetingDetailsTabSection/tabcontents/components/ShareClipModal';
import { EditClipModal } from '../meeting/components/MeetingDetailsTabSection/tabcontents/components/EditClipModal';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { organizationSettings } from '../../Atoms/settings';
import { teamDataAtom } from '../../Atoms/userAtoms';
import { showSuccessNotification } from '../../helpers/notifications';
import { getVideoClip } from '../../api/clips';
import { logger } from '../../helpers/logger';
import { AddToCollectionModal } from '../../components/Modals/CreateClipModals/AddToCollectionModal';
import { useCollections } from '../../customHooks/useCollections';
import { VideoClip } from '../../Atoms/clips';
import { isAuthenticated } from '../../Atoms/auth';
import { meetingClipsAtom } from '../../Atoms/meetingAtom';
import { faCheck, faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = createStyles((theme) => ({
	container: {
		height: '100%',
	},
	main: {
		maxWidth: '64rem',
		margin: '0 auto',
		padding: '1.5rem 1rem',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	titleRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	title: {
		fontSize: '1.5rem',
		fontWeight: 600,
		color: theme.colors.gray[9],
	},
	videoWrapper: {
		marginBottom: '1.5rem',
	},
}));

const fetcher = async ([clipId, organizationID, sharingToken]) => {
	try {
		const response = await getVideoClip(clipId, organizationID, sharingToken);
		return response;
	} catch (error) {
		logger('error', 'Error fetching video clip', error);
		throw error;
	}
};

export function ClipPage() {
	console.log('ClipPage rendered');
	const navigate = useNavigate();
	const { clipID } = useParams<{ clipID: string }>();
	const { classes, theme } = useStyles();
	const [searchParams] = useSearchParams();
	const user = useRecoilValue(currentUser);
	const [sharingOption, setSharingOption] = useState(ANYONE_WITH_LINK);
	const [userListWithOwnerFirst, setUserListWithOwnerFirst] = useState([]);
	const [buttonLoading, setButtonLoading] = useState(false);
	const userID = user?.id;
	const teamData = useRecoilValue(teamDataAtom);
	const videoRef = useRef<HTMLVideoElement>(null);
	const [shareModalOpened, { open: openShareModal, close: closeShareModal }] =
		useDisclosure(false);
	const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
		useDisclosure(false);
	const [isRegenerating, setIsRegenerating] = useState(false);
	const { sharing: sharingEnabled } = useRecoilValue(organizationSettings);
	const [addToCollectionModalOpened, setAddToCollectionModalOpened] =
		useState(false);
	const { addContentToCollection } = useCollections(false);
	const authenticated = useRecoilValue(isAuthenticated);
	const setClips = useSetRecoilState(meetingClipsAtom);
	const viewToken = searchParams.get('vt');
	const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
	const {
		data: clip,
		error,
		isLoading,
		mutate,
	} = useSWR<VideoClip>(
		clipID ? [clipID, user.currentOrganizationID, viewToken] : null,
		fetcher
	);

	useEffect(() => {
		if (clip) {
			setSharingOption(
				clip.sharingToken?.active ? ANYONE_WITH_LINK : INVITE_ONLY
			);
			setUserListWithOwnerFirst(
				[...(clip.videoClipUserLinks || [])].sort((a, b) => {
					if (clip.owningUserID === a.userID) return -1;
					if (clip.owningUserID === b.userID) return 1;
					return (
						new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
					);
				})
			);
		}
	}, [clip]);

	const {
		id = '',
		organizationID = '',
		meetingID = '',
		owningUserID = '',
		title = '',
		startTime = 0,
		endTime = 0,
		thumbnailTime = 0,
		summary = '',
		createdAt = '',
		playbackURL = '',
		owningUser = {},
		meeting = { owningUser: {}, publishedAt: '', meetingDate: '' },
		videoClipUserLinks = [],
		collectionContents = [],
	} = clip || {};

	let name = '';
	let meetingOwningUser = { firstName: '', lastName: '' };
	let publishedAt = '';
	let meetingDate = '';

	if ('name' in meeting) {
		name = meeting.name;
		meetingOwningUser = {
			firstName: meeting.owningUser?.firstName || '',
			lastName: meeting.owningUser?.lastName || '',
		};
		publishedAt = meeting.publishedAt;
		meetingDate = meeting.meetingDate;
	}
	const meetingHost = `${meetingOwningUser?.firstName || ''} ${
		meetingOwningUser?.lastName || ''
	}`.trim();

	const formattedMeetingDate = meetingDate
		? DateTime.fromMillis(+meetingDate).toFormat('MM/dd/yyyy')
		: publishedAt
		? DateTime.fromMillis(+publishedAt).toFormat('MM/dd/yyyy')
		: 'N/A';
	const avatarUrl = clip?.owningUser?.userAvatarURL || '';
	const copyLinkUrl = clip?.sharingToken?.active
		? `${origin}/clips/${clip?.id}?vt=${clip?.sharingToken?.token}`
		: `${origin}/clips/${clip?.id}`;

	const playbackUrlWithTimestamps = buildVideoClipsPlaybackUrl(
		playbackURL,
		startTime,
		endTime
	);
	const isOwner = owningUserID === userID;
	const tabsRootRef = useRef(null);
	const tabsListRef = useRef(null);
	const summaryHeight = tabsRootRef.current
		? tabsRootRef.current.clientHeight - tabsListRef.current.clientHeight
		: 0;
	console.log('summaryHeight', summaryHeight);

	const handleShare = () => {
		openShareModal();
	};

	const handleAddToCollection = async () => {
		setAddToCollectionModalOpened(true);
	};

	const handleShareClip = useCallback(
		(clip) => {
			openShareModal();
		},
		[openShareModal]
	);

	const handleEditClip = useCallback(
		(clip) => {
			openEditModal();
		},
		[openEditModal]
	);

	const handleClipUpdate = (updatedClip: VideoClip | null) => {
		if (updatedClip) {
			// Update your state here based on parent context
			setClips((prev) =>
				prev.map((c) => (c.id === updatedClip.id ? updatedClip : c))
			);
			mutate(updatedClip, false);
		}
	};

	const onCopyLink = (clip) => {
		navigator.clipboard.writeText(`${window.location.origin}/clips/${clip.id}`);
		const url = new URL(`${window.location.origin}/clips/${clip.id}`);
		if (clip?.sharingToken?.active) {
			url.searchParams.append('vt', clip.sharingToken.token);
		}
		navigator.clipboard.writeText(url.toString());
		showSuccessNotification({ message: 'Link copied to clipboard.' });
	};

	if (error) return <div>Failed to load clip data</div>;
	if (isLoading) return <Loader />;

	return (
		<div className={classes.container}>
			<main className={classes.main}>
				<div className={classes.titleRow}>
					<h1 className={classes.title}>{title}</h1>
					<Group noWrap spacing={'xs'}>
						{isOwner ? (
							<Button onClick={handleShare} radius={'md'}>
								Share
							</Button>
						) : (
							<CopyButton value={copyLinkUrl} timeout={2000}>
								{({ copied, copy }) => (
									<Tooltip
										label={copied ? 'Copied' : 'Copy link'}
										withArrow
										position='right'
									>
										<ActionIcon onClick={copy}>
											{copied ? (
												<FontAwesomeIcon icon={faCheck} />
											) : (
												<FontAwesomeIcon icon={faLink} />
											)}
										</ActionIcon>
									</Tooltip>
								)}
							</CopyButton>
						)}

						{authenticated && (
							<ClipCardMenu
								size='xl'
								clip={clip}
								handleAddToCollection={handleAddToCollection}
								onCopyLink={onCopyLink}
								onEdit={handleEditClip}
								onManageAccess={handleShareClip}
							/>
						)}
					</Group>
				</div>
				<div className={classes.videoWrapper}>
					<VideoPlayer src={playbackUrlWithTimestamps} />
				</div>

				<Tabs
					defaultValue='meeting-info'
					styles={{
						root: {
							flex: 1, // fill the remaining space in main
							display: 'flex',
							flexDirection: 'column',
							overflow: 'hidden',
						},
						panel: {
							flex: 2,
						},
					}}
					ref={tabsRootRef}
				>
					<Tabs.List ref={tabsListRef}>
						<Group position='apart' noWrap w={'100%'} spacing={0}>
							<Group spacing={0} noWrap>
								<Tabs.Tab value='meeting-info'>Meeting Info</Tabs.Tab>
								<Tabs.Tab value='clip-description'>Clip Description</Tabs.Tab>
							</Group>
							{authenticated && (
								<Button
									onClick={() => navigate(`/meetings/${clip?.meetingID}/clips`)}
									ml={'auto'}
									variant='transparent'
									c={'primary-cta'}
									px={0}
									size={isMobile ? 'xs' : 'sm'}
								>
									View full meeting
								</Button>
							)}
						</Group>
					</Tabs.List>

					<Tabs.Panel value='meeting-info'>
						<ClipInfo
							title={title}
							creator={`${clip?.owningUser?.firstName} ${clip?.owningUser?.lastName}`}
							host={meetingHost}
							meetingTitle={name}
							avatarUrl={avatarUrl}
							date={formattedMeetingDate}
						/>
					</Tabs.Panel>

					<Tabs.Panel value='clip-description'>
						<ClipSummary summary={summary} height={summaryHeight} />
					</Tabs.Panel>
				</Tabs>

				<ShareClipModal
					opened={shareModalOpened}
					onClose={closeShareModal}
					selectedClip={clip}
					onClipUpdate={handleClipUpdate}
					organizer={clip?.meeting?.organizer}
					isClipOwner={clip?.owningUserID === user.id}
				/>

				<EditClipModal
					opened={editModalOpened}
					onClose={closeEditModal}
					selectedClip={clip}
					currentMeetingData={meeting}
					videoRef={videoRef}
					mutate={mutate}
				/>

				<AddToCollectionModal
					opened={addToCollectionModalOpened}
					close={() => setAddToCollectionModalOpened(false)}
					clip={clip}
					mutate={mutate}
				/>
			</main>
		</div>
	);
}
