import { useState, useRef, useEffect } from 'react';
import {
	Modal,
	Button,
	Text,
	Stack,
	createStyles,
	Group,
	Divider,
	Select,
	RangeSlider,
	Box,
	Checkbox,
	Textarea,
	ActionIcon,
	Tooltip,
	TextInput,
	Input,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faScissors,
	faUser,
	faImage,
	faPaperPlaneTop,
	faInfoCircle,
	faArrowLeft,
	faArrowRight,
	faPipe,
	faClock,
} from '@fortawesome/pro-regular-svg-icons';
import { useForm } from '@mantine/form';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { teamDataAtom } from '../../../Atoms/userAtoms';
import { addUsersToClip, createVideoClip } from '../../../api/clips';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import ModalTitle from '../../Titles/ModalTitle';
import { logger } from '../../../helpers/logger';
import { UsersMultiSelect } from '../../UsersMultiSelect';
import {
	currentMeeting,
	currentMeetingDistributionList,
	meetingClipsAtom,
	videoPlayerCurrentTime,
} from '../../../Atoms/meetingAtom';
import { MILLISECONDS_PER_SECOND } from '../../constants';
import ReelayVideoPlayer from '../../../pages/meeting/components/ReelayVideoPlayer';
import { ShareWithOthersModal } from './ShareWithOthersModal';
import { AddToCollectionModal } from './AddToCollectionModal';
import { VideoClip } from '../../../Atoms/clips';
import { useCollections } from '../../../customHooks/useCollections';
import ClipVideoPlayer from './ClipVideoPlayer';
import { ANYONE_WITH_LINK } from '../../../helpers/settings/settings.constants';
import { useId } from '@mantine/hooks';
import { IMaskInput } from 'react-imask';
import { formatSecondsToTime, parseTimeToSeconds } from '../../../_utils/time';

const TimeInputSection = ({
	label,
	value,
	onChange,
	error,
	onSetCurrentTime,
	duration,
}: {
	label: string;
	value: string;
	onChange: (value: string) => void;
	error: string;
	onSetCurrentTime: () => void;
	duration: number;
}) => {
	const id = useId();
	const durationIsOneHour = duration >= 3600;

	return (
		<Input.Wrapper id={id} w={durationIsOneHour ? 150 : 90}>
			<Input<any>
				component={IMaskInput}
				mask={durationIsOneHour ? '00:00:00' : '00:00'}
				id={id}
				placeholder={durationIsOneHour ? 'HH:MM:SS' : 'MM:SS'}
				value={value}
				onChange={(e) => onChange(e.currentTarget.value)}
				error={error}
				rightSection={
					<Tooltip
						label={`Set ${label.toLowerCase()} to current video player time`}
					>
						<ActionIcon
							variant='transparent'
							onClick={onSetCurrentTime}
							style={{ cursor: 'pointer' }}
							size={'md'}
						>
							<FontAwesomeIcon icon={faClock} />
						</ActionIcon>
					</Tooltip>
				}
			/>
		</Input.Wrapper>
	);
};

const useStyles = createStyles((theme) => ({
	preview: {
		width: '100%',
		aspectRatio: '16/9',
		backgroundColor: theme.colors.dark[7],
		borderRadius: theme.radius.md,
		overflow: 'hidden',
	},
	video: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
	},
	createButton: {
		height: 40,
		borderRadius: theme.radius.md,
		padding: '0 24px',
		backgroundColor: theme.fn.primaryColor(),
		color: theme.white,
		fontWeight: 500,
		'&:hover': {
			backgroundColor: theme.fn.primaryColor(),
		},
	},
	divider: {
		borderTopColor: theme.colors['background-gray-2'][3],
		margin: `${theme.spacing.md}px 0`,
	},
	timeLabel: {
		position: 'absolute',
		top: '18%',
		transform: 'translateY(-50%)',
		color: theme.colors['secondary-text'][0],
		fontSize: theme.fontSizes.sm,
		fontWeight: 500,
	},
	startTimeLabel: {
		left: '3%',
	},
	endTimeLabel: {
		right: '3%',
	},
	dateInput: {
		borderRadius: '8px',
		fontSize: '12px',
		width: '120px',
		height: '40px',
	},
}));

interface CreateClipModalProps {
	opened: boolean;
	onClose: () => void;
	videoUrl: string;
	meetingID: string;
}

export function CreateClipModal({
	opened,
	onClose,
	videoUrl,
	meetingID,
}: CreateClipModalProps) {
	const { classes, theme } = useStyles();
	const videoRef = useRef<HTMLVideoElement>(null);
	const [meeting, setMeeting] = useRecoilState(currentMeeting);
	const setClips = useSetRecoilState(meetingClipsAtom);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [shareChecked, setShareChecked] = useState(false);
	const [addToCollectionChecked, setAddToCollectionChecked] = useState(false);
	// const [message, setMessage] = useState('');
	const [thumbnailTime, setThumbnailTime] = useState(0);
	const [sharingOption, setSharingOption] = useState(ANYONE_WITH_LINK);
	const { videoMetadata, organizationID } = meeting;
	const { duration, playbackURL } = videoMetadata;
	const durationInSeconds = Math.floor(duration / MILLISECONDS_PER_SECOND);
	const [timeRange, setTimeRange] = useState<[number, number]>([
		0,
		durationInSeconds,
	]);
	const [timeRangeEnd, setTimeRangeEnd] = useState<[number, number]>([
		0,
		durationInSeconds,
	]);

	const [shareModalOpened, setShareModalOpened] = useState(false);
	const [addToCollectionModalOpened, setAddToCollectionModalOpened] =
		useState(false);
	const [modalsQueue, setModalsQueue] = useState<string[]>([]);
	const [newClip, setNewClip] = useState<VideoClip | null>(null);
	const { addContentToCollection } = useCollections(false);
	const currentTime = useRecoilValue(videoPlayerCurrentTime);

	const [startTimeString, setStartTimeString] = useState(
		formatSecondsToTime(timeRange[0])
	);
	const [endTimeString, setEndTimeString] = useState(
		formatSecondsToTime(timeRange[1])
	);
	const [startError, setStartError] = useState('');
	const [endError, setEndError] = useState('');

	const form = useForm({
		initialValues: {
			title: '',
			// viewers: [],
		},
		validate: {
			title: (value) => (value ? null : 'Title is required.'),
			// viewers: (value) =>
			// 	value.length ? null : 'At least one viewer is required.',
		},
	});

	useEffect(() => {
		if (videoRef.current) {
			const handleLoadedMetadata = () => {
				const duration = videoRef.current?.duration;
				setTimeRange([0, duration]);
			};
			videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
			return () => {
				videoRef.current?.removeEventListener(
					'loadedmetadata',
					handleLoadedMetadata
				);
			};
		}
	}, []);

	useEffect(() => {
		if (!opened) {
			const initialStart = 0;
			const initialEnd = durationInSeconds;
			setTimeRange([initialStart, initialEnd]);
			setStartTimeString(formatSecondsToTime(initialStart)); // Set initial string
			setEndTimeString(formatSecondsToTime(initialEnd)); // Set initial string
			form.reset();
			setShareChecked(false);
			setAddToCollectionChecked(false);
			setThumbnailTime(0);
			setSharingOption(ANYONE_WITH_LINK);
		} else {
			setTimeRange([currentTime, durationInSeconds]);
			setStartTimeString(formatSecondsToTime(currentTime));
		}
	}, [opened]);

	useEffect(() => {
		// whenever the timeRange changes, we should update the video player's current time
		if (videoRef.current) {
			videoRef.current.currentTime = timeRange[0];
		}
	}, [timeRange]);

	useEffect(() => {
		if (modalsQueue.length) {
			const firstItem = modalsQueue[0];
			if (firstItem === 'share') {
				setShareModalOpened(true);
			} else if (firstItem === 'collection') {
				setAddToCollectionModalOpened(true);
			}
		}
	}, [modalsQueue]);

	useEffect(() => {
		if (
			!shareModalOpened &&
			!addToCollectionModalOpened &&
			modalsQueue.length
		) {
			setModalsQueue((queue) => queue.slice(1));
		}
	}, [shareModalOpened, addToCollectionModalOpened]);

	const formatTime = (seconds: number) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = Math.floor(seconds % 60);
		return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
	};

	const handleRangeChange = (values: [number, number]) => {
		setTimeRange(values);
		setStartTimeString(formatSecondsToTime(values[0]));
	};

	const handleRangeChangeEnd = (values: [number, number]) => {
		setTimeRangeEnd(values);
		setEndTimeString(formatSecondsToTime(values[1]));
	};

	const handleCreateVideoClip = async () => {
		try {
			if (startError || endError) {
				showFailureNotification({
					message: 'Invalid start or end time',
				});
				return;
			}
			setButtonLoading(true);
			// const emails = flattenValidateAndFilterInvitees(form.values.viewers);

			const clipData = await createVideoClip(
				{
					meetingID,
					startTime: Math.floor(timeRange[0]),
					endTime: Math.floor(timeRange[1]),
					thumbnailTime: thumbnailTime || timeRange[0] + 1,
					title: form.values.title,
				},
				organizationID
			);

			setClips((clips) =>
				[...clips, clipData].sort((a, b) => a.startTime - b.startTime)
			);
			const optionalModals = [];
			if (shareChecked) {
				optionalModals.push('share');
			}
			if (addToCollectionChecked) {
				optionalModals.push('collection');
			}
			setModalsQueue(optionalModals);
			setNewClip(clipData);
			showSuccessNotification({
				title: 'Success!',
				message: 'Video clip created.',
			});
			form.reset();
			// setNotifyPeopleChecked(false);
			onClose();
		} catch (error) {
			showFailureNotification({ message: 'Failed to share video clip' });
			logger('error', 'Failed to share video clip', error);
		} finally {
			setButtonLoading(false);
		}
	};

	// const finalPlaybackURL = buildVideoClipsPlaybackUrl(
	// 	playbackURL,
	// 	timeRange[0],
	// 	timeRange[1]
	// );

	const handleSetThumbnail = () => {
		if (videoRef.current) {
			setThumbnailTime(Math.floor(videoRef.current.currentTime));
			showSuccessNotification({
				message: 'Thumbnail set.',
			});
		}
	};

	const onShare = async ({ emails, message, sharingOption }) => {
		try {
			const {
				videoClipUserLinks,
				token: sharingToken,
				message: returnMessage,
			} = await addUsersToClip({
				emails,
				videoClipID: newClip.id,
				organizationID,
				message,
				sharingOption,
			});

			const invitedUserLinks = videoClipUserLinks;

			if (!Array.isArray(invitedUserLinks)) {
				showFailureNotification({
					message: returnMessage || 'Failed to share video clip',
				});
				return;
			}
			const updatedClip = {
				...newClip,
				sharingToken,
				videoClipUserLinks: invitedUserLinks.map((link) => ({
					...link,
					firstName: link.user.firstName,
					lastName: link.user.lastName,
					email: link.user.email,
					userID: link.user.id,
					avatarImageURL: link.user.avatarImageURL,
				})),
			};
			setNewClip(updatedClip);
			setClips((prevClips) =>
				prevClips.map((clip) =>
					clip.id === updatedClip.id ? updatedClip : clip
				)
			);
			showSuccessNotification({
				title: 'Success!',
				message: 'Video clip shared successfully',
			});
		} catch (error) {
			console.log('onShare error', error);
			showFailureNotification({
				message: 'Failed to share video clip',
			});
			logger('error', 'Failed to share video clip', error);
		}
	};

	const validateTime = (timeString: string, isStart: boolean) => {
		// First validate the format structure
		const timeFormatRegex =
			durationInSeconds >= 3600
				? /^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/ // HH:MM:SS
				: /^[0-5][0-9]:[0-5][0-9]$/; // MM:SS

		if (!timeFormatRegex.test(timeString)) {
			return durationInSeconds >= 3600
				? 'Invalid format (HH:MM:SS required)'
				: 'Invalid format (MM:SS required)';
		}

		// Then validate the numeric values
		const seconds = parseTimeToSeconds(timeString);

		if (seconds < 0) return 'Time cannot be negative';
		if (seconds > durationInSeconds) return 'Exceeds video duration';

		if (isStart && seconds >= timeRange[1]) {
			return 'Must be before end time';
		}
		if (!isStart && seconds <= timeRange[0]) {
			return 'Must be after start time';
		}

		return '';
	};

	const handleStartChange = (value: string) => {
		setStartTimeString(value);
		const seconds = parseTimeToSeconds(value);
		const error = validateTime(value, true);
		console.log('handleStartChange error', error);
		setStartError(error);

		if (!error && !isNaN(seconds)) {
			console.log('handleStartChange setting time range', [
				seconds,
				timeRange[1],
			]);
			setTimeRange([seconds, timeRange[1]]);
		}
	};

	const handleEndChange = (value: string) => {
		setEndTimeString(value);
		const seconds = parseTimeToSeconds(value);
		const error = validateTime(value, false);
		setEndError(error);

		if (!error && !isNaN(seconds)) {
			setTimeRange([timeRange[0], seconds]);
		}
	};

	const handleSetCurrentStart = () => {
		if (!videoRef.current) return;
		const currentTime = Math.floor(videoRef.current.currentTime);
		console.log('handleSetCurrentStart currentTime', currentTime);
		const newEndTime = Math.max(currentTime, timeRange[1]);
		setTimeRange([currentTime, newEndTime]);
		setStartTimeString(formatSecondsToTime(currentTime));
		setEndTimeString(formatSecondsToTime(newEndTime));
	};

	const handleSetCurrentEnd = () => {
		if (!videoRef.current) return;
		const currentTime = Math.floor(videoRef.current.currentTime);
		const newStart = Math.min(currentTime, timeRange[0]);
		setTimeRange([newStart, currentTime]);
		setStartTimeString(formatSecondsToTime(newStart));
		setEndTimeString(formatSecondsToTime(currentTime));
	};

	return (
		<>
			<Modal
				opened={opened}
				onClose={onClose}
				title={
					<ModalTitle
						text='Create a Clip'
						icon={<FontAwesomeIcon icon={faScissors} size='xl' />}
					/>
				}
				size='lg'
				radius='md'
				zIndex={10000}
				styles={(theme) => ({
					header: {
						borderRadius: theme.spacing.lg,
					},
					close: {
						color: theme.colors['background-gray-2'][6],
					},
				})}
			>
				<Stack spacing='md'>
					<Divider className={classes.divider} />

					<Text weight={500}>
						Drag time boxes to select times to trim your video:
					</Text>

					<Box sx={{ position: 'relative', marginTop: '2.75rem' }}>
						<RangeSlider
							min={10}
							max={durationInSeconds}
							inverted={false}
							step={1}
							value={timeRange}
							onChange={handleRangeChange}
							onChangeEnd={handleRangeChangeEnd}
							// labelAlwaysOn
							label={null}
							thumbChildren={[
								<FontAwesomeIcon
									key={1}
									icon={faPipe}
									size='lg'
									style={{ color: 'white' }}
								/>,
								<FontAwesomeIcon
									key={2}
									icon={faPipe}
									size='lg'
									style={{ color: 'white' }}
								/>,
							]}
							styles={(theme) => ({
								root: {
									height: '48px',
								},
								track: {
									backgroundColor: theme.colors['background-gray-2'][3],
									height: '48px',
									'&::before': {
										borderRadius: 0,
										right: 0,
										left: 0,
									},
								},
								bar: {
									backgroundColor: theme.fn.primaryColor(),
									opacity: 0.3,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									borderRadius: 0,
								},
								thumb: {
									cursor: 'ew-resize',
									width: '16px',
									height: '60px',
									backgroundColor: theme.fn.primaryColor(),
									border: 'none',
									boxShadow: 'none',
									'&::before': {
										display: 'none',
									},
								},
							})}
						/>
						<Group noWrap position='center' spacing='sm'>
							<TimeInputSection
								label='End time'
								value={startTimeString}
								onChange={handleStartChange}
								error={startError}
								onSetCurrentTime={handleSetCurrentStart}
								duration={durationInSeconds}
							/>
							<Text fz={'xl'} weight={400} c={'secondary-text'}>
								-
							</Text>
							<TimeInputSection
								label='End time'
								value={endTimeString}
								onChange={handleEndChange}
								error={endError}
								onSetCurrentTime={handleSetCurrentEnd}
								duration={durationInSeconds}
							/>
						</Group>
					</Box>

					<Text weight={600} size='sm'>
						Preview your trimmed video clip:
					</Text>

					<div className={classes.preview}>
						<ClipVideoPlayer
							meeting={meeting}
							playbackURL={playbackURL}
							playerRef={videoRef}
							timeRange={timeRange}
							setTimeRange={setTimeRange}
						/>
					</div>

					<form
						onSubmit={(e) => {
							e.preventDefault();
							if (form.validate().hasErrors) return;
							handleCreateVideoClip();
						}}
					>
						<Stack spacing='md'>
							<TextInput
								required
								label='Clip Title'
								placeholder='Enter a brief description of your video clip'
								{...form.getInputProps('title')}
							/>

							<Group spacing='sm' noWrap position='apart'>
								<Group spacing={'sm'} noWrap>
									<Checkbox
										checked={shareChecked}
										onChange={(event) =>
											setShareChecked(event.currentTarget.checked)
										}
										label='Share with others'
									/>
									<Checkbox
										checked={addToCollectionChecked}
										onChange={(event) =>
											setAddToCollectionChecked(event.currentTarget.checked)
										}
										label='Add to collection'
									/>
								</Group>

								<Group spacing={0} noWrap>
									<Tooltip
										label='To set a custom thumbnail for this clip, change the video player time to the desired thumbnail time and click the thumbnail icon to the right.'
										position='top'
									>
										<ActionIcon variant='transparent' size='lg'>
											<FontAwesomeIcon
												icon={faInfoCircle}
												style={{
													color: theme.colors['primary-cta'][6],
												}}
											/>
										</ActionIcon>
									</Tooltip>
									<Tooltip label='Set current time as thumbnail' position='top'>
										<ActionIcon
											variant='transparent'
											onClick={handleSetThumbnail}
											size='lg'
										>
											<FontAwesomeIcon icon={faImage} />
										</ActionIcon>
									</Tooltip>
								</Group>
							</Group>

							<Group position='right'>
								<Button
									type='submit'
									className={classes.createButton}
									loading={buttonLoading}
									leftIcon={<FontAwesomeIcon icon={faScissors} />}
								>
									Create
								</Button>
							</Group>
						</Stack>
					</form>
				</Stack>
			</Modal>
			<ShareWithOthersModal
				opened={shareModalOpened}
				close={() => setShareModalOpened(false)}
				onShare={onShare}
				clip={newClip}
			/>
			<AddToCollectionModal
				opened={addToCollectionModalOpened}
				close={() => setAddToCollectionModalOpened(false)}
				clip={newClip}
			/>
		</>
	);
}
