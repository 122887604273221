import {
	ActionIcon,
	Button,
	createStyles,
	Group,
	Popover,
	Slider,
	Stack,
	Title,
	Text,
	TextInput,
	Loader,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBackward,
	faExpand,
	faForward,
	faPlay,
	faVolume,
	faPause,
	faVolumeXmark,
	faCheck,
	faPencil,
} from '@fortawesome/pro-solid-svg-icons';
import React, { forwardRef, useState } from 'react';
import { IMeeting } from '../../interfaces/meeting';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentUserMeetings, user } from '../../Atoms/userAtoms';
import { useFocusTrap } from '@mantine/hooks';
import { updateMeeting } from '../../api/api';
import { showNotification } from '@mantine/notifications';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../constants';
import { showFailureNotification } from '../../helpers/notifications';
import useMediaQueries from '../../customHooks/useMediaQueries';

const useStyles = createStyles(() => ({
	controlsWrapper: {
		// background:     'rgba(0,0,0,0.6)',
		borderRadius: '16px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flexGrow: 0,
		flexShrink: 0,
		zIndex: 1,
	},
	controlIcons: {
		color: 'black',
		transform: 'scale(0.9)',
		'&:hover': {
			color: '#999',
			transform: 'scale(1)',
		},
	},

	bottomControls: {
		background: 'white',
		borderBottomRightRadius: '16px',
		borderBottomLeftRadius: '16px',
	},
	bottomIcons: {
		color: 'black',
		'&:hover': {
			color: '#999',
		},
	},
	volumeControl: {
		width: 100,
	},
	time: {
		padding: 0,
		color: 'black',
		// '&:hover': {
		//   color: '#999',
		// },
		fontWeight: 'normal',
		cursor: 'auto',
	},
}));

interface Props {
	name: string;
	onPlayPause: () => void;
	playing: boolean;
	onRewind: () => void;
	onForward: () => void;
	onMute: () => void;
	muted: boolean;
	onVolumeChange: (arg0: number) => void;
	onVolumeSeekUp: (arg0: number) => void;
	playbackRate: number;
	onPlaybackRateChange: (arg0: number) => void;
	onToggleFullScreen: () => void;
	played: number;
	onSeek: (arg0: number) => void;
	onSeekMouseDown: () => void;
	onSeekMouseUp: (newValue: number) => void;
	totalDuration: string;
	elapsedTime: string;
	volume: number;
	meeting: IMeeting;
	setMeeting: React.Dispatch<React.SetStateAction<IMeeting>>;
	nameInputWidth: number;
	audio_enabled?: boolean;
	// isSupport: boolean,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PlayerControls = forwardRef(
	(
		{
			name,
			onPlayPause,
			playing,
			onRewind,
			onForward,
			onMute,
			muted,
			onVolumeChange,
			onVolumeSeekUp,
			playbackRate,
			onPlaybackRateChange,
			onToggleFullScreen,
			played,
			onSeek,
			onSeekMouseDown,
			onSeekMouseUp,
			totalDuration,
			elapsedTime,
			meeting,
			setMeeting,
			nameInputWidth,
			audio_enabled = true,
		}: // isSupport
		Props,
		ref: never
	) => {
		const { classes } = useStyles();
		const currentUser = useRecoilValue(user);
		const [editingName, setEditingName] = useState(false);
		const [meetingName, setMeetingName] = useState(name);
		const focusTrapRef = useFocusTrap();
		const { widthOrHeightLessThanLG } = useMediaQueries();
		const [currentMeetings, setCurrentMeetings] =
			useRecoilState(currentUserMeetings);
		const [loading, setLoading] = useState(false);

		const handleInputKeyPress = (e) => {
			if (e.key === 'Enter') {
				// The Enter key was pressed, trigger the button click or form submit here
				e.preventDefault(); // Prevent the default form submission behavior
				updateMeetingName(); // Call your custom submit function
			}
		};

		const updateMeetingName = async () => {
			try {
				setLoading(true);
				// update meeting info (objective, agenda, or summary)
				const updatedMeeting = await updateMeeting(
					{
						name: meetingName,
					},
					meeting.id,
					meeting.organizationID
				);

				setMeeting(updatedMeeting.data.data);

				// update meeting in array
				setCurrentMeetings(
					currentMeetings.map((meeting: IMeeting) => {
						if (meeting.id === updatedMeeting.data.data.id)
							return updatedMeeting.data.data;
						return meeting;
					})
				);

				setEditingName(false);
			} catch {
				showFailureNotification({
					message:
						'Sorry, there was an error when updating the meeting name. Please try again.',
				});
			} finally {
				setLoading(false);
			}
		};

		return (
			<div className={classes.controlsWrapper}>
				{/* Middle Controls */}

				{/* Bottom Controls */}
				<Stack align={'normal'} className={classes.bottomControls} spacing={0}>
					<Group position='apart' grow={true}>
						<Slider
							color={'primary'}
							size={'md'}
							value={played * 100}
							label={elapsedTime}
							onChange={onSeek}
							onMouseDown={onSeekMouseDown}
							onChangeEnd={onSeekMouseUp}
							min={0}
							step={1}
						/>
					</Group>
					<Group
						position={'apart'}
						mx={widthOrHeightLessThanLG ? 'sm' : 'xl'}
						mb={widthOrHeightLessThanLG ? 0 : 'lg'}
						noWrap
					>
						<Group position={'left'} noWrap>
							<ActionIcon
								onClick={onPlayPause}
								variant='transparent'
								className={classes.bottomIcons}
							>
								{playing ? (
									<FontAwesomeIcon icon={faPause} />
								) : (
									<FontAwesomeIcon icon={faPlay} />
								)}
							</ActionIcon>
							<Button variant={'subtle'} className={classes.time}>
								{elapsedTime}/{totalDuration}
							</Button>
						</Group>
						<Group noWrap spacing={widthOrHeightLessThanLG ? 'xs' : 'md'}>
							<ActionIcon
								onClick={onRewind}
								variant='transparent'
								className={classes.controlIcons}
							>
								<FontAwesomeIcon icon={faBackward} />
							</ActionIcon>
							<ActionIcon
								onClick={onForward}
								variant='transparent'
								className={classes.controlIcons}
							>
								<FontAwesomeIcon icon={faForward} />
							</ActionIcon>
							<Popover width={100} position='top' withArrow shadow='md'>
								<Popover.Target>
									<Button p={0} variant={'subtle'}>
										{playbackRate}x
									</Button>
								</Popover.Target>
								<Popover.Dropdown>
									<Stack>
										{/* eslint-disable-next-line no-magic-numbers */}
										{[2, 1.5, 1.25, 1, 0.5].map((rate) => (
											<Button
												key={rate}
												onClick={() => onPlaybackRateChange(rate)}
												variant={'subtle'}
												color={rate === playbackRate ? 'red' : 'primary'}
											>{`${rate}x`}</Button>
										))}
									</Stack>
								</Popover.Dropdown>
							</Popover>
							<ActionIcon
								disabled={!audio_enabled}
								onClick={onMute}
								variant='transparent'
								className={classes.bottomIcons}
							>
								{muted ? (
									<FontAwesomeIcon icon={faVolumeXmark} />
								) : (
									<FontAwesomeIcon icon={faVolume} />
								)}
							</ActionIcon>
							<ActionIcon
								onClick={onToggleFullScreen}
								variant='transparent'
								className={classes.bottomIcons}
							>
								<FontAwesomeIcon icon={faExpand} />
							</ActionIcon>
						</Group>
					</Group>
				</Stack>
			</div>
		);
	}
);
export default PlayerControls;
