import { createStyles, Grid, Text } from '@mantine/core';
import UserCard from './UserCard';
import { InvitedUser } from '../../interfaces/user';
import { IOrganizer } from '../../interfaces/meeting';

interface Props {
	users: (InvitedUser | [string | null, string | null, string | null])[];
	gridColSpan: number;
	searchValue?: string;
	emptyText: string;
	handleRemoveUser?: (email: string) => void;
	handleRemoveUserByID?: (id: string) => void;
	inviteUser?: ({
		email,
		firstName,
		lastName,
	}: {
		email: string;
		firstName: string;
		lastName: string;
	}) => void;
	isAttendees?: boolean;
	showLinkedIn?: boolean;
	hideIcons?: boolean;
	organizer?: IOrganizer;
	owningUserID?: string;
	height?: string;
}

const useStyles = createStyles((theme) => ({
	container: {
		maxHeight: '40%',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	grid: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
}));

const UserList = ({
	users,
	gridColSpan,
	emptyText,
	handleRemoveUser,
	handleRemoveUserByID,
	inviteUser,
	searchValue = '',
	isAttendees = false,
	showLinkedIn = true,
	hideIcons = false,
	organizer = null,
	owningUserID = null,
	height = 'auto',
}: Props) => {
	const { classes } = useStyles();

	return (
		<>
			{users.length ? (
				<div className={classes.container} style={{ height }}>
					<Grid className={classes.grid} gutter={0}>
						{users.map((user, index) => {
							const uniqueKey = `${index}-${Math.random()}`;
							if (Array.isArray(user)) {
								const [email = '', firstName = '', lastName = ''] = user;
								return (
									<UserCard
										key={uniqueKey}
										gridColSpan={12}
										email={email}
										firstName={firstName}
										lastName={lastName}
										searchValue={searchValue}
										handleRemoveUser={handleRemoveUser}
										handleRemoveUserByID={handleRemoveUserByID}
										isAttendees={isAttendees}
										showLinkedIn={showLinkedIn}
										organizer={organizer}
										owningUserID={owningUserID}
										inviteUser={inviteUser}
										hideIcons={hideIcons}
									/>
								);
							}
							const {
								email = '',
								firstName = '',
								lastName = '',
								id = '',
								userID = '',
							} = user;
							const userIDToUse = id.startsWith('USR') ? id : userID;
							return (
								<UserCard
									key={uniqueKey}
									gridColSpan={12}
									email={email}
									firstName={firstName}
									lastName={lastName}
									userID={userIDToUse}
									searchValue={searchValue}
									handleRemoveUser={handleRemoveUser}
									handleRemoveUserByID={handleRemoveUserByID}
									id={id}
									isAttendees={isAttendees}
									showLinkedIn={showLinkedIn}
									organizer={organizer}
									owningUserID={owningUserID}
									inviteUser={inviteUser}
									hideIcons={hideIcons}
								/>
							);
						})}
					</Grid>
				</div>
			) : (
				<Text size={14} align={'left'}>
					{emptyText}
				</Text>
			)}
		</>
	);
};

export default UserList;
