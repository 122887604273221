import React from 'react';
import { Header, Group, Badge, Burger } from '@mantine/core';
import { Logo } from '../../reelayLogo';
import MobileHeader from '../../mobileHeader';
import CreateReelayModal from '../../create_reelay_modal';
import { NotificationsPopover } from '../../Popovers/NotificationsPopover';
import { AvatarHeaderWithMenu } from '../../AvatarHeaderWithMenu';

export default function AppShellHeader({
	authenticated,
	isMobile,
	isTabletOrSmaller,
	navbarOpened,
	setNavbarOpened,
	theme,
	atLeastOneCalendarSyncing,
	syncingCompleted,
	timerExpired,
	seeUploadMeetingButton,
	logout,
}) {
	return !authenticated ? (
		<Header
			height={70}
			style={{
				boxShadow: '0 3px 12px 0 rgba(30, 30, 30, 0.08)',
				paddingLeft: 16,
			}}
		>
			<Logo />
		</Header>
	) : isMobile ? (
		<MobileHeader />
	) : (
		<Header height={70} p={26} style={{ border: '1px solid #E1E1E1' }}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					height: '100%',
					justifyContent: 'space-between',
				}}
			>
				{isTabletOrSmaller && (
					<Burger
						opened={navbarOpened}
						onClick={() => setNavbarOpened((o) => !o)}
						size='sm'
						color={theme.colors.gray[6]}
						mr='xl'
					/>
				)}
				<Logo addPoweredByCaption={true} pl={0} pr={0} />
				<Group noWrap>
					{atLeastOneCalendarSyncing ? (
						<Badge variant='dot'>SYNCING CALENDAR</Badge>
					) : syncingCompleted && !timerExpired ? (
						<Badge variant='dot' color='green'>
							CALENDAR SYNCED
						</Badge>
					) : null}
					{seeUploadMeetingButton && <CreateReelayModal />}
					<NotificationsPopover />
					{/* <AvatarHeaderWithMenu logout={logout} /> */}
				</Group>
			</div>
		</Header>
	);
}
