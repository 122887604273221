import React from 'react';
import { Center, Container, Image, Stack, Text } from '@mantine/core';
import CreateCollectionButton from '../../../../../../components/Buttons/CreateButton';
import { FOLDERS_IMAGE } from '../../../CollectionTable';

interface Props {
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
	searching: boolean;
	isCollectionOwner: boolean;
}

export default function EmptyCollectionPage({
	setOpened,
	searching,
	isCollectionOwner,
}: Props) {
	return (
		<Center sx={() => ({ height: 500 })}>
			<Container>
				<Stack align='center' justify='center'>
					<Image maw={200} src={FOLDERS_IMAGE} alt={'A folder'} />
					<Text color={'reelay-secondary-dark'} align='center'>
						{searching ? (
							'No meetings found.'
						) : isCollectionOwner ? (
							'This collection has no content.'
						) : (
							<>
								Sorry, it looks like the collection owner has not added any
								meetings yet.
								<br />
								Once they do, you will be able to see it here.
							</>
						)}
					</Text>
					{isCollectionOwner ? (
						<CreateCollectionButton
							setOpened={setOpened}
							text={'Add a Meeting or Clip'}
						/>
					) : null}
				</Stack>
			</Container>
		</Center>
	);
}
