import { Paper, Stack, Group, Text, createStyles } from '@mantine/core';
import MeetingDetailsTabSection from '../MeetingDetailsTabSection/MeetingDetailsTabSection';
import { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentOrganizationID,
	currentUser,
	currentUserMeetings,
} from '../../../../Atoms/userAtoms';
import { getMeetingHost } from '../../../../helpers/meetings';
import { useParams } from 'react-router';
import { inviteUsersToMeeting, sendAnalyticEvent } from '../../../../api/api';
import { ShareButton } from '../../../../components/Buttons/ShareButton';
import { isMeetingOwnerOrProjectManager } from '../../../../helpers/auth';
import { logger } from '../../../../helpers/logger';
import {
	attendeesList,
	createClipModalOpenedAtom,
	currentMeeting,
	currentMeetingDistributionList,
} from '../../../../Atoms/meetingAtom';

import AddUserModal from '../../../../components/AddUserModal';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { useDisclosure } from '@mantine/hooks';
import MeetingMinutesModal from '../../../../components/Modals/MeetingMinutesModal';
import useMediaQueries from '../../../../customHooks/useMediaQueries';
import ReelayVideoPlayer from '../ReelayVideoPlayer';
import { isAuthenticated } from '../../../../Atoms/auth';
import { useSearchParams } from 'react-router-dom';
import { CreateClipModal } from '../../../../components/Modals/CreateClipModals/CreateClipModal';

const useStyles = createStyles(() => ({
	enabled: {
		cursor: 'pointer',
	},
	disabled: {
		cursor: 'none',
		pointerEvents: 'none',
		color: 'gray',
	},
	date: {
		position: 'relative',
		bottom: '10px',
	},
	actionButton: {
		cursor: 'pointer',
	},
}));

interface Props {
	isGuestViewing: boolean;
	openSharingModal: () => void;
	openRequestsModal: () => void;
	openManageAccessModal: () => void;
	openMyLinksModal: () => void;
	name: string;
	setName: (value: string) => void;
	outerContainerRef: React.RefObject<HTMLDivElement | null>;
}

export default function MeetingLeftCard({
	isGuestViewing,
	openManageAccessModal,
	openMyLinksModal,
	openRequestsModal,
	openSharingModal,
	name,
	outerContainerRef,
	setName,
}: Props) {
	const { classes, theme } = useStyles();
	const { meetingID } = useParams();
	const { widthOrHeightLessThanLG, maxMediumBreakpoints } = useMediaQueries();
	const [meeting, setMeeting] = useRecoilState(currentMeeting);
	const {
		id,
		owningUserID,
		meetingDate,
		publishedAt,
		status,
		author,
		organizer,
		videoMetadata,
		viewingDeadline,
	} = meeting;
	const nameRef = useRef(null);
	const playerRef = useRef<any>(null);
	const setDistributionList = useSetRecoilState(currentMeetingDistributionList);
	const meetings = useRecoilValue(currentUserMeetings);
	const user = useRecoilValue(currentUser);
	const organizationID = useRecoilValue(currentOrganizationID);
	const [createClipModalOpened, setCreateVideoModalOpened] = useRecoilState(
		createClipModalOpenedAtom
	);
	const meetingPublished = status === 'published';

	const isMeetingHostOrPM = isMeetingOwnerOrProjectManager(
		organizer,
		owningUserID,
		user.id,
		user.roles
	);
	const [
		minutesModalOpened,
		{ open: openMinutesModal, close: closeMinutesModal },
	] = useDisclosure(false);
	const [opened, setOpened] = useState(false);
	const [newUserValues, setNewUserValues] = useState({
		firstName: '',
		lastName: '',
		email: '',
	});
	const isAuthorized = useRecoilValue(isAuthenticated);
	const [searchParams] = useSearchParams();
	const sharingToken = searchParams.get('vt');

	const timestamp = meetingDate || publishedAt;
	const date =
		typeof timestamp === 'number'
			? DateTime.fromMillis(timestamp).toFormat('MM/dd/yyyy')
			: DateTime.fromISO(timestamp).toFormat('MM/dd/yyyy');
	const hostName = getMeetingHost(organizer, author);

	/**
	 * When meeting is opened as a status 'published', then fire
	 * analytic event that meeting was opened.
	 */
	useEffect(() => {
		if (meetingPublished) {
			sendAnalyticEvent(
				{
					name: 'reelay.video',
					sourceID: id,
					targetID: videoMetadata?.id,
					data: {
						type: 'meeting opened',
						startTime: 0,
					},
				},
				organizationID,
				sharingToken
			);
		}
	}, [meeting]);

	const inviteUser = async ({
		email,
		firstName,
		lastName,
	}: {
		email: string;
		firstName: string;
		lastName: string;
	}) => {
		try {
			if (!email) {
				// if user is null or not found, show modal for them to add email.
				// need to send firstName and lastName to the modal
				setOpened(true);
				setNewUserValues({ email, firstName, lastName });
				return;
			}
			// invite invitees to meet
			const res = await inviteUsersToMeeting({
				emails: [email],
				meetingID: id,
				organizationID: organizationID,
			});
			const invitedUsers = res.data.data;
			logger('info', 'users added to meeting', invitedUsers);
			// need to update viewers list with new users.
			setDistributionList((prev) => {
				const result = [...prev, ...invitedUsers].filter(
					(item, index, array) => {
						return array.findIndex((i) => i.id === item.id) === index;
					}
				);
				return result;
			});
			showSuccessNotification({
				message:
					'Attendee was successfully invited to this meeting. Great work!',
			});
		} catch (error: unknown) {
			logger('error', 'failed to invite user to meeting', error);
			showFailureNotification({
				message:
					"Sorry, there was an issue while trying to invite user to the meeting. Please try inviting them again, and if you encounter further difficulties, don't hesitate to contact our support team for assistance.",
			});
		}
	};

	const responsiveWidth = widthOrHeightLessThanLG
		? 500
		: maxMediumBreakpoints
		? 400
		: 600;

	const flexValue = 4;
	const HEADER_FONT_SIZE = 20;
	const leftCardWidth =
		outerContainerRef.current?.clientWidth - responsiveWidth;

	return (
		<div
			style={{
				position: 'relative',
				height: '100%',
				boxShadow: 'none',
				flex: flexValue,
				display: 'flex',
				width: `${leftCardWidth}px`,
			}}
		>
			<Paper
				h={'100%'}
				sx={(theme) => ({
					borderTopLeftRadius: theme.spacing.lg,
					borderTopRightRadius: theme.spacing.lg,
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				})}
				p={'xl'}
				w={'100%'}
				style={{
					boxShadow: 'none',
					flex: flexValue,
					display: 'flex',
				}}
				bg={theme.colors['background-gray-2'][0]}
			>
				<Stack
					w={'100%'}
					h={'100%'}
					spacing={widthOrHeightLessThanLG ? 'xs' : 'md'}
				>
					<Stack spacing={isMeetingHostOrPM ? 0 : 'xs'}>
						<Group position={'apart'} pb={0} align='flex-start' noWrap>
							<Stack spacing={'xs'} justify='space-between'>
								<Text size={HEADER_FONT_SIZE} fw={700}>
									{name}
								</Text>
								<Text size={14} color='secondary-text' className={classes.date}>
									{date} |{` Hosted by ${hostName}`}
								</Text>
							</Stack>

							<Group position={'apart'} align='flex-start'>
								{!isAuthorized || isGuestViewing ? null : (
									<ShareButton
										openSharingModal={openSharingModal}
										openRequestsModal={openRequestsModal}
										openMyLinksModal={openMyLinksModal}
										openManageAccessModal={openManageAccessModal}
										isMeetingHostOrPM={isMeetingHostOrPM}
										meeting={meeting}
									/>
								)}
							</Group>
						</Group>
					</Stack>

					<Stack spacing={'sm'} justify='center' align='center' mah={'50%'}>
						<ReelayVideoPlayer
							meeting={meeting}
							playbackURL={''}
							playerRef={playerRef}
						/>
					</Stack>
					<MeetingDetailsTabSection
						meeting={meeting}
						playerRef={playerRef}
						inviteUser={inviteUser}
						openMinutesModal={openMinutesModal}
					/>
				</Stack>
			</Paper>
			{!isAuthorized || isGuestViewing ? null : (
				<div id='sharing-modals'>
					<MeetingMinutesModal
						opened={minutesModalOpened}
						close={closeMinutesModal}
						meeting={meeting}
					/>
					<AddUserModal
						opened={opened}
						setOpened={setOpened}
						newUserValues={newUserValues}
						meeting={meeting}
					/>
				</div>
			)}
			<CreateClipModal
				opened={createClipModalOpened}
				onClose={() => setCreateVideoModalOpened(false)}
				videoUrl={((meeting as any).videoUrl as any) || ''}
				meetingID={id}
			/>
		</div>
	);
}
