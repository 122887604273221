import { atom, selector, selectorFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export interface UserAddress {
	address_line_1?: string;
	address_line_2?: string;
	city?: string;
	country?: string;
	state?: string;
	zip_code?: string;
	zip?: string;
}

export interface UserSettings {
	job_title?: string;
	bio?: string;
	meeting_minutes?: boolean;
	address?: UserAddress;
	sharing?: boolean;
	agreed_with_terms?: boolean;
	time_zone?: string;
	calendar_modal_dont_ask_again?: boolean;
}

export const userSettings = atom<UserSettings>({
	key: 'userSettings',
	default: {
		job_title: '',
		bio: '',
		meeting_minutes: false,
		address: {},
		sharing: false,
		agreed_with_terms: false,
		time_zone: '',
		calendar_modal_dont_ask_again: false,
	},
});

export const defaultBrand = {
	name: '',
	logo_url: '',
	icon: '',
	color_primary: '',
	color_secondary: '',
	color_level_primary: 0,
	color_level_secondary: 0,
	sso: false,
};

export interface BrandDetails {
	name?: string;
	logo_url?: string;
	icon?: string;
	color_primary?: string;
	color_secondary?: string;
	color_level_primary?: number;
	color_level_secondary?: number;
	sso?: boolean;
}

export const organizationBrand = atom<BrandDetails>({
	key: 'organizationBrand',
	default: {
		sso: false,
	},
	// eslint-disable-next-line camelcase
	// effects_UNSTABLE: [persistAtom],
});

export interface OrganizationSettings {
	bot_name?: string;
	bot_picture?: string;
	bot_custom_join_message?: string;
	meeting_minutes?: boolean;
	sharing?: boolean;
	sharing_default?: boolean;
	internal_email_domains?: string[];
	bot_restricted_email_domains?: string[];
	distribution_preference?: string;
	skip_audit?: boolean;
	scim_enabled?: boolean;
	fallback_transferee?: string;
	sharing_clips_external?: boolean;
}

export const organizationSettings = atom<OrganizationSettings>({
	key: 'organizationSettings',
	default: {},
	// eslint-disable-next-line camelcase
	// effects_UNSTABLE: [persistAtom],
});

export const isCustomBrandedAtom = selector({
	key: 'isCustomBrandedAtom',
	get: ({ get }) => {
		const isBranded = Boolean(get(organizationBrand)?.color_primary);
		return isBranded;
	},
});

export const originalFaviconHREF = atom<string>({
	key: 'originalFaviconHREF',
	default: '',
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const organizationSettingSelector = selectorFamily({
	key: 'organizationSettingSelector',
	get:
		(settingKey: keyof OrganizationSettings) =>
		({ get }) => {
			const settings = get(organizationSettings);
			return settings[settingKey];
		},
});
export const sharingClipsDisabled = selector({
	key: 'sharingClipsDisabled',
	get: ({ get }) => {
		const sharingClipsExternally = get(
			organizationSettingSelector('sharing_clips_external')
		);
		const sharingDisabled =
			typeof sharingClipsExternally === 'boolean'
				? !sharingClipsExternally
				: false;
		return sharingDisabled;
	},
});
