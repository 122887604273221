import React, { useState } from 'react';
import { MultiSelect, Loader, SelectItem } from '@mantine/core';
import { debounce } from 'lodash';
import { searchUsers } from '../../api/api';
import { useRecoilValue } from 'recoil';
import { User, userOrganization } from '../../Atoms/userAtoms';
interface Props {
	data?: any[];
	setData?: any;
	form?: any;
	name?: string;
	clearable?: boolean;
	searchable?: boolean;
	required?: boolean;
	withAsterisk?: boolean;
	placeholder?: string;
	errorText?: string;
	labelText: string | React.ReactNode;
	descriptionText?: string;
	variantType?: string;
	creatable?: boolean;
	labelWidth?: string | number | null;
	size?: string;
	icon?: React.ReactNode;
	// autoFocus?: boolean;
}

export const UsersMultiSelect = ({
	form,
	name,
	clearable = true,
	searchable = true,
	creatable = false,
	required = false,
	withAsterisk = false,
	placeholder = '',
	errorText = 'This field is required.',
	labelText,
	labelWidth = null,
	data,
	setData,
	descriptionText = '',
	variantType = '',
	size = 'sm',
	icon = null,
}: // autoFocus = false,
Props) => {
	const [searchResults, setSearchResults] = useState([]);
	const [loading, setLoading] = useState(false);
	const [dropdownOpened, setDropdownOpened] = useState(false);
	const organization = useRecoilValue(userOrganization);
	const handleSearchChange = async (searchTerm: string) => {
		if (searchTerm.length === 0) return;

		setLoading(true);
		const usersResponse = await searchUsers(organization.id, searchTerm);
		const results = usersResponse.data.data.map((user: User) => ({
			...user,
			value: user.email,
			label: `${user.firstName} ${user.lastName}`,
			key: user.id,
		}));
		setSearchResults(results);
		setLoading(false);
	};

	return (
		<div>
			<MultiSelect
				// data-autofocus={autoFocus}
				data={data as SelectItem[]}
				{...form.getInputProps(String(name))}
				clearable={clearable}
				clearButtonProps={{ 'aria-label': 'Clear selection' }}
				searchable={searchable}
				required={required}
				withAsterisk={withAsterisk}
				placeholder={placeholder}
				creatable={creatable}
				getCreateLabel={(query) => `+ Add ${query}`}
				// error={errorText}
				label={labelText}
				rightSection={loading ? <Loader size={16} /> : null}
				// onSearchChange={debounce(handleSearchChange, 400)}
				nothingFound='Nothing found'
				// maxDropdownHeight={250}
				dropdownPosition={'flip'}
				// limit={5}
				variant={variantType}
				dropdown
				onCreate={(query) => {
					const item = { value: query, label: query, id: query };
					setData((current) => [...current, item]);
					return item;
				}}
				styles={{
					label: labelWidth ? { width: labelWidth } : {},
					dropdown: {
						zIndex: 100000000,
					},
				}}
				size={size}
				icon={icon}
			/>
		</div>
	);
};
