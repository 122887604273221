import React, { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import {
	Button,
	createStyles,
	Group,
	Loader,
	Modal,
	Pagination,
	ScrollArea,
	Stack,
	Text,
	TextInput,
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	addMeetingToCollectionModalOpenedAtom,
	archiveCollectionAtom,
} from '../../../../Atoms/collections';
import { logger } from '../../../../helpers/logger';
import ModalTitle from '../../../../components/Titles/ModalTitle';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import {
	fetchAvailableContent,
	updateCollectionContent,
} from '../../../../api/collections';
import { currentOrganizationID } from '../../../../Atoms/userAtoms';
import AddContentTable from './components/AddContentTable';
import { useDebouncedState } from '@mantine/hooks';

const useStyles = createStyles(() => ({
	outerContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: 0,
	},
}));

export default function AddContentToCollectionModal({
	mutateCollectionContent,
}: {
	mutateCollectionContent: () => void;
}) {
	const { classes } = useStyles();
	let { collectionID } = useParams();
	const [addModalOpened, setAddModalOpened] = useRecoilState(
		addMeetingToCollectionModalOpenedAtom
	);
	const [search, setSearch] = useDebouncedState('', 300);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(20);
	const [selection, setSelection] = useState([]);
	const viewport = useRef(null);
	const organizationID = useRecoilValue(currentOrganizationID);
	const archivedCollection = useRecoilValue(archiveCollectionAtom);

	if (!collectionID) {
		collectionID = archivedCollection?.id;
	}

	const fetcher = async (url) => {
		const response = await fetchAvailableContent({
			organizationID: organizationID,
			collectionID,
			search,
			limit,
			offset: (page - 1) * limit,
		});
		return response;
	};

	const { data, error, isValidating, isLoading, mutate } = useSWR(
		addModalOpened
			? `v1/collections/${collectionID}/available-content?page=${page}&search=${search}&limit=${limit}`
			: null,
		fetcher
	);

	useEffect(() => {
		// if (addModalOpened) {
		// 	mutate();
		// }
	}, [addModalOpened, search, page]);

	const handleAddContent = async () => {
		try {
			// Assume an API function for adding content to a collection
			const res = await updateCollectionContent(
				collectionID,
				selection.map((item) => ({ targetID: item }))
			);
			showSuccessNotification({
				title: 'Success!',
				message: 'Content added to collection successfully.',
			});
			setAddModalOpened(false);
			mutateCollectionContent();
		} catch (error) {
			logger('error', 'Error adding content to collection', error);
			showFailureNotification({
				message: 'Failed to add content. Please try again.',
			});
		}
	};

	const renderContent = () => {
		if (isValidating) {
			return <Loader />;
		}
		if (error) {
			return (
				<Text color='red' align='center'>
					Failed to load content. Please try again.
				</Text>
			);
		}
		return (
			<AddContentTable
				content={data?.data || []}
				selection={selection}
				setSelection={setSelection}
				loading={isLoading}
			/>
		);
	};

	const closeModal = () => {
		setAddModalOpened(false);
		setSearch('');
		setPage(1);
		setSelection([]);
	};

	return (
		<Modal
			size='60%'
			title={<ModalTitle text='Add Content' />}
			opened={addModalOpened}
			onClose={closeModal}
		>
			<div className={classes.outerContainer}>
				<Stack spacing='sm'>
					<Group position='apart' align='center' spacing='md' noWrap>
						<TextInput
							placeholder='Search content...'
							onChange={(e) => setSearch(e.target.value)}
							defaultValue={search}
							style={{ flex: 1 }}
						/>
					</Group>
				</Stack>

				<div
					style={{
						flex: 1,
						width: '100%',
						margin: 0,
						padding: 0,
						paddingTop: '16px',
					}}
				>
					<ScrollArea
						viewportRef={viewport}
						type='always'
						styles={{ viewport: { height: '100%' } }}
					>
						{renderContent()}
					</ScrollArea>
				</div>

				<Group position='apart' mt='md'>
					<Pagination
						value={page}
						onChange={setPage}
						total={Math.ceil((data?.total || 0) / limit)}
					/>
					<Button
						onClick={handleAddContent}
						disabled={!selection.length}
						loading={isValidating}
					>
						Add Selected
					</Button>
				</Group>
			</div>
		</Modal>
	);
}
