import React, { useEffect, useState } from 'react';
import { Container, createStyles } from '@mantine/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSearchParams } from 'react-router-dom';
import { decode } from 'js-base64';
import { useMediaQuery } from 'react-responsive';
import { MobileLandingPage } from '../mobileLanding';
import { clipsState, clipsView, VideoClip } from '../../Atoms/clips';
import Clips from './components/Clips';

const useStyles = createStyles((theme) => ({
	outerContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	topContainer: {
		flex: 1,
	},
	bottomContainer: {
		height: 'auto',
	},
}));

export interface ClipsRouteProps {
	segmentValue: string;
	highlightsOnly?: boolean;
}

export default function ClipsPage(props: ClipsRouteProps) {
	const { classes, theme } = useStyles();
	const { segmentValue, highlightsOnly } = props;
	const [searchParams, setSearchParams] = useSearchParams();
	const setClips = useSetRecoilState(clipsState);
	const skipParam = searchParams.get('skip');
	const skip = Number.isNaN(skipParam as unknown as number)
		? 0
		: Number(skipParam);
	const order = searchParams.get('order') ?? '';
	const searchParam = searchParams.get('search');
	const search = searchParam ? String(searchParam).trim() : '';
	const isMobile = useMediaQuery({
		query: `(max-width: ${theme.breakpoints.xs})`,
	});

	const [currentSkip, setSkip] = useState(skip);
	const [currentSearch, setSearch] = useState(searchParam);

	useEffect(() => {
		if (skip !== currentSkip) {
			setSkip(skip);
		}
		if (search !== currentSearch) {
			setSearch(search);
		}
	}, [skip, search, currentSkip, currentSearch]);

	return (
		<>
			{isMobile ? (
				<MobileLandingPage />
			) : (
				<Container className={classes.outerContainer} size={'xl'} pb={'xl'}>
					<Clips
						segmentValue={segmentValue}
						highlightsOnly={highlightsOnly}
						skip={skip}
						search={decode(search)}
						order={order}
					/>
				</Container>
			)}
		</>
	);
}
