import React from 'react';
import {
	ActionIcon,
	Group,
	Menu,
	Paper,
	Text,
	createStyles,
	Tooltip,
	AspectRatio,
	Image,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEllipsisVertical,
	faEye,
	faShare,
	faPencil,
	faUserGroup,
	faLink,
	faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { VideoClip } from '../../../../../../Atoms/clips';
import { createVideoThumbnail } from '../../../../../../helpers/data';
import { extractPlaybackID } from '../../../../../../_utils/video';
import {
	convertSecondsToHHMMSS,
	formatTimestamp,
} from '../../../../../../_utils/time';

const useStyles = createStyles((theme) => ({
	clipCard: {
		// padding: theme.spacing.md,
		marginBottom: theme.spacing.sm,
		backgroundColor:
			theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
		transition: 'all 0.2s ease',
		borderRadius: theme.radius.md,
		display: 'flex',
		alignItems: 'center',
	},
	thumbnailContainer: {
		width: '20%',
		height: '100%',
		borderTopLeftRadius: theme.radius.md,
		borderBottomLeftRadius: theme.radius.md,
	},
	clipDetails: {
		flex: 1,
		padding: theme.spacing.md,
	},
}));

interface ClipItemProps {
	clip: VideoClip;
	onPlay: (clip: any) => void;
	onQuickView: (clip: any) => void;
	onShare: (clip: any) => void;
	onEdit: (clip: any) => void;
	onCopyLink: (clip: any) => void;
	onDelete: (clipId: string) => void;
	currentUserId: string;
}

export function ClipItem({
	clip,
	onPlay,
	onQuickView,
	onShare,
	onEdit,
	onCopyLink,
	onDelete,
	currentUserId,
}: ClipItemProps) {
	const { classes, theme } = useStyles();

	const {
		id,
		startTime,
		endTime,
		title,
		owningUser,
		owningUserID,
		playbackURL,
		thumbnailTime,
	} = clip;

	const formatTimeRange = (startTime: number, endTime: number) => {
		const formatTime = (time: number) => {
			const minutes = Math.floor(time / 60);
			const seconds = Math.floor(time % 60);
			return `${minutes}:${seconds.toString().padStart(2, '0')}`;
		};
		return `${formatTime(startTime)} - ${formatTime(endTime)}`;
	};

	const isOwner = owningUserID === currentUserId;
	const thumbnailSrc =
		createVideoThumbnail(extractPlaybackID(playbackURL), thumbnailTime) ||
		'/placeholder.svg';
	const duration = formatTimestamp(endTime - startTime);

	return (
		<Paper className={classes.clipCard} shadow='sm'>
			<div className={classes.thumbnailContainer}>
				<AspectRatio ratio={16 / 9}>
					<Image
						src={thumbnailSrc}
						alt={title || 'Clip thumbnail'}
						style={{
							objectFit: 'cover',
							width: '100%',
							height: '100%',
							marginRight: theme.spacing.md,
							borderTopLeftRadius: theme.radius.md,
							borderBottomLeftRadius: theme.radius.md,
						}}
					/>
				</AspectRatio>
			</div>
			<div className={classes.clipDetails}>
				<Group position='apart' noWrap>
					<Group spacing='xs' noWrap style={{ flex: 1 }}>
						<div style={{ flex: 1 }}>
							<Text weight={600} size='sm' lineClamp={1}>
								{title}
							</Text>
							<Text size='xs'>{formatTimeRange(startTime, endTime)}</Text>
							<Text size='xs' color='dimmed'>
								{duration}
							</Text>
						</div>
						{!isOwner && (
							<Text size='xs' color='dimmed'>
								Owned by {owningUser?.firstName} {owningUser?.lastName}
							</Text>
						)}
					</Group>
					<Group spacing={0} noWrap>
						<Tooltip label='View' position='top'>
							<ActionIcon
								variant='transparent'
								onClick={() => onQuickView(clip)}
							>
								<FontAwesomeIcon icon={faEye} />
							</ActionIcon>
						</Tooltip>
						{isOwner ? (
							<Tooltip label='Share' position='top'>
								<ActionIcon variant='transparent' onClick={() => onShare(clip)}>
									<FontAwesomeIcon icon={faShare} />
								</ActionIcon>
							</Tooltip>
						) : (
							<Tooltip label='Copy link' position='top'>
								<ActionIcon
									variant='transparent'
									onClick={() => onCopyLink(clip)}
								>
									<FontAwesomeIcon icon={faLink} />
								</ActionIcon>
							</Tooltip>
						)}

						<Menu shadow='md' width={200} position='bottom-end'>
							<Menu.Target>
								<ActionIcon variant='transparent' w={14} miw={14}>
									<FontAwesomeIcon icon={faEllipsisVertical} />
								</ActionIcon>
							</Menu.Target>
							<Menu.Dropdown>
								{isOwner && (
									<>
										<Menu.Item
											icon={<FontAwesomeIcon icon={faPencil} />}
											onClick={() => onEdit(clip)}
										>
											Edit clip
										</Menu.Item>
										<Menu.Item
											icon={<FontAwesomeIcon icon={faUserGroup} />}
											onClick={() => onShare(clip)}
										>
											Manage access
										</Menu.Item>
										<Menu.Item
											icon={<FontAwesomeIcon icon={faLink} />}
											onClick={() => onCopyLink(clip)}
										>
											Copy link
										</Menu.Item>
									</>
								)}

								<Menu.Item
									icon={<FontAwesomeIcon icon={faTrash} />}
									onClick={() => onDelete(id)}
									color='red'
								>
									Delete
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</Group>
				</Group>
			</div>
		</Paper>
	);
}
