import {
	createStyles,
	Paper,
	Table,
	Text,
	Checkbox,
	Stack,
	Loader,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { StatusBadge } from '../../../../../components/Badges/StatusBadge';
import TableRowSkeleton from '../../../../../components/Skeletons/TableRowSkeleton';
import { getMeetingHost } from '../../../../../helpers/meetings';
import { formatDeadlineTime } from '../../../../../helpers/date';
import PlatformIcon from '../../../../../components/PlatformIcon';
import { useRecoilValue } from 'recoil';
import { userOrganizationMembersMap } from '../../../../../Atoms/userAtoms';
import { DateTime } from 'luxon';

const useStyles = createStyles(() => ({
	header: {
		height: '45px',
		alignContent: 'center',
	},
	paperClip: {
		cursor: 'pointer',
	},
}));

interface Props {
	content: any[]; // Supports both meetings and clips
	loading: boolean;
	selection: string[];
	setSelection: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function AddContentTable({
	content,
	loading,
	selection,
	setSelection,
}: Props) {
	const { classes } = useStyles();
	const orgUsers = useRecoilValue(userOrganizationMembersMap);

	const toggleRow = (id: string) =>
		setSelection((current) =>
			current.includes(id)
				? current.filter((item) => item !== id)
				: [...current, id]
		);
	const toggleAll = () =>
		setSelection((current) =>
			current.length === content.length ? [] : content.map((item) => item.id)
		);

	const ths = (
		<tr>
			<th>
				<Checkbox
					onChange={toggleAll}
					checked={selection.length === content.length}
					indeterminate={
						selection.length > 0 && selection.length !== content.length
					}
					size={'xs'}
				/>
			</th>
			<th>Name</th>
			<th>Type</th>
			<th>Created By</th>
			<th>Date</th>
			<th>Status</th>
			<th>Platform</th>
		</tr>
	);

	const rows = content.map((item) => {
		const {
			id,
			name,
			author,
			organizer,
			botMetadata,
			status,
			platform,
			friendlyID,
			createdAt,
			title,
			owningUser,
			owningUserID,
			meetingDate,
			startAt,
			publishedAt,
		} = item;

		const user = orgUsers.get(owningUserID);
		const host = owningUser || user;

		const hostName = `${host?.firstName || ''} ${host?.lastName || ''}`;
		const type = id.startsWith('MET_') ? 'Meeting' : 'Clip';
		const date =
			type === 'Meeting'
				? DateTime.fromMillis(
						+(meetingDate || startAt || publishedAt)
				  ).toFormat('MM/dd/yyyy')
				: DateTime.fromMillis(+createdAt).toFormat('MM/dd/yyyy');

		return (
			<tr key={id}>
				<td>
					<Checkbox
						size={'xs'}
						checked={selection.includes(id)}
						onChange={() => toggleRow(id)}
					/>
				</td>
				<td>
					<Text weight={600}>{name || title}</Text>
				</td>
				<td>{type}</td>
				<td>{hostName}</td>
				<td>{date}</td>
				<td>
					<StatusBadge status={status} botStatus={botMetadata?.status} />
				</td>
				<td>
					<PlatformIcon platform={platform} />
				</td>
			</tr>
		);
	});

	return (
		<>
			{content.length ? (
				<Paper radius='md' shadow='md' withBorder>
					<Table captionSide='bottom' striped>
						<thead className={classes.header}>{ths}</thead>
						<tbody>
							{loading
								? Array.from({ length: 20 }, (_, index) => (
										<TableRowSkeleton key={index} colSpan={6} />
								  ))
								: rows}
						</tbody>
					</Table>
				</Paper>
			) : (
				<Text color='reelay-secondary-dark'>No content found.</Text>
			)}
		</>
	);
}
