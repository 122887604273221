import { getTeams } from '../api/api';
import { User } from '../Atoms/userAtoms';

// export const fetchMembers = async (userData: [], organizationID: string) => {
// 	// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// 	// // @ts-ignore
// 	let formattedMembers: {
// 		value: string | string[];
// 		label: string;
// 		group: string;
// 		id: string;
// 		key: string;
// 	}[] = [];

// 	formattedMembers = [...formattedMembers, ...userData];

// 	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// 	// @ts-ignore

// 	const teams = await getTeams(organizationID);
// 	//  loop through teams, add to teamData
// 	teams.data.data.forEach((team: any, index: number) => {
// 		//loop through users array and add here
// 		const tempUserArr: any[] = [];
// 		team.users.forEach((user: any) => {
// 			tempUserArr.push(user.id);
// 		});
// 		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// 		// @ts-ignore
// 		formattedMembers.push({
// 			value: JSON.stringify(tempUserArr),
// 			label: team.name,
// 			group: 'Teams',
// 			id: team.id,
// 			key: team.id,
// 		});
// 	});
// 	return formattedMembers;
// };

export const flattenValidateAndFilterInvitees = (invitees: string[]) => {
	return invitees
		.map((str) => {
			// the array of member emails in a team is stringified earlier.
			// need to check for teams and parse back into array.
			if (str[0] === '[' && str[str.length - 1] === ']') {
				return JSON.parse(str);
			}
			return str;
		})
		.flat()
		.filter(
			(email: string, idx: number, arr: string[]) =>
				arr.indexOf(email) === idx && email.includes('@')
		);
};

export const formatMembersData = (orgUsers: any[], teams: any[]) => {
	let formattedMembers: {
		value: string | string[];
		label: string;
		group: string;
		id: string;
		key: string;
	}[] = [];

	// Format orgUsers
	const users = orgUsers.map((user) => ({
		...user,
		label: user.email,
		value: user.email,
		key: user.id,
	}));

	formattedMembers = [...formattedMembers, ...users];

	// Format teams
	for (const team of teams) {
		const tempUserArr: string[] = team.users.map(
			(user: { id: string }) => user.id
		);
		formattedMembers.push({
			value: JSON.stringify(tempUserArr),
			label: team.name,
			group: 'Teams',
			id: team.id,
			key: team.id,
		});
	}

	return formattedMembers;
};
