import { createStyles, Avatar, Title, Text, Stack, Group } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.75rem',
		marginTop: theme.spacing.xl,
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.25rem',
	},
	creatorInfo: {
		display: 'flex',
		alignItems: 'center',
	},
	creatorDetails: {
		display: 'flex',
		flexDirection: 'column',
	},
	creatorName: {
		fontSize: '0.875rem',
		fontWeight: 500,
	},
	clipTitle: {
		fontSize: '0.875rem',
		color: theme.colors.gray[6],
	},
}));

interface ClipInfoProps {
	title: string;
	creator: string;
	host: string;
	meetingTitle: string;
	date: string;
	avatarUrl: string;
}

export function ClipInfo({
	title,
	creator,
	host,
	meetingTitle,
	date,
	avatarUrl,
}: ClipInfoProps) {
	const { classes } = useStyles();

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<Title order={3}>{meetingTitle}</Title>
				<Text fz='sm' fw={500} color={'secondary-text'}>
					{date} | Hosted by {host}
				</Text>
			</div>
			<Group noWrap>
				<Avatar
					radius='xl'
					size='md'
					color={'primary'}
					variant={'filled'}
					src={avatarUrl}
				>
					{creator
						.split(' ')
						.map((n) => n[0])
						.join('')}
				</Avatar>
				<Stack spacing={0}>
					<Text className={classes.creatorName}>Clip created by {creator}</Text>
					<Text className={classes.clipTitle}>Clip title: {title}</Text>
				</Stack>
			</Group>
		</div>
	);
}
