import React, { Dispatch, SetStateAction, useState } from 'react';
import {
	createStyles,
	Paper,
	Table,
	Text,
	Menu,
	CopyButton,
	Checkbox,
	ActionIcon,
	Group,
	UnstyledButton,
	Center,
	rem,
	Loader,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {} from '@fortawesome/pro-light-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import { VideoClip } from '../../../../../Atoms/clips';
import {
	faEllipsisVertical,
	faPaperclip,
	faPlus,
	faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import TableRowSkeleton from '../../../../../components/Skeletons/TableRowSkeleton';
import {
	addMeetingToCollectionModalOpenedAtom,
	archiveCollectionAtom,
	bulkDeletingCollectionMeetingsSelection,
	Collection,
	currentCollection,
	itemBeingAddedToCollection,
	SpecialCollections,
} from '../../../../../Atoms/collections';
import classes from './CollectionContentTable.module.css';
import {
	IconChevronDown,
	IconChevronUp,
	IconSelector,
} from '@tabler/icons-react';
import { RowData } from '../../../../../components/MeetingTable/helper';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { previousPageURL } from '../../../../../Atoms/meetingAtom';
import {
	currentUser,
	userOrganizationMembersMap,
} from '../../../../../Atoms/userAtoms';
import { useFlags } from 'flagsmith/react';
import { flagsmithFeatureFlags } from '../../../../../components/constants';
import { organizationSettings } from '../../../../../Atoms/settings';
import { useCollections } from '../../../../../customHooks/useCollections';
import Introduction from '../../../../meetings/components/Introduction';
import { IMeeting } from '../../../../../interfaces/meeting';

export const VIDEO_UPLOAD_COMPLETED_PERCENTAGE = 100;

interface ThProps {
	children: React.ReactNode;
	reversed: boolean;
	sorted: boolean;
	onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
	const Icon = sorted
		? reversed
			? IconChevronUp
			: IconChevronDown
		: IconSelector;
	return (
		<th
			className={classes.th}
			style={{
				width: children === 'Name' ? '30%' : 'auto',
			}}
		>
			<UnstyledButton onClick={onSort} className={classes.control}>
				<Group position='apart' noWrap>
					<Text fw={500} fz='sm'>
						{children}
					</Text>
					<Center className={classes.icon}>
						<Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
					</Center>
				</Group>
			</UnstyledButton>
		</th>
	);
}

const useStyles = createStyles(() => ({
	header: {
		height: '45px',
		alignContent: 'center',
	},
	paperClip: {
		cursor: 'pointer',
	},
}));

interface Props {
	data: (IMeeting | VideoClip)[];
	fetchingMeetings: boolean;
	sortBy: keyof RowData | null;
	reverseSortDirection: boolean;
	setSortBy: Dispatch<SetStateAction<keyof RowData | null>>;
	setReverseSortDirection: Dispatch<SetStateAction<boolean>>;
	search: string;
	mutateCollectionContent: () => void;
}

export default function CollectionContentTable({
	search,
	data,
	fetchingMeetings,
	sortBy,
	reverseSortDirection,
	setSortBy,
	setReverseSortDirection,
	mutateCollectionContent,
}: Props) {
	const { classes } = useStyles();
	const location = useLocation();
	const flags = useFlags([flagsmithFeatureFlags.meetingCollections]);
	const user = useRecoilValue(currentUser);
	const collection = useRecoilValue(currentCollection);
	const isCollectionOwner = collection?.owningUserID === user.id;
	const { removeContentFromCollection, addContentToCollection } =
		useCollections(false);
	const [selection, setSelection] = useRecoilState(
		bulkDeletingCollectionMeetingsSelection
	);
	const setAddMeetingToCollectionModalOpened = useSetRecoilState(
		addMeetingToCollectionModalOpenedAtom
	);
	const setItemToAdd = useSetRecoilState(itemBeingAddedToCollection);
	const setPreviousURL = useSetRecoilState(previousPageURL);
	const orgUsers = useRecoilValue(userOrganizationMembersMap);
	const viewingArchived = collection?.name === SpecialCollections.Archived;

	const toggleRow = (id: string) =>
		setSelection((current) =>
			current.includes(id)
				? current.filter((item) => item !== id)
				: [...current, id]
		);
	const toggleAll = () =>
		setSelection((current) =>
			current.length === data.length ? [] : data.map((item) => item.id)
		);

	const [loadingIndex, setLoadingIndex] = useState<number | null>(null);

	const handleRemoveFromCollection = async (
		collection: Collection,
		targetID: string,
		index: number
	) => {
		setLoadingIndex(index);
		try {
			const res = await removeContentFromCollection(collection, targetID);
			if (res) {
				mutateCollectionContent();
			}
		} catch (error) {
			console.error('Failed to remove content from collection:', error);
		} finally {
			setLoadingIndex(null);
		}
	};

	const ths = (
		<tr>
			<th>
				<Checkbox
					onChange={toggleAll}
					checked={selection.length === data.length && !fetchingMeetings}
					indeterminate={
						selection.length > 0 && selection.length !== data.length
					}
					size={'xs'}
				/>
			</th>
			<Th
				sorted={sortBy === 'name'}
				reversed={reverseSortDirection}
				onSort={() => {
					const reversed = sortBy === 'name' ? !reverseSortDirection : false;
					setReverseSortDirection(reversed);
					setSortBy('name');
				}}
			>
				Name
			</Th>
			<Th
				sorted={sortBy === 'type'}
				reversed={reverseSortDirection}
				onSort={() => {
					const reversed = sortBy === 'type' ? !reverseSortDirection : false;
					setReverseSortDirection(reversed);
					setSortBy('type');
				}}
			>
				Type
			</Th>
			<Th
				sorted={sortBy === 'host'}
				reversed={reverseSortDirection}
				onSort={() => {
					const reversed = sortBy === 'host' ? !reverseSortDirection : false;
					setReverseSortDirection(reversed);
					setSortBy('host');
				}}
			>
				Host
			</Th>
			<Th
				sorted={sortBy === 'date'}
				reversed={reverseSortDirection}
				onSort={() => {
					const reversed = sortBy === 'date' ? !reverseSortDirection : false;
					setReverseSortDirection(reversed);
					setSortBy('date');
				}}
			>
				Date
			</Th>
			<Th
				sorted={sortBy === 'summary'}
				reversed={reverseSortDirection}
				onSort={() => {
					const reversed = sortBy === 'summary' ? !reverseSortDirection : false;
					setReverseSortDirection(reversed);
					setSortBy('summary');
				}}
			>
				Summary
			</Th>
		</tr>
	);

	const rows = data.map((item: IMeeting | VideoClip, index) => {
		const { id, summary } = item;
		const isMeeting = id.startsWith('MET_');
		const name = isMeeting
			? (item as IMeeting).name
			: (item as VideoClip).title;
		const type = isMeeting ? 'Meeting' : 'Clip';
		const link = (() => {
			const url = new URL(
				`${window.location.origin}/${isMeeting ? 'meetings' : 'clips'}/${id}`
			);
			if ((item as VideoClip)?.sharingToken?.active) {
				url.searchParams.append('vt', (item as VideoClip).sharingToken.token);
			}
			return url.toString();
		})();
		const createdBy = isMeeting
			? orgUsers.get((item as IMeeting).owningUserID)
			: (item as VideoClip).owningUser;
		const formattedDate = isMeeting
			? DateTime.fromISO(
					(item as IMeeting).meetingDate ||
						(item as IMeeting).startAt ||
						(item as IMeeting).createdAt
			  ).toFormat('MM/dd/yyyy')
			: DateTime.fromISO(
					(item as VideoClip).createdAt as unknown as string
			  ).toFormat('MM/dd/yyyy');

		const createdByName = `${createdBy?.firstName || ''} ${
			createdBy?.lastName || ''
		}`.trim();

		return (
			<tr key={id}>
				<td>
					<Checkbox
						size={'xs'}
						checked={selection.includes(id)}
						onChange={() => toggleRow(id)}
					/>
				</td>
				<td>
					<Link
						to={link}
						onClick={() => setPreviousURL(location.pathname)}
						style={{ color: 'black', textDecoration: 'none' }}
					>
						<Text weight={600} lineClamp={1}>
							{name}
						</Text>
					</Link>
				</td>
				<td> {type}</td>
				<td>{createdByName}</td>
				<td>{formattedDate}</td>
				<td>
					<Text lineClamp={1}>{summary}</Text>
				</td>
				<td>
					<Group position={'right'}>
						<Menu position='left' withArrow>
							<Menu.Target>
								{loadingIndex === index ? (
									<Loader size='xs' />
								) : (
									<ActionIcon>
										<FontAwesomeIcon icon={faEllipsisVertical} />
									</ActionIcon>
								)}
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item
									icon={<FontAwesomeIcon icon={faPaperclip} />}
									color='gray'
								>
									<CopyButton value={link} timeout={2000}>
										{({ copied, copy }) => (
											<Text color={copied ? 'teal' : 'gray'} onClick={copy}>
												{copied ? 'Copied' : 'Copy link'}
											</Text>
										)}
									</CopyButton>
								</Menu.Item>

								{isCollectionOwner ? (
									<Menu.Item
										color='gray'
										icon={<FontAwesomeIcon icon={faTrash} />}
									>
										<Text
											color={'gray'}
											onClick={() =>
												handleRemoveFromCollection(collection, id, index)
											}
										>
											Remove from collection
										</Text>
									</Menu.Item>
								) : null}
							</Menu.Dropdown>
						</Menu>
					</Group>
				</td>
			</tr>
		);
	});

	return (
		<>
			{/*Meetings List View*/}
			{fetchingMeetings ? (
				<Table captionSide='bottom' striped highlightOnHover>
					<thead className={classes.header}>{ths}</thead>
					<tbody>
						{Array.from({ length: 20 }, (_, index) => (
							<TableRowSkeleton key={index} colSpan={11} height={'42.50px'} />
						))}
					</tbody>
				</Table>
			) : data.length ? (
				<Paper radius='md' shadow='md' withBorder>
					<Table captionSide='bottom' striped highlightOnHover>
						<thead className={classes.header}>{ths}</thead>
						<tbody>{rows}</tbody>
					</Table>
				</Paper>
			) : (
				<Introduction segmentValue={''} />
			)}
		</>
	);
}
