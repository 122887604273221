import React, { Dispatch, SetStateAction } from 'react';
import { Grid, Paper, Stack } from '@mantine/core';
import { VideoClip } from '../../../Atoms/clips';
import { useRecoilValue } from 'recoil';
import { hasCalendar } from '../../../Atoms/integrations';
import SkeletonCard from '../../../components/Skeletons/SkeletonCard';
import Introduction from '../../meetings/components/Introduction';
import { ClipCard } from './ClipCard';

interface Props {
	clips: VideoClip[];
	setClips: Dispatch<SetStateAction<VideoClip[]>>;
	searchValue: string;
	cardsPerPage: number;
	clipCount: number;
	loading: boolean;
	handleGetClipsData?: any;
	segmentValue?: string;
	openShareModal?: (clip: VideoClip) => void;
	openEditModal?: (VideoClip: VideoClip) => void;
}

const ClipsCardView = ({
	clips,
	setClips,
	searchValue,
	handleGetClipsData,
	loading,
	segmentValue,
	openShareModal,
	openEditModal,
}: Props) => {
	const calendarIntegrated = useRecoilValue(hasCalendar);
	const clipCountPerPage = 20;
	const skeletonCardArray = Array.from(
		{ length: clipCountPerPage },
		(_, index) => index
	);

	return (
		<>
			{/*Clips List View*/}
			{loading ? (
				<Stack w={'100%'} spacing={'xl'}>
					<Grid gutterSm={32} gutterXl={32} h={'100%'} pr={10}>
						{skeletonCardArray.map((idx) => (
							<SkeletonCard key={idx} />
						))}
					</Grid>
				</Stack>
			) : clips.length ? (
				<Stack w={'100%'} spacing={'xl'}>
					<Grid gutterSm={32} gutterXl={32} h={'100%'} pr={10}>
						{clips.map((clip: VideoClip) => (
							<ClipCard
								key={clip.id}
								clip={clip}
								searchValue={searchValue}
								openEditModal={openEditModal}
								openShareModal={openShareModal}
							/>
						))}
					</Grid>
				</Stack>
			) : (
				<Introduction segmentValue={segmentValue} isClips={true} />
			)}
		</>
	);
};

export default ClipsCardView;
