/**
 * @param {string} method - info | error | warn | debug
 * @param {string} message - The message to be displayed in the logs.
 * @param {any} data - The data to be displayed in the logs.
 */

export const logger = (
	method: 'info' | 'error' | 'warn' | 'debug',
	message: string,
	data: any
) => {
	if (process.env.NODE_ENV !== 'production') {
		return;
	}
	const logObject = {
		message,
		data,
	};

	switch (method) {
		case 'info': {
			console.log(logObject);
			break;
		}
		case 'error': {
			console.error({
				message,
				error: data,
			});
			break;
		}
		case 'warn': {
			console.warn(logObject);
			break;
		}
		case 'debug': {
			console.debug(logObject);
			break;
		}
		default: {
			console.log(logObject);
			break;
		}
	}
};
