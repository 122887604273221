import { createStyles, Group, Header, UnstyledButton } from '@mantine/core';
import { Logo } from '../reelayLogo';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/pro-light-svg-icons';
import { useSetRecoilState } from 'recoil';
import { userAuthenticated as userAuthenticatedAtom } from '../../Atoms/auth';
import { NotificationsPopover } from '../Popovers/NotificationsPopover';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { logger } from '../../helpers/logger';
import { useMediaQuery } from 'react-responsive';

const useStyles = createStyles(() => ({
	mobileHeader: {
		borderBottom: '1px solid #EEF1F5',
		height: 70,
		paddingLeft: 16,
	},
}));

export default function MobileHeader() {
	const { classes, theme } = useStyles();
	const setAuthStatus = useSetRecoilState(userAuthenticatedAtom);
	const isMiniature = useMediaQuery({
		query: `(max-width: 200px`,
	});

	const handleLogout = () => {
		setAuthStatus(false);
		try {
			// clear the Atom values on this effort
			localStorage.clear();
			showSuccessNotification({
				message: 'You were logged out!',
			});
			navigate('/login');
		} catch (err: unknown) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			showFailureNotification({
				message: 'There was an error logging you out. Please try again.',
			});
			logger('error', 'There was an error logging you out', err);
		}
		return;
	};

	const navigate = useNavigate();
	const handleRouteChange = () => {
		navigate('/meetings');
	};

	return (
		<Header
			height={70}
			style={{ boxShadow: '0 3px 12px 0 rgba(30, 30, 30, 0.08)' }}
		>
			<Group
				className={classes.mobileHeader}
				position={'apart'}
				noWrap
				spacing={isMiniature ? 0 : 'md'}
			>
				<div onClick={handleRouteChange}>
					<Logo width={isMiniature ? 100 : 150} />
				</div>
				<Group mx={isMiniature ? 0 : 'lg'} noWrap>
					<NotificationsPopover />
					<UnstyledButton onClick={handleLogout}>
						<FontAwesomeIcon
							style={{ cursor: 'pointer' }}
							icon={faRightFromBracket}
							size={'1x'}
						/>
					</UnstyledButton>
				</Group>
			</Group>
		</Header>
	);
}
