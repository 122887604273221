/**
 * Extracts the playback ID from a Mux playback URL.
 * @param {string} url - The Mux playback URL.
 * @returns {string | null} - The extracted playback ID or null if not found.
 */
export function extractPlaybackID(url: string): string | null {
	const regex = /https:\/\/stream\.mux\.com\/([a-zA-Z0-9]+)\.m3u8/;
	const match = url.match(regex);
	return match ? match[1] : null;
}
