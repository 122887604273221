import { useRecoilState, useRecoilValue } from 'recoil';
import {
	type Collection,
	collectionsAtom,
	type CollectionUser,
	currentCollection,
	SpecialCollections,
} from '../Atoms/collections';
import {
	addCollectionUsers,
	deleteCollection,
	deleteCollectionContent,
	deleteCollectionUser,
	getCollectionContent,
	getCollectionUsers,
	getCollections,
	updateCollectionContent,
} from '../api/collections';
import { showSuccessNotification } from '../helpers/notifications';
import { currentOrganizationID, currentUser } from '../Atoms/userAtoms';
import { useEffect, useRef } from 'react';
import { flagsmithFeatureFlags } from '../components/constants';
import { useFlags } from 'flagsmith/react';
import { logger } from '../helpers/logger';

export const useCollections = (shouldFetch = true) => {
	const user = useRecoilValue(currentUser);
	const organizationID = useRecoilValue(currentOrganizationID);
	const [collection, setCollection] = useRecoilState(currentCollection);
	const [collections, setCollections] = useRecoilState(collectionsAtom);
	const { meetingCollections, actionItems } = flagsmithFeatureFlags;
	const flags = useFlags([meetingCollections, actionItems]);
	const collectionsEnabled = flags?.content_collections?.enabled;
	const hasFetchedCollections = useRef(false);

	useEffect(() => {
		if (collectionsEnabled && shouldFetch && !hasFetchedCollections.current) {
			fetchCollections();
		}
	}, [shouldFetch]);

	const fetchCollections = async () => {
		try {
			if (hasFetchedCollections.current) return;

			const response = await getCollections(organizationID);
			const archiveCollectionIndex = response.findIndex(
				(c) => c.name === SpecialCollections.Archived
			);
			const archiveCollection = response[archiveCollectionIndex];
			if (archiveCollection) {
				const archiveContent = await getCollectionContent(archiveCollection.id);
				const archiveUsers = await getCollectionUsers(archiveCollection.id);
				response[archiveCollectionIndex] = {
					...archiveCollection,
					content: archiveContent,
					users: archiveUsers,
				};
			}
			if (Array.isArray(response)) {
				setCollections(response);
			}
			hasFetchedCollections.current = true;
		} catch (error) {
			console.error('Error fetching collections:', error);
			logger('error', 'Error fetching collections', error);
		}
	};

	const removeCollection = async (
		collectionID: string,
		organizationID: string
	) => {
		try {
			const res = await deleteCollection(collectionID, organizationID);
			if (res?.status === 200) {
				const newCollections = collections.filter(
					(collection) => collection.id !== res?.data?.id
				);
				setCollections(newCollections);
				showSuccessNotification({
					message: 'Collection successfully deleted.',
				});
				return newCollections;
			}
		} catch (error) {
			return error;
		}
	};

	const removeContentFromCollection = async (
		collection: Collection,
		targetID: string,
		showNotification = true
	) => {
		const content = collection.content.find((item) => item.id === targetID);
		const res = await deleteCollectionContent(
			collection.id,
			content?.content?.id
		);
		const deletedContentID = res?.id;
		const updatedCollection = {
			...collection,
			content: collection.content.filter((c) => c.id !== deletedContentID),
		};
		setCollection(updatedCollection);
		setCollections((prev) =>
			prev.map((c) => (c.id === updatedCollection.id ? updatedCollection : c))
		);
		showNotification &&
			showSuccessNotification({
				message: 'Content successfully removed from collection!',
			});
		return deletedContentID;
	};

	const bulkRemoveContent = async (targetIDs: string[]) => {
		const res = await Promise.allSettled(
			targetIDs.map((targetID) =>
				removeContentFromCollection(collection, targetID, false)
			)
		);
		const deletedContentIDs = res
			.filter(({ status }) => status === 'fulfilled')
			.map(({ value }: any) => value);
		const updatedCollection = {
			...collection,
			content: collection.content.filter(
				(c) => !deletedContentIDs.includes(c?.content?.id)
			),
		};

		setCollection(updatedCollection);
		setCollections((prev) =>
			prev.map((c) => (c.id === updatedCollection.id ? updatedCollection : c))
		);
		showSuccessNotification({
			message: 'Content successfully removed from collection!',
		});
	};

	const updateCollectionUsers = async (
		usersToAdd: string[],
		usersToRemove: string[]
	) => {
		let updatedUsers = [...collection.users];
		try {
			if (usersToAdd.length) {
				const newUsersPayload = usersToAdd.map((userID) => ({ userID }));
				const res = await addCollectionUsers(collection.id, newUsersPayload);
				const newUsers = res?.data?.map((item: CollectionUser) => item.user);
				updatedUsers = [...updatedUsers, ...newUsers];
			}

			if (usersToRemove.length) {
				const res = await Promise.allSettled(
					usersToRemove.map((userID) =>
						deleteCollectionUser(collection.id, userID)
					)
				);
				const deletedUserIDs: any = res?.filter(
					(item) => item.status === 'fulfilled'
				);
				const deletedUsers = deletedUserIDs?.map((item) => item?.value?.userID);
				updatedUsers = updatedUsers.filter(
					(user) => !deletedUsers.includes(user.id)
				);
			}

			const updatedCollection = {
				...collection,
				users: updatedUsers,
			};

			setCollection(updatedCollection);
			setCollections((prev) =>
				prev.map((c) => (c.id === collection.id ? updatedCollection : c))
			);
		} catch (error) {
			throw new Error(error);
		}
	};

	const addContentToCollection = async (
		collectionID: string,
		targetIDs: string[]
	) => {
		const res = await updateCollectionContent(
			collectionID,
			targetIDs.map((targetID) => ({ targetID }))
		);
		return Array.isArray(res?.data?.data) ? res?.data?.data : [];
	};

	return {
		collection,
		setCollection,
		collections,
		setCollections,
		removeCollection,
		removeContentFromCollection,
		bulkRemoveContent,
		updateCollectionUsers,
		addContentToCollection,
		fetchCollections,
	};
};
