import useSWR from 'swr';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getMeetingNotifications } from '../api/notifications';
import { logger } from '../helpers/logger';
import { currentOrganizationID } from '../Atoms/userAtoms';
import { notifications } from '../Atoms/notifications';

const fetcher = async (organizationID: string) => {
	try {
		const response = await getMeetingNotifications();
		return response;
	} catch (error) {
		logger('error', 'Error fetching notifications', error);
		return [];
	}
};

export function useNotifications() {
	const organizationID = useRecoilValue(currentOrganizationID);
	const setNotifications = useSetRecoilState(notifications);

	const { data, error, isLoading } = useSWR(
		organizationID ? ['getMeetingNotifications', organizationID] : null,
		(_, organizationID) => fetcher(organizationID),
		{
			onSuccess: (data) => {
				setNotifications(data);
			},
		}
	);

	return {
		notifications: data,
		error,
		isLoading,
	};
}
