import useSWR from 'swr';
import { getTeams } from '../../api/api';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { teamsAtom } from '../../Atoms/userAtoms';

const fetcher = (organizationID: string) =>
	getTeams(organizationID).then((res) => res.data);

export function useGetTeams(organizationID: string) {
	const setTeams = useSetRecoilState(teamsAtom);
	const { data, error, mutate } = useSWR(
		organizationID ? ['teams', organizationID] : null,
		() => fetcher(organizationID)
	);

	useEffect(() => {
		if (Array.isArray(data?.data)) {
			setTeams(data.data);
		}
	}, [data]);

	return {
		teams: data,
		isLoading: !error && !data,
		isError: error,
		mutateTeams: mutate,
	};
}
