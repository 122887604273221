import React, { useState } from 'react';
import { Button, Group, Menu, Radio, useMantineTheme } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { useRecoilValue } from 'recoil';
import { transcriptID } from '../../../../Atoms/meetingAtom';
import {
	reelayAIModelList,
	reelayAIModels,
} from '../../../../components/constants';

interface Props {
	handleMenuSelect: (model: string, type: string) => void;
	fetchingData?: boolean;
	insideModal?: boolean;
	type?: string;
}

export default function RegenerateMeetingDetailsButton({
	handleMenuSelect,
	fetchingData = false,
	insideModal = false,
	type,
}: Props) {
	const theme = useMantineTheme();
	const transcriptionID = useRecoilValue(transcriptID);
	const [value, setValue] = useState(reelayAIModels['openai-3.5']);

	return (
		<Group position={'right'}>
			<Menu
				shadow='md'
				width={200}
				styles={{
					itemLabel: {
						fontSize: theme.fontSizes.sm,
					},
				}}
			>
				<Menu.Target>
					<Button
						size={'xs'}
						leftIcon={<FontAwesomeIcon icon={faRotateRight} />}
						disabled={fetchingData}
					>
						Regenerate
					</Button>
				</Menu.Target>
				{insideModal ? (
					<Menu.Dropdown>
						{reelayAIModelList.map((model) => (
							<Menu.Item
								key={model.value}
								onClick={() => handleMenuSelect(model.value, type)}
							>
								{model.label}
							</Menu.Item>
						))}
					</Menu.Dropdown>
				) : (
					<Menu.Dropdown>
						<Menu.Label>Model</Menu.Label>
						<Radio.Group size={'sm'} value={value} onChange={setValue} p='sm' >
							<Group>
								{reelayAIModelList.map((model) => (
									<Radio
										key={model.value}
										value={model.value}
										label={model.label}
									/>
								))}
							</Group>
						</Radio.Group>
						<Menu.Divider />
						<Menu.Item onClick={() => handleMenuSelect(value, 'summary')}>
							Summary
						</Menu.Item>
						<Menu.Item onClick={() => handleMenuSelect(value, 'longSummary')}>
							Long Summary
						</Menu.Item>
						<Menu.Item onClick={() => handleMenuSelect(value, 'chapters')}>
							Topics
						</Menu.Item>
						<Menu.Item onClick={() => handleMenuSelect(value, 'highlight')}>
							Highlights
						</Menu.Item>
						<Menu.Item onClick={() => handleMenuSelect(value, 'action')}>
							Actions
						</Menu.Item>
						<Menu.Item onClick={() => handleMenuSelect(value, 'question')}>
							Questions
						</Menu.Item>
					</Menu.Dropdown>
				)}
			</Menu>
		</Group>
	);
}
