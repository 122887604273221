import useSWR from 'swr';
import { getCollections } from '../../api/collections';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { collectionsAtom } from '../../Atoms/collections';

const fetcher = (url: string, organizationID: string) =>
	getCollections(organizationID);

export function useGetCollections(organizationID: string) {
	if (!organizationID)
		return {
			collections: [],
			error: null,
			isLoading: false,
		};
	const [collections, setCollections] = useRecoilState(collectionsAtom);

	const {
		data: fetchedCollections,
		error,
		isLoading,
	} = useSWR(['getCollections', organizationID], ([url, organizationID]) =>
		fetcher(url, organizationID)
	);

	useEffect(() => {
		if (fetchedCollections) {
			setCollections(fetchedCollections);
		}
	}, [fetchedCollections, setCollections]);

	return {
		collections: fetchedCollections,
		setCollections,
		error,
		isLoading,
	};
}
