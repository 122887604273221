import {
	type MantineThemeOverride,
	type Tuple,
	type DefaultMantineColor,
	Modal,
} from '@mantine/core';

type ExtendedCustomColors =
	| 'primary'
	| 'secondary'
	| 'background-gray'
	| 'outline-stroke'
	| 'button-field-stroke'
	| 'secondary-text'
	| 'primary-text'
	| 'light-blue'
	| 'medium-blue'
	| 'indigo-stroke'
	| 'green-fill'
	| 'light-indigo'
	| 'secondary-blue'
	| 'red-fill'
	| 'red-stroke'
	| DefaultMantineColor;

declare module '@mantine/core' {
	export interface MantineThemeColorsOverride {
		// eslint-disable-next-line no-magic-numbers
		colors: Record<ExtendedCustomColors, Tuple<string, 10>>;
	}
}

const brandData = localStorage.getItem('brand');
const brand: { color_primary: string; color_secondary: string } =
	brandData !== 'undefined' ? JSON.parse(brandData) : {};

const defaultPrimary: ColorType = [
	'#ffffff',
	'#EFF6FF',
	'#3b368f',
	'#3b368f',
	'#3b368f',
	'#3b368f',
	'#3b368f',
	'#3b368f',
	'#3b368f',
	'#3b368f',
];

const defaultSecondary: ColorType = [
	'#ffffff',
	'#EFF6FF',
	'#3b368f',
	'#3b368f',
	'#3b368f',
	'#3b368f',
	'#3b368f',
	'#3b368f',
	'#3b368f',
	'#3b368f',
];

type ColorType = [
	string?,
	string?,
	string?,
	string?,
	string?,
	string?,
	string?,
	string?,
	string?,
	string?
];

const primary: ColorType = brand?.color_primary
	? (Array.from({ length: 10 }, () => brand.color_primary) as ColorType)
	: defaultPrimary;
const secondary: ColorType = brand?.color_secondary
	? (Array.from({ length: 10 }, () => brand.color_secondary) as ColorType)
	: defaultSecondary;

const theme: MantineThemeOverride = {
	fontFamily: 'Inter, sans-serif',
	fontFamilyMonospace: 'Monaco, Courier, monospace',
	headings: { fontFamily: 'Inter, sans-serif' },
	breakpoints: {
		xs: '480px',
		sm: '768px',
		md: '1024px',
		lg: '1184px',
		xl: '1440px',
	},
	colors: {
		primary,
		secondary,
		'reelay-purple': [
			'#3b368f',
			'#3b368f',
			'#3b368f',
			'#3b368f',
			'#3b368f',
			'#3b368f',
			'#3b368f',
			'#3b368f',
		],
		'secondary-purple': [
			'#3B3591',
			'#3B3591',
			'#3B3591',
			'#3B3591',
			'#3B3591',
			'#3B3591',
			'#3B3591',
			'#3B3591',
			'#3B3591',
			'#3B3591',
		],
		'background-gray': [
			'#f9fafb',
			'#f9fafb',
			'#f9fafb',
			'#f9fafb',
			'#f9fafb',
			'#f9fafb',
			'#f9fafb',
			'#f9fafb',
			'#f9fafb',
			'#f9fafb',
		],
		'background-gray-2': [
			'#F3F3F3',
			'#F3F3F3',
			'#F3F3F3',
			'#F3F3F3',
			'#F3F3F3',
			'#F3F3F3',
			'#F3F3F3',
			'#F3F3F3',
			'#F3F3F3',
			'#F3F3F3',
		],
		'background-gray-3': [
			'#D9D9D9',
			'#D9D9D9',
			'#D9D9D9',
			'#D9D9D9',
			'#D9D9D9',
			'#D9D9D9',
			'#D9D9D9',
			'#D9D9D9',
			'#D9D9D9',
			'#D9D9D9',
		],
		'light-indigo': [
			'#e0e7ff',
			'#e0e7ff',
			'#e0e7ff',
			'#e0e7ff',
			'#e0e7ff',
			'#e0e7ff',
			'#e0e7ff',
			'#e0e7ff',
			'#e0e7ff',
			'#e0e7ff',
		],
		// replace with primary[0] to match shade
		'outline-stroke': [
			'#eef1f5',
			'#eef1f5',
			'#eef1f5',
			'#eef1f5',
			'#eef1f5',
			'#eef1f5',
			'#eef1f5',
			'#eef1f5',
			'#eef1f5',
			'#eef1f5',
		],
		'button-field-stroke': [
			'#c4c8ce',
			'#c4c8ce',
			'#c4c8ce',
			'#c4c8ce',
			'#c4c8ce',
			'#c4c8ce',
			'#c4c8ce',
			'#c4c8ce',
			'#c4c8ce',
			'#c4c8ce',
		],
		'secondary-text': [
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
		],
		'secondary-gray': [
			'#8C919B',
			'#8C919B',
			'#8C919B',
			'#8C919B',
			'#8C919B',
			'#8C919B',
			'#8C919B',
			'#8C919B',
			'#8C919B',
			'#8C919B',
		],
		'reelay-secondary-dark': [
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
			'#707271',
		],
		'reelay-secondary-light': [
			'#dededf',
			'#dededf',
			'#dededf',
			'#dededf',
			'#dededf',
			'#dededf',
			'#dededf',
			'#dededf',
			'#dededf',
			'#dededf',
		],
		'primary-text': [
			'#111827',
			'#111827',
			'#111827',
			'#111827',
			'#111827',
			'#111827',
			'#111827',
			'#111827',
			'#111827',
			'#111827',
		],
		// replaced with primary[0]
		'light-blue': [
			'#eff6ff',
			'#eff6ff',
			'#eff6ff',
			'#eff6ff',
			'#eff6ff',
			'#eff6ff',
			'#eff6ff',
			'#eff6ff',
			'#eff6ff',
			'#eff6ff',
		],
		// replaced with primary[4]
		'medium-blue': [
			'#bfdbfe',
			'#bfdbfe',
			'#bfdbfe',
			'#bfdbfe',
			'#bfdbfe',
			'#bfdbfe',
			'#bfdbfe',
			'#bfdbfe',
			'#bfdbfe',
			'#bfdbfe',
		],
		// replaced with primary[8] to match shade
		'indigo-stroke': [
			'#4f46e5',
			'#4f46e5',
			'#4f46e5',
			'#4f46e5',
			'#4f46e5',
			'#4f46e5',
			'#4f46e5',
			'#4f46e5',
			'#4f46e5',
			'#4f46e5',
		],
		'red-fill': [
			'#ef4444',
			'#ef4444',
			'#ef4444',
			'#ef4444',
			'#ef4444',
			'#ef4444',
			'#ef4444',
			'#ef4444',
			'#ef4444',
			'#ef4444',
		],
		'red-stroke': [
			'#BB3131',
			'#BB3131',
			'#BB3131',
			'#BB3131',
			'#BB3131',
			'#BB3131',
			'#BB3131',
			'#BB3131',
			'#BB3131',
			'#BB3131',
		],
		'green-fill': [
			'#059669',
			'#059669',
			'#059669',
			'#059669',
			'#059669',
			'#059669',
			'#059669',
			'#059669',
			'#059669',
			'#059669',
		],
		'success-green': [
			'#3FBF57',
			'#3FBF57',
			'#3FBF57',
			'#3FBF57',
			'#3FBF57',
			'#3FBF57',
			'#3FBF57',
			'#3FBF57',
			'#3FBF57',
			'#3FBF57',
		],
		// replaced with primary[9]
		'dark-blue': [
			'#93C5FD',
			'#93C5FD',
			'#93C5FD',
			'#93C5FD',
			'#93C5FD',
			'#93C5FD',
			'#93C5FD',
			'#93C5FD',
			'#93C5FD',
			'#93C5FD',
		],
		// chris recommended primary[5] so replaced with that.
		'secondary-blue': [
			'#6B7280',
			'#6B7280',
			'#6B7280',
			'#6B7280',
			'#6B7280',
			'#6B7280',
			'#6B7280',
			'#6B7280',
			'#6B7280',
			'#6B7280',
		],
		// dark shade replaced with primary[9]
		'primary-cta': [
			'#3730A3',
			'#3730A3',
			'#3730A3',
			'#3730A3',
			'#3730A3',
			'#3730A3',
			'#3730A3',
			'#3730A3',
			'#3730A3',
			'#3730A3',
		],
	},
	primaryColor: 'primary',
	primaryShade: 6,
	activeStyles: { transform: 'scale(0.95)' },
	cursorType: 'pointer',
	components: {
		Avatar: {
			// Subscribe to theme and component params
			styles: (theme) => ({
				root: {
					backgroundColor: theme.fn.primaryColor(),
				},
			}),
		},
		ScrollArea: {
			styles: (theme) => ({
				scrollbar: {
					'&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
						backgroundColor: theme.fn.primaryColor(),
					},
					'&[data-orientation="horizontal"] .mantine-ScrollArea-thumb': {
						display: 'none',
					},
					width: '10px',
				},
			}),
		},
		Modal: {
			styles: (theme) => ({
				header: {
					borderTopLeftRadius: theme.radius.lg,
					borderTopRightRadius: theme.radius.lg,
				},
			}),
		},
	},
	globalStyles: (theme) => ({
		'.sub-header': {
			color: theme.colors['primary-text'][0],
			fontWeight: 600,
			fontSize: '14px',
			lineHeight: '21.78px',
		},
	}),
};

export default theme;

declare module '@mantine/core' {
	export interface MantineThemeColorsOverride {
		// eslint-disable-next-line no-magic-numbers
		colors: Record<ExtendedCustomColors, Tuple<string, 10>>;
	}
}
