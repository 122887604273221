import { useEffect, useRef, useState } from 'react';
import { createStyles, Stack } from '@mantine/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUserMeetings, user } from '../../Atoms/userAtoms';
import {
	meetingsSharedWithMe as SharedMeetingAtom,
	myMeetings as MyMeetingAtom,
} from '../../Atoms/meetingAtom';
import MobileVideoQueue from '../../components/mobile_video_queue';
import { addViewsToMeetings } from '../../helpers/meetings';
import ReelayLogoPageLoader from '../../components/loaders/ReelayLogoPageLoader';
import { logger } from '../../helpers/logger';
import { searchMeetings } from '../../api/api';

const useStyles = createStyles(() => ({
	outerContainer: {
		overflowY: 'auto',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		height: 'auto',
	},
	bottomContainer: {
		flexGrow: 1,
		overflow: 'auto',
	},
}));

const order = '-publishedAt';
const status = ['published'];
const isExpired = false;

export const MobileLandingPage = () => {
	const { classes } = useStyles();
	const currentUser = useRecoilValue(user);
	const [myMeetings, setMyMeetings] = useRecoilState(MyMeetingAtom);
	const [isLoading, setIsLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const [meetingCount, setMeetingCount] = useState(0);
	const bottomContainerRef = useRef(null);

	useEffect(() => {
		handleGetMeetingData().then(() =>
			logger('info', 'fetched meetings', {
				userID: currentUser.id,
				organizationID: currentUser.currentOrganizationID,
			})
		);

		return () => {
			setOffset(0);
		};
	}, []);

	const handleGetMeetingData = async () => {
		try {
			setIsLoading(true);
			const meetings = await searchMeetings({
				offset,
				order,
				status,
				isExpired,
				limit: 1000000, // no limit for now, future we need to reintroduce infinite scroll + limit.
				organizationID: currentUser.currentOrganizationID,
			});
			const meetingsWithViews = await addViewsToMeetings(meetings.data.data);
			const count = meetings.data.count;
			setMeetingCount(count);
			setOffset((prevOffset) => prevOffset + meetings.data.data.length);
			setMyMeetings(meetingsWithViews);
		} catch (error) {
			logger('error', 'failed to fetch meetings', {
				userID: currentUser.id,
				organizationID: currentUser.currentOrganizationID,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const loadMoreMeetings = async () => {
		const meetings = await searchMeetings({
			offset,
			order,
			status,
			isExpired,
			limit: 20,
			organizationID: currentUser.currentOrganizationID,
		});
		const meetingsWithViews = await addViewsToMeetings(meetings.data.data);
		const count = meetings.data.count;
		setMeetingCount(count);
		setOffset((prevOffset) => prevOffset + meetings.data.data.length);
		const newMeetings = [...myMeetings, ...meetingsWithViews];
		setMyMeetings(newMeetings);
	};

	return (
		<>
			<Stack spacing={0} className={classes.outerContainer}>
				<div className={classes.bottomContainer} ref={bottomContainerRef}>
					{isLoading ? (
						<ReelayLogoPageLoader />
					) : (
						<MobileVideoQueue
							bottomContainerRef={bottomContainerRef.current}
							meetingCount={meetingCount}
							meetings={myMeetings}
							fetchData={loadMoreMeetings}
						/>
					)}
				</div>
			</Stack>
		</>
	);
};
