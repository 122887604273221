import React, { useState } from 'react';
import { Group, Text } from '@mantine/core';
import CollectionsOverview from './components/CollectionsOverview';
import EmptyCollectionsPage from './components/EmptyCollectionsPage';
import AddCollectionModal from './components/AddCollectionModal';
import RenameCollectionModal from './components/RenameCollectionModal';
import { useGetCollections } from '../../customHooks/collections/useGetCollections';
import { currentOrganizationID } from '../../Atoms/userAtoms';
import { useRecoilValue } from 'recoil';
import { ReelayLoader } from '../../components/ReelayLoader';
import { useLocation } from 'react-router';

export default function Collections() {
	const location = useLocation();
	const organizationID = useRecoilValue(currentOrganizationID);
	const { collections, isLoading, error } = useGetCollections(organizationID);
	const [opened, setOpened] = useState(false);
	const myCollections = !location.pathname.includes('shared');

	if (isLoading) return <ReelayLoader />;

	if (error)
		return (
			<Group grow position='center' h={'100%'}>
				<Text color='red'>
					Failed to load collections. Please try again refreshing the page or
					navigating back.
				</Text>
			</Group>
		);

	return (
		<>
			<Group grow position='apart' h={'100%'}>
				{collections?.length ? (
					<CollectionsOverview
						setOpened={setOpened}
						myCollections={myCollections}
					/>
				) : (
					<EmptyCollectionsPage setOpened={setOpened} />
				)}
			</Group>
			<AddCollectionModal setOpened={setOpened} opened={opened} />
			<RenameCollectionModal />
		</>
	);
}
