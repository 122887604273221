import { useState } from 'react';
import {
	handleDeleteClip,
	handleRegenerateSummary,
	handleSaveClip,
} from '../../helpers/clips';
import { VideoClip } from '../../Atoms/clips';
import { useSetRecoilState } from 'recoil';
import { meetingClipsAtom } from '../../Atoms/meetingAtom';

export const useClips = (organizationID: string) => {
	const [isRegenerating, setIsRegenerating] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const setClips = useSetRecoilState(meetingClipsAtom);

	const regenerateSummary = async (
		clip: VideoClip,
		updateClip: (updatedClip: any) => void
	) => {
		setIsRegenerating(true);
		try {
			const newClip = await handleRegenerateSummary(
				clip,
				organizationID,
				updateClip
			);
			return newClip;
		} finally {
			setIsRegenerating(false);
		}
	};

	const saveClip = async (
		clip: any,
		values: {
			title: string;
			summary: string;
			collectionIDs: string[];
			startTime: number;
			endTime: number;
		},
		updateClip: (updatedClip: any) => void
	) => {
		setIsSaving(true);
		try {
			const updatedClip = await handleSaveClip({
				clip,
				organizationID,
				...values,
				updateClip,
				setClips,
			});
			setIsSaving(false);
			return updatedClip;
		} finally {
			setIsSaving(false);
		}
	};

	const deleteClip = async (
		clipId: string,
		updateClips: (clipIds: string[]) => void
	) => {
		setIsDeleting(true);
		try {
			await handleDeleteClip(clipId, organizationID, updateClips);
		} finally {
			setIsDeleting(false);
		}
	};

	return {
		isRegenerating,
		setIsRegenerating,
		isSaving,
		isDeleting,
		regenerateSummary,
		saveClip,
		deleteClip,
	};
};
