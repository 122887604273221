import { Stack, Group, createStyles } from '@mantine/core';
import DateRangePicker from '../../../components/DateRangePicker';
import CardListViewToggle from '../../../components/CardListViewToggle';
import { useRecoilState, useRecoilValue } from 'recoil';
import { clipsView } from '../../../Atoms/clips';
import { MeetingSearch } from '../../../components/MeetingSearch';
import BulkActionRow from '../../../components/BulkActionRow/BulkActionRow';
import { bulkDeletingCollectionMeetingsSelection } from '../../../Atoms/collections';

const useStyles = createStyles(() => ({
	topContainer: {
		flex: 1,
	},
}));

const statusOptions = [
	{ label: 'All', value: 'all' },
	{ label: 'Highlights', value: 'highlights' },
	{ label: 'Recent', value: 'recent' },
];

export interface ClipsHeaderProps {
	searchValue: string;
	handleSearch: (value: string) => void;
	opened: boolean;
	setOpened: (o: any) => void;
	bulkActionDeleteClips: () => void;
	handleBulkAddToCollection: (meetingIDs: string[]) => void;
	handleBulkArchive: (meetingIDs: string[]) => void;
	dateRange: [Date | null, Date | null];
	setDateRange: (dateRange: [Date | null, Date | null]) => void;
	segmentValue: string;
}

export default function ClipsHeader({
	searchValue,
	handleSearch,
	opened,
	setOpened,
	bulkActionDeleteClips,
	handleBulkArchive,
	handleBulkAddToCollection,
	dateRange,
	setDateRange,
	segmentValue,
}: ClipsHeaderProps) {
	const { classes } = useStyles();
	const [viewType, setViewType] = useRecoilState(clipsView);
	const selection = useRecoilValue(bulkDeletingCollectionMeetingsSelection);

	return (
		<Stack className={classes.topContainer} spacing={0}>
			<Group position={'apart'} align={'center'} spacing={0} noWrap>
				<Group align={'center'} style={{ flex: 2 }}>
					<CardListViewToggle viewType={viewType} setViewType={setViewType} />

					<MeetingSearch
						searchValue={searchValue}
						handleSearch={handleSearch}
						viewType={viewType}
						placeholder='Search by clip name...'
					/>
				</Group>
				{viewType === 'table' ? (
					<BulkActionRow
						bulkActionDelete={bulkActionDeleteClips}
						handleBulkArchive={handleBulkArchive}
						opened={opened}
						setOpened={setOpened}
						selection={selection}
						handleBulkAddToCollection={handleBulkAddToCollection}
						deleteConfirmationText='Are you sure you want to delete these clips?'
					/>
				) : null}

				<DateRangePicker
					dateRange={dateRange}
					setDateRange={setDateRange}
					segmentValue={segmentValue}
				/>
			</Group>
		</Stack>
	);
}
