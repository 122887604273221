import React, { useEffect, useState } from 'react';
import {
	createStyles,
	Table,
	ScrollArea,
	UnstyledButton,
	Group,
	Text,
	Center,
	TextInput,
	rem,
	Menu,
	ActionIcon,
	Button,
	Stack,
	Image,
} from '@mantine/core';
import { keys } from '@mantine/utils';
import {
	IconSelector,
	IconChevronDown,
	IconChevronUp,
	IconSearch,
} from '@tabler/icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEllipsisVertical,
	faTrash,
	faPencil,
	faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import OwnerRow from './OwnerRow';
import {
	currentOrganizationID,
	currentUser,
	User,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';
import { useNavigate } from 'react-router-dom';
import DeleteCollectionModal from './DeleteCollectionModal';
import { useDisclosure } from '@mantine/hooks';
import {
	SpecialCollections,
	editingCollectionAtom,
	renameModalOpenedAtom,
} from '../../../Atoms/collections';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { logger } from '../../../helpers/logger';
import { showFailureNotification } from '../../../helpers/notifications';
import { transformCollectionsToTableProps } from '../../../helpers/collections';
import { useCollections } from '../../../customHooks/useCollections';
import { getEnvForGleap } from '../../../_utils/trackers';

export const FOLDERS_IMAGE =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/folder_icon.png';
const ICON_REM_SIZE = 21;
const useStyles = createStyles((theme) => ({
	th: {
		padding: '0 !important',
	},

	control: {
		width: '100%',
		padding: `${theme.spacing.xs} ${theme.spacing.md}`,

		'&:hover': {
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[6]
					: theme.colors.gray[0],
		},
	},

	icon: {
		width: rem(ICON_REM_SIZE),
		height: rem(ICON_REM_SIZE),
		borderRadius: rem(ICON_REM_SIZE),
	},

	meetingName: {
		cursor: 'pointer',
	},
}));

export interface CollectionTableProps {
	id: string;
	name: string;
	owner: User | 'Unknown';
	lastModified: string;
}

interface TableSortProps {
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
	myCollections: boolean;
}

interface ThProps {
	children: React.ReactNode;
	reversed: boolean;
	sorted: boolean;
	onSort(): void;
	isName?: boolean;
}

function Th({ children, reversed, sorted, onSort, isName = false }: ThProps) {
	const { classes } = useStyles();
	const Icon = sorted
		? reversed
			? IconChevronUp
			: IconChevronDown
		: IconSelector;
	return (
		<th className={classes.th} style={{ width: isName ? '60%' : 'auto' }}>
			<UnstyledButton onClick={onSort} className={classes.control}>
				<Group position='apart'>
					<Text fw={500} fz='sm'>
						{children}
					</Text>
					<Center className={classes.icon}>
						<Icon size='0.9rem' stroke={1.5} />
					</Center>
				</Group>
			</UnstyledButton>
		</th>
	);
}

function filterData(data: CollectionTableProps[], search: string) {
	const query = search.toLowerCase().trim();
	return data.filter((item) =>
		keys(data[0]).some((key) => {
			if (key === 'owner') {
				const owner =
					item.owner === 'Unknown'
						? 'Unknown'
						: `${item.owner.firstName} ${item.owner.lastName}`;
				return owner.toLowerCase().includes(query);
			}
			return item[key].toLowerCase().includes(query);
		})
	);
}

function sortData(
	data: CollectionTableProps[],
	payload: {
		sortBy: keyof CollectionTableProps | null;
		reversed: boolean;
		search: string;
	}
) {
	const { sortBy } = payload;

	if (!sortBy) {
		return filterData(data, payload.search);
	}

	return filterData(
		[...data].sort((a, b) => {
			if (sortBy === 'owner') {
				const aName =
					a.owner === 'Unknown'
						? 'Unknown'
						: `${a.owner.firstName} ${a.owner.lastName}`;
				const bName =
					b.owner === 'Unknown'
						? 'Unknown'
						: `${b.owner.firstName} ${b.owner.lastName}`;

				return payload.reversed
					? bName.localeCompare(aName)
					: aName.localeCompare(bName);
			} else {
				if (payload.reversed) {
					return b[sortBy].localeCompare(a[sortBy]);
				}

				return a[sortBy].localeCompare(b[sortBy]);
			}
		}),
		payload.search
	);
}

export default function CollectionTable({
	setOpened,
	myCollections,
}: TableSortProps) {
	const navigate = useNavigate();
	const { classes } = useStyles();
	const organizationID = useRecoilValue(currentOrganizationID);
	const organizationUsers = useRecoilValue(userOrganizationMembers);
	const user = useRecoilValue(currentUser);

	const { removeCollection, collections, fetchCollections } =
		useCollections(false);
	const [search, setSearch] = useState('');
	const [sortedData, setSortedData] = useState([]);
	const [sortBy, setSortBy] = useState<keyof CollectionTableProps | null>(null);
	const [reverseSortDirection, setReverseSortDirection] = useState(false);
	const [opened, { open, close }] = useDisclosure(false);
	const [editingCollection, setEditingCollection] = useRecoilState(
		editingCollectionAtom
	);
	const setRenameModalOpened = useSetRecoilState(renameModalOpenedAtom);
	const [pageLoaded, setPageLoaded] = useState(false);

	const modifiedTableData: CollectionTableProps[] =
		transformCollectionsToTableProps(
			collections
				.filter((col) =>
					myCollections
						? col.owningUserID === user.id
						: col.owningUserID !== user.id
				)
				.map((collection) => {
					const owner = organizationUsers.find(
						(user: User) => user.id === collection.owningUserID
					);
					return {
						...collection,
						owner: owner ? owner : 'Unknown',
					};
				})
		).sort((a, b) => a.name === SpecialCollections.Archived && -1);

	useEffect(() => {
		setSortedData(modifiedTableData);
	}, [collections, myCollections]);

	useEffect(() => {
		setPageLoaded(true);
		if (pageLoaded) fetchCollections();
	}, [myCollections]);

	const setSorting = (field: keyof CollectionTableProps) => {
		const reversed = field === sortBy ? !reverseSortDirection : false;
		setReverseSortDirection(reversed);
		setSortBy(field);
		setSortedData(
			sortData(modifiedTableData, { sortBy: field, reversed, search })
		);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget;
		setSearch(value);
		setSortedData(
			sortData(modifiedTableData, {
				sortBy,
				reversed: reverseSortDirection,
				search: value,
			})
		);
	};

	const handleCollectionClick = (collectionID: string) => {
		navigate(`/collections/${collectionID}`);
	};

	const handleRenameClick = (collection: CollectionTableProps) => {
		setEditingCollection(collection);
		setRenameModalOpened(true);
	};

	const handleDeleteCollection = (collection: CollectionTableProps) => {
		setEditingCollection(collection);
		open();
	};

	const confirmDeleteCollection = async (collection: CollectionTableProps) => {
		try {
			const newCollections = await removeCollection(
				collection.id,
				organizationID
			);
		} catch (error) {
			logger('error', 'Error deleting collection', error);
			showFailureNotification({
				message: "Sorry, we couldn't delete the collection. Please try again.",
			});
		} finally {
			close();
		}
	};

	const rows = sortedData.map((collection) => (
		<tr
			key={collection.id}
			style={{
				cursor: 'pointer',
			}}
			onClick={() =>
				handleCollectionClick(
					collection.name === SpecialCollections.Archived
						? 'archive'
						: collection.id
				)
			}
		>
			<td>
				<Group noWrap>
					<Image maw={20} src={FOLDERS_IMAGE} />
					<Text
						// onClick={() => handleCollectionClick(collection.id)}
						className={classes.meetingName}
						lineClamp={1}
					>
						{collection.name}
					</Text>
				</Group>
			</td>
			<td>
				<OwnerRow owner={collection.owner} />
			</td>
			<td>
				<Group position={'apart'} noWrap>
					{`${DateTime.fromISO(collection.lastModified).toLocaleString(
						DateTime.DATETIME_MED
					)}`}
					<Group position={'right'}>
						{collection.name !== SpecialCollections.Archived ? (
							<Menu position='right-start' withArrow>
								<Menu.Target>
									<ActionIcon onClick={(e) => e.stopPropagation()}>
										<FontAwesomeIcon icon={faEllipsisVertical} />
									</ActionIcon>
								</Menu.Target>
								<Menu.Dropdown>
									<Menu.Item
										onClick={(e) => {
											e.stopPropagation();
											handleRenameClick(collection);
										}}
										icon={<FontAwesomeIcon icon={faPencil} />}
									>
										Rename
									</Menu.Item>
									<Menu.Item
										onClick={(e) => {
											e.stopPropagation();
											handleDeleteCollection(collection);
										}}
										icon={<FontAwesomeIcon icon={faTrash} />}
									>
										Delete
									</Menu.Item>
								</Menu.Dropdown>
							</Menu>
						) : (
							<div></div>
						)}
					</Group>
				</Group>
			</td>
		</tr>
	));

	return (
		<Stack>
			<DeleteCollectionModal
				opened={opened}
				close={close}
				collection={editingCollection}
				deleteCollection={confirmDeleteCollection}
			/>
			<Group position={'right'} noWrap>
				{/* <TextInput
					placeholder='Search by any field'
					icon={<IconSearch size='0.9rem' stroke={1.5} />}
					value={search}
					onChange={handleSearchChange}
					style={{ flex: 2 }}
				/> */}
				<Button
					onClick={() => setOpened(true)}
					radius={'md'}
					size={'sm'}
					leftIcon={<FontAwesomeIcon icon={faPlus} />}
					id={`VIS_ctf9nfdtmsxfgg2vmcq0_${getEnvForGleap()}`}
				>
					Create
				</Button>
			</Group>
			<ScrollArea>
				<Table
					highlightOnHover
					horizontalSpacing='md'
					verticalSpacing='xs'
					miw={700}
					sx={{ tableLayout: 'fixed' }}
				>
					<thead>
						<tr>
							<Th
								sorted={sortBy === 'name'}
								reversed={reverseSortDirection}
								onSort={() => setSorting('name')}
								isName={true}
							>
								Name
							</Th>
							<Th
								sorted={sortBy === 'owner'}
								reversed={reverseSortDirection}
								onSort={() => setSorting('owner')}
							>
								Owner
							</Th>
							<Th
								sorted={sortBy === 'lastModified'}
								reversed={reverseSortDirection}
								onSort={() => setSorting('lastModified')}
							>
								Last modified
							</Th>
						</tr>
					</thead>
					<tbody>
						{rows.length > 0 ? (
							rows
						) : (
							<tr id={`VIS_ctf9nfdtmsxfgg2vmct0_${getEnvForGleap()}`}>
								<td colSpan={3}>
									<Text weight={500} align='center'>
										No collections have been shared with you
									</Text>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</ScrollArea>
		</Stack>
	);
}
