import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { getSharingToken } from '../../api/api';
import { isAuthenticated } from '../../Atoms/auth';
import { isCustomBrandedAtom } from '../../Atoms/settings';
import { user } from '../../Atoms/userAtoms';
import { useRecoilValue } from 'recoil';
import ReelayLogoPageLoader from '../loaders/ReelayLogoPageLoader';

export { ProtectedRoute };

function getGuestMeetingViewToken(pathname) {
	const [searchParams] = useSearchParams();
	const guestViewToken = searchParams.get('vt');
	if (!guestViewToken) return;

	// Regex to match possible meeting and clip view paths:
	// /meetings/MET_cremdrftmsxe6n2t3hm0
	// /meetings/MET_cremdrftmsxe6n2t3hm0/summary
	// /clips/MVC_cyckmp27fa8grf1kfb30
	const viewRegex = /^\/(meetings|clips)\/[a-zA-Z0-9_]+(\/[a-zA-Z]+)?$/;
	if (!viewRegex.test(pathname)) {
		return;
	}
	return guestViewToken;
}

function ProtectedRoute({ children }: { children: any }) {
	const history = useLocation();
	const userAuthenticated = useRecoilValue(isAuthenticated);
	const currentUser = useRecoilValue(user);
	const isCustomBranded = useRecoilValue(isCustomBrandedAtom);
	const viewToken = getGuestMeetingViewToken(history.pathname);

	const [isLoading, setIsLoading] = useState(viewToken !== undefined);
	const [isAllowedGuest, setIsAllowedGuest] = useState(false);
	useEffect(() => {
		if (viewToken) {
			const validateViewToken = async () => {
				const {
					data: { data: sharingToken },
				} = await getSharingToken(viewToken);
				setIsAllowedGuest(viewToken && sharingToken.active === true);
				setIsLoading(false);
			};

			validateViewToken();
		}
	}, [history.pathname]);

	if (isLoading) {
		return <ReelayLogoPageLoader />;
	}

	if (!userAuthenticated && !isAllowedGuest) {
		// not logged in and not viewing meeting as a guest
		// so redirect to login page with the return url
		// return <Navigate to="/login" />;
		return (
			<Navigate
				to={
					isCustomBranded
						? `/login?organizationID=${currentUser.currentOrganizationID}`
						: '/login'
				}
				state={{ from: history }}
			/>
		);
	}

	// authorized so return child components
	return children;
}
