import React, { useState } from 'react';
import {
	Card,
	Image,
	Text,
	Group,
	Grid,
	Flex,
	Box,
	Divider,
	useMantineTheme,
	AspectRatio,
	Button,
	Center,
	Stack,
	Checkbox,
	createStyles,
	ActionIcon,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFileLines,
	faCirclePlay,
	faFileInvoice,
} from '@fortawesome/pro-regular-svg-icons';
import { DateTime } from 'luxon';
import { IMeeting } from '../../../interfaces/meeting';
import { createVideoThumbnail } from '../../../helpers/data';
import { StatusBadge } from '../../Badges/StatusBadge';
import { MeetingCardMenu } from './MeetingCardMenu';
import { MILLISECONDS_PER_SECOND } from '../../constants';
import { convertSecondsToHHMMSS } from '../../../_utils/time';
import { removeHtmlTags } from '../../../_utils/handy-functions';
import reactStringReplace from 'react-string-replace';
import { downloadAttachment } from '../../../helpers/attachments';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { getAttachmentsForMeeting, updateMeeting } from '../../../api/api';
import { previousPageURL } from '../../../Atoms/meetingAtom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router';
import {
	bulkDeletingCollectionMeetings,
	bulkDeletingCollectionMeetingsSelection,
} from '../../../Atoms/collections';
import { isMeetingExpired } from '../../../helpers/meetings';
import {
	currentUserMeetings,
	userOrganizationMembersMap,
} from '../../../Atoms/userAtoms';
import PlatformIcon from '../../PlatformIcon';

const IMAGE_ASPECT_RATIO_NOMINATOR = 16;
const IMAGE_ASPECT_RATIO_DENOMINATOR = 9;
const TEXT_LINE_CLAMP_5_HEIGHT = 102.97;
const CONSERVATIVE_MAX_CHAR_COUNT_TEXT_LINE_CLAMP_5 = 214.88;
const THUMBNAIL_ROW_HEIGHT = 37;

const useStyles = createStyles((theme) => ({
	thumbnailContainer: {
		position: 'relative',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		borderRadius: theme.radius.lg,
	},
	recapOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: theme.colors['light-blue'][0],
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	timestampContainer: {
		backgroundColor: 'black',
		opacity: 0.8,
		color: 'white',
		marginBottom: '2.5%',
		marginRight: '1.5%',
		borderRadius: '4px',
		padding: 3,
	},
	card: {
		position: 'relative',
	},
	checkboxContainer: {
		position: 'absolute',
		top: '20px',
		left: '20px',
		zIndex: 1,
	},
}));

interface MeetingCardProps {
	meeting: IMeeting;
	searchValue?: string;
	handleGetMeetingsData?: ({ searchValue }: { searchValue: string }) => void;
	openSharingModal?: () => void;
}

export function MeetingCard({
	meeting,
	searchValue = '',
	openSharingModal,
	handleGetMeetingsData,
}: MeetingCardProps) {
	const navigate = useNavigate();
	const theme = useMantineTheme();
	const { classes } = useStyles();
	const setPreviousURL = useSetRecoilState(previousPageURL);
	const [downloadingAttachment, setDownloadingAttachment] = useState(false);
	const bulkDeletingMeetings = useRecoilValue(bulkDeletingCollectionMeetings);
	const [selection, setSelection] = useRecoilState(
		bulkDeletingCollectionMeetingsSelection
	);
	const orgUsers = useRecoilValue(userOrganizationMembersMap);
	const setMeetings = useSetRecoilState(currentUserMeetings);

	const {
		id,
		friendlyID,
		organizationID,
		name,
		author,
		organizer,
		meetingDate,
		videoMetadata,
		summary,
		startAt,
		views,
		actionsCount,
		questionsCount,
		clipsCount,
		status,
		thumbnailTime,
		botMetadata,
		botCreationStatus,
		invitedUsers,
		platform,
		publishedAt,
		recapOnly,
		viewingDeadline,
		owningUserID,
	} = meeting;

	const highlightText = (text: string | undefined) => {
		return reactStringReplace(text, searchValue, (match, i) => (
			<span key={i} style={{ backgroundColor: 'yellow' }}>
				{match}
			</span>
		));
	};

	const startAtTime = startAt || botMetadata?.joinAt;
	const isScheduledMeeting = status === 'scheduled';
	const isPublished = status === 'published';
	const meetingExpired =
		isMeetingExpired(viewingDeadline) && status === 'published';

	const summaryText =
		typeof summary === 'string'
			? searchValue.length
				? highlightText(removeHtmlTags(summary))
				: removeHtmlTags(summary)
			: summary;

	const content = isScheduledMeeting
		? `Reelay is scheduled to join your ${name} meeting on ${DateTime.fromISO(
				startAtTime
		  ).toFormat('MMMM dd, yyyy')} at ${DateTime.fromISO(startAtTime).toFormat(
				'h:mm a'
		  )}. If you need to make any updates to the meeting you can do so by clicking on the menu button above.`
		: summary
		? summaryText
		: 'No summary yet.';

	let organizerUser = organizer;

	if (!organizerUser) {
		organizerUser = orgUsers.get(owningUserID);
	}
	const hostName =
		organizerUser?.firstName || organizerUser?.lastName
			? `${organizerUser?.firstName || ''} ${
					organizerUser?.lastName || ''
			  }`.trim()
			: author
			? `${author.firstName} ${author.lastName}`
			: 'Unknown';
	const formattedDate =
		status === 'scheduled'
			? DateTime.fromISO(startAtTime).toFormat('MM/dd/yyyy')
			: platform === 'uploaded' && publishedAt
			? DateTime.fromISO(publishedAt).toFormat('MM/dd/yyyy')
			: meetingDate
			? DateTime.fromISO(meetingDate).toFormat('MM/dd/yyyy')
			: 'N/A';

	const meetingTime = startAtTime
		? DateTime.fromISO(startAtTime).toFormat('h:mm a')
		: 'N/A';

	const onMinutesClick = async () => {
		try {
			setDownloadingAttachment(true);
			const attachments = await getAttachmentsForMeeting(id, organizationID);
			const minutesDoc = attachments.find(
				(attachment) =>
					attachment.type === 'minutes' ||
					attachment.fileName.includes('Minutes')
			);
			if (!minutesDoc) {
				showFailureNotification({
					message: 'Sorry, no meeting minutes found for this meeting.',
				});
				return;
			}
			const res = await downloadAttachment(minutesDoc, organizationID);
			showSuccessNotification({
				message: 'Meeting minutes downloaded successfully.',
			});
		} catch (error) {
			console.error('Error downloading meeting minutes', error);
			showFailureNotification({
				message:
					'Apologies, the meeting minutes could not be downloaded. Please try again.',
			});
		} finally {
			setDownloadingAttachment(false);
		}
	};

	const openMeeting = (e) => {
		e.preventDefault();
		if (recapOnly || status !== 'published') return;
		setPreviousURL(location.pathname);
		navigate(`/meetings/${friendlyID}`);
	};

	const handleCheckboxChange = () => {
		setSelection((current) =>
			current.includes(id)
				? current.filter((item) => item !== id)
				: [...current, id]
		);
	};

	const handleIconClick = (e) => {
		e.stopPropagation();
		if (botMetadata?.meetingLink) {
			window.open(botMetadata.meetingLink, '_blank');
		}
	};

	return (
		<Grid.Col xs={12} md={6} xl={4}>
			<Card shadow='md' radius='lg' withBorder p='xs' className={classes.card}>
				{bulkDeletingMeetings && (
					<div className={classes.checkboxContainer}>
						<Checkbox
							checked={selection.includes(id)}
							onChange={handleCheckboxChange}
						/>
					</div>
				)}
				<Flex direction='column' style={{ height: '100%' }}>
					<Box
						sx={{
							position: 'relative',
							borderRadius: theme.radius.lg,
							overflow: 'hidden',
							flex: 1,
							cursor:
								recapOnly || status !== 'published' ? 'default' : 'pointer',
						}}
						onClick={bulkDeletingMeetings ? handleCheckboxChange : openMeeting}
					>
						<AspectRatio
							ratio={
								IMAGE_ASPECT_RATIO_NOMINATOR / IMAGE_ASPECT_RATIO_DENOMINATOR
							}
							sx={{ backgroundColor: theme.colors['light-blue'][0] }}
						>
							<div className={classes.thumbnailContainer}>
								<Image
									src={
										recapOnly
											? ''
											: createVideoThumbnail(
													videoMetadata?.playbackID,
													thumbnailTime
											  ) || '/placeholder.svg'
									}
									alt={name || 'Meeting thumbnail'}
									style={{ objectFit: 'cover', width: '100%', height: '100%' }}
								/>
								{recapOnly && (
									<div className={classes.recapOverlay}>
										<Stack
											justify={
												bulkDeletingMeetings ? 'space-between' : 'center'
											}
											align='center'
											h='100%'
											w='100%'
										>
											{bulkDeletingMeetings && (
												<Group
													pt='2.5%'
													pl='1.5%'
													position='left'
													style={{ width: '100%' }}
												>
													<Checkbox checked={selection.includes(id)} />
												</Group>
											)}
											<FontAwesomeIcon
												icon={faFileInvoice}
												size='4x'
												opacity={0.8}
											/>
										</Stack>
									</div>
								)}
							</div>
						</AspectRatio>
						<Box
							sx={{
								position: 'absolute',
								bottom: 0,
								left: 0,
								right: 0,
								height: '38%',
								background: 'rgba(0, 0, 0, 0.5)',
								borderBottomLeftRadius: theme.radius.lg,
								borderBottomRightRadius: theme.radius.lg,
								paddingLeft: theme.spacing.md,
								paddingRight: theme.spacing.md,
							}}
						>
							<Flex justify='center' align='center' style={{ height: '100%' }}>
								<Box style={{ flex: 1 }}>
									<Text
										size='18px'
										weight={700}
										color='white'
										lh='20px'
										mb='5px'
										lineClamp={2}
									>
										{name || 'Untitled Meeting'}
									</Text>
									<Text size='12px' color='white'>
										{hostName} | {formattedDate} | {meetingTime}
									</Text>
								</Box>
								<Flex
									direction='column'
									align='center'
									justify='center'
									style={
										!isScheduledMeeting
											? { position: 'relative', top: '6px' }
											: {}
									}
								>
									{platform !== 'uploaded' ? (
										<ActionIcon variant='transparent' onClick={handleIconClick}>
											<PlatformIcon platform={platform} size={38} />
										</ActionIcon>
									) : (
										<FontAwesomeIcon
											icon={faCirclePlay}
											color='white'
											style={{ width: '38px', height: '38px' }}
										/>
									)}

									{!isScheduledMeeting && !recapOnly && (
										<Text size='xs' color='#ffffff' mt={4}>
											{convertSecondsToHHMMSS(
												videoMetadata?.duration / MILLISECONDS_PER_SECOND
											)}
										</Text>
									)}
								</Flex>
							</Flex>
						</Box>
						<Box style={{ position: 'absolute', top: 8, left: 8 }}>
							<StatusBadge
								status={meetingExpired ? 'expired' : status}
								variant='filled'
								isThumbnailBadge={true}
								botStatus={botMetadata?.status}
								botCreationStatus={botCreationStatus}
							/>
						</Box>
					</Box>

					<Box style={{ flex: 1 }}>
						<Flex
							justify='space-between'
							align='center'
							mt='md'
							mb='xs'
							gap='2px'
						>
							<Group
								spacing='xs'
								noWrap
								style={{ flexGrow: 1, overflow: 'hidden' }}
							>
								<Group spacing='xs' noWrap style={{ width: '100%' }}>
									<Flex align='center'>
										<Text size='12px' color='primary-text' mr={6} fw={400}>
											Actions
										</Text>
										<Text
											size='12px'
											component='span'
											color='primary-cta'
											weight={700}
										>
											{actionsCount || 0}
										</Text>
									</Flex>
									<Text size='12px' color='#c7c7c7'>
										|
									</Text>
									<Flex align='center'>
										<Text size='12px' color='primary-text' mr={6} fw={400}>
											Questions
										</Text>
										<Text
											size='12px'
											component='span'
											color='primary-cta'
											weight={700}
										>
											{questionsCount || 0}
										</Text>
									</Flex>
									<Text size='12px' color='#c7c7c7'>
										|
									</Text>
									<Flex align='center'>
										<Text size='12px' color='primary-text' mr={6} fw={400}>
											Clips
										</Text>
										<Text
											size='12px'
											component='span'
											color='primary-cta'
											weight={700}
										>
											{clipsCount || 0}
										</Text>
									</Flex>
								</Group>
							</Group>
							<MeetingCardMenu
								meeting={meeting}
								searchValue={searchValue}
								handleGetMeetingsData={handleGetMeetingsData}
							/>
						</Flex>

						<Divider my='xs' color='#8C919B4D' />

						<Text size='12px' color='primary-text' mb='xs' lineClamp={3}>
							{content}
						</Text>

						{isPublished && (
							<Button
								onClick={onMinutesClick}
								leftIcon={
									<FontAwesomeIcon
										icon={faFileLines}
										size='lg'
										color={theme.colors['reelay-purple'][0]}
									/>
								}
								loading={downloadingAttachment}
								variant='subtle'
								styles={{
									label: {
										position: 'relative',
										top: '2px',
									},
								}}
							>
								<Text
									size='12px'
									color={theme.colors['reelay-purple'][0]}
									fw={700}
								>
									View/Download Meeting Minutes
								</Text>
							</Button>
						)}
					</Box>
				</Flex>
			</Card>
		</Grid.Col>
	);
}
