import React, { useEffect, useState } from 'react';
import {
	Button,
	FileButton,
	Group,
	Image,
	Paper,
	Stack,
	Text,
	Textarea,
	TextInput,
	Modal,
	Progress,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFloppyDisk,
	faTrash,
	faUpload,
	faExclamationTriangle,
} from '@fortawesome/pro-light-svg-icons';
import { styles } from '../../../../../../settings/tabs/styles';
import { logger } from '../../../../../../../helpers/logger';
import { OrganizationSettings } from '../../../../../../../Atoms/settings';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../../../helpers/notifications';
import {
	deleteOrganizationSettings,
	saveOrganizationSettings,
} from '../../../../../../../api/settings';
import { DangerNoButton } from '../../../../../../../components/Buttons/DangerNoButton';
import { useParams } from 'react-router';
import { updateAllBots } from '../../../../../../../api/recall';
import { DateTime } from 'luxon';
import { parseSafeISO } from '../../../../../../../helpers/date';

const BOT_PICTURE_CHAR_LIMIT = 1835008;
const SAFETY_MARGIN_FOR_ERROR = 50000;
const MAX_CHAR_LIMIT = BOT_PICTURE_CHAR_LIMIT - SAFETY_MARGIN_FOR_ERROR;

interface Props {
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
}

export default function OrganizationBranding({
	organizationSettings,
	setOrganizationSettings,
}: Props) {
	const { classes, theme } = styles();
	const { organizationID } = useParams();
	const { bot_name, bot_picture, bot_custom_join_message } =
		organizationSettings;
	const [orgBotNameValue, setOrgBotNameValue] = useState(bot_name || '');
	const [botCustomJoinMessage, setBotCustomJoinMessage] = useState(
		bot_custom_join_message || ''
	);
	const [imagePreview, setImagePreview] = useState<string | null>(
		bot_picture || null
	);
	const [base64, setBase64] = useState<string | null>(null);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [messageButtonLoading, setMessageButtonLoading] = useState(false);
	const [updateAllBotsButtonLoading, setUpdateAllBotsButtonLoading] =
		useState(false);
	const [uploadButtonLoading, setUploadButtonLoading] = useState(false);
	const [removeButtonLoading, setRemoveButtonLoading] = useState(false);
	const [confirmModalOpened, setConfirmModalOpened] = useState(false);

	// update all bots
	const [updateProgress, setUpdateProgress] = useState({
		total: 0,
		success: 0,
		failed: 0,
		lastUpdated: new Date().toISOString(),
		isUpdating: false,
		isComplete: false,
	});

	// Add this useEffect for Pusher subscription
	useEffect(() => {
		if (!organizationID) return;

		const channelName = `org-${organizationID}`;
		const channel = window?.pusher?.subscribe(channelName);

		channel.bind(
			'bot-update-progress',
			(data: {
				total: number;
				success: number;
				failed: number;
				updatedAt: string;
			}) => {
				console.log('bot-update-progress', data);
				setUpdateProgress((prev) => ({
					...prev,
					...data,
					isComplete: data.success + data.failed >= data.total,
					lastUpdated: data.updatedAt || new Date().toISOString(),
				}));
			}
		);

		return () => {
			channel.unbind('bot-update-progress');
			window?.pusher?.unsubscribe(channelName);
		};
	}, [organizationID]);

	useEffect(() => {
		setImagePreview(bot_picture || '');
		setOrgBotNameValue(bot_name || '');
		setBotCustomJoinMessage(bot_custom_join_message || '');
	}, [organizationSettings]);

	const handleSaveOrgBotName = async () => {
		try {
			setButtonLoading(true);
			const payload = [
				{
					key: 'bot_name',
					value: orgBotNameValue,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				bot_name: details?.value,
			}));
			setOrgBotNameValue(details?.value);
			showSuccessNotification({
				message:
					'Organization bot name saved successfully. You should see it named this when it joins your meetings.',
			});
		} catch (error) {
			logger('error', 'Error saving org bot name', error);
			showFailureNotification({
				message:
					'Sorry, there was an error saving your organization bot name. Please try again.',
			});
		} finally {
			setButtonLoading(false);
		}
	};

	const handleSaveBotCustomJoinMessage = async () => {
		try {
			setMessageButtonLoading(true);
			const payload = [
				{
					key: 'bot_custom_join_message',
					value: botCustomJoinMessage,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				bot_custom_join_message: details?.value,
			}));
			setBotCustomJoinMessage(details?.value);
			showSuccessNotification({
				message: 'Bot custom join message saved successfully.',
			});
		} catch (error) {
			logger('error', 'Error saving bot custom join message', error);
			showFailureNotification({
				message:
					'Sorry, there was an error saving your bot custom join message. Please try again.',
			});
		} finally {
			setMessageButtonLoading(false);
		}
	};

	const handleFileChange = (file: File) => {
		try {
			if (!file) return;
			setUploadButtonLoading(true);

			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async () => {
				const base64String = reader.result as string;
				if (base64String.length > MAX_CHAR_LIMIT) {
					return showFailureNotification({
						message: `Sorry, your file is too large. Please try again with a smaller file.`,
					});
				}

				const fileUrl = URL.createObjectURL(file);
				setImagePreview(fileUrl);

				const payload = [
					{
						key: 'bot_picture',
						value: base64String,
					},
				];
				const res = await saveOrganizationSettings(payload, organizationID);
				setOrganizationSettings((prev) => ({
					...prev,
					bot_picture: res[0]?.value,
				}));
				showSuccessNotification({
					message: 'Organization bot avatar has been saved.',
				});
			};
			reader.onerror = (error) => {
				showFailureNotification({
					message: `Sorry, there was an error saving your organization bot avatar. Please try again or with a different file. Your file may also be too large.`,
				});
			};
		} catch (error) {
			logger('error', `Error saving organization bot avatar`, error);
		} finally {
			setUploadButtonLoading(false);
		}
	};

	const removeBotPicture = async () => {
		try {
			setRemoveButtonLoading(true);
			const res = await deleteOrganizationSettings(
				'bot_picture',
				organizationID
			);
			if (res[0]?.key === 'bot_picture') {
				setOrganizationSettings((prev) => ({
					...prev,
					bot_picture: '',
				}));
				showSuccessNotification({
					message: 'Organization bot avatar has been removed.',
				});
			}
		} catch (error) {
			logger('error', 'Error removing bot picture', error);
			showFailureNotification({
				message: `Sorry, there was an error removing your organization bot avatar. Please try again.`,
			});
		} finally {
			setRemoveButtonLoading(false);
		}
	};

	const handleUpdateAllBots = async () => {
		try {
			setConfirmModalOpened(false);
			setUpdateAllBotsButtonLoading(true);
			const payload = {
				bot_name: orgBotNameValue,
				bot_custom_join_message: botCustomJoinMessage,
			};
			const res = await updateAllBots(organizationID);
			console.log('handleUpdateAllBots res', res);
			showSuccessNotification({
				message: 'QUEUE started. Updating all bots...',
			});
		} catch (error) {
			logger('error', 'Error updating all bots', error);
			showFailureNotification({
				message: `Sorry, there was an error updating all bots. Please try again.`,
			});
		} finally {
			setUpdateAllBotsButtonLoading(false);
		}
	};

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Group position={'apart'}>
				<Stack spacing={0}>
					<Text mb={4} size={'sm'} c={'black'}>
						Rename your organization's instance of Reelay
					</Text>
					<TextInput
						value={orgBotNameValue}
						onChange={(event) => setOrgBotNameValue(event.currentTarget.value)}
						radius='md'
						bg={'primary.0'}
						styles={{
							input: {
								backgroundColor: theme.colors.primary[0],
							},
						}}
						placeholder='Bot name'
					/>
				</Stack>
				<Button
					type={'submit'}
					variant={'filled'}
					radius={'xl'}
					rightIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
					color={'primary'}
					loading={buttonLoading}
					onClick={handleSaveOrgBotName}
				>
					Save
				</Button>
			</Group>
			<Group position={'apart'} mt={'lg'} noWrap>
				<Stack spacing={0} w={'100%'}>
					<Text mb={4} size={'sm'} c={'black'}>
						Bot Join Custom Message
					</Text>
					<Textarea
						minRows={5}
						value={botCustomJoinMessage}
						onChange={(event) =>
							setBotCustomJoinMessage(event.currentTarget.value)
						}
						radius='md'
						bg={'primary.0'}
						styles={{
							input: {
								backgroundColor: theme.colors.primary[0],
							},
						}}
						placeholder='The custom message that the bot will say when it joins a meeting.'
					/>
				</Stack>
				<Button
					type={'submit'}
					variant={'filled'}
					radius={'xl'}
					rightIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
					color={'primary'}
					loading={messageButtonLoading}
					onClick={handleSaveBotCustomJoinMessage}
				>
					Save
				</Button>
			</Group>

			<Group mt='lg'>
				<Stack spacing={0} w='100%'>
					<Text mb={4} size='sm' c='black'>
						Pressing this button will update all the organization's existing
						bots with the current bot name and custom join message.{' '}
					</Text>

					{updateProgress.total > 0 && (
						<>
							<Progress
								value={
									((updateProgress.success + updateProgress.failed) /
										updateProgress.total) *
									100
								}
								label={`${Math.round(
									((updateProgress.success + updateProgress.failed) /
										updateProgress.total) *
										100
								)}%`}
								size='lg'
								radius='xl'
								striped
								animate={!updateProgress.isComplete}
								mt='sm'
							/>

							<Group position='apart' mt='xs'>
								<Text size='sm'>
									{updateProgress.isComplete ? 'Completed: ' : 'Processing: '}
									{updateProgress.success + updateProgress.failed}/
									{updateProgress.total}
								</Text>
								<Group spacing='xl'>
									<Text size='sm' color='green'>
										Success: {updateProgress.success}
									</Text>
									<Text size='sm' color='red'>
										Failed: {updateProgress.failed}
									</Text>
								</Group>
							</Group>

							<Text size='xs' mt='sm' color='dimmed'>
								Last update:{' '}
								{parseSafeISO(updateProgress.lastUpdated).toLocaleString(
									DateTime.DATETIME_MED
								)}
								{updateProgress.isComplete && ' - Completed'}
							</Text>
						</>
					)}

					<Button
						type='button'
						variant='filled'
						radius='xl'
						color='red'
						w={250}
						onClick={() => setConfirmModalOpened(true)}
						disabled={updateProgress.total > 0 && !updateProgress.isComplete}
						loading={
							updateAllBotsButtonLoading ||
							(updateProgress.total > 0 && !updateProgress.isComplete)
						}
					>
						{updateProgress.total > 0
							? updateProgress.isComplete
								? 'Update Again'
								: 'Update in Progress'
							: 'Update Existing Bot Details'}
					</Button>

					{updateProgress.isUpdating && (
						<Text size='xs' mt='sm' color='dimmed'>
							Last update:{' '}
							{updateProgress.lastUpdated
								? parseSafeISO(updateProgress.lastUpdated).toLocaleString(
										DateTime.DATETIME_MED
								  )
								: 'N/A'}
						</Text>
					)}
				</Stack>
			</Group>
			<Group position={'apart'} mt={'lg'}>
				<Stack spacing={0}>
					<Text mb={4} size={'sm'}>
						Upload a picture for your organization's instance of Reelay
					</Text>
					{imagePreview ? (
						<Image
							src={imagePreview}
							alt='Bot Avatar image preview'
							width={150}
							height={150}
						/>
					) : (
						<Image
							width={150}
							height={150}
							src={null}
							alt='With default placeholder'
							withPlaceholder
						/>
					)}
					<Text size='sm' align='left' mt='sm'>
						accepts only jpeg files. Image should be 16:9. Recommended
						resolution is 640x360.
					</Text>
				</Stack>

				<Stack>
					<FileButton onChange={handleFileChange} accept='image/jpeg'>
						{(props) => (
							<Button
								loading={uploadButtonLoading}
								radius={'xl'}
								variant={'filled'}
								rightIcon={<FontAwesomeIcon icon={faUpload} />}
								color={'primary'}
								{...props}
							>
								Upload image
							</Button>
						)}
					</FileButton>
					{bot_picture?.length > 0 ? (
						<DangerNoButton
							clickFunc={removeBotPicture}
							text={'Remove'}
							leftIcon={<FontAwesomeIcon icon={faTrash} />}
							radius={'lg'}
							loading={removeButtonLoading}
						/>
					) : null}
				</Stack>
			</Group>

			<Modal
				opened={confirmModalOpened}
				onClose={() => setConfirmModalOpened(false)}
				title='Update Existing Bot Details'
			>
				<Text size='sm'>
					Pressing this button will update all the bots for this organization
					with the current bot name and custom join message. This is an
					expensive process. Are you sure you want to do this?
				</Text>
				<Group position='right' mt='md'>
					<Button
						radius={'xl'}
						variant='outline'
						onClick={() => setConfirmModalOpened(false)}
					>
						Cancel
					</Button>
					<Button
						type={'button'}
						variant={'filled'}
						radius={'xl'}
						color={'red'}
						onClick={handleUpdateAllBots}
					>
						Confirm
					</Button>
				</Group>
			</Modal>
		</Paper>
	);
}
