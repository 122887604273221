import { Paper, Switch, Text, Title } from '@mantine/core';
import { styles } from '../../../../../../settings/tabs/styles';
import { OrganizationSettings } from '../../../../../../../Atoms/settings';
import { useState } from 'react';
import { logger } from '../../../../../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../../../helpers/notifications';
import { saveOrganizationSettings } from '../../../../../../../api/settings';
import { useParams } from 'react-router';
import { getEnvForGleap } from '../../../../../../../_utils/trackers';
import { useRecoilValue } from 'recoil';
import { currentOrganizationID } from '../../../../../../../Atoms/userAtoms';

interface Props {
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
	// New props for reusability
	settingKey: string;
	label: string;
	description: string;
	successMessage: string;
	failureMessage: string;
	sharingOptionText?: string; // Made optional
	defaultOptionText?: string; // Made optional
}

export default function SettingSwitch({
	organizationSettings,
	setOrganizationSettings,
	settingKey,
	label,
	description,
	successMessage,
	failureMessage,
	sharingOptionText, // Optional
	defaultOptionText, // Optional
}: Props) {
	const { classes } = styles();
	const userOrgID = useRecoilValue(currentOrganizationID);
	const { organizationID: orgID } = useParams();

	const organizationID = orgID || userOrgID;

	// Extract the current setting value dynamically based on the key
	const settingValue = organizationSettings[settingKey] as boolean;
	const [checked, setChecked] = useState<boolean>(settingValue);

	if (checked !== settingValue) {
		setChecked(settingValue);
	}

	const handleSettingChange = async (value: boolean) => {
		try {
			setChecked(value);
			const payload = [
				{
					key: settingKey, // Use the passed setting key
					value,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				message: successMessage, // Use passed success message
			});
		} catch (error) {
			logger('error', `error changing ${settingKey} setting`, error);
			setChecked(!value);
			showFailureNotification({
				message: failureMessage, // Use passed failure message
			});
		}
	};

	return (
		<Paper
			className={classes.card}
			shadow='xs'
			p='md'
			radius={'md'}
			id={`VIS_ctf9nfdtmsxfgg2vmcd0_${getEnvForGleap()}`}
		>
			<Switch
				labelPosition='left'
				label={
					<Title order={4} color='primary-text'>
						{label}
					</Title>
				}
				description={
					<Text
						style={{
							cursor: 'default',
						}}
					>
						{description} {/* Use passed description */}
						{/* Conditionally render sharingOptionText and defaultOptionText only if they are provided */}
						{sharingOptionText && defaultOptionText && (
							<>
								<Text span fs='italic'>
									{sharingOptionText}
								</Text>
								. Otherwise, the default will be{' '}
								<Text span fs='italic'>
									{defaultOptionText}
								</Text>
								.
							</>
						)}
					</Text>
				}
				size='sm'
				w={'100%'}
				width={'100%'}
				mb={'lg'}
				checked={checked}
				onChange={(e) => handleSettingChange(e.currentTarget.checked)}
				styles={{
					body: {
						width: '100%',
						justifyContent: 'space-between',
						flex: 1,
					},
				}}
			/>
		</Paper>
	);
}
