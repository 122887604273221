// src/hooks/useShareClipModal.ts
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { VideoClip } from '../../Atoms/clips';
import { changeMeetingSharing } from '../../helpers/meetings';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { addUsersToClip, removeUsersFromClip } from '../../api/clips';
import { logger } from '../../helpers/logger';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { meetingClipsAtom } from '../../Atoms/meetingAtom';

export const useShareClipModal = (organizationID: string) => {
	const [buttonLoading, setButtonLoading] = useState(false);
	const [selectedClip, setSelectedClip] = useState<VideoClip | null>(null);
	const [clips, setClips] = useRecoilState(meetingClipsAtom);

	const form = useForm({
		initialValues: {
			viewers: [],
		},
		validate: {
			viewers: (value) =>
				value.length === 0 ? 'Please add at least one viewer' : null,
		},
	});

	const handleChangeSharingOption = async (value: string) => {
		if (!selectedClip) return;

		try {
			const newSharingToken = await changeMeetingSharing(
				value,
				selectedClip.id,
				organizationID
			);
			return {
				...selectedClip,
				sharingToken: newSharingToken,
			};
		} catch (err) {
			logger('error', 'Error changing sharing option', err);
			showFailureNotification({
				message: 'Failed to change sharing option. Please try again.',
			});
			return null;
		}
	};

	const handleRemoveUser = async (userId: string) => {
		if (!selectedClip) return;

		try {
			const { removedUserLinks } = await removeUsersFromClip(
				[userId],
				selectedClip.id,
				organizationID
			);
			return {
				...selectedClip,
				videoClipUserLinks: selectedClip.videoClipUserLinks.filter(
					(link) => !removedUserLinks.includes(link.userID)
				),
			};
		} catch (error) {
			logger('error', 'Error removing user from clip', error);
			showFailureNotification({
				message: 'Failed to remove user. Please try again.',
			});
			return null;
		}
	};

	const handleAddUsers = async (customMessage: string) => {
		if (!selectedClip || form.values.viewers.length === 0) return;

		setButtonLoading(true);
		try {
			const {
				videoClipUserLinks,
				message,
				token: sharingToken,
			} = await addUsersToClip({
				videoClipID: selectedClip.id,
				emails: form.values.viewers,
				organizationID,
				message: customMessage,
			});

			const invitedUserLinks = videoClipUserLinks;
			if (!Array.isArray(invitedUserLinks)) {
				showFailureNotification({ message });
				return null;
			}

			const updatedClip = {
				...selectedClip,
				sharingToken,
				videoClipUserLinks: [
					...selectedClip.videoClipUserLinks,
					...invitedUserLinks.map((link) => ({
						...link,
						firstName: link.user.firstName,
						lastName: link.user.lastName,
						email: link.user.email,
						userID: link.user.id,
						avatarImageURL: link.user.avatarImageURL,
					})),
				],
			};

			setClips((prevClips) =>
				prevClips.map((clip) =>
					clip.id === updatedClip.id ? updatedClip : clip
				)
			);
			form.reset();
			showSuccessNotification({ message: 'Users added successfully.' });
			return updatedClip;
		} catch (err) {
			logger('error', 'Error adding users to clip', err);
			showFailureNotification({
				message: 'Failed to add users. Please try again.',
			});
			return null;
		} finally {
			setButtonLoading(false);
		}
	};

	return {
		selectedClip,
		setSelectedClip,
		form,
		buttonLoading,
		handleChangeSharingOption,
		handleRemoveUser,
		handleAddUsers,
	};
};
