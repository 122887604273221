import { atom } from 'recoil';
import { Content } from './collections';
import { IMeeting, SharingToken } from '../interfaces/meeting';
import { User } from './userAtoms';

export interface VideoClip {
	id: string;
	organizationID: string;
	meetingID: string;
	owningUserID: string;
	title?: string;
	startTime: number;
	endTime: number;
	thumbnailTime: number;
	summary?: string;
	retryCount: number;
	createdAt: number | string;
	updatedAt: number | string;
	playbackURL: string;
	videoClipUserLinks?: VideoClipUserLink[];
	collectionContents: Content[];
	sharingToken: SharingToken;
	owningUser?: User;
	meeting?: IMeeting;
	content?: Content;
	collectionIDs?: string[];
}

export interface VideoClipUserLink {
	id: string;
	videoClipID: string;
	userID: string;
	status: string;
	role: string;
	createdAt: number;
	updatedAt: number;
}

export const clipsState = atom<VideoClip[]>({
	key: 'clipsState',
	default: [],
});

export const clipUserLinksState = atom<VideoClipUserLink[]>({
	key: 'clipUserLinksState',
	default: [],
});

export const clipsView = atom<string>({
	key: 'clipsView',
	default: 'card',
});

export const selectedClipIdState = atom<string | null>({
	key: 'selectedClipIdState',
	default: null,
});

export const reloadClipsAtom = atom<boolean>({
	key: 'reloadClipsAtom',
	default: false,
});

// New atoms
export const addClipToCollectionModalOpenedAtom = atom<boolean>({
	key: 'addClipToCollectionModalOpenedAtom',
	default: false,
});

export const clipBeingAddedToCollection = atom<VideoClip | null>({
	key: 'clipBeingAddedToCollection',
	default: null,
});

export const selectedClipAtom = atom<VideoClip | null>({
	key: 'selectedClipAtom',
	default: null,
});
