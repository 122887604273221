import {
	Button,
	Group,
	Paper,
	ScrollArea,
	Stack,
	Title,
	Tooltip,
	useMantineTheme,
} from '@mantine/core';
import SupportUserCard from '../../components/SupportUserCard';
import OrganizationBranding from './components/OrganizationBranding';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPlus } from '@fortawesome/pro-light-svg-icons';
import { Organization } from '../../../../../Atoms/organizationAtom';
import {
	BrandDetails,
	OrganizationSettings,
} from '../../../../../Atoms/settings';
import CustomBranding from './components/CustomBranding';
import DistributionPreference from './components/SettingSelectPreference/SettingSelectPreference';
import { saveOrganizationSettings } from '../../../../../api/settings';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../helpers/notifications';
import { logger } from '../../../../../helpers/logger';
import SettingSwitch from './components/SettingSwitch';
import EmailDomainsCard from './components/EmailDomainsCard';
import SettingSelectPreference from './components/SettingSelectPreference/SettingSelectPreference';

interface Props {
	open: () => void;
	organization: Organization;
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
	brand: BrandDetails;
	setBrand: React.Dispatch<React.SetStateAction<BrandDetails>>;
}

export default function OrganizationOverview({
	open,
	organization,
	organizationSettings,
	setOrganizationSettings,
	brand,
	setBrand,
}: Props) {
	const { supportLinks, id } = organization;
	const theme = useMantineTheme();

	const handleSettingChange = async (setting: string, value: any) => {
		try {
			const payload = [
				{
					key: setting,
					value,
				},
			];
			const res = await saveOrganizationSettings(payload, id);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				message: 'You have successfully changed the default sharing setting.',
			});
		} catch (error) {
			logger('error', 'error changing default sharing', error);
			showFailureNotification({
				message:
					'Sorry, there was an error changing the default sharing setting. Please try again.',
			});
		}
	};

	return (
		<ScrollArea h={'100%'} offsetScrollbars>
			<Stack h={'100%'}>
				<Group position='apart'>
					<Group spacing={'xs'}>
						<Title order={4}>Support Users</Title>
						<Tooltip
							label={
								'Assigning support users to organizations means you will be notified via email whenever meetings reach audit status.'
							}
							withArrow
						>
							<FontAwesomeIcon icon={faCircleInfo} />
						</Tooltip>
					</Group>

					<Button
						type={'submit'}
						variant={'filled'}
						radius={'xl'}
						rightIcon={<FontAwesomeIcon icon={faPlus} />}
						color={'primary'}
						onClick={open}
					>
						Add
					</Button>
				</Group>
				{supportLinks?.length ? (
					supportLinks.map((supportLink, index) => (
						<SupportUserCard supportUser={supportLink} index={index} />
					))
				) : (
					<Paper>
						No assigned support users. Click on the button to the right to add
						some.
					</Paper>
				)}
				<Title order={4}>Meeting Minutes</Title>
				<SettingSwitch
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					settingKey='meeting_minutes'
					label='Meeting Minutes Docx Feature'
					description='Enabling this feature will have all users within this organization receive the meeting minutes email instead of the fast summary one.'
					successMessage='You have successfully changed the meeting minutes setting.'
					failureMessage='Sorry, there was an error changing the meeting minutes setting. Please try again.'
				/>
				<SettingSelectPreference
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					title='Distribution Default'
					settingKey='distribution_preference'
					options={[
						{ value: 'none', label: 'None' },
						{ value: 'attendees', label: 'Attendees' },
						{ value: 'invitees', label: 'Invitees' },
						{ value: 'all', label: 'All' },
					]}
					description='This dropdown allows you to define which users of a calendar event automatically receive published reelays. The options are: None - no one is added automatically; Attendees - all meeting attendees who are verified Reelay users; Invitees - all calendar invitees who are verified Reelay users; and All - all calendar invitees. This setting streamlines the distribution of published reelays based on your selected criteria.'
				/>
				<SettingSelectPreference
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					title='Recall Speech Model'
					settingKey='speech_model'
					options={[
						{ value: 'best', label: 'Best' },
						{ value: 'nano', label: 'Nano' },
					]}
					description='Select the speech model for recall. The options are: Nothing selected, Best, and Nano.'
				/>
				<Title order={4}>Sharing</Title>
				<SettingSwitch
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					settingKey='sharing'
					label='Sharing'
					description='Enabling this feature will allow all users within this organization to share meetings with anyone who has the link.'
					successMessage='You have successfully changed the sharing setting.'
					failureMessage='Sorry, there was an error changing the sharing setting. Please try again.'
					sharingOptionText='anyone with the link'
					defaultOptionText='invite only'
				/>
				<SettingSwitch
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					settingKey='sharing_default'
					label="Organization Users' Default Sharing Preference"
					description='Enable this setting to have new meetings created with sharing preference set to'
					successMessage='You have successfully changed the default sharing setting.'
					failureMessage='Sorry, there was an error changing the default sharing setting. Please try again.'
					sharingOptionText='Anyone with Link'
					defaultOptionText='Invite only'
				/>
				<SettingSwitch
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					settingKey='consent_email'
					label='24 hour Reelay consent emails'
					description='Enable this setting to have external users receive a consent email 24 hours prior to a meeting where Reelay will join.'
					successMessage='You have successfully changed the email consent setting.'
					failureMessage='Sorry, there was an error changing the email consent setting. Please try again.'
				/>
				<EmailDomainsCard
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					settingKey='internal_email_domains'
					label='Internal Email Domains'
					successAddMessage={(value) =>
						`You have successfully added a new internal email domain: ${value}.`
					}
					successRemoveMessage={(value) =>
						`You have successfully removed internal email domain: ${value}.`
					}
					description='Email domains added here will be considered internal in regards to the calendar integration settings for this organization.'
					placeholder='enter a email domain ex. reelay.com'
				/>

				<EmailDomainsCard
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					settingKey='bot_restricted_email_domains'
					label='Bot Restricted Email Domains'
					successAddMessage={(value) =>
						`You have successfully added a new bot restricted email domain: ${value}.`
					}
					successRemoveMessage={(value) =>
						`You have successfully removed bot restricted email domain: ${value}.`
					}
					description='Reelay will not join calendar events with attendees that contain these email domains.'
					placeholder='enter a email domain ex. reelay.com'
				/>

				<SettingSwitch
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					settingKey='skip_audit'
					label='Skip Audit'
					description='When this is enabled, the meeting will undergo processing and checks for content. Then instead of moving to audit it will move directly to published. If any checks fail (No transcript, no video, no summary, no moments, no topics), the meeting will be moved to audit instead.'
					successMessage='You have successfully updated the Skip Audit setting.'
					failureMessage='Sorry, there was an error changing the Skip Audit setting. Please try again.'
				/>

				<SettingSwitch
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					settingKey='sharing_clips_external'
					label='External Clip Sharing'
					description='Enable this setting to allow users to share their clips externally. This means anyone with a link can view a clip.'
					successMessage='Success! You have successfully updated the external clip sharing setting.'
					failureMessage='Sorry, there was an error updating the external clip sharing setting. Please try again.'
				/>

				<Title order={4}>Bot Customization</Title>
				<OrganizationBranding
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
				/>
				<Title order={4}>Custom Branding</Title>
				<CustomBranding
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					brand={brand}
					setBrand={setBrand}
				/>
			</Stack>
		</ScrollArea>
	);
}
