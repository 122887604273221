import { createStyles, ScrollArea, Text } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	container: {
		backgroundColor: theme.colors.gray[0],
		borderRadius: theme.radius.lg,
		height: '100%',
		overflow: 'hidden',
	},
	summary: {
		fontSize: '0.875rem',
		color: theme.colors.gray[9],
	},
}));

interface ClipSummaryProps {
	summary: string;
	height?: number;
}

export function ClipSummary({ summary, height }: ClipSummaryProps) {
	const { classes } = useStyles();

	return (
		<ScrollArea h={height || '100%'} type='auto' scrollbarSize={10} pt={'md'}>
			<Text className={classes.summary} span>
				{summary}
			</Text>
		</ScrollArea>
	);
}
