import React from 'react';
import { SegmentedControl, Group, Center, Box } from '@mantine/core';
import { useRecoilState } from 'recoil';
import { meetingsView } from '../../Atoms/meetingAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faCardsBlank } from '@fortawesome/pro-solid-svg-icons';
import { getEnvForGleap } from '../../_utils/trackers';

interface CardListViewToggleProps {
	viewType: string;
	setViewType: (value: string) => void;
}

const CardListViewToggle: React.FC<CardListViewToggleProps> = ({
	viewType,
	setViewType,
}) => {
	return (
		<Group
			position='center'
			id={`VIS_ctf9nfdtmsxfgg2vmccg_${getEnvForGleap()}`}
		>
			<SegmentedControl
				value={viewType}
				onChange={setViewType}
				data={[
					{
						value: 'card',
						label: (
							<Center>
								<FontAwesomeIcon icon={faCardsBlank} />
								<Box ml={10}>Card</Box>
							</Center>
						),
					},
					{
						value: 'list',
						label: (
							<Center>
								<FontAwesomeIcon icon={faListUl} />
								<Box ml={10}>List</Box>
							</Center>
						),
					},
				]}
			/>
		</Group>
	);
};

export default CardListViewToggle;
