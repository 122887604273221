import React, { useEffect, useState } from 'react';
import {
	Button,
	createStyles,
	Modal,
	Stack,
	Text,
	TextInput,
} from '@mantine/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	editScheduledMeetingInitialValues,
	editScheduledMeetingModalOpen,
	meetingsSharedWithMe as SharedMeetingAtom,
	myMeetings as MyMeetingAtom,
} from '../../../Atoms/meetingAtom';
import { useForm } from '@mantine/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard } from '@fortawesome/pro-solid-svg-icons';
import { DateInput, TimeInput } from '@mantine/dates';
import { logger } from '../../../helpers/logger';
import { handleFormatTime } from '../../../helpers/date';
import { updateBot, updateMeeting } from '../../../api/api';
import { IMeeting } from '../../../interfaces/meeting';
import { currentUser, currentUserMeetings } from '../../../Atoms/userAtoms';
import { DateTime } from 'luxon';
import CustomCalendarDay from '../../CustomCalendarDay';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';

const useStyles = createStyles(() => ({
	dateInput: {
		width: '200px',
	},
}));
export const EditScheduledMeetingModal = () => {
	const { classes, theme } = useStyles();
	const [opened, setOpened] = useRecoilState(editScheduledMeetingModalOpen);
	const editScheduledMeetingInitialFormValues = useRecoilValue(
		editScheduledMeetingInitialValues
	);
	const [allMeetings, setAllMeetings] = useRecoilState(currentUserMeetings);
	const user = useRecoilValue(currentUser);
	const [myMeetings, setMyMeetings] = useRecoilState(MyMeetingAtom);
	const [meetingsSharedWithMe, setMeetingsSharedWithMe] =
		useRecoilState(SharedMeetingAtom);

	const { name, meetingLink, joinAt, meetingID, botID } =
		editScheduledMeetingInitialFormValues;
	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues: {
			meetingUrl: meetingLink,
			meetingTitle: name,
			date: new Date(),
			time: DateTime.fromISO(joinAt).toFormat('HH:mm'),
		},
		validate: {
			meetingTitle: (value) =>
				value.length ? null : 'Meeting title is required.',
			meetingUrl: (value) =>
				value.length ? null : 'Meeting link is required.',
			date: (value) => (value instanceof Date ? null : 'Date is required.'),
			time: (value) => (value ? null : 'Time is required.'),
		},
	});

	useEffect(() => {
		form.setValues({
			meetingUrl: meetingLink,
			meetingTitle: name,
			date: DateTime.fromISO(joinAt).toJSDate() || null,
			time: DateTime.fromISO(joinAt).toFormat('HH:mm'),
		});
	}, [editScheduledMeetingInitialFormValues]);

	const updateRecallBot = async (values: any) => {
		logger('info', 'updateRecallBot fired', values);
		const { meetingUrl, meetingTitle } = values;
		try {
			setLoading(true);
			// build joinAt ISO String
			const joinAt = handleFormatTime(values.date, values.time);
			// updateRecallBot
			const response = await updateBot({
				botID,
				data: {
					// meetingLink: meetingUrl, comment out for now because its disabled, user not allowed to change meeting link
					joinAt,
				},
				organizationID: user.currentOrganizationID,
			});
			logger('info', 'Bot updated', response);

			// update meeting
			const updatedMeeting = await updateMeeting(
				{ name: meetingTitle },
				meetingID,
				user.currentOrganizationID
			);
			logger('info', 'Meeting updated', updatedMeeting);

			// combine data for new meeting.
			const newMeeting = {
				...updatedMeeting.data.data,
				botMetadata: response.data.data,
			};

			// update meetings lists
			setMyMeetings(
				myMeetings.map((meeting: IMeeting) =>
					meeting.id === newMeeting.id ? newMeeting : meeting
				)
			);
			setMeetingsSharedWithMe(
				meetingsSharedWithMe.map((meeting: IMeeting) =>
					meeting.id === newMeeting.id ? newMeeting : meeting
				)
			);
			setAllMeetings(
				allMeetings.map((meeting: IMeeting) =>
					meeting.id === newMeeting.id ? newMeeting : meeting
				)
			);

			// success message
			showSuccessNotification({
				message: 'Reelay meeting successfully updated.',
			});
			form.reset();
			setOpened(false);
		} catch (err) {
			logger('error', 'Error updating meeting', err);
			// error message
			showFailureNotification({
				message: `Reelay was not able to update your meeting details. Please try again.`,
			});
		} finally {
			// reset state
			setLoading(false);
		}
	};

	return (
		<Modal
			size={'xl'}
			opened={opened}
			onClose={() => setOpened(false)}
			title='Edit Scheduled Meeting'
		>
			<form onSubmit={form.onSubmit((values) => updateRecallBot(values))}>
				<Stack spacing={'xl'}>
					<TextInput
						label={'Meeting Title'}
						{...form.getInputProps('meetingTitle')}
						placeholder='Enter Meeting Title'
						description={'Optional'}
						inputWrapperOrder={['label', 'error', 'input', 'description']}
						style={{ flex: 2 }}
					/>
					<TextInput
						disabled
						label={'Meeting Link'}
						{...form.getInputProps('meetingUrl')}
						icon={<FontAwesomeIcon icon={faKeyboard} />}
						description={'Accepts Zoom, Google Meet, and Microsoft Teams.'}
						placeholder='Enter Meeting Link'
						inputWrapperOrder={['label', 'error', 'input', 'description']}
					/>
					<Stack spacing={'xs'}>
						<Text>Meeting Date</Text>
						<DateInput
							{...form.getInputProps('date')}
							defaultValue={null}
							placeholder='Date'
							className={classes.dateInput}
							valueFormat='MM/DD/YYYY'
							// value={viewingDeadlineDate}
							// onChange={(date: Date) => setViewingDeadlineDate(date)}
							// required
							// error="Meeting requires a deadline"
							description={'Date'}
							renderDay={(date) => <CustomCalendarDay date={date} />}
						/>
						<TimeInput
							{...form.getInputProps('time')}
							// required
							// error="Meeting requires a deadline"
							// value={viewingDeadlineTime}
							// onChange={(date: Date) => setViewingDeadlineTime(date)}
							// defaultValue={new Date()}
							className={classes.dateInput}
							description={'Time'}
						/>
					</Stack>
					<Button type={'submit'} loading={loading}>
						Save
					</Button>
				</Stack>
			</form>
		</Modal>
	);
};
