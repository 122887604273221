import { Stack, Group, createStyles, Indicator } from '@mantine/core';
import CardListViewToggle from '../CardListViewToggle';
import { MeetingSearch } from '../MeetingSearch';
import BulkActionRow from '../BulkActionRow/BulkActionRow';
import { useRecoilState, useRecoilValue } from 'recoil';
import { meetingsView } from '../../Atoms/meetingAtom';
import { bulkDeletingCollectionMeetingsSelection } from '../../Atoms/collections';
import { DatePickerProps } from '@mantine/dates';
import { useRef } from 'react';
import DateRangePicker from '../DateRangePicker';
import MeetingsCardView from '../MeetingsCardView';

const useStyles = createStyles(() => ({
	topContainer: {
		flex: 1,
	},
}));

const statusOptions = [
	{ label: 'Live', value: 'live' }, //'published' },
	{ label: 'Upcoming', value: 'upcoming' }, //'scheduled,created' },
	{ label: 'Review', value: 'review' },
	{ label: 'Expired', value: 'expired' },
	{ label: 'All', value: 'all' },
];

export interface MeetingsHeaderProps {
	searchValue: string;
	handleSearch: (value: string) => void;
	opened: boolean;
	setOpened: (o: any) => void;
	bulkActionDeleteMeetings: () => void;
	handleBulkAddToCollection: (meetingIDs: string[]) => void;
	handleBulkArchive: (meetingIDs: string[]) => void;
	dateRange: [Date | null, Date | null];
	setDateRange: (dateRange: [Date | null, Date | null]) => void;
	segmentValue: string;
}

export default function MeetingsHeader({
	searchValue,
	handleSearch,
	opened,
	setOpened,
	bulkActionDeleteMeetings,
	handleBulkArchive,
	handleBulkAddToCollection,
	dateRange,
	setDateRange,
	segmentValue,
}: MeetingsHeaderProps) {
	const { classes } = useStyles();
	const selection = useRecoilValue(bulkDeletingCollectionMeetingsSelection);
	const [viewType, setViewType] = useRecoilState(meetingsView);

	return (
		<Stack className={classes.topContainer} spacing={0}>
			<Group position={'apart'} align={'center'} spacing={0} noWrap>
				<Group align={'center'} style={{ flex: 2 }}>
					<CardListViewToggle viewType={viewType} setViewType={setViewType} />

					<MeetingSearch
						searchValue={searchValue}
						handleSearch={handleSearch}
						viewType={viewType}
					/>
				</Group>
				{viewType === 'list' ? (
					<BulkActionRow
						bulkActionDelete={bulkActionDeleteMeetings}
						handleBulkArchive={handleBulkArchive}
						opened={opened}
						setOpened={setOpened}
						selection={selection}
						handleBulkAddToCollection={handleBulkAddToCollection}
						deleteConfirmationText='Are you sure you want to permanently delete these meetings?'
					/>
				) : null}

				<DateRangePicker
					dateRange={dateRange}
					setDateRange={setDateRange}
					segmentValue={segmentValue}
				/>
			</Group>
		</Stack>
	);
}
