import React, { useCallback, useEffect, useRef, useState } from 'react';
import './app.css';
import Layout from './components/Appshell/Appshell';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import OrganizationManagement from './pages/admin/organization_management';
import EditOrganization from './pages/admin/edit_organization';
import Actions from './pages/actions/Actions';
import Meeting from './pages/meeting';
import MeetingManagement from './pages/admin/meeting_management';
import { ProtectedRoute } from './components/ProtectedRoute';
import { ProtectedAdminRoute } from './components/ProtectedAdminRoute';
import { UnauthorizedAccess } from './components/UnauthorizedAccess';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isAuthenticated, originalURL } from './Atoms/auth';
import { PageNotFound } from './components/PageNotFound';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router';
import { AuditPage } from './pages/admin/AuditPage';
import {
	ActionIcon,
	Center,
	createStyles,
	Loader,
	MantineProvider,
	Overlay,
} from '@mantine/core';
import { creatingHighlightAtom, teamDataAtom, user } from './Atoms/userAtoms';
import { logger } from './helpers/logger';
import { SignupPage } from './pages/signup';
import { ServerError } from './components/ServerError';
import { isAdmin, isSuperadmin, isSupportUser } from './helpers/auth';
import { ErrorBoundary } from 'react-error-boundary';
import Settings from './pages/settings/Settings';
import ShareableMeetingLink from './pages/ShareableMeetingLink';
import {
	organizationBrand,
	organizationSettings,
	originalFaviconHREF,
	UserSettings,
	userSettings,
} from './Atoms/settings';
import { buildThemeWithBrandColors } from './helpers/settings';
import { getOrganizationBrand, getOrganizationSettings } from './api/settings';
import TermsOfUseModal from './components/Modals/TermsOfUseModal';
import DecideAccessRedirect from './pages/DecideAccessRedirect';
import { debounce, getPageTitle } from './_utils/handy-functions';
import { MobileHostView } from './pages/mobileHostView';
import Collections from './pages/collections';
import MeetingsPage from './pages/meetings';
import CollectionPage from './pages/collections/components/CollectionPage/CollectionPage';
import { useFlags } from 'flagsmith/react';
import { flagsmithFeatureFlags } from './components/constants';
import { createPusherInstance } from './helpers/pusher';
import {
	calendarStatusData,
	calendarSyncingData,
	integrations,
} from './Atoms/integrations';
import { useCollections } from './customHooks/useCollections';
import { getLiveMeetings } from './api/playPauseRecording';
import { liveMeetingsWithBots, upcomingMeetings } from './Atoms/recallAI';
import {
	getUserUpcomingMeetings,
	updateLiveMeetingsByBotEvent,
} from './helpers/recallai';
import {
	filestackUpload,
	getOrganization,
	getUserCalendars,
	getUserSettings,
} from './api/api';
import LiveBots from './pages/admin/LiveBots';
import { addMobileCheck } from './helpers/mobile';
import CalendarIntegrationModal from './components/Modals/CalendarIntegrationModal';
import { DateTime } from 'luxon';
import MinutesPage from './pages/minutes/MinutesPage';
import FilestackPicker from './components/FilestackPicker/FilestackPicker';
import {
	filestackModalOpenedAtom,
	filestackUploadingAtom,
	usingUploadMeetingButtonAtom,
} from './Atoms/filestack';
import {
	showFailureNotification,
	showSuccessNotification,
} from './helpers/notifications';
import { IMeeting } from './interfaces/meeting';
import { PickerFileMetadata, PickerResponse } from 'filestack-js';
import { reloadMeetingsAtom } from './Atoms/meetingAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-regular-svg-icons';
import Gleap from 'gleap';
import MassResyncPage from './pages/massresync';
import { modals } from '@mantine/modals';
import ModalTitle from './components/Titles/ModalTitle';
import NewUsersWithOrg from './pages/onboarding/NewUserWithOrg';
import NewUsersWithoutOrg from './pages/onboarding/NewUsersWithoutOrg';
import TutorialModal from './components/Modals/TutorialModal';
import Security from './pages/settings/tabs/Security';
import Teams from './pages/teams';
import Billing from './pages/settings/tabs/Billing/Billing';
import Integrations from './pages/integrations';
import MyAccount from './pages/settings/tabs/MyAccount';
import Members from './pages/settings/tabs/Members/Members';
import { UserRoles } from './interfaces/user';
import LoginPage from './pages/loginpage/page';
import { ReelayLoader } from './components/ReelayLoader';
import { fetchTestimonials } from './api/contentful';
import { testimonialsAtom } from './Atoms/testimonialsAtom';
import { destructureSettingsResponse } from './helpers/settings/settings';
import { ClipPage } from './pages/ClipPage/ClipPage';
import ClipsPage from './pages/ClipsPage/ClipsPage';
import { useGetTeams } from './customHooks/collections/useGetTeams';

const useStyles = createStyles(() => ({
	filestackCloseButton: {
		position: 'absolute',
		top: 10,
		right: 10,
		backgroundColor: 'transparent',
		border: 'none',
		cursor: 'pointer',
		fontSize: 20,
		zIndex: 21474836492343,
		color: 'black',
	},
}));

function App() {
	const { classes, theme } = useStyles();
	const location = useLocation();
	const setOriginalURL = useSetRecoilState(originalURL);
	const currentUser = useRecoilValue(user);
	const [userSettingsData, setUserSettings] = useRecoilState(userSettings);
	const [userSettingsRetrieved, setUserSettingsRetrieved] = useState(false);
	const [brand, setBrand] = useRecoilState(organizationBrand);
	const setOrganizationSettings = useSetRecoilState(organizationSettings);
	const [originalFaviconPath, setOriginalFaviconPath] =
		useRecoilState(originalFaviconHREF);
	const creatingHighlight = useRecoilValue(creatingHighlightAtom);
	const authenticated = useRecoilValue(isAuthenticated);
	const [liveMeetings, setLiveMeetings] = useRecoilState(liveMeetingsWithBots);
	const navigate = useNavigate();
	const [opened, { open, close }] = useDisclosure(false);
	const [
		calendarModalOpened,
		{ open: openCalendarModal, close: closeCalendarModal },
	] = useDisclosure(false);
	const { meetingCollections, actionItems, settingsSecurity } =
		flagsmithFeatureFlags;
	const flags = useFlags([meetingCollections, actionItems, settingsSecurity]);
	const [flagsLoaded, setFlagsLoaded] = useState(false);
	const collectionsEnabled = flags?.meeting_collections?.enabled;
	const actionItemsEnabled = flags?.action_items?.enabled;
	const [calendarSyncing, setCalendarSyncing] =
		useRecoilState(calendarSyncingData);
	const [calendarStatus, setCalendarStatus] =
		useRecoilState(calendarStatusData);
	const setUpcomingMeetings = useSetRecoilState(upcomingMeetings);
	const setCalendarIntegrations = useSetRecoilState(integrations);
	const [filestackModalOpened, setFilestackModalOpened] = useRecoilState(
		filestackModalOpenedAtom
	);
	const [usingUploadMeetingButton, setUsingUploadMeetingButton] =
		useRecoilState(usingUploadMeetingButtonAtom);
	const [filestackUploading, setFilestackUploading] = useRecoilState(
		filestackUploadingAtom
	);
	const [reloadMeetings, setReloadMeetings] =
		useRecoilState(reloadMeetingsAtom);
	const hasShownModalRef = useRef(false); // Add a ref to track if the modal has been shown
	const queryParams = new URLSearchParams(location.search);
	const guestViewToken = queryParams.get('vt');
	const isOnboarding =
		location.pathname.includes('register') ||
		location.pathname.includes('accept-invitation');
	const openTutorial =
		queryParams.get('openTutorial') === 'true' ? true : false;
	const [tutorialModalOpened, setTutorialModalOpened] = useState(false);
	const setTestimonials = useSetRecoilState(testimonialsAtom);
	const isSuperAdmin = isSuperadmin(currentUser.roles);
	const securityEnabled = flags?.settings_security?.enabled;
	const auditSide = location.pathname.includes('/admin');
	useGetTeams(currentUser?.currentOrganizationID);

	useEffect(() => {
		const checkFlags = async () => {
			try {
				if (Object.keys(flags).length > 0) {
					setFlagsLoaded(true);
				}
			} catch (error) {
				console.error('Error loading flags:', error);
				setFlagsLoaded(true);
			}
		};
		checkFlags();
	}, [collectionsEnabled, actionItemsEnabled]);

	useEffect(() => {
		if (authenticated && location.pathname === '/') {
			if (isSupportUser(currentUser.roles)) navigate('/admin/meetings');
			else navigate('/meetings');
		} else if (
			!authenticated &&
			location.pathname !== '/login' &&
			!location.pathname.includes('register') &&
			!location.pathname.includes('signup') &&
			!location.pathname.includes('accept-invitation') &&
			!guestViewToken
		) {
			console.error('Navigating to login...');
			navigate('/login');
		}

		// if pathname is /share/ then add a flag createAccount=true to the URL.
		const createAccountParam =
			location.pathname === '/share/' ? '&createAccount=true' : '';
		setOriginalURL(location.pathname + location.search + createAccountParam);

		logger('warn', 'App component rendered via logger helper function', {
			name: 'app-component-rendered',
			component: 'App',
		});

		// setup pusher instance on window obj and subscribe to channel
		createPusherInstance();
		// add mobile check to window obj
		addMobileCheck();

		const channelName = `${
			currentUser?.id
				? `${currentUser.id}-${currentUser.currentOrganizationID}`
				: currentUser?.currentOrganizationID
		}`;

		const channel = window?.pusher?.subscribe(channelName);

		const debouncedUpdateMeetings = debounce(async () => {
			const upcomingMeetings = await getUserUpcomingMeetings(
				currentUser.currentOrganizationID
			);
			setUpcomingMeetings(upcomingMeetings);
			setReloadMeetings((prev) => !prev);
		}, 3000); // Adjust the delay as needed

		channel.bind('calendar:syncing', async (data) => {
			try {
				setCalendarSyncing((prev) => ({
					...prev,
					[data?.calendarID]: data?.isSyncing,
				}));
				if (!data?.isSyncing) {
					debouncedUpdateMeetings();
				}
			} catch (error) {
				console.error('calendar:syncing error', error);
				setCalendarSyncing((prev) => ({
					...prev,
					[data?.calendarID]: data?.isSyncing,
				}));
				if (!data?.isSyncing) {
					debouncedUpdateMeetings();
				}
				showFailureNotification({
					message:
						'Sorry, there was a syncing error. Please refresh the page for updates.',
				});
			}
		});

		channel.bind('calendar:status', async (data) => {
			try {
				setCalendarStatus((prev) => ({
					...prev,
					[data?.calendarID]: data?.status,
				}));
				if (data?.status === 'connected') {
					const upcomingMeetings = await getUserUpcomingMeetings(
						currentUser.currentOrganizationID
					);
					setUpcomingMeetings(upcomingMeetings);
				}
			} catch (error) {
				console.error('calendar:status error', error);
				setCalendarStatus((prev) => ({
					...prev,
					[data?.calendarID]: data?.status,
				}));
				if (data?.status === 'connected') {
					const upcomingMeetings = await getUserUpcomingMeetings(
						currentUser.currentOrganizationID
					);
					setUpcomingMeetings(upcomingMeetings);
				}
			}
		});

		// channel.bind('meeting:paused', (data) => {
		//
		// 	//
		// });
		// channel.bind('meeting:resumed', (data) => {
		//
		// });

		const debouncedMeetingEventHandler = debounce(async (data) => {
			try {
				const meeting = data?.meeting;
				const newLiveMeetings = updateLiveMeetingsByBotEvent(
					liveMeetings,
					meeting
				);
				setLiveMeetings(newLiveMeetings);
				const upcomingMeetings = await getUserUpcomingMeetings(
					currentUser.currentOrganizationID
				);
				setUpcomingMeetings(upcomingMeetings);
			} catch (error) {
				console.error('meeting:event error', error);
				const meeting = data?.meeting;
				const newLiveMeetings = updateLiveMeetingsByBotEvent(
					liveMeetings,
					meeting
				);
				setLiveMeetings(newLiveMeetings);
				const upcomingMeetings = await getUserUpcomingMeetings(
					currentUser.currentOrganizationID
				);
				setUpcomingMeetings(upcomingMeetings);
				// Since the logic in catch is the same as in try, consider handling the error differently or removing the redundancy.
			}
		}, 1000);

		channel.bind('meeting:event', debouncedMeetingEventHandler);

		if (isSupportUser(currentUser.roles)) {
			const supportChannelName = `reelay-support-users`;
			const supportChannel = window?.pusher?.subscribe(supportChannelName);

			supportChannel.bind('meeting:full:asset', async (data) => {
				showSuccessNotification({
					title: `Meeting ${data?.meeting?.name} has been successfully converted to a full asset!`,
					message: `You can now view the full asset in the meeting details page. If you are already viewing the meeting, please refresh the page to see the changes.`,
				});
			});

			supportChannel.bind('calendar-sync-logs', async (data) => {
				console.log('calendar-sync-logs', data);
			});
		}
	}, [currentUser]);

	useEffect(() => {
		const refetchUpcomingAndLiveMeetings = async () => {
			try {
				const [res, upcomingMeetings] = await Promise.all([
					getLiveMeetings(currentUser.currentOrganizationID),
					getUserUpcomingMeetings(currentUser.currentOrganizationID),
				]);
				setLiveMeetings(res?.data || []);
				setUpcomingMeetings(upcomingMeetings);
			} catch (error) {
				logger('error', 'Error refetching upcoming and live meetings', error);
				const [res, upcomingMeetings] = await Promise.all([
					getLiveMeetings(currentUser.currentOrganizationID),
					getUserUpcomingMeetings(currentUser.currentOrganizationID),
				]);
				setLiveMeetings(res || []);
				setUpcomingMeetings(upcomingMeetings);
			}
		};
		if (
			authenticated &&
			!isOnboarding &&
			!auditSide &&
			currentUser.currentOrganizationID
		)
			refetchUpcomingAndLiveMeetings();
	}, [reloadMeetings, authenticated, currentUser]);

	useEffect(() => {
		const favicon = document.getElementById('favicon-logo') as HTMLLinkElement;
		const title = document.getElementById('title') as HTMLTitleElement;
		if (typeof brand === 'object' && Object.keys(brand).length) {
			if (!originalFaviconPath.length) setOriginalFaviconPath(favicon.href);
			if (brand?.name)
				title.text = `${getPageTitle(location.pathname)} | ${brand?.name}`;
			if (brand?.icon) favicon.href = brand?.icon;
		} else {
			title.text = `${getPageTitle(location.pathname)} | Reelay`;
			favicon.href = `${window.location.origin}/favicon.ico`;
		}
	}, [brand, location]);

	const colorsWithBrandedColors = buildThemeWithBrandColors(
		theme.colors,
		brand
	);

	useEffect(() => {
		const fetchUserData = async () => {
			if (currentUser?.currentOrganizationID && !isOnboarding && !auditSide) {
				try {
					// Fire all promises simultaneously
					const [brandRes, settingsRes, userSettingsRes] = await Promise.all([
						getOrganizationBrand(currentUser.currentOrganizationID),
						getOrganizationSettings(currentUser.currentOrganizationID),
						getUserSettings(currentUser.id),
					]);

					// Set brand if the response is valid
					if (brandRes) setBrand(brandRes?.value);

					// Process organization settings and set state
					const organizationSettings = settingsRes.reduce((acc, curr) => {
						if (curr.key !== 'brand') acc[curr.key] = curr.value;
						return acc;
					}, {});
					setOrganizationSettings(organizationSettings);

					// Set user settings if the response is valid
					if (Array.isArray(userSettingsRes)) {
						const data: UserSettings =
							destructureSettingsResponse(userSettingsRes);
						// save to state
						setUserSettings(data);
						setUserSettingsRetrieved(true);
					}
				} catch (err) {
					logger('error', 'Error fetching organization data', err);
				}
			}
		};

		fetchUserData();
	}, [currentUser]);

	useEffect(() => {
		const loadOrganizationSettingsAndCollections = async () => {
			if (currentUser?.currentOrganizationID) {
				getOrganizationBrand(currentUser.currentOrganizationID)
					.then((res) => {
						if (res) setBrand(res?.value);
					})
					.catch((err) => {
						logger('error', 'Error getting organization brand', err);
					});
			}
		};
		if (!isOnboarding) loadOrganizationSettingsAndCollections();
	}, [currentUser, userSettingsData]);

	useEffect(() => {
		const loadCalendarModal = async () => {
			const createMeetingRole = currentUser?.roles?.includes(
				UserRoles.CreateMeeting
			);
			if (
				!authenticated ||
				isOnboarding ||
				(openTutorial && !createMeetingRole) ||
				auditSide ||
				!userSettingsRetrieved
			)
				return;

			// only check if the userSettingsData is valid and have been retrieved first.
			if (
				(!userSettingsData?.agreed_with_terms ||
					!userSettingsData?.time_zone) &&
				authenticated
			) {
				open();
				return;
			}

			const res = await getUserCalendars(currentUser.currentOrganizationID);
			const calendars = res.data.data; // Using the existing calendars variable
			// Call the function to show the calendar disconnected modal

			showCalendarDisconnectedModal(calendars);
			setCalendarIntegrations(calendars);
			const last_asked_calendar_sync = localStorage.getItem(
				'last_asked_calendar_sync'
			);

			if (
				calendars.length ||
				!currentUser?.roles?.includes(UserRoles.CreateMeeting) ||
				opened
			) {
				// clear local storage
				localStorage.removeItem('last_asked_calendar_sync');
				// return. No need to open calendar modal
				return;
			}

			const HOUR_LIMIT = 12; // Original time limit
			let askedCalendarSyncOverIntervalAgo = false;

			if (last_asked_calendar_sync) {
				const lastAskedTime = DateTime.fromISO(last_asked_calendar_sync);
				const now = DateTime.now();
				const diff = now.diff(lastAskedTime, 'hours').hours; // Original difference check for hours
				const timeExceeded = diff > HOUR_LIMIT;
				askedCalendarSyncOverIntervalAgo = timeExceeded;
				if (timeExceeded) {
					localStorage.setItem(
						'last_asked_calendar_sync',
						new Date().toISOString()
					);
				}
			} else {
				// if there is no
				localStorage.setItem(
					'last_asked_calendar_sync',
					new Date().toISOString()
				);
			}

			const shouldOpenCalendarModal =
				!calendars.length &&
				currentUser?.roles?.includes(UserRoles.CreateMeeting) &&
				!userSettingsData?.calendar_modal_dont_ask_again &&
				(!last_asked_calendar_sync || askedCalendarSyncOverIntervalAgo) &&
				userSettingsData?.agreed_with_terms &&
				Boolean(userSettingsData?.time_zone);

			if (shouldOpenCalendarModal) {
				openCalendarModal();
			}
		};

		// Function to show the calendar disconnected modal
		const showCalendarDisconnectedModal = (calendars) => {
			if (hasShownModalRef.current) return; // Prevent showing the modal more than once

			const disconnectedCalendar = calendars.find(
				(integration) =>
					integration.status === 'disconnected' &&
					!calendars.some(
						(other) =>
							other.platform === integration.platform &&
							other.status === 'connected'
					)
			);

			if (disconnectedCalendar) {
				modals.openConfirmModal({
					title: <ModalTitle text='Reconnect Calendar' />,
					children: (
						<p
							style={{
								marginBottom: '48px',
							}}
						>
							Your calendar has been disconnected and needs to be reconnected
							for Reelay to function properly. Please go to the Calendars page
							to reconnect your calendar and ensure Reelay can join your
							meetings as expected.
						</p>
					),
					labels: { confirm: 'Reconnect Calendar', cancel: 'Cancel' },
					onConfirm: () => {
						navigate('/settings/integrations');
					},
					closeOnConfirm: true,
					styles: {
						content: {
							padding: 24,
							paddingTop: 0,
							paddingBottom: '1rem',
							borderRadius: '16px',
						},
						header: {
							padding: '1rem',
						},
						body: {
							paddingBottom: 0,
						},
					},
				});

				hasShownModalRef.current = true; // Set the flag to true after showing the modal
			}
		};

		if (!isOnboarding) loadCalendarModal();
	}, [userSettingsData, userSettingsRetrieved]);

	useEffect(() => {
		if (openTutorial) {
			Gleap.startProductTour('6719902e040d0322aaeca44f');
		}
	}, [openTutorial]);

	const handleClose = () => {
		setTutorialModalOpened(false);
	};

	const handleConfirm = async () => {
		setTutorialModalOpened(false);
	};

	const handleUploadStarted = useCallback((file: PickerFileMetadata) => {
		showSuccessNotification({
			title: 'Uploading files...',
			message:
				'Please wait while we upload your files. These may take a few minutes.',
		});
		setFilestackUploading(true);
	}, []);

	const handleUploadDone = useCallback(
		async ({ filesUploaded, filesFailed }: PickerResponse) => {
			try {
				if (filesUploaded.length) {
					for (const file of filesUploaded) {
						const response = await filestackUpload({
							organizationID: currentUser.currentOrganizationID,
							platform: 'uploaded',
							recapOnly: !usingUploadMeetingButton,
							name: file.filename,
							filestackData: {
								uploadID: file.uploadId,
								url: file.url,
							},
							fullAsset: usingUploadMeetingButton,
						});
						const newMeeting: IMeeting = response?.data?.data;
						setReloadMeetings((prev) => !prev);
						showSuccessNotification({
							title: 'Upload successful!',
							message: usingUploadMeetingButton
								? `Your meeting ${newMeeting.name} has been uploaded successfully. You will be able to view it once processing is complete.`
								: `Your minutes ${newMeeting.name} has been uploaded successfully. You will be able to download it once processing is complete.`,
						});
					}
				}
				if (filesFailed.length) {
					showFailureNotification({
						message: 'Failed to upload files',
					});
				}
				if (usingUploadMeetingButton) setUsingUploadMeetingButton(false);
			} catch (error) {
				console.error('handleUploadDone error', error);
			} finally {
				setFilestackUploading(false);
			}
		},
		[currentUser.currentOrganizationID, usingUploadMeetingButton]
	);

	const handleCloseFilestackModal = () => {
		setFilestackModalOpened(false);
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const shouldOpenGleap =
			urlParams.get('openSupport') === 'true' ||
			urlParams.get('opensupport') === 'true';
		if (shouldOpenGleap) {
			Gleap.openHelpCenter();
		}
	}, []);

	useEffect(() => {
		const loadTestimonial = async () => {
			const testimonials = await fetchTestimonials();
			if (testimonials.length > 0) {
				setTestimonials(testimonials);
			}
		};

		const currentPath = location.pathname;
		if (
			currentPath === '/login' ||
			currentPath === '/register' ||
			currentPath === '/accept-invitation'
		) {
			loadTestimonial();
		}
	}, []);

	if (!flagsLoaded) {
		return (
			<Center style={{ height: '100vh' }}>
				<ReelayLoader />
			</Center>
		);
	}

	return (
		<ErrorBoundary
			FallbackComponent={ServerError}
			onReset={() =>
				navigate(isAdmin(currentUser.roles) ? '/admin/meetings' : '/meetings')
			}
		>
			<MantineProvider
				theme={{
					...colorsWithBrandedColors,
				}}
				inherit
			>
				<div className='app'>
					{/* Modals  */}
					<TermsOfUseModal
						opened={opened}
						close={close}
						// openCalendarModal={openCalendarModal}
					/>
					<CalendarIntegrationModal
						opened={calendarModalOpened}
						close={closeCalendarModal}
					/>
					{filestackModalOpened ? (
						<Overlay color='#000' opacity={0.75} />
					) : null}
					{filestackModalOpened ? (
						<div className='filestack-picker-wrapper'>
							<ActionIcon
								variant='transparent'
								className={classes.filestackCloseButton}
								onClick={handleCloseFilestackModal}
							>
								<FontAwesomeIcon
									icon={faX}
									size={'xs'}
									color='rgb(68, 68, 68)'
								/>
							</ActionIcon>
							<FilestackPicker
								handleUploadDone={handleUploadDone}
								handleUploadStarted={handleUploadStarted}
							/>
						</div>
					) : null}
					<TutorialModal
						opened={tutorialModalOpened}
						onClose={handleClose}
						onConfirm={handleConfirm}
					/>
					<Routes>
						<Route path='/login' element={<LoginPage />}>
							<Route path=':token' element={<LoginPage />} />
						</Route>
						<Route path='/register' element={<NewUsersWithoutOrg />} />
						<Route path='/accept-invitation' element={<NewUsersWithOrg />} />
						<Route
							path=''
							element={
								<ProtectedRoute>
									<Layout />
								</ProtectedRoute>
							}
						>
							<Route path='admin'>
								<Route path={'meetings'}>
									<Route index element={<Navigate replace to='audit' />} />
									<Route
										path='audit'
										element={
											<ProtectedAdminRoute>
												<MeetingManagement segmentValue={'audit'} />
											</ProtectedAdminRoute>
										}
									/>
									<Route
										path='draft'
										element={
											<ProtectedAdminRoute>
												<MeetingManagement segmentValue={'draft'} />
											</ProtectedAdminRoute>
										}
									/>
									<Route
										path='review'
										element={
											<ProtectedAdminRoute>
												<MeetingManagement segmentValue={'review'} />
											</ProtectedAdminRoute>
										}
									/>
									<Route
										path='scheduled'
										element={
											<ProtectedAdminRoute>
												<MeetingManagement segmentValue={'scheduled'} />
											</ProtectedAdminRoute>
										}
									/>
									<Route
										path='all'
										element={
											<ProtectedAdminRoute>
												<MeetingManagement segmentValue={'all'} />
											</ProtectedAdminRoute>
										}
									/>
								</Route>
								<Route path='edit/:meetingID' element={<AuditPage />} />
								<Route path='organizations'>
									<Route index element={<OrganizationManagement />} />
									<Route path='edit/:organizationID'>
										<Route index element={<Navigate replace to='audit' />} />
										<Route
											path='audit'
											element={
												<ProtectedAdminRoute>
													<EditOrganization segmentValue={'audit'} />
												</ProtectedAdminRoute>
											}
										/>
										<Route
											path='draft'
											element={
												<ProtectedAdminRoute>
													<EditOrganization segmentValue={'draft'} />
												</ProtectedAdminRoute>
											}
										/>
										<Route
											path='review'
											element={
												<ProtectedAdminRoute>
													<EditOrganization segmentValue={'review'} />
												</ProtectedAdminRoute>
											}
										/>
										<Route
											path='scheduled'
											element={
												<ProtectedAdminRoute>
													<EditOrganization segmentValue={'scheduled'} />
												</ProtectedAdminRoute>
											}
										/>
										<Route
											path='all'
											element={
												<ProtectedAdminRoute>
													<EditOrganization segmentValue={'all'} />
												</ProtectedAdminRoute>
											}
										/>
									</Route>
								</Route>
								<Route path='live'>
									<Route index element={<LiveBots />} />
								</Route>
								<Route path={'secret-resync'} element={<MassResyncPage />} />
							</Route>
							{/* client side */}
							<Route path='meetings'>
								<Route index element={<MeetingsPage segmentValue='live' />} />
								<Route
									path='recordings'
									element={<MeetingsPage segmentValue='live' />}
								/>
								<Route
									path='schedule'
									element={<MeetingsPage segmentValue='upcoming' />}
								/>
								<Route
									path='minutes'
									element={
										<MinutesPage
											buttonLoading={filestackUploading}
											setButtonLoading={setFilestackUploading}
										/>
									}
								/>
								<Route
									path='clips'
									element={<ClipsPage segmentValue='clips' />}
								/>
								<Route path=':meetingID' element={<Meeting />} />
								<Route path=':meetingID/:tabValue' element={<Meeting />} />
							</Route>
							<Route path='clips/:clipID' element={<ClipPage />} />
							<Route path='users/:userID' element={<MobileHostView />} />
							{actionItemsEnabled && (
								<Route path='actions'>
									<Route index element={<Actions segmentValue='todo' />} />
									<Route
										path='todo'
										index
										element={<Actions segmentValue='todo' />}
									/>
									<Route
										path='archive'
										element={<Actions segmentValue='archive' />}
									/>
								</Route>
							)}
							<Route path='/settings' element={<Settings />}>
								<Route path='account' element={<MyAccount />} />
								{securityEnabled && isSuperAdmin && (
									<Route path='security' element={<Security />} />
								)}

								<Route path='teams' element={<Teams />} />
								<Route path='members' element={<Members />} />
								{isSuperAdmin && <Route path='billing' element={<Billing />} />}
								<Route path='integrations' element={<Integrations />} />
							</Route>
							<Route path='/collections'>
								<Route index element={<Collections />} />
								<Route path='shared' element={<Collections />} />
								<Route path='archive' element={<CollectionPage />} />
								<Route path=':collectionID' element={<CollectionPage />} />
								<Route path='view/:meetingID' element={<Meeting />} />
								<Route path=':meetingID/view' element={<Meeting />} />
							</Route>
							<Route path='/settings' element={<Settings />} />
							<Route path='/settings/:tabValue' element={<Settings />} />
							{/* <Route path='/account' element={<AccountSettings />} /> */}
							<Route path='/unauthorized' element={<UnauthorizedAccess />} />
							<Route path='/share' element={<ShareableMeetingLink />} />
							<Route
								path='/access-request'
								element={<DecideAccessRedirect />}
							/>
							<Route path='*' element={<PageNotFound />} />
						</Route>
					</Routes>
				</div>
			</MantineProvider>
		</ErrorBoundary>
	);
}

export default App;
