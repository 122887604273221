import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { isAdmin, isProjectManager } from '../helpers/auth';
import { IMeeting } from '../interfaces/meeting';
import { Team } from '../helpers/data';
import { Organization } from './organizationAtom';

const { persistAtom } = recoilPersist();

// @TODO: Create a new File in ./Atoms called Auth.ts for us to store all auth related Atoms including the ones below and some additional ones like the JWT Tokens.

export interface User {
	createdAt?: string;
	dob?: string;
	email?: string;
	firstName?: string;
	currentOrganizationID?: string;
	id?: string;
	lastName?: string;
	phone?: string;
	roles?: string[];
	tags?: [];
	updatedAt?: string;
	userAvatarURL?: string;
	avatarImageURL?: string;
	avatarSizes?: number[];
	urls?: [string, string, string];
	agreed_with_terms?: boolean;
	skipAudit?: boolean | null;
	emailAliases?: string[];
	hasSeat?: boolean;
	seat?: ISeat;
}

export type ISeat = {
	id: string;
	userID: string;
	subscriptionID: string;
	status: 'active' | 'inactive';
	createdAt?: string;
	updatedAt?: string | null;
};

export interface DeletingUser extends User {
	organizations: Organization[];
}

export interface UserStats {
	name: string;
	countByStatus: { [status: string]: number };
	calendarPlatforms: string[];
}

export interface DeletingUser extends User {
	organizations: Organization[];
}

export interface IAttachment {
	attachmentURL: string;
	createdAt: string;
	errorMessage: string | null;
	fileName: string;
	id: string;
	meetingID: string;
	mimeType: string;
	owningUserID: string;
	status: string;
	updatedAt: string;
	type: string | null;
}

export interface TranscriptResponse {
	matches: any;
	meeting: IMeeting;
	transcription: Transcription;
}

export interface Transcription {
	id: string;
	meetingID: string;
	transcriptionText: string;
}

export const defaultUser = {
	avatarSizes: [],
	createdAt: '',
	dob: null,
	email: '',
	firstName: '',
	currentOrganizationID: '',
	id: '',
	lastName: '',
	phone: null,
	roles: [''],
	tags: [],
	updatedAt: '',
	userAvatarURL: '',
	urls: ['', '', ''],
	agreed_with_terms: false,
};

export const user = atom({
	key: 'user',
	default: defaultUser,
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const userOrganization = atom({
	key: 'userOrganization',
	default: { id: '', createdAt: '', name: '', updatedAt: '' },
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const userOrganizationMembers = atom({
	key: 'userOrganizationMembers',
	default: [],
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const userOrganizationMembersMap = selector({
	key: 'userOrganizationMembersMap',
	get: ({ get }) => {
		const members = get(userOrganizationMembers);
		const membersMap = new Map();
		members.forEach((member: User) => {
			if (member.id) {
				membersMap.set(member.id, member);
			}
		});
		return membersMap;
	},
});

export const teamsAtom = atom({
	key: 'teamsAtom',
	default: [],
});

export const teamDataAtom = selector({
	key: 'teamDataAtom',
	get: ({ get }) => {
		const teams = get(teamsAtom);
		const members = get(userOrganizationMembers);
		const formattedMembers: any[] = [];
		const users = members.map((user: User) => ({
			...user,
			label: user.email,
			value: user.email,
			key: user.id,
		}));

		formattedMembers.push(...users);
		teams.forEach((team: any, index: number) => {
			const tempUserArr: any[] = [];
			for (const user of team.users as User[]) {
				tempUserArr.push(user.id);
			}
			formattedMembers.push({
				value: JSON.stringify(tempUserArr),
				label: team.name,
				group: 'Teams',
				id: team.id,
				key: team.id,
			});
		});
		return formattedMembers;
	},
});

export const currentUserMeetings = atom<IMeeting[]>({
	key: 'currentUserMeetings',
	default: [],
});

export const currentUserInvitedReelays = atom({
	key: 'currentUserInvitedReelays',
	default: [],
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const currentUserTeams = atom({
	key: 'userTeams',
	default: { teams: [] },
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const currentUserFormattedTeams = atom({
	key: 'formattedTeams',
	default: [] as Team[],
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

// @TODO: Ensure the below selector only returns back a single item from the userOrganizations array
export const currentUser = selector({
	key: 'currentUser',
	get: ({ get }) => {
		return get(user);
	},
});

// @TODO: Ensure the below selector only returns back a single item from the userOrganizations array
export const currentOrganizationID = selector({
	key: 'currentOrganizationID',
	get: ({ get }) => {
		return get(user).currentOrganizationID;
	},
});

export const currentUserID = selector({
	key: 'currentUserID',
	get: ({ get }) => {
		const userAtom = get(user);
		return userAtom.id;
	},
});

export const currentUserRoles = selector({
	key: 'currentUserRoles',
	get: ({ get }) => {
		const userAtom = get(user);
		return userAtom.roles;
	},
});

export const hasAdminAccess = selector({
	key: 'hasAdminAccess',
	get: ({ get }) => {
		const userAtom = get(user);
		return isAdmin(userAtom.roles);
	},
});

export const activeLink = atom({
	key: 'activeLink',
	default: '',
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const editingHighlightAtom = atom({
	key: 'editingHighlightAtom',
	default: false,
});

export const creatingHighlightAtom = atom({
	key: 'creatingHighlightAtom',
	default: false,
});

export const editingHighlightInitialValuesAtom = atom({
	key: 'editingHighlightInitialValues',
	default: {
		content: '',
		type: '',
		visibility: '',
		timeStartMS: 0,
		isTopQuestion: null,
	},
});

export const editingHighlightID = atom({
	key: 'editingHighlightID',
	default: '',
});

export const currentHost = atom({
	key: 'currentHost',
	default: {} as User,
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const isProjectManagerAtom = selector({
	key: 'isProjectManagerAtom',
	get: ({ get }) => {
		const userAtom = get(user);
		return isProjectManager(userAtom.roles);
	},
});
