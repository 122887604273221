import type React from 'react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
	Modal,
	Group,
	Stack,
	Text,
	Button,
	Tooltip,
	Textarea,
	Paper,
	TextInput,
	MultiSelect,
	Input,
	ActionIcon,
	RangeSlider,
	Box,
	Divider,
	createStyles,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faRotateRight,
	faFolders,
	faClock,
	faPipe,
	faScissors,
} from '@fortawesome/pro-regular-svg-icons';
import { useForm } from '@mantine/form';
import ReelayVideoPlayer from '../../../ReelayVideoPlayer';
import ModalTitle from '../../../../../../components/Titles/ModalTitle';
import { buildVideoClipsPlaybackUrl } from '../../../../../../helpers/clips';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	collectionsAtom,
	collectionsHashMapAtom,
} from '../../../../../../Atoms/collections';
import { selectedClipAtom, VideoClip } from '../../../../../../Atoms/clips';
import { currentUser } from '../../../../../../Atoms/userAtoms';
import { useClips } from '../../../../../../customHooks/clips/useClips';
import { IMaskInput } from 'react-imask';
import { MILLISECONDS_PER_SECOND } from '../../../../../../components/constants';
import {
	formatSecondsToTime,
	parseTimeToSeconds,
} from '../../../../../../_utils/time';

const useStyles = createStyles((theme) => ({
	preview: {
		width: '100%',
		aspectRatio: '16/9',
		backgroundColor: theme.colors.dark[7],
		borderRadius: theme.radius.md,
		overflow: 'hidden',
	},
	createButton: {
		height: 40,
		borderRadius: theme.radius.md,
		padding: '0 24px',
		backgroundColor: theme.fn.primaryColor(),
		color: theme.white,
		fontWeight: 500,
		'&:hover': {
			backgroundColor: theme.fn.primaryColor(),
		},
	},
	divider: {
		borderTopColor: theme.colors['background-gray-2'][3],
		margin: `${theme.spacing.md}px 0`,
	},
}));

const EditTimeInputSection = ({
	value,
	onChange,
	error,
	onSetCurrentTime,
	duration,
}: {
	value: string;
	onChange: (value: string) => void;
	error: string;
	onSetCurrentTime: () => void;
	duration: number;
}) => {
	const durationIsOneHour = duration >= 3600;
	return (
		<Input.Wrapper w={durationIsOneHour ? 150 : 90}>
			<Input
				component={IMaskInput}
				mask={durationIsOneHour ? '00:00:00' : '00:00'}
				placeholder={durationIsOneHour ? 'HH:MM:SS' : 'MM:SS'}
				value={value}
				onChange={(e) => onChange(e.currentTarget.value)}
				error={error}
				rightSection={
					<Tooltip label='Set to current video time'>
						<ActionIcon
							onClick={onSetCurrentTime}
							variant='transparent'
							style={{ cursor: 'pointer' }}
							size='md'
						>
							<FontAwesomeIcon icon={faClock} />
						</ActionIcon>
					</Tooltip>
				}
			/>
		</Input.Wrapper>
	);
};

interface EditClipModalProps {
	opened: boolean;
	onClose: () => void;
	selectedClip: VideoClip;
	currentMeetingData: any;
	videoRef: React.RefObject<HTMLVideoElement>;
	setReloadClips?: Dispatch<SetStateAction<boolean>>;
	mutate?: (updatedClip: VideoClip, isDeleted: boolean) => void;
}

export function EditClipModal({
	opened,
	onClose,
	selectedClip,
	currentMeetingData,
	videoRef,
	setReloadClips,
	mutate,
}: EditClipModalProps) {
	if (!selectedClip) return null;
	const { classes, theme } = useStyles();
	const user = useRecoilValue(currentUser);
	const organizationID = user.currentOrganizationID;
	const collections = useRecoilValue(collectionsAtom);
	const collectionsHashMap = useRecoilValue(collectionsHashMapAtom);
	const setSelectedClip = useSetRecoilState(selectedClipAtom);
	const {
		saveClip: onSave,
		regenerateSummary,
		isRegenerating,
	} = useClips(organizationID);
	const [collectionValue, setCollectionValue] = useState<string[]>([]);
	const collectionsData = collections
		.map((collection) => ({
			...collection,
			label: collection.name,
			value: collection.id,
			key: collection.id,
		}))
		.filter((collection) => collection.owningUserID === user.id);
	const durationInSeconds = Math.floor(
		currentMeetingData?.videoMetadata?.duration / MILLISECONDS_PER_SECOND
	);
	const [timeRange, setTimeRange] = useState<[number, number]>([
		Number(selectedClip.startTime),
		Number(selectedClip.endTime),
	]);
	const [startTimeString, setStartTimeString] = useState(
		formatSecondsToTime(Number(selectedClip.startTime))
	);
	const [endTimeString, setEndTimeString] = useState(
		formatSecondsToTime(Number(selectedClip.endTime))
	);
	const [startError, setStartError] = useState('');
	const [endError, setEndError] = useState('');

	const { playbackURL } = selectedClip;

	const clipForm = useForm({
		initialValues: {
			title: selectedClip.title || '',
			summary: selectedClip.summary || '',
		},
		validate: {
			title: (value) => (value ? null : 'Title is required.'),
		},
	});

	useEffect(() => {
		const existingCollections = (selectedClip.collectionContents || []).map(
			({ collectionID }) => {
				const collection = collectionsHashMap[collectionID];
				return collection?.id;
			}
		);
		setCollectionValue(existingCollections);
		clipForm.setValues({
			title: selectedClip.title || '',
			summary: selectedClip.summary || '',
		});
		setTimeRange([
			Number(selectedClip.startTime),
			Number(selectedClip.endTime),
		]);
		setStartTimeString(formatSecondsToTime(Number(selectedClip.startTime)));
		setEndTimeString(formatSecondsToTime(Number(selectedClip.endTime)));
	}, [selectedClip, collectionsHashMap]);

	const validateTime = (timeString: string, isStart: boolean) => {
		const seconds = parseTimeToSeconds(timeString);
		if (isNaN(seconds)) return 'Invalid time format';
		if (seconds < 0) return 'Time cannot be negative';
		const videoDuration = Math.floor(
			currentMeetingData?.videoMetadata?.duration / MILLISECONDS_PER_SECOND
		);
		if (seconds > videoDuration) return 'Exceeds video duration';
		if (isStart && seconds >= timeRange[1]) return 'Must be before end time';
		if (!isStart && seconds <= timeRange[0]) return 'Must be after start time';
		return '';
	};

	const handleStartChange = (value: string) => {
		setStartTimeString(value);
		const seconds = parseTimeToSeconds(value);
		const error = validateTime(value, true);
		setStartError(error);
		if (!error && !isNaN(seconds)) {
			setTimeRange([seconds, timeRange[1]]);
		}
	};

	const handleEndChange = (value: string) => {
		setEndTimeString(value);
		const seconds = parseTimeToSeconds(value);
		const error = validateTime(value, false);
		setEndError(error);
		if (!error && !isNaN(seconds)) {
			setTimeRange([timeRange[0], seconds]);
		}
	};

	const handleRangeChange = (values: [number, number]) => {
		setTimeRange(values);
		setStartTimeString(formatSecondsToTime(values[0]));
		setEndTimeString(formatSecondsToTime(values[1]));
	};

	const handleRangeChangeEnd = (values: [number, number]) => {
		setEndTimeString(formatSecondsToTime(values[1]));
	};

	const handleSetCurrentStart = () => {
		if (!videoRef.current) return;
		const current = Math.floor(videoRef.current.currentTime);
		setTimeRange([current, timeRange[1]]);
		setStartTimeString(formatSecondsToTime(current));
	};

	const handleSetCurrentEnd = () => {
		if (!videoRef.current) return;
		const current = Math.floor(videoRef.current.currentTime);
		setTimeRange([timeRange[0], current]);
		setEndTimeString(formatSecondsToTime(current));
	};

	const handleSave = async () => {
		if (startError || endError) {
			alert('Please fix time errors before saving');
			return;
		}
		try {
			const updatedClip = await onSave(
				selectedClip,
				{
					...clipForm.values,
					collectionIDs: collectionValue,
					startTime: timeRange[0],
					endTime: timeRange[1],
				},
				setSelectedClip
			);
			if (mutate) mutate(updatedClip, false);
			onClose();
		} catch (error) {
			console.error('Error saving clip:', error);
		}
	};

	return (
		<Modal
			opened={opened}
			onClose={onClose}
			size='lg'
			title={
				<ModalTitle
					text='Edit Clip'
					icon={<FontAwesomeIcon icon={faScissors} size='xl' />}
				/>
			}
		>
			<Stack spacing='md'>
				<Divider className={classes.divider} />
				<Text weight={500}>Drag time boxes to adjust your video clip:</Text>
				<Box sx={{ position: 'relative', marginTop: '2.75rem' }}>
					<RangeSlider
						min={0}
						max={Math.floor(
							currentMeetingData?.videoMetadata?.duration /
								MILLISECONDS_PER_SECOND
						)}
						step={1}
						value={timeRange}
						onChange={handleRangeChange}
						onChangeEnd={handleRangeChangeEnd}
						label={null}
						thumbChildren={[
							<FontAwesomeIcon
								key={1}
								icon={faPipe}
								size='lg'
								style={{ color: 'white' }}
							/>,
							<FontAwesomeIcon
								key={2}
								icon={faPipe}
								size='lg'
								style={{ color: 'white' }}
							/>,
						]}
						styles={(theme) => ({
							root: { height: '48px' },
							track: {
								backgroundColor: theme.colors['background-gray-2'][3],
								height: '48px',
								'&::before': {
									borderRadius: 0,
									right: 0,
									left: 0,
								},
							},
							bar: {
								backgroundColor: theme.fn.primaryColor(),
								opacity: 0.3,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: 0,
							},
							thumb: {
								cursor: 'ew-resize',
								width: '16px',
								height: '60px',
								backgroundColor: theme.fn.primaryColor(),
								border: 'none',
								boxShadow: 'none',
								'&::before': { display: 'none' },
							},
						})}
					/>
					<Group noWrap position='center' spacing='sm'>
						<EditTimeInputSection
							value={startTimeString}
							onChange={handleStartChange}
							error={startError}
							onSetCurrentTime={handleSetCurrentStart}
							duration={durationInSeconds}
						/>
						<Text fz='xl' weight={400} c='secondary-text'>
							-
						</Text>
						<EditTimeInputSection
							value={endTimeString}
							onChange={handleEndChange}
							error={endError}
							onSetCurrentTime={handleSetCurrentEnd}
							duration={durationInSeconds}
						/>
					</Group>
				</Box>
				<Text weight={600} size='sm'>
					Preview your edited video clip:
				</Text>
				<div className={classes.preview}>
					<ReelayVideoPlayer
						meeting={currentMeetingData}
						playbackURL={buildVideoClipsPlaybackUrl(
							playbackURL,
							timeRange[0],
							timeRange[1]
						)}
						playerRef={videoRef}
					/>
				</div>
				<Paper>
					<Stack spacing='md'>
						<Text size='sm' fw={600}>
							Title
						</Text>
						<TextInput
							{...clipForm.getInputProps('title')}
							placeholder='Enter clip title'
							required
						/>
						<Text size='sm' fw={600}>
							Add to collection
						</Text>
						<MultiSelect
							value={collectionValue}
							defaultValue={collectionValue}
							onChange={setCollectionValue}
							data={collectionsData}
							placeholder='Select collections'
							searchable
							clearable
							clearButtonProps={{ 'aria-label': 'Clear selection' }}
							nothingFound='No collections found'
							variant='filled'
							size='sm'
							icon={<FontAwesomeIcon icon={faFolders} />}
						/>
						<Group position='apart' align='center'>
							<Text size='sm' fw={600}>
								Summary
							</Text>
							<Tooltip label='Regenerate Summary' position='top'>
								<Button
									p={0}
									variant='transparent'
									size='sm'
									onClick={() =>
										regenerateSummary(selectedClip, setSelectedClip)
									}
									loading={isRegenerating}
								>
									<FontAwesomeIcon icon={faRotateRight} />
								</Button>
							</Tooltip>
						</Group>
						<Textarea
							{...clipForm.getInputProps('summary')}
							autosize
							minRows={6}
							maxRows={6}
						/>
					</Stack>
				</Paper>
				<Group position='right'>
					<Button
						onClick={handleSave}
						className={classes.createButton}
						leftIcon={<FontAwesomeIcon icon={faScissors} />}
					>
						Save
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
