import React, { useEffect, useRef } from 'react';
import { createStyles, ActionIcon } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import ReelayVideoPlayer from '../../meeting/components/ReelayVideoPlayer';
import { reelayVideoThumbnailFallbackImage } from '../../../helpers/data';
import Hls from 'hls.js';

const useStyles = createStyles((theme) => ({
	container: {
		position: 'relative',
		backgroundColor: theme.black,
		borderRadius: theme.radius.lg,
		overflow: 'hidden',
	},
	moreButton: {
		position: 'absolute',
		top: '1rem',
		right: '1rem',
		zIndex: 10,
		color: theme.white,
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.1)',
		},
	},
	video: {
		width: '100%',
		height: '100%',
		aspectRatio: '16 / 9',
	},
}));

interface VideoPlayerProps {
	src: string;
}

export function VideoPlayer({ src }: VideoPlayerProps) {
	const { classes, theme } = useStyles();
	const playerRef = useRef<HTMLVideoElement>(null);
	// const { videoMetadata } = meeting;
	// const user = useRecoilValue(currentUser);
	// const timestampValue = useRecoilValue(timestamp);
	// const [sentWatchedEvent, setSentWatchedEvent] = useState(false);
	// const [pageLoaded, setPageLoaded] = useState(false);
	// const [userInteracted, setUserInteracted] = useState(false);
	// const [searchParams, setSearchParams] = useSearchParams();
	// const sharingToken = searchParams.get('vt');
	// const organizationID = useRecoilValue(currentOrganizationID);
	// const src = src || videoMetadata?.src;

	useEffect(() => {
		if (Hls.isSupported() && src) {
			const hls = new Hls();
			hls.loadSource(src);
			hls.attachMedia(playerRef.current);
		}
	}, [src]);

	return (
		<div className={classes.container}>
			<div className={classes.video}>
				<video
					controls
					ref={playerRef}
					style={{
						borderRadius: 16,
						height: '100%',
						width: '100%',
					}}
					playsInline
					poster={reelayVideoThumbnailFallbackImage}
				></video>{' '}
			</div>
		</div>
	);
}
