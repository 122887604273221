import React from 'react';
import { Button, Group, Image, Stack, Text, Timeline } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { recallAIBotDetails } from '../../../Atoms/recallAI';
import { DateTime } from 'luxon';
import { googleMeetIcon, outlookTeamsIcon, zoomIcon } from '../../constants';
import { getPlatformIcon } from '../../../helpers/integrations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

export default function BotDetailsTimelineBars({
	recallBotID,
}: {
	recallBotID: string;
}) {
	const botDetails = useRecoilValue(recallAIBotDetails);
	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const status_changes: {
		code: string;
		created_at: string;
		message: string | null;
		sub_code: string | null;
	}[] = (botDetails?.status_changes || []).map((status_change) => ({
		...status_change,
		code: status_change.code.replace(/_/g, ' '),
		sub_code: status_change.sub_code?.replace(/_/g, ' '),
		created_at: DateTime.fromISO(status_change.created_at, {
			zone: 'utc',
		})
			.setZone(userTimeZone)
			.toFormat('MM/dd/yyyy h:mm a'),
	}));

	return (
		<Stack>
			<Text size={'sm'} fw={400}><b>RecallBotID:</b> {recallBotID}</Text>
			<Group position={'left'} noWrap>
				<Button
					component="a"  // This makes the button behave like a link
					href={`https://api.recall.ai/dashboard/explorer/bot/${recallBotID}`}     // Sets the link destination
					target="_blank" // Opens the link in a new tab
					rel="noopener noreferrer" // Security best practices for external links
					size='xs'
					rightIcon={<FontAwesomeIcon
						style={{ cursor: 'pointer' }}
						icon={faExternalLink}
						size={'xs'}
					/>}
				>
					See details
				</Button>

			</Group>


			<Group position={'apart'} noWrap>
				<Text className={'sub-header'}>{'Bot status changes'}</Text>
				<Image
					maw={30}
					radius='md'
					src={getPlatformIcon(botDetails?.meeting_url?.platform)}
					alt='Platform image'
					mb={3}
				/>{' '}
			</Group>
			<Timeline active={status_changes.length - 1}>
				{status_changes.map((status_change, index) => (
					<Timeline.Item
						key={`${status_change.code}-${status_change.created_at}-${index}`}
						title={status_change.code}
						bullet={index + 1}
					>
						<Text color='dimmed' size='sm'>
							{status_change.sub_code || ''}
						</Text>
						<Text size='xs' mt={4}>
							{status_change.created_at}
						</Text>
					</Timeline.Item>
				))}
			</Timeline>
		</Stack>
	);
}
