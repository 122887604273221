import React from 'react';
import { Select, Tooltip } from '@mantine/core';
import {
	ANYONE_WITH_LINK,
	INVITE_ONLY,
} from '../../helpers/settings/settings.constants';

interface SharePermissionSelectProps {
	sharingOption: string;
	setSharingOption: (value: string) => void;
	sharingDisabled?: boolean;
}

const SharePermissionSelect: React.FC<SharePermissionSelectProps> = ({
	sharingOption,
	setSharingOption,
	sharingDisabled = false,
}) => {
	return (
		<Tooltip
			label='Only users invited below will be able to view this clip. Contact your administrator to change this setting to allow anyone with the link to view.'
			disabled={!sharingDisabled}
			withArrow
			zIndex={10000000000}
		>
			<div>
				<Select
					radius={'xl'}
					w={'180px'}
					value={sharingOption}
					onChange={(value) => setSharingOption(value)}
					data={[
						{ label: 'Invite Only', value: INVITE_ONLY },
						{ label: 'Anyone With Link', value: ANYONE_WITH_LINK },
					]}
					placeholder='Select Sharing Option'
					styles={{
						label: {
							marginBottom: '7px',
						},
					}}
					disabled={sharingDisabled}
				/>
			</div>
		</Tooltip>
	);
};

export default SharePermissionSelect;
