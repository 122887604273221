import React, { useEffect, useMemo, useState } from 'react';
import {
	Modal,
	Group,
	Stack,
	Text,
	Button,
	Select,
	Paper,
	ScrollArea,
	CopyButton,
	Tooltip,
	ActionIcon,
	Checkbox,
	Textarea,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUser,
	faPaperPlaneTop,
	faLink,
	faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import ModalTitle from '../../../../../../components/Titles/ModalTitle';
import {
	ANYONE_WITH_LINK,
	INVITE_ONLY,
} from '../../../../../../helpers/settings/settings.constants';
import UserList from '../../../../../../components/UserList';
import { UsersMultiSelect } from '../../../../../../components/UsersMultiSelect';
import type {
	VideoClip,
	VideoClipUserLink,
} from '../../../../../../Atoms/clips';
import { useRecoilValue } from 'recoil';
import {
	currentOrganizationID,
	teamDataAtom,
	userOrganizationMembersMap,
} from '../../../../../../Atoms/userAtoms';
import type { InvitedUser } from '../../../../../../interfaces/user';
import SharePermissionSelect from '../../../../../../components/SharePermissionSelect/SharePermissionSelect';
import {
	organizationSettings,
	organizationSettingSelector,
	sharingClipsDisabled,
} from '../../../../../../Atoms/settings';
import { useShareClipModal } from '../../../../../../customHooks/clips/useShareClipModal';

interface ShareClipModalProps {
	opened: boolean;
	onClose: () => void;
	selectedClip: VideoClip;
	organizer: any;
	isClipOwner: boolean;
	onClipUpdate: (updatedClip: VideoClip | null) => void;
}

export function ShareClipModal({
	opened,
	onClose,
	selectedClip,
	organizer,
	isClipOwner,
	onClipUpdate,
}: ShareClipModalProps) {
	const organizationID = useRecoilValue(currentOrganizationID);
	const orgUserMap = useRecoilValue(userOrganizationMembersMap);
	const teamData = useRecoilValue(teamDataAtom);
	const [data, setData] = useState<InvitedUser[]>([]);
	const sharingDisabled = useRecoilValue(sharingClipsDisabled);
	const [notifyPeopleChecked, setNotifyPeopleChecked] = useState(false);
	const [message, setMessage] = useState('');

	const {
		form,
		buttonLoading,
		handleChangeSharingOption,
		handleRemoveUser,
		handleAddUsers,
		setSelectedClip,
	} = useShareClipModal(organizationID || '');

	useEffect(() => {
		setSelectedClip(selectedClip);
	}, [selectedClip]);

	useEffect(() => {
		if (!notifyPeopleChecked) setMessage('');
	}, [notifyPeopleChecked]);

	const userListWithOwnerFirst = useMemo(() => {
		return [...(selectedClip?.videoClipUserLinks || [])]
			.sort((a, b) => {
				if (selectedClip.owningUserID === a.userID) return -1;
				if (selectedClip.owningUserID === b.userID) return 1;
				return (
					new Date(a.createdAt || 0).getTime() -
					new Date(b.createdAt || 0).getTime()
				);
			})
			.map((link: VideoClipUserLink): any => {
				const user = orgUserMap.get(link.userID) || {};
				if (user && typeof user === 'object' && Object.keys(user).length > 0) {
					return {
						id: link.userID,
						email: user.email || '',
						firstName: user.firstName || '',
						lastName: user.lastName || '',
						userlink: link as any,
					};
				}

				return link;
			});
	}, [
		selectedClip?.videoClipUserLinks,
		selectedClip?.owningUserID,
		orgUserMap,
	]);

	useEffect(() => {
		const userIDs = new Set(userListWithOwnerFirst.map((user) => user.id));
		const filteredTeamData = [...teamData].filter(
			(user) => !userIDs.has(user.id)
		);
		setData(filteredTeamData);
	}, [teamData, userListWithOwnerFirst]);

	const sharingOption = selectedClip?.sharingToken?.active
		? ANYONE_WITH_LINK
		: INVITE_ONLY;

	const { origin } = window.location;
	const sharingToken = selectedClip?.sharingToken;
	const copyLinkUrl = sharingToken?.active
		? `${origin}/clips/${selectedClip?.id}?vt=${sharingToken?.token}`
		: `${origin}/clips/${selectedClip?.id}`;

	// Unified handler for clip updates
	const handleClipOperation = async (
		operation: () => Promise<VideoClip | null>
	) => {
		const updatedClip = await operation();
		onClipUpdate(updatedClip);
	};

	return (
		<Modal
			opened={opened}
			onClose={onClose}
			title={<ModalTitle text={`Share ${selectedClip?.title}`} />}
			size='md'
		>
			<Stack spacing='md'>
				{isClipOwner && (
					<Group position='apart' noWrap>
						<Text size='sm' weight={600}>
							People with access
						</Text>
						<Group noWrap spacing='xs'>
							<CopyButton value={copyLinkUrl} timeout={2000}>
								{({ copied, copy }) => (
									<Tooltip
										label={copied ? 'Copied' : 'Copy link'}
										withArrow
										position='right'
									>
										<ActionIcon onClick={copy}>
											{copied ? (
												<FontAwesomeIcon icon={faCheck} />
											) : (
												<FontAwesomeIcon icon={faLink} />
											)}
										</ActionIcon>
									</Tooltip>
								)}
							</CopyButton>
							<SharePermissionSelect
								sharingOption={sharingOption}
								setSharingOption={(value) =>
									handleClipOperation(() => handleChangeSharingOption(value))
								}
								sharingDisabled={sharingDisabled}
							/>
						</Group>
					</Group>
				)}
				<Paper
					p='md'
					withBorder
					style={{
						display: 'flex',
						flexDirection: 'column',
						height: '300px',
					}}
				>
					<ScrollArea style={{ flex: 1 }}>
						<UserList
							searchValue=''
							users={userListWithOwnerFirst}
							gridColSpan={12}
							emptyText='No users have access to this clip.'
							handleRemoveUserByID={(userId) =>
								handleClipOperation(() => handleRemoveUser(userId))
							}
							showLinkedIn={false}
							organizer={organizer}
							owningUserID={selectedClip?.owningUserID}
						/>
					</ScrollArea>
				</Paper>
				{isClipOwner && (
					<form
						onSubmit={(e) => {
							e.preventDefault();
							if (form.validate().hasErrors) {
								console.log('form has errors', form.errors);
								return;
							}
							handleClipOperation(() => handleAddUsers(message));
						}}
					>
						<Stack spacing='md'>
							<UsersMultiSelect
								creatable
								descriptionText='Add Viewers'
								variantType='filled'
								data={data}
								setData={setData}
								searchable
								labelText=''
								placeholder='Add a name, email or team.'
								name='viewers'
								form={form}
								icon={<FontAwesomeIcon icon={faUser} />}
							/>

							<Group position='apart' align='center'>
								<Checkbox
									checked={notifyPeopleChecked}
									onChange={(event) =>
										setNotifyPeopleChecked(event.currentTarget.checked)
									}
									label='Add a message'
								/>
							</Group>

							{notifyPeopleChecked && (
								<Textarea
									placeholder='Add a custom message to the notification email'
									minRows={3}
									value={message}
									onChange={(event) => setMessage(event.currentTarget.value)}
								/>
							)}
							<Button
								type='submit'
								loading={buttonLoading}
								leftIcon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
							>
								Share
							</Button>
						</Stack>
					</form>
				)}
			</Stack>
		</Modal>
	);
}
