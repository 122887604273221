import React, { useEffect, useState } from 'react';
import {
	Accordion,
	Group,
	Text,
	createStyles,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { useLocation } from 'react-router-dom';
import {
	adminNavLinks,
	flagsmithFeatureFlags,
	navigationLinks,
} from '../constants';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { activeLink, currentUser, hasAdminAccess } from '../../Atoms/userAtoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { navbarOpenedAtom } from '../../Atoms/layout';
import { useFlags } from 'flagsmith/react';
import { isSuperadmin } from '../../helpers/auth';

interface MainLinkProps {
	icon: any;
	label: string;
	path: string;
	nestedLinks?: { label: string; path: string; icon: React.ReactNode }[];
}

const useStyles = createStyles((theme) => ({
	nestedLink: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: theme.radius.md,
		padding: theme.spacing.xs,
		paddingLeft: theme.spacing.sm,
		fontSize: theme.fontSizes.sm,
		'&:hover': {
			backgroundColor: theme.colors.gray[0],
		},
	},
	activeNestedLink: {
		backgroundColor:
			theme.primaryColor === 'primary'
				? theme.colors.violet[0]
				: theme.colors[theme.primaryColor][theme.primaryShade as number],
		color: theme.colors.primary[theme.primaryShade as number],
	},
	accordionControl: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: theme.radius.md,
		fontSize: theme.fontSizes.md,
		fontWeight: 500,
		paddingRight: 0,
		paddingLeft: 0,
		'&:hover': {
			backgroundColor: 'transparent',
			textDecoration: 'underline',
		},
	},
	accordionPanel: {
		paddingLeft: theme.spacing.md,
	},
	iconSpacing: {
		marginRight: theme.spacing.sm,
	},
}));

function MainLink({ icon, label, path, nestedLinks }: MainLinkProps) {
	const { classes, cx, theme } = useStyles();
	const navigate = useNavigate();
	const [active, setActive] = useRecoilState(activeLink);
	const setNavbarOpened = useSetRecoilState(navbarOpenedAtom);
	const [nestedActive, setNestedActive] = useState('');
	const hasLinks = Boolean(nestedLinks);
	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes('/meetings')) {
			setActive('Library');
		}
		if (location.pathname.includes('/recordings')) {
			setNestedActive('Recordings');
		} else if (location.pathname.includes('/minutes')) {
			setNestedActive('Minutes');
		} else if (location.pathname.includes('/schedule')) {
			setNestedActive('Schedule');
		} else if (location.pathname.includes('/clips')) {
			setNestedActive('Clips');
		} else {
			setNestedActive('');
		}
	}, [location.pathname]);

	const isActive = (linkPath: string) => {
		const pathSegments = location.pathname.split('/');
		const furthestSubroute = pathSegments[pathSegments.length - 1];
		const lastSegmentOfLinkPath = linkPath.split('/').pop();
		return lastSegmentOfLinkPath.includes(furthestSubroute);
	};

	const items = (hasLinks ? nestedLinks : []).map((link) => (
		<UnstyledButton
			key={link.label}
			className={cx(classes.nestedLink, {
				[classes.activeNestedLink]: isActive(link.path),
			})}
			onClick={() => {
				setNestedActive(link.label);
				navigate(link.path);
				setNavbarOpened(false);
			}}
		>
			{link.icon && (
				<FontAwesomeIcon
					icon={link.icon as any}
					className={classes.iconSpacing}
				/>
			)}
			<Text>{link.label}</Text>
		</UnstyledButton>
	));

	return (
		<Accordion.Item value={path.substring(1)}>
			<Accordion.Control className={classes.accordionControl}>
				<Group position={'apart'} spacing={0}>
					<Group noWrap spacing={0}>
						{icon && (
							<FontAwesomeIcon icon={icon} className={classes.iconSpacing} />
						)}
						<Text fw={500} fz={'md'}>
							{label}
						</Text>
					</Group>
				</Group>
			</Accordion.Control>
			{hasLinks ? (
				<Accordion.Panel className={classes.accordionPanel}>
					<Stack spacing={0}>{items}</Stack>
				</Accordion.Panel>
			) : null}
		</Accordion.Item>
	);
}

interface Props {
	openAccordionValue: string;
	setOpenAccordionValue: (value: string) => void;
}

export default function MainLinks({
	openAccordionValue,
	setOpenAccordionValue,
}: Props) {
	const user = useRecoilValue(currentUser);
	const adminAccess = useRecoilValue(hasAdminAccess);
	const { meetingCollections, actionItems } = flagsmithFeatureFlags;
	const flags = useFlags([meetingCollections, actionItems]);
	const collectionsEnabled = flags.meeting_collections.enabled;
	const actionItemsEnabled = flags.action_items.enabled;
	const superAdmin = isSuperadmin(user.roles);

	const links = (adminAccess ? adminNavLinks : navigationLinks)
		.filter((link) => {
			if (link.label === 'Actions') {
				return actionItemsEnabled;
			}
			if (link.label === 'Collections') {
				return collectionsEnabled;
			}
			return true;
		})
		.map((link) => {
			const { icon, label, path, nestedLinks: allNestedLinks } = link;

			let nestedLinks = allNestedLinks;
			if (label === 'Settings' && !superAdmin) {
				nestedLinks = allNestedLinks.filter(
					(nestedLink) =>
						nestedLink.label !== 'Security' && nestedLink.label !== 'Billing'
				);
			}

			return (
				<MainLink
					icon={icon}
					path={path}
					label={label}
					key={link.label}
					nestedLinks={nestedLinks}
				/>
			);
		});

	return (
		<Accordion
			value={openAccordionValue}
			onChange={setOpenAccordionValue}
			multiple={false}
			styles={(theme) => ({
				content: {
					paddingLeft: 0,
					paddingTop: 0,
				},
			})}
		>
			{links}
		</Accordion>
	);
}
