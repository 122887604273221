import React, { useState } from 'react';
import { ActionIcon, Menu, Loader } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEllipsisVertical,
	faTrash,
	faEdit,
	faBoxArchive,
	faPlus,
	faFolderGear,
	faUserGroup,
	faPencil,
	faLink,
} from '@fortawesome/pro-regular-svg-icons';
import { modals } from '@mantine/modals';
import { Text } from '@mantine/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUser } from '../../../Atoms/userAtoms';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { useCollections } from '../../../customHooks/useCollections';
import { useFlags } from 'flagsmith/react';
import { useLocation, useNavigate } from 'react-router';
import {
	addClipToCollectionModalOpenedAtom,
	clipBeingAddedToCollection,
	clipsState,
	reloadClipsAtom,
	VideoClip,
} from '../../../Atoms/clips';
import { destroyVideoClips } from '../../../api/clips';
import {
	archiveCollectionAtom,
	SpecialCollections,
} from '../../../Atoms/collections';
import { flagsmithFeatureFlags } from '../../../components/constants';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { useGetCollections } from '../../../customHooks/collections/useGetCollections';
import { useSWRConfig } from 'swr';

interface ClipCardMenuProps {
	clip: VideoClip;
	searchValue?: string;
	handleAddToCollection?: () => void;
	onEdit?: (clip: any) => void;
	onManageAccess?: (clip: any) => void;
	onCopyLink?: (clip: any) => void;
	handleGetClipsData?: ({ searchValue }: { searchValue: string }) => void;
	size?: SizeProp;
	isClipCard?: boolean;
}

export function ClipCardMenu({
	clip,
	searchValue,
	handleGetClipsData,
	handleAddToCollection,
	onEdit,
	onManageAccess,
	onCopyLink,
	size = 'xl',
	isClipCard = false,
}: ClipCardMenuProps) {
	const navigate = useNavigate();
	const location = useLocation();
	const { mutate } = useSWRConfig();
	const flags = useFlags([flagsmithFeatureFlags.meetingCollections]);
	const collectionsEnabled = flags.meeting_collections.enabled;
	const user = useRecoilValue(currentUser);
	const setAddClipToCollectionModalOpened = useSetRecoilState(
		addClipToCollectionModalOpenedAtom
	);
	const setClipToAdd = useSetRecoilState(clipBeingAddedToCollection);
	const archiveCollection = useRecoilValue(archiveCollectionAtom);
	const [clips, setClips] = useRecoilState(clipsState);
	const setReloadClips = useSetRecoilState(reloadClipsAtom);
	const {
		addContentToCollection,
		removeContentFromCollection,
		collection,
		setCollection,
	} = useCollections(false);
	const [menuLoading, setMenuLoading] = useState(false);

	if (!clip) {
		return null;
	}

	const { id, organizationID, title, owningUserID } = clip;

	const {
		collections,
		setCollections,
		error: fetchError,
		isLoading,
	} = useGetCollections(organizationID);

	const isOwner = owningUserID === user.id;
	const isArchived = archiveCollection?.content?.some((c) => c.id === id);
	const isCollections = location.pathname.includes('/collections');
	const isCollectionOwner = collection?.owningUserID === user.id;

	const handleDeleteClip = async () => {
		modals.openConfirmModal({
			title: `Delete ${title || 'Clip'}`,
			children: (
				<Text size='sm'>
					Are you sure you want to delete this clip? This action is
					irreversible.
				</Text>
			),
			labels: { confirm: 'Delete', cancel: 'Cancel' },
			onCancel: () => console.log('cancel'),
			onConfirm: () => deleteClip(),
		});
	};

	const deleteClip = async () => {
		try {
			await destroyVideoClips([id], organizationID);
			showSuccessNotification({
				message: `Clip was successfully deleted.`,
			});
			setClips(clips.filter((c) => c.id !== id));
			setReloadClips((prev) => !prev);
			if (handleGetClipsData) {
				handleGetClipsData({ searchValue });
			} else {
				navigate('/meetings/clips');
			}
		} catch (err) {
			console.error('Error deleting clip', err);
			showFailureNotification({
				message: `Apologies, the clip was not successfully deleted. Please try the operation again.`,
			});
		}
	};

	const handleArchiveClip = async () => {
		try {
			setMenuLoading(true);
			await addContentToCollection(archiveCollection.id, [id]);
			showSuccessNotification({
				message: `Clip was successfully archived.`,
			});
			setReloadClips((prev) => !prev);
		} catch (err) {
			console.error('Error archiving clip', err);
			showFailureNotification({
				message: `Apologies, the clip was not successfully archived. Please try the operation again.`,
			});
		} finally {
			setMenuLoading(false);
		}
	};

	const handleUnarchiveClip = async () => {
		try {
			setMenuLoading(true);
			const res = await removeContentFromCollection(
				archiveCollection,
				id,
				false
			);
			showSuccessNotification({
				message: `Clip was successfully unarchived.`,
			});
			setReloadClips((prev) => !prev);
			mutate([SpecialCollections.Archived, organizationID]);
		} catch (err) {
			console.error('Error unarchiving clip', err);
			showFailureNotification({
				message: `Apologies, the clip was not successfully unarchived. Please try the operation again.`,
			});
		} finally {
			setMenuLoading(false);
		}
	};

	const handleRemoveFromCollection = async () => {
		try {
			const res = await removeContentFromCollection(collection, id);
			if (res) {
				setCollection((prev) => ({
					...prev,
					content: prev.content.filter((c) => c.id !== id),
				}));
			}
		} catch (err) {
			console.error('Error removing clip from collection', err);
			showFailureNotification({
				message: `Apologies, the clip was not successfully removed from the collection. Please try the operation again.`,
			});
		}
	};

	return (
		<Menu shadow='md' width={200} position='bottom-end'>
			<Menu.Target>
				<ActionIcon size='xs' variant='transparent'>
					{menuLoading ? (
						<Loader size={'sm'} />
					) : (
						<FontAwesomeIcon icon={faEllipsisVertical} size={size} />
					)}
				</ActionIcon>
			</Menu.Target>
			<Menu.Dropdown>
				{collectionsEnabled && isCollections && isCollectionOwner && (
					<Menu.Item
						onClick={
							isArchived ? handleUnarchiveClip : handleRemoveFromCollection
						}
						icon={<FontAwesomeIcon icon={faTrash} />}
					>
						{isArchived ? 'Unarchive' : 'Remove from collection'}
					</Menu.Item>
				)}

				{!isCollections && (
					<>
						<Menu.Item
							icon={<FontAwesomeIcon icon={faPlus} />}
							onClick={handleAddToCollection}
						>
							Add to a collection
						</Menu.Item>
						{!isClipCard && (
							<Menu.Item
								onClick={(e) => {
									e.stopPropagation();
									navigate('/collections');
								}}
								icon={
									<FontAwesomeIcon
										style={{
											cursor: 'pointer',
										}}
										icon={faFolderGear}
										size={'sm'}
									/>
								}
							>
								Manage Collection(s)
							</Menu.Item>
						)}

						<Menu.Item
							icon={<FontAwesomeIcon icon={faBoxArchive} />}
							onClick={isArchived ? handleUnarchiveClip : handleArchiveClip}
						>
							{isArchived ? 'Unarchive' : 'Archive'}
						</Menu.Item>
					</>
				)}

				{isOwner && (
					<>
						{onEdit && (
							<Menu.Item
								icon={<FontAwesomeIcon icon={faPencil} />}
								onClick={() => onEdit(clip)}
							>
								Edit clip
							</Menu.Item>
						)}

						{onManageAccess && (
							<Menu.Item
								icon={<FontAwesomeIcon icon={faUserGroup} />}
								onClick={() => onManageAccess(clip)}
							>
								Manage access
							</Menu.Item>
						)}
					</>
				)}

				<Menu.Item
					icon={<FontAwesomeIcon icon={faLink} />}
					onClick={() => onCopyLink(clip)}
				>
					Copy link
				</Menu.Item>
				<Menu.Item
					icon={<FontAwesomeIcon icon={faTrash} />}
					onClick={handleDeleteClip}
				>
					Delete
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}
