import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMantineTheme } from '@mantine/core';
import Hls from 'hls.js';
import { useSearchParams } from 'react-router-dom';
import { sendAnalyticEvent } from '../../../api/api';
import { MILLISECONDS_PER_SECOND, WATCH_DURATION } from '../../constants';
import { reelayVideoThumbnailFallbackImage } from '../../../helpers/data';
import {
	timestamp,
	videoPlayerCurrentTime,
	videoPlayerElapsedTime,
} from '../../../Atoms/meetingAtom';
import { currentOrganizationID, currentUser } from '../../../Atoms/userAtoms';
import { IMeeting } from '../../../interfaces/meeting';

export interface Props {
	playbackURL: string;
	meeting: IMeeting;
	playerRef: any;
	timeRange: [number, number];
	setTimeRange: Dispatch<SetStateAction<[number, number]>>;
}

export default function ClipVideoPlayer({
	playbackURL,
	meeting,
	playerRef,
	setTimeRange,
	timeRange,
}: Props) {
	const theme = useMantineTheme();
	const { videoMetadata } = meeting;
	const [sentWatchedEvent, setSentWatchedEvent] = useState(false);
	const [userInteracted, setUserInteracted] = useState(false);
	const [searchParams] = useSearchParams();
	const sharingToken = searchParams.get('vt');
	const organizationID = useRecoilValue(currentOrganizationID);
	const [videoPausedByReachingEnd, setVideoPausedByReachingEnd] =
		useState(false);
	const playbackUrl = playbackURL || videoMetadata?.playbackURL;

	// Refs for state management
	const isUserSeek = useRef(false);
	const isProgrammaticSeek = useRef(false);
	const seekLock = useRef(false);
	const pauseLock = useRef(false);
	const hasPausedRef = useRef(false);

	// Recoil state management
	const [currentTime, setCurrentTime] = useRecoilState(videoPlayerCurrentTime);
	const setElapsedTime = useSetRecoilState(videoPlayerElapsedTime);

	// HLS initialization
	useEffect(() => {
		if (Hls.isSupported() && playbackUrl) {
			const hls = new Hls();
			hls.loadSource(playbackUrl);
			if (playerRef.current) {
				hls.attachMedia(playerRef.current);
			}
			return () => hls.destroy();
		}
	}, [meeting, playbackUrl]);

	// Event handlers with proper cleanup
	const handleTimeUpdate = useCallback(
		(event: Event) => {
			const video = event.target as HTMLVideoElement;
			const currentTime = video.currentTime;

			// Pause at end of range
			if (
				currentTime >= timeRange[1] &&
				!video.paused &&
				!hasPausedRef.current
			) {
				video.pause();
				setVideoPausedByReachingEnd(true);
				hasPausedRef.current = true;
				setTimeout(() => (hasPausedRef.current = false), 100);
			}
		},
		[timeRange[1], sentWatchedEvent]
	);

	const fireOnVideoStart = useCallback(() => {
		if (!playerRef.current) return;

		// Reset to start if paused at end
		if (videoPausedByReachingEnd) {
			seekLock.current = true;
			isProgrammaticSeek.current = true;
			playerRef.current.currentTime = timeRange[0];
			setVideoPausedByReachingEnd(false);
			setTimeout(() => (seekLock.current = false), 100);
		}

		// Analytics
		sendAnalyticEvent(
			{
				name: 'reelay.video',
				sourceID: meeting?.id,
				targetID: meeting?.videoMetadata?.id,
				data: {
					type: 'play',
					startTime: playerRef.current.currentTime * MILLISECONDS_PER_SECOND,
				},
			},
			organizationID,
			sharingToken
		);
		setUserInteracted(true);
	}, [videoPausedByReachingEnd, timeRange[0]]);

	const fireOnVideoPause = useCallback(() => {
		if (playerRef.current) {
			sendAnalyticEvent(
				{
					name: 'reelay.video',
					sourceID: meeting?.id,
					targetID: meeting?.videoMetadata?.id,
					data: {
						type: 'pause',
						startTime: playerRef.current.currentTime * MILLISECONDS_PER_SECOND,
					},
				},
				organizationID,
				sharingToken
			);
			setUserInteracted(true);
		}
	}, []);

	// Event listener setup
	useEffect(() => {
		const video = playerRef.current;
		if (!video) return;

		video.addEventListener('timeupdate', handleTimeUpdate);
		video.addEventListener('play', fireOnVideoStart);
		video.addEventListener('pause', fireOnVideoPause);

		return () => {
			video.removeEventListener('timeupdate', handleTimeUpdate);
			video.removeEventListener('play', fireOnVideoStart);
			video.removeEventListener('pause', fireOnVideoPause);
		};
	}, [handleTimeUpdate, fireOnVideoStart, fireOnVideoPause]);

	// Seek handling
	useEffect(() => {
		const handleSeeking = () => {
			if (seekLock.current) return;
			isUserSeek.current = true;
		};

		const handleSeeked = () => {
			if (!isUserSeek.current || !playerRef.current) return;

			seekLock.current = true;
			const newTime = playerRef.current.currentTime;

			// Always update timeRange[0] to the new seek position
			// setTimeRange((prev) => [
			// 	// Clamp the value between 0 and current end time
			// 	Math.min(Math.max(newTime, 0), prev[1]),
			// 	prev[1],
			// ]);

			setTimeout(() => {
				seekLock.current = false;
				isUserSeek.current = false;
			}, 100);
		};
		const video = playerRef.current;
		video?.addEventListener('seeking', handleSeeking);
		video?.addEventListener('seeked', handleSeeked);

		return () => {
			video?.removeEventListener('seeking', handleSeeking);
			video?.removeEventListener('seeked', handleSeeked);
		};
	}, [playerRef]);

	// Programmatic seek handling
	useEffect(() => {
		if (!playerRef.current || seekLock.current) return;

		const currentTime = playerRef.current.currentTime;
		const targetTime = Math.min(Math.max(timeRange[0], 0), timeRange[1]);

		if (Math.abs(currentTime - targetTime) > 0.1) {
			isProgrammaticSeek.current = true;
			playerRef.current.currentTime = targetTime;
		}
	}, [timeRange[0]]);

	return (
		<video
			controls
			ref={playerRef}
			style={{
				borderRadius: 16,
				height: '100%',
				width: '100%',
			}}
			playsInline
			poster={reelayVideoThumbnailFallbackImage}
		/>
	);
}
