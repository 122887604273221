import React, { Dispatch, SetStateAction } from 'react';
import { Grid, Stack } from '@mantine/core';
import { IMeeting } from '../../interfaces/meeting';
import VerticalMeetingCard from '../VerticalMeetingCard';
import Introduction from '../../pages/meetings/components/Introduction';
import { useRecoilValue } from 'recoil';
import { hasCalendar } from '../../Atoms/integrations';
import SkeletonCard from '../Skeletons/SkeletonCard';
import { MeetingCard } from '../Meetings/components/MeetingCard';
import { currentUserMeetings } from '../../Atoms/userAtoms';
import { currentCollection } from '../../Atoms/collections';
import { VideoClip } from '../../Atoms/clips';
import { ClipCard } from '../../pages/ClipsPage/components/ClipCard';

interface Props {
	searchValue: string;
	cardsPerPage: number;
	meetingCount: number;
	loading: boolean;
	handleGetMeetingsData?: any;
	segmentValue?: string;
	openSharingModal?: () => void;
	isCollections?: boolean;
	sortedData?: (IMeeting | VideoClip)[];
}

const MeetingsCardView = ({
	searchValue,
	handleGetMeetingsData,
	loading,
	segmentValue,
	openSharingModal,
	sortedData = [],
	isCollections = false,
}: Props) => {
	const meetings = useRecoilValue(currentUserMeetings);
	const collection = useRecoilValue(currentCollection);
	const meetingCountPerPage = 20;
	const skeletonCardArray = Array.from(
		{ length: meetingCountPerPage },
		(_, index) => index
	);

	const data = isCollections ? sortedData : meetings;

	return (
		<>
			{/*Meetings List View*/}
			{loading ? (
				<Stack w={'100%'} spacing={'xl'}>
					<Grid gutterSm={32} gutterXl={32} h={'100%'} pr={10}>
						{skeletonCardArray.map((idx) => (
							<SkeletonCard key={idx} />
						))}
					</Grid>
				</Stack>
			) : data.length ? (
				<Stack w={'100%'} spacing={'xl'}>
					<Grid gutterSm={32} gutterXl={32} h={'100%'} pr={10}>
						{data.map((item) =>
							item.id.startsWith('MVC_') ? (
								<ClipCard
									key={item.id}
									clip={item as VideoClip}
									searchValue={searchValue}
									// handleGetMeetingsData={handleGetMeetingsData}
									// openSharingModal={openSharingModal}
								/>
							) : item.id.startsWith('MET_') ? (
								<MeetingCard
									key={item.id}
									meeting={item as IMeeting}
									searchValue={searchValue}
									handleGetMeetingsData={handleGetMeetingsData}
									openSharingModal={openSharingModal}
								/>
							) : null
						)}
					</Grid>
				</Stack>
			) : (
				<Introduction segmentValue={segmentValue} />
			)}
		</>
	);
};

export default MeetingsCardView;
