import React, { useEffect, useState } from 'react';
import {
	AppShell,
	useMantineTheme,
	Center,
	Button,
	Alert,
	Group,
	Box,
} from '@mantine/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Outlet, useNavigate } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';

import {
	isAuthenticated,
	originalURL,
	userAuthenticated as userAuthenticatedAtom,
} from '../../Atoms/auth';
import {
	user as userAtom,
	userOrganizationMembers,
	currentUser,
	defaultUser,
} from '../../Atoms/userAtoms';
import { ServerError } from '../ServerError';
import { isAdmin, isSuperadmin } from '../../helpers/auth';
import { navbarOpenedAtom } from '../../Atoms/layout';
import * as API from '../../api/api';
import { logger } from '../../helpers/logger';
import { ReelayLoader } from '../ReelayLoader';
import { useFlagsmith } from 'flagsmith/react';
import flagsmith from 'flagsmith';
import { calendarSyncingData } from '../../Atoms/integrations';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import Gleap from 'gleap';
import useMediaQueries from '../../customHooks/useMediaQueries';
import { viewingMeetingAtom } from '../../Atoms/meetingAtom';
import AppShellHeader from './components/Header';
import { Text } from '@mantine/core';
import CustomNavbar from './components/Navbar';
import { stripeSubscriptionAtom } from '../../Atoms/billing';
import useStripe from '../../customHooks/useStripe';
import { UserRoles } from '../../interfaces/user';
import { useNotifications } from '../../customHooks/useNotifications';

export default function Layout() {
	const theme = useMantineTheme();
	const navigate = useNavigate();
	const { identify } = useFlagsmith();
	const setCurrentUser = useSetRecoilState(userAtom);
	const authenticated = useRecoilValue(isAuthenticated);
	const [navbarOpened, setNavbarOpened] = useRecoilState(navbarOpenedAtom);
	const setAuthStatus = useSetRecoilState(userAuthenticatedAtom);
	const setOriginalUrl = useSetRecoilState(originalURL);
	const [error, setError] = useState<string | null>();
	const [isLoading, setIsLoading] = useState(true);
	const user = useRecoilValue(currentUser);
	const setUserState = useSetRecoilState(userAtom);
	const setOrganizationMembers = useSetRecoilState(userOrganizationMembers);
	const calendarSyncing = useRecoilValue(calendarSyncingData);
	const [syncingCompleted, setSyncingCompleted] = useState(false);
	const [timerExpired, setTimerExpired] = useState(false);
	const [showBanner, setShowBanner] = useState(true);
	useNotifications();

	const atLeastOneCalendarSyncing =
		Object.keys(calendarSyncing).length === 0
			? false
			: Object.values(calendarSyncing).some((value) => value === true);
	const { isMobile, isTabletOrSmaller, widthOrHeightLessThanLG } =
		useMediaQueries();
	const navbarHidden = !navbarOpened && (isMobile || isTabletOrSmaller);
	const isViewingMeeting = window.location.href.includes('/meetings/');
	const isAuditPage = window.location.href.includes('/admin/edit/');
	const seeUploadMeetingButton =
		user.roles.includes(UserRoles.CreateMeeting) &&
		!user.roles.includes(UserRoles.Support) &&
		!user.roles.includes(UserRoles.MasterAdmin);

	const { subscription, isLoading: stripeLoading } = useStripe(true);

	const seeAddBotButton =
		user.roles.includes('support') || user.roles.includes('masteradmin');
	const isSuperAdmin = isSuperadmin(user.roles);
	const trialDaysLeft = Math.ceil(
		(subscription?.trialEnd - Date.now()) / (1000 * 60 * 60 * 24)
	);

	async function logout() {
		setAuthStatus(false);
		try {
			await API.logout();
			setCurrentUser(defaultUser);
			localStorage.clear();
			Gleap.clearIdentity();
			showSuccessNotification({
				message: 'You were logged out!',
			});
			navigate('/login');
			setOriginalUrl('');
		} catch (err: unknown) {
			setError((err as Error).message);
			showFailureNotification({
				message:
					'Sorry, we encountered an error while logging you out. Please try again.',
			});
		}
	}

	const HOW_LONG_TO_SHOW_SYNCING_COMPLETE = 10000;
	useEffect(() => {
		let timer;
		const syncingComplete =
			Object.keys(calendarSyncing).length &&
			Object.values(calendarSyncing).every((value) => value === false);

		if (syncingComplete) {
			setSyncingCompleted(true);
			timer = setTimeout(() => {
				setSyncingCompleted(true);
				setTimerExpired(true);
			}, HOW_LONG_TO_SHOW_SYNCING_COMPLETE);
		} else {
			clearTimeout(timer);
			setSyncingCompleted(false);
			setTimerExpired(false);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [calendarSyncing]);

	useEffect(() => {
		const handleGetCurrentUser = async () => {
			try {
				const user = await API.getCurrentUser();
				setUserState(user);

				flagsmith.identify(user.id, {
					id: user.id,
					email: user.email,
					first_name: user.firstName,
					last_name: user.lastName,
					role: JSON.stringify(user.roles),
					organizationID: user.currentOrganizationID,
				});

				Gleap.identify(user.id, {
					email: user.email,
					name: `${user.firstName} ${user.lastName}`,
					companyId: user.currentOrganizationID,
					customData: {
						role: JSON.stringify(user.roles),
					},
				});

				const { currentOrganizationID } = user;
				if (currentOrganizationID) {
					const usersResponse = await API.getUsersByOrg(currentOrganizationID);
					setOrganizationMembers(usersResponse);
				}
			} catch (err) {
				logger('error', 'Error getting current user', err);
			} finally {
				setIsLoading(false);
			}
		};
		if (authenticated) {
			handleGetCurrentUser();
		} else {
			setIsLoading(false);
		}
	}, []);

	const handleUpgradeNow = async () => {
		try {
			// send them to Stripe customer billing portal
			window.open(
				'https://billing.stripe.com/p/login/test_4gwbMd64Obxh3ew4gg',
				'_blank'
			);
		} catch (error) {
			console.error('Failed to end trial:', error);
			showFailureNotification({
				title: 'Failed to Upgrade',
				message:
					'An error occurred while ending the trial. Please try again. If this issue persists, please contact support.',
			});
		}
	};

	return (
		<AppShell
			asideOffsetBreakpoint='sm'
			padding={isMobile ? 0 : 'md'}
			style={{
				backgroundColor: theme.colors['background-gray'][0],
				height: '100vh',
				maxHeight: '100vh',
				boxSizing: 'border-box',
			}}
			styles={{
				body: {
					height: '100%',
				},
				main: {
					paddingBottom: isViewingMeeting
						? 0
						: 'calc(var(--mantine-footer-height, 0px) + 1rem)',
					paddingTop: 'calc(var(--mantine-header-height, 0px))',
					paddingLeft: 'calc(var(--mantine-navbar-width, 0px))',
					paddingRight: 'calc(var(--mantine-aside-width, 0px))',
				},
			}}
			navbar={
				<CustomNavbar
					{...{
						isAuditPage,
						isMobile,
						navbarHidden,
						authenticated,
						logout,
					}}
				/>
			}
			header={
				<AppShellHeader
					{...{
						authenticated,
						isMobile,
						isTabletOrSmaller,
						navbarOpened,
						setNavbarOpened,
						theme,
						atLeastOneCalendarSyncing,
						syncingCompleted,
						timerExpired,
						seeUploadMeetingButton,
						logout,
					}}
				/>
			}
		>
			<ErrorBoundary
				FallbackComponent={ServerError}
				onReset={() =>
					navigate(isAdmin(user.roles) ? '/admin/meetings' : '/meetings')
				}
			>
				{isLoading ? (
					<Center h={'100%'}>
						<ReelayLoader />
					</Center>
				) : (
					<Box
						sx={(theme) => ({
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						})}
					>
						{authenticated &&
							showBanner &&
							isSuperAdmin &&
							!subscription?.renewal?.renewalDate &&
							subscription.isTrialing &&
							!stripeLoading && (
								<Alert
									withCloseButton
									onClose={() => setShowBanner(false)}
									styles={{
										root: {
											backgroundColor: theme.fn.primaryColor(),
											borderRadius: 0,
											overflow: 'visible',
										},
										closeButton: {
											color: 'white',
										},
									}}
								>
									<Group
										w={'100%'}
										position='center'
										align='center'
										spacing={'xl'}
									>
										<Text c={'white'} span fw={500}>
											{`You have ${trialDaysLeft} days left in your free trial!`}
										</Text>
										<Button
											size='sm'
											variant='outline'
											radius={'md'}
											onClick={handleUpgradeNow}
											sx={() => ({
												color: '#3b368f',
												backgroundColor: 'white',
												borderColor: 'white',
												'&:hover': {
													backgroundColor: 'white',
													color: '#3b368f',
												},
												transition: 'color 0.2s, background-color 0.2s',
											})}
										>
											Upgrade Now
										</Button>
									</Group>
								</Alert>
							)}
						<Box
							sx={(theme) => ({
								paddingTop: theme.spacing.md,
								paddingLeft: theme.spacing.md,
								paddingRight: theme.spacing.md,
								overflow: 'auto',
								overflowY: 'hidden',
								flex: 1,
							})}
						>
							<Outlet />
						</Box>
					</Box>
				)}
			</ErrorBoundary>
		</AppShell>
	);
}
