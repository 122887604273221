import React, { useEffect, useState } from 'react';
import {
	Paper,
	Button,
	Textarea,
	createStyles,
	Stack,
	Group,
	Menu,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { currentMeeting } from '../../Atoms/meetingAtom';
import { RichTextEditor } from '@mantine/tiptap';
import { updateMeeting } from '../../api/api';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../constants';
import { replaceApostropheHTMLEntityWithSingleQuote } from '../../_utils/handy-functions';
import { currentUserMeetings } from '../../Atoms/userAtoms';
import { RichTextEditorComponent } from '../RichTextEditorComponent';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { logger } from '../../helpers/logger';

const useStyles = createStyles((theme) => ({
	submitButton: {
		backgroundColor: theme.colors?.primary[8],
		// width:           '100%',
		// fontSize:        16,
		marginTop: 8,
	},
	'&:hover': {
		backgroundColor: theme.colors?.primary[8],
	},
}));
export default function EditMeetingTextbox(data: {
	field: string;
	title: string;
}) {
	const { field } = data;
	const [meeting, setMeeting] = useRecoilState(currentMeeting);
	const [meetings, setMeetings] = useRecoilState(currentUserMeetings);
	const stringWithoutHTMLEntities = replaceApostropheHTMLEntityWithSingleQuote(
		meeting[field]
	);
	const [richTextValue, setRichTextValue] = useState<string>(meeting[field]);
	const form = useForm({
		initialValues: {
			[field]: stringWithoutHTMLEntities,
		},
		validate: {
			[field]: (value: string) =>
				value.length === 0 ? 'Field required.' : null,
		},
	});

	useEffect(() => {
		form.setValues({
			[field]: stringWithoutHTMLEntities,
		});
		setRichTextValue(meeting[field]);
	}, [meeting]);

	const updateMeetingField = async () => {
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatedMeetingResponse = await updateMeeting(
				{ [field]: richTextValue || form.values[field] },
				meeting.id
			);
			showSuccessNotification({
				message: 'Meeting was successfully updated!  Great work!',
			});
			// update meeting
			setMeeting(updatedMeetingResponse.data.data);
			// update meetings[] state
			setMeetings(
				meetings.map((meeting) =>
					meeting.id === updatedMeetingResponse.data.data.id
						? updatedMeetingResponse.data.data
						: meeting
				)
			);
		} catch (err: unknown) {
			logger('error', 'Error updating meeting field', err);
			showFailureNotification({
				message: 'Sorry, failed to update meeting field. Please try again.',
			});
		}
	};

	const { classes } = useStyles();

	return (
		<Paper p='md' shadow='sm' radius='md'>
			{['agenda', 'summary', 'longSummary'].includes(field) ? (
				<RichTextEditorComponent
					field={field}
					placeholder={`Please add a ${field}.`}
					{...form.getInputProps(field)}
					richTextValue={richTextValue}
					setRichTextValue={setRichTextValue}
					meeting={meeting}
				/>
			) : (
				<Textarea
					id='rte'
					// value={textValue}
					// onChange={setTextValue}
					placeholder={meeting[field]}
					{...form.getInputProps(field)}
				/>
			)}
			<Button
				onClick={updateMeetingField}
				leftIcon={<FontAwesomeIcon icon={faPlus} />}
				mt='xl'
				className={classes.submitButton}
			>
				Submit
			</Button>
		</Paper>
	);
}
